.view-form-asp-employee {
  margin-top: 120px;
  padding: 29px 60px;
  margin-bottom: 60px;
}
.view-form-asp-employee .title-view-asp {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-top: 17px;
  margin-bottom: 16px;
}
.slick-prev {
  top: 40%;
}

/* Sticky Footer */
/* .footer-sticky-asp-end {
  height: 72px;
  width: 100% !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  z-index: 999;
} */
.footer-sticky-asp-done {
  height: 72px;
  width: 100% !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  padding-right: 55px;
  bottom: 0px;
  z-index: 999;
}
.footer-sticky-asp-submit {
  height: 72px;
  width: 100% !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding-left: 120px;
  padding-right: 120px;
  bottom: 0px;
  z-index: 999;
}
.button-footer-sticky-done {
  display: flex;
  margin: 16px 60px;
  padding: 9px 45px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  background-color: #095dff;
  border-radius: 4px;
  cursor: pointer;
}
.button-footer-sticky-sign {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #095dff;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  cursor: pointer;
}
.button-footer-sticky-cancel {
  display: flex;
  width: 148px;
  padding: 9px 16px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #454545;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;
}
.view-form-asp-employee .section-container-asp-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}
.view-form-asp-employee .form-view-header {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}

.spinner-asp {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 60vh;
  background-color: #fff;
  margin-top: 12px;
}
