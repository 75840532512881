.module_leaderboard {
    display: flex;
    height: 32px;
    padding: 4px 12px;
    margin-right: 12px;
    align-items: center;
    width: auto;
    justify-content: space-around   ;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    background:  #FFF;
    color:  #454545;
    font-family: "Proximanova-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px; /* 140% */
}
.module_title{
    color:  #454545;
    font-family: "Proximanova-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px; /* 140% */
    cursor: pointer;
}

.locked_clear_game {
    padding: 3px 16px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: #8c8c8c;
    border: none;
    background: none;
    font-family: "Proximanova-Semibold";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    /* 140% */
    cursor: pointer;
  }
  .locked_clear_game_active {
    padding: 3px 16px 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: #0642B5;
    border: none;
    background: none;
    font-family: "Proximanova-Semibold";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    /* 140% */
    cursor: pointer;
  }
  