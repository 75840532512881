.total-weight-green {
  color: #008000;
}

.total-weight-red {
  color: #e65a14;
}
.performanceContainer {
  margin-left: 0;
}
.performance-body .ant-tabs-tab-active {
  border: 0 !important;
  border-bottom: 0 !important;
  background-color: transparent !important;
}

.performance-header-empDetail {
  margin: 18px 60px 0px 65px;
  /* margin: 8px 60px 20px 40px; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 16px;
  gap: 11px;
}

.team-performanceContainer {
  margin-top: 95px;
}

.breadcrum_performance {
  margin-top: 145px;
  margin-left: 55px;
  margin-right: 55px;
}

.performance_success-alert {
  .ant-alert-message {
    margin-left: 15px;
  }
}

.menu-icon button:first-child {
  display: none;
}

.menu-icon button:nth-child(2) {
  border-start-start-radius: 6px !important;
  border-end-start-radius: 6px !important;
}

h5 {
  margin: 0;
}

.left-side,
.right-side {
  display: flex;
  align-items: center;
}

.left-side {
  gap: 10px;
}

.right-side {
  gap: 15px;
}

.performance-body {
  margin: 0 60px 60px 60px;
}

.today-weight {
  background-color: #fff7e6;
  display: flex;
  padding: 11px 12px 0px 12px;
  position: relative;
  margin-bottom: 23px;
  border-radius: 8px;
}

.weight-center {
  margin-left: 16px;
  padding-bottom: 16px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.success-close-icon {
  position: absolute;
  top: 157px;
  right: 70px;
  cursor: pointer;
}

.empty-goals {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
}

.title {
  margin-bottom: 16px;
}

.behind,
.behind:hover {
  color: #c00 !important;
  background-color: #fae6e6 !important;
  box-shadow: none;
}

.Completed,
.Completed:hover {
  color: #1976d2 !important;
  background-color: #e8f1fb !important;
}

.on-track,
.on-track:hover {
  color: #008000 !important;
  background-color: #e6f2e6 !important;
}

.notstarted,
.notstarted:hover {
  color: #fff !important;
  background-color: #9c9fa9 !important;
}

.view-goal.smart-goal_preview .status-section.behind-bg {
  background: #fae6e6;
  color: #cc0000;
  padding: 6px 16px;
  height: auto;
  width: 80px;
}
.view-goal.smart-goal_preview .status-section.behind-bg p {
  color: #cc0000;
}
.view-goal.smart-goal_preview .status-section.completed-bg {
  background: #e8f1fb;
  color: #1976d2;
  padding: 6px 16px;
  height: auto;
  width: 110px;
}
.view-goal.smart-goal_preview .status-section.completed-bg p {
  color: #1976d2;
}
.view-goal.smart-goal_preview .status-section.notstarted-bg {
  background: #9c9fa9;
  color: #fff;
  padding: 6px 16px;
  height: auto;
  width: 120px;
}
.view-goal.smart-goal_preview .status-section.notstarted-bg p {
  color: #fff;
}
.view-goal.smart-goal_preview .status-section.ontrack-bg {
  background: #e6f2e6;
  color: #008000;
  padding: 6px 16px;
  height: auto;
  width: 100px;
}
.view-goal.smart-goal_preview .status-section.ontrack-bg p {
  color: #008000;
}

.tbl-inner-section .inner-table-milestone {
  display: flex;
  align-items: center;
}

.tbl-inner-section .inner-table-milestone span {
  margin-left: 10px;
}

.tbl-outer-section table thead tr .ant-table-cell {
  background: transparent !important;
  border: 0;
}

.tbl-outer-section table tbody tr .ant-table-cell {
  /* padding: 40px; */
  background: transparent;
  font-family: "ProximaNova-Regular";
  border-bottom: 0px solid !important;
}
.tbl-outer-section table tbody tr.ant-table-row td {
  border-top: 1px solid #e1e2e4 !important;
}
.tbl-outer-section table tbody tr.ant-table-row td table tr td {
  border-top: 0px solid !important;
}
.tbl-outer-section table thead tr .ant-table-cell::before {
  background: transparent !important;
}

.tbl-outer-section table tbody tr:first-child td {
  border-top: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr:first-child td:first-child {
  border-top-left-radius: 8px !important;
}

.tbl-outer-section table tbody tr:first-child td:last-child {
  border-top-right-radius: 8px !important;
}

.tbl-outer-section table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.tbl-outer-section table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row:last-child
  td.ant-table-cell {
  border-bottom: 1px solid #e1e2e4 !important;
  border-radius: 0px;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row:last-child
  td.ant-table-cell
  table
  td {
  border-bottom: 0px solid #e1e2e4 !important;
  border-radius: 0px;
}
.tbl-outer-section table tbody tr.ant-table-row:nth-last-child(2) td {
  border-bottom: 1px solid #e1e2e4 !important;
}
.tbl-outer-section table tbody tr.ant-table-row:nth-last-child(1) td {
  border-bottom: 1px solid #e1e2e4 !important;
}
.tbl-outer-section table tbody table tr.ant-table-row:nth-last-child(2) td {
  border-bottom: 0px solid #e1e2e4 !important;
}
.tbl-outer-section table tbody table tr.ant-table-row:nth-last-child(1) td {
  border-bottom: 0px solid #e1e2e4 !important;
}
.tbl-outer-section table tbody tr td:first-child {
  border-left: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr td:last-child {
  border-right: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr:last-child td {
  border-bottom: 1px solid #d9d9d9;
}

.tbl-outer-section table thead tr:hover th {
  background: transparent !important;
}

.tbl-outer-section table tbody tr:hover td {
  background: transparent !important;
}

/* start inner section */
.tbl-outer-section .tbl-inner-section table thead tr .ant-table-cell {
  background: #f5f5f5 !important;
  border: 0;
  padding: 16px 16px;
  border-bottom: 0px solid !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr .ant-table-cell {
  padding: 16px 16px;
  background-color: #f5f5f5 !important;
  border: 0px solid !important;
}

.tbl-outer-section .tbl-inner-section table thead tr .ant-table-cell::before {
  background: transparent !important;
}

.tbl-outer-section
  .tbl-outer-section
  .tbl-inner-section
  table
  tbody
  tr:first-child
  td {
  border-top: 0 solid #d9d9d9 !important;
  background-color: #f5f5f5 !important;
}

.tbl-outer-section
  .tbl-inner-section
  table
  tbody
  tr:first-child
  td:first-child {
  border-top-left-radius: 0 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:first-child td:last-child {
  border-top-right-radius: 0 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.tbl-outer-section .tbl-inner-section table tbody tr td:first-child {
  border-left: 0 solid #d9d9d9;
  background-color: #f5f5f5;
  width: 50%;
  padding-right: 70px;
}

.tbl-outer-section .tbl-inner-section table tbody tr td:last-child {
  border-right: 0 solid #d9d9d9;
  background-color: #f5f5f5;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td {
  border-bottom: 0 solid #d9d9d9;
  background-color: #f5f5f5;
}

.tbl-outer-section .tbl-inner-section table thead tr:hover th {
  background: #f5f5f5 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:hover td {
  background: #f5f5f5 !important;
}

.tbl-inner-section table tbody tr .ant-table-cell .ant-space-item a {
  color: #095dff;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
}
.year-selection .ant-space-item {
  color: #e65a14;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

/* end inner section */

.form-footer-section {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.expand {
  cursor: pointer;
}

.year-selection,
.smart {
  color: #e65a14;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.milestone-left h3 {
  margin-top: 5px;
  font-size: 24px;
  font-family: "ProximaNova-Bold";
  margin-bottom: 5px;
  line-height: 28.8px;
}

.milestone-left span {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.info-alert {
  margin-bottom: 16px;
}

.info-alert .ant-alert {
  padding: 10px 16px;
  border: none;
}

.info-alert .ant-alert .ant-alert-message {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  color: #1976d2;
}
.performance-header .right-side button.add_goal-btn {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 20px;
  background: #0642b5;
  height: 40px;
  border-radius: 8px;
}
.performance-header .right-side button.ant-btn {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  border-radius: 8px;
  background: #0642b5;
  border-color: #0642b5;
  color: #fff;
}
.performance-header .right-side button.ant-btn:hover {
  background: #0642b5;
  border-color: #0642b5;
  color: #fff;
}
.performance-header .right-side .menu-icon button.ant-btn {
  width: 40px;
  height: 40px;
  background: #e6efff;
}
.performance-header .right-side .menu-icon button.ant-btn svg {
  color: #0642b5;
}
/* .right-side button:hover {
    color: #0642b5;
    border-color: #0642b5;
    background: transparent;
} */
.new-goal .certify_otr-field {
  padding: 0;
  bottom: 15px;
}

.business {
  margin-top: 16px;
}

.new-goal .ant-select-selector {
  height: 52px !important;
  padding: 11px 11px !important;
}

.left-side h5 {
  font-size: 24px;
  font-family: "ProximaNova-Bold";
  margin: 0;
  color: #0a0a0a;
}

.performance-header .left-side a {
  font-size: 20px;
  font-family: "ProximaNova-Bold";
}

.performance-header .left-side a .ant-space-item {
  display: flex;
  transition: 0.5s;
}

.performance-body .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #095dff !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn:hover,
.performance-body .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #095dff !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: "ProximaNova-Regular";
}

.ant-tabs-content-holder .ant-tabs-tabpane p span {
  color: #000;
  font-family: "ProximaNova-Regular";
}

.weight-center h5 {
  font-size: 16px;
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
}

.weight-center p {
  font-size: 14px;
  color: #000;
  font-family: "ProximaNova-Regular";
  display: table;
  width: 85%;
}

.weight-center p span.smart {
  color: #e65a14;
  font-family: "ProximaNova-Semibold";
}

.weight-center button.ant-btn {
  border-color: #0642b5;
  border-radius: 8px;
}

.weight-center button.ant-btn span {
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  color: #0642b5;
  line-height: 20px;
}

.today-weight .weight-left {
  display: flex;
}

.business h5.title {
  font-size: 16px;
  color: #000;
  font-family: "ProximaNova-Semibold";
  margin-bottom: 0px;
}

.business .ant-table-content table {
  border: 0px solid;
}

.business .ant-table-content table thead tr th {
  border-bottom: 0px solid #b0b3ba !important;
  font-family: "ProximaNova-Bold";
  color: #262626;
  font-weight: 700;
}
.cascade-third-section
  .ant-table-content
  thead.ant-table-thead
  tr
  th.ant-table-cell:first-child {
  padding-left: 28px;
}
.cascade-third-section
  .ant-table-content
  tbody.ant-table-tbody
  tr
  td.ant-table-cell:first-child {
  padding-left: 28px;
}
.cascade-third-section
  .ant-table-content
  tbody.ant-table-tbody
  tr
  td.ant-table-cell {
  background: #fff;
  border-bottom: 1px solid #e1e2e4 !important;
}
.business .ant-table-content table button {
  box-shadow: none;
  font-family: "ProximaNova-Semibold";
}

.inner-table-milestone span.title-mile {
  font-size: 16px;
  color: #232a3d;
  font-family: "ProximaNova-Semibold";
  width: 210px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.inner-table-milestone span.cont-mile {
  font-size: 14px;
  color: #2b3348;
  font-family: "ProximaNova-Regular";
  display: -webkit-box;
  max-width: 270px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-table-milestone img {
  width: 16px;
  margin: 3px 0 0;
}

.inner-table-milestone {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  line-height: 24px;
}

.milestone-dates-section .ant-form-item .ant-picker {
  height: 52px;
}

.milestone-form-details .goal-row-two-section {
  margin-right: 15%;
}

.milestone-form-details .goal-row-two-section.ant-form-item-label label {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #1f1f1f;
  display: inline;
  position: relative;
}

.milestone-form-details .ant-form-item-required::before {
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 12px !important;
}

.title-delete-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 20px;
}

.title-delete-section h4 {
  margin: 0;
  font-size: 16px;
  font-family: "ProximaNova-SemiBold";
  line-height: 22.4px;
}

.title-delete-section button {
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0;
  display: flex;
}

.delete-milestone-popup .ant-modal-body h5 {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  line-height: 28.8px;
  margin: 20px 0 0 10px;
}
.delete-milestone-popup .ant-modal-body h6 {
  margin-bottom: 0px;
}

.delete-milestone-popup .ant-modal-body p {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 16px;
}

.delete-milestone-popup .button-section {
  display: flex;
  justify-content: center;
}

.delete-milestone-popup {
  width: 400px;
}

.delete-milestone-popup .button-section button {
  width: 48%;
  height: 40px;
}

.delete-milestone-popup .button-section button.ant-btn-default {
  margin-right: 32px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: #fff;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  line-height: 22.4px;
  color: #5c5c5c;
}

.delete-milestone-popup .button-section button.ant-btn-primary {
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: #0642b5;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  line-height: 22.4px;
  color: #fff;
}

.menu-icon button span.anticon {
  transform: rotate(90deg);
}

.performance-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "ProximaNova-Semibold";
}

/* .tbl-inner-section {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      min-height: 48px !important;
      max-height: 200px !important;
    } */
.ant-picker-date-panel {
  width: 303px !important;
}

.ant-picker-content {
  border: none;
}

.performance-header-empDetail .detail-username {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  font-family: "ProximaNova-Bold";
}
.cascade-third-section p {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #232a3d;
  font-family: "ProximaNova-Regular";
}
.cascade-third-section p.Behind-bg {
  color: #cc0000;
  font-family: "ProximaNova-SemiBold";
}
.cascade-third-section p.Completed-bg {
  color: #1976d2;
  font-family: "ProximaNova-SemiBold";
}
.cascade-third-section p.OnTrack-bg {
  color: #008000;
  font-family: "ProximaNova-SemiBold";
}

.empDetialBreadcrumb {
  margin-left: 65px;
  margin-top: 30px;
}

.new-goal .certify_otr-field .ant-col.ant-form-item-label label {
  font-size: 16px;
  color: #1f1f1f;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}

.new-goal .certify_otr-field .ant-col.ant-form-item-label label:before {
  font-size: 12px !important;
  top: 0;
}

.new-goal .certify_otr-field .ant-col.ant-form-item-control textarea {
  border: 1px solid #bfbfbf;
  color: #595959;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px;
  line-height: 24px;
}

.new-goal
  .certify_otr-field
  .ant-col.ant-form-item-control
  textarea::placeholder,
.new-goal .certify_otr-field .ant-col.ant-form-item-control input::placeholder {
  font-family: "ProximaNova-Regular" !important;
}

.new-goal .certify_otr-field .ant-col.ant-form-item-control textarea:-moz-placeholder,
/* Firefox 18- */
.new-goal .certify_otr-field .ant-col.ant-form-item-control textarea::-moz-placeholder,
/* Firefox 19+ */
.new-goal .certify_otr-field .ant-col.ant-form-item-control textarea:-ms-input-placeholder,
/* IE 10+ */
.new-goal .certify_otr-field .ant-col.ant-form-item-control textarea::-webkit-input-placeholder,
/* Webkit based */
.new-goal .certify_otr-field .ant-col.ant-form-item-control textarea::placeholder {
  /* Modern browsers */
  font-family: "ProximaNova-Regular" !important;
}

.new-goal .certify_otr-field .ant-form-item:last-child {
  padding-bottom: 0;
}

.new-goal .button-area {
  /* margin-top: 0; */
}
.cascade-right-button {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
.cascade-right-button .cascade-btn-style {
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: #0642b5;
  height: 40px;
  width: 111px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 20px;
  margin-right: 0;
}
.cascade-right-button .ant-space-compact.ant-dropdown-button {
  margin-left: 18px;
}
.cascade-right-button .cascade-btn-style:hover {
  box-shadow: none;
  color: #fff;
  border-color: #0642b5;
}
.cascade-right-button .menu-icon .ant-btn-compact-last-item {
  width: 55px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #095dff;
  background: #fff;
}
.cascade-right-button .menu-icon .ant-btn-compact-last-item span svg {
  width: 23px;
  height: 23px;
  fill: #095dff;
}
.manager-view-container {
  margin: 75px 0 0;
}
.cascade-third-section {
  margin: 0 65px;
}
.cascading-goal-card {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
}
.selected-goals-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6efff;
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.selected-goals-count {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}
.selected-goals-action {
  display: flex;
  align-items: center;
}
.selected-goals-action button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-goals-action button.secondary-btn {
  width: 90px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: transparent;
  color: #474747;
  font-family: "ProximaNova-SemiBold";
  font-size: 12px;
  line-height: 17px;
  margin-right: 16px;
}
.selected-goals-action button.primary-btn {
  width: 93px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-SemiBold";
  font-size: 12px;
  line-height: 17px;
}
.selected-goals-action button.primary-btn:hover {
  background: #0642b5;
  border-color: #0642b5;
  color: #fff;
}
.manager-view-container .ant-checkbox .ant-checkbox-inner {
  border: 1.5pt solid #454545;
}
.select-all-check-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 22px;
  padding: 0 24px;
}
.manager-cascade-popup .ant-modal-content .select-employee-list {
  font-size: 18px;
  line-height: 25px;
  color: #000;
  font-family: "ProximaNova-SemiBold";
}
.select-all-check-box label.ant-checkbox-wrapper {
  display: flex;
}
.select-all-check-box label.ant-checkbox-wrapper span.ant-checkbox {
  order: 2;
  padding-right: 0;
}
.select-all-check-box label.ant-checkbox-wrapper span {
  order: 1;
  color: #595959;
  font-size: 16px;
  line-height: 24px;
  padding-right: 12px;
  font-family: "ProximaNova-Regular";
}
.select-all-check-box
  label.ant-checkbox-wrapper
  span.ant-checkbox
  span.ant-checkbox-inner {
  border-color: #454545;
}
.select-all-check-box .cascade-checkbox-area {
  display: flex;
  flex-direction: row-reverse;
  align-items: center !important;
  width: auto !important;
}
.select-all-check-box .cascade-checkbox-area label {
  margin-right: 12px;
  color: #595959;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.select-all-check-box .cascade-checkbox-area input {
  width: 20px;
  height: 20px;
  border: 1pt solid #454545;
  margin: 0;
}
.cascade-checkbox-area {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
.cascade-checkbox-area label {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1pt solid #e1e2e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cascade-checkbox-area label span.ant-checkbox {
  order: 2;
  align-self: center;
}
.cascade-checkbox-area label span.ant-checkbox span.ant-checkbox-inner {
  border-color: #454545;
}
.cascading-profile {
  display: flex;
}
.cascading-profile-img {
  width: 47px;
  height: 47px;
  margin-right: 16px;
}
.cascading-profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.cascading-profile .avatar-container {
  margin-right: 16px;
}
.cascade-checkbox-area input {
  width: 15px;
  height: 15px;
  border: 1pt solid #454545;
  margin: 0 2px 0 0;
}
.cascading-profile-title {
  color: #1f1f1f;
  font-family: "ProximaNova-SemiBold";
  font-size: 16px;
  line-height: 22.4px;
}
.cascading-profile-text .cascading-profile-desc {
  font-size: 14px;
  line-height: 20px;
  color: #595959;
  font-family: "ProximaNova-Regular";
}
.cascading-profile-des {
  color: #757a87;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}
.cascading-profile-list {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
}
.manager-cascade-popup.ant-modal {
  width: 606px !important;
  height: auto;
}
.manager-cascade-popup .ant-modal-content {
  padding: 0 10px 20px 0;
  height: auto;
  margin: 0 0 0 0;
}
.manager-cascade-popup .ant-modal-header {
  padding: 20px 24px 0;
}
.manager-cascade-popup .ant-modal-header .ant-modal-title {
  font-size: 24px;
  line-height: 29px;
  font-family: "ProximaNova-Bold";
}
.manager-cascade-popup button.ant-modal-close {
  top: 22px;
  right: 25px;
  left: auto;
}
.manager-search-area {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 0 24px;
  align-items: center;
}
.manager-cascade-popup button.ant-modal-close svg {
  width: 18px;
  color: #000;
  height: 18px;
}
.manager-search-area .search-bar {
  width: 420px;
}
.manager-cascade-popup .manager-search-area .ant-select-selector {
  height: auto;
  border: 0px solid;
  box-shadow: none;
  padding-left: 35px;
  padding-inline-end: 10px;
  width: 100%;
}
.manager-cascade-popup .manager-search-area .ant-space {
  border: 1px solid;
  border-radius: 8px;
  border-color: #bfbfbf;
  padding: 2px 8px;
}
.manager-cascade-popup
  .manager-search-area
  .ant-space
  .ant-select.ant-select-show-search {
  display: flex;
  align-items: center;
}
.manager-cascade-popup
  .manager-search-area
  span.ant-select-selection-placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  opacity: 0.7;
  padding-left: 35px;
}
.manager-cascade-popup .manager-search-area .ant-space span.ant-select-arrow {
  left: 10px;
  right: auto;
}
.manager-cascade-popup
  .manager-search-area
  .ant-space
  span.ant-select-arrow
  svg {
  font-size: 20px;
  color: #595959;
}
.manager-search-area .ant-input {
  border-radius: 4px !important;
  border: 1px solid #bfbfbf;
  background: #fff;
  padding-left: 40px;
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
}
.cascadeBtn {
  border-radius: 8px;
  background: #0642b5;
  border-color: #0642b5;
  color: #fff;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  width: 130px;
  height: 40px;
  float: right;
  margin-left: 20px;
}
.cascadeBtn[disabled] {
  border-radius: 8px;
  background: #e2e2e2;
  border-color: #e2e2e2;
  color: #5c5c5c;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  width: 130px;
  height: 40px;
  float: right;
  margin-left: 20px;
}
.cascading-profile-list {
  padding: 0 12px 20px 24px;
}
.Behind-bg {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  color: #c00;
  border-radius: 8px;
  background: #fae6e6;
  padding: 6px 16px;
  text-align: center;
  width: 77px;
}
.Completed-bg {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  color: #1976d2;
  border-radius: 8px;
  background: #e8f1fb;
  padding: 6px 16px;
  text-align: center;
  width: 107px;
}
.OnTrack-bg {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  color: #008000;
  border-radius: 8px;
  background: #e6f2e6;
  padding: 6px 16px;
  text-align: center;
  width: 89px;
}

.tbl-outer-section table tbody tr.ant-table-expanded-row {
  background: transparent !important;
}
.tbl-outer-section table tbody tr.ant-table-expanded-row td.ant-table-cell {
  padding: 0 !important;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td.ant-table-cell
  .ant-table {
  margin: 0 !important;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  .ant-table-content
  table {
  border: 0px solid;
  padding: 20px;
}
.tbl-outer-section table tbody tr.ant-table-expanded-row td table thead {
  background: #f5f5f5 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  display: block;
}
.tbl-outer-section table tbody tr.ant-table-expanded-row td table tr {
  padding: 20px 20px 14px 20px;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  th.ant-table-cell:first-child {
  text-align: left;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  th.ant-table-cell {
  width: 20%;
  text-align: left;
  padding: 0;
}

.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  td.ant-table-cell {
  width: 20%;
  text-align: left;
  padding: 0;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  th.ant-table-cell:first-child {
  width: 50%;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  td.ant-table-cell:first-child {
  width: 50%;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  .ant-table-content
  table
  tr
  th.ant-table-cell {
  width: 12%;
  max-width: initial;
  text-align: left;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  .ant-table-content
  table
  tr
  th.ant-table-cell:first-child {
  width: 40%;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  .ant-table-content
  table
  tr
  td.ant-table-cell {
  width: 12%;
  max-width: initial;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  .ant-table-content
  table
  tr
  td.ant-table-cell:first-child {
  width: 40%;
}
.tbl-outer-section table tbody tr.ant-table-expanded-row td table tbody {
  background: #f5f5f5 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  display: block;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  td.ant-table-cell:first-child {
  text-align: left;
}
.tbl-outer-section
  table
  tbody.ant-table-tbody
  tr.ant-table-expanded-row
  td
  .ant-table-empty
  table
  tr
  td.ant-table-cell {
  width: 100%;
}
.tbl-outer-section
  table
  tbody.ant-table-tbody
  tr.ant-table-expanded-row
  td
  .ant-table-empty
  table
  thead
  tr
  td.ant-table-cell {
  width: auto;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  .ant-table-empty
  table
  tr.ant-table-placeholder {
  padding: 0;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  p.Behind-bg {
  color: #cc0000;
  background: transparent;
  text-align: left;
  width: auto;
  padding: 0;
  margin: 0;
}
.tbl-outer-section
  table
  tbody
  tr.ant-table-expanded-row
  td
  table
  tr
  p.Completed-bg {
  color: #008000;
  background: transparent;
  text-align: left;
  width: auto;
  padding: 0;
  margin: 0;
}
.status-bg-styles {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  border-radius: 8px;
  padding: 6px 16px;
  text-align: center;
  width: 89px;
}
.weight-center .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #0642b5;
  border-color: #0642b5;
  background: transparent;
}

.milestone-status {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}
.tbl-inner-section .inner-table-milestone {
  align-items: baseline;
}
.cascade-card-list {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 40px;
}
.cascade-card-list .business {
  padding: 0;
  background: transparent;
  border-radius: 0;
  margin-top: 0;
}
.breadcrumb-container-manager {
  margin-top: 80px;
}
.quarterly-checkin-manager-container {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 10px 60px;
  border-radius: 8px;
}
.milestone-text-styles {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
  display: contents;
}
.managercascade-multipleselect .ant-select-selection-item {
  background: #f0f0f0 !important;
}
.managercascade-multipleselect .ant-select-selection-overflow-item {
  margin-right: 8px;
  margin-bottom: 8px;
}
.manager-cmnt-sec-input-box {
  background-color: #f0f0f0;
}
.draft-popup-heading-image {
  display: block;
  margin: 0 auto 20px;
  text-align: center;
}
.draft-popup-section .button-section .ant-btn-default {
  border-color: #0642b5 !important;
}
