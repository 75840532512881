.dex-tilte {
  font-family: "ProximaNova-Extrabold";
  font-size: 16px;
  letter-spacing: 0.48px;
  margin-left: 8px;
}

.logout-img {
  width: 12px;
}

.logout-icon {
  margin-top: "8px";
  cursor: pointer;
}

.search-bar {
  width: 264px;
}

.search-bar .ant-input-wrapper {
  position: relative;
}

.search-bar
  :where(.css-dev-only-do-not-override-1fviqcj).ant-input-affix-wrapper-focused,
:where(.css-dev-only-do-not-override-1fviqcj).ant-input-affix-wrapper:focus {
  z-index: 0;
  box-shadow: none;
}

.search-bar .ant-input-wrapper .ant-input-affix-wrapper {
  padding: 8px 10px 8px 32px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #b1b4bb;
  background: #fff;
}

.search-bar .ant-input-group-addon {
  position: absolute;
  top: 0;
  left: 0;
}

.search-bar .ant-input-group-addon .ant-btn {
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.search-bar .ant-input-wrapper .ant-input-affix-wrapper .ant-input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  font-family: "ProximaNova-Regular";
}

.ant-input-affix-wrapper .ant-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  font-family: "ProximaNova-Regular";
}

.search-icon {
  width: 20px;
  height: 20px;
}

.search-bar .ant-input-suffix {
  cursor: pointer;
}

.chat-icon-area {
  position: relative;
}

a.chat-icon-area.help-desk {
  display: flex;
  background: transparent;
  padding: 0;
  border-radius: 50%;
  transition: 0.5s;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

a.chat-icon-area.help-desk:hover {
  background: #05338c;
}

.dots-btn {
  display: flex;
  background: transparent;
  height: 40px !important;
  width: 40px;
  border-radius: 50%;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
}

.dots-btn:hover {
  background: #f5f5f5;
}

.helpdesk-tooltip .ant-tooltip-content .ant-tooltip-inner {
  height: 28px;
  width: 92px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 14%), 0px 3px 1px rgb(0 0 0 / 12%),
    0px 1px 5px rgb(0 0 0 / 20%);
  text-align: center;
  color: #000;
  border-radius: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "ProximaNova-Regular";
}

.helpdesk-tooltip .ant-tooltip-arrow:before {
  background: #fff;
}

.chat-notification {
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
  right: 0;
  background: #cc0000;
  border-radius: 25px;
}

.profile-section .profile-btn {
  background: #0642b5 !important;
  border: 0;
  box-shadow: none;
  padding: 0;
}

.profile-img {
  width: 32px;
  height: 32px;
}

button.ant-btn.dots-btn.list-btn div {
  box-shadow: none !important;
}

.ant-popover-content {
  margin-right: 30px;
  /* width: 390px; */
}

.ant-popover-inner {
  padding: 16px 16px 24px;
}

.profile-popover .ant-popover-content {
  margin-right: 30px;
  width: 308px;
}

.profile-popover .ant-popover-inner {
  padding: 0px;
}

.user-details-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

.name-area-padding {
  padding: 17.41px;
}

.username-area span.sub-menu-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.username-area {
  display: flex;
  align-items: center;
  margin: 0;
}

.popover-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2pt solid #d9dbe3;
}

.user-credentials {
  margin-left: 8px;
}

.username {
  margin: 0 0 4px;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}

.usermail {
  margin: 0;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
}

.my-profile-content .line-separator {
  margin: 0px 16px;
  background: #e9eaeb;
  height: 1pt;
}

.common-bold-text {
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  font-style: normal;
  line-height: 22.4px;
  margin-bottom: 12px;
}

.profile-menu-section ul {
  margin: 0;
  padding: 0;
}

.profile-menu-section ul li {
  list-style: none;
  padding: 16px 12px 0px 12px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.profile-menu-section ul li .item-container {
  border-bottom: 1pt solid #e9eaeb;
}

.profile-menu-section .item-padding {
  margin-bottom: 12px !important;
}

.profile-menu-section ul li:hover {
  background: #f5f5f5;
}

.profile-menu-section ul li a {
  color: #000;
}

.profile-menu-section ul li a > .user-details-area {
  margin: 0;
}

.profile-menu-section ul li a > .user-details-area > .username-area {
  margin: 0;
}

.profile-menu-section
  ul
  li
  a
  > .user-details-area
  > .username-area
  .sub-menu-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-menu-icon .profile-icon-myprofile {
  height: 24px;
  width: 24px;
}

.profile-menu-section
  ul
  li
  a
  > .user-details-area
  > .username-area
  .sub-menu-text {
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  font-family: "ProximaNova-Regular";
}

.logout-section {
  padding: 14px 0 0 2px;
}

.logout-section a {
  display: flex;
  align-items: center;
}

.popoverlogout-icon {
  margin-right: 11px;
  display: flex;
}

.popoverlogout-text {
  font-size: 14px;
  line-height: 19.6px;
  font-family: "ProximaNova-Regular";
  color: #c00;
}

/* tabs */
.ant-tabs-tab span {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ant-tabs-tab span img {
  margin: 0 10px 0 0;
}

.ant-tabs-tab span {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "ProximaNova-Semibold";
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-color: #0642b5;
  border-width: 4px;
}

.ant-tabs-tab.ant-tabs-tab-active span {
  color: #0642b5;
}

.ant-tabs .ant-tabs-nav:before {
  border: 0px solid;
}

.ant-tabs-tab.ant-tabs-tab-active span img.tabicons {
  display: none;
}

.ant-tabs-tab.ant-tabs-tab-active span img.tabiconsactive {
  display: block;
}

.ant-tabs-tab span img.tabicons {
  display: block;
}

.ant-tabs-tab span img.tabiconsactive {
  display: none;
}

.ant-tabs-nav-wrap .ant-tabs-tab {
  padding-bottom: 25px;
  padding-top: 5px;
}

.ant-tabs-nav-wrap .ant-tabs-tab:nth-child(3) img {
  width: 16px;
}

.ant-layout .ant-layout-header .ant-space.tab_main-otr {
  position: absolute;
  width: 100%;
  left: 0;
}

.ant-layout .ant-layout-header .ant-space.tab_main-otr .ant-tabs-nav {
  display: table;
  margin: 0 auto;
  position: relative;
  right: 130px;
}

.ant-layout .ant-layout-header .ant-space.tab_main-otr .ant-space-item {
  width: 100%;
}

.list-btn {
  background: none;
  border: 0;
  box-shadow: none;
  padding: 0;
  height: 24px;
}

.headerTabs ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.headerlinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  line-height: 19.6px;
  font-family: "ProximaNova-Regular";
  color: #f0f0f0;
}

.headertabIcon {
  margin-right: 8px;
  display: flex;
}

.headerlinksactive {
  color: #fff;
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
  border-radius: 8px;
  background: #05338c;
  padding: 12px;
  height: 36px;
  margin-left: 10px;
}

.headerlinks:hover {
  /* color: #fff; */
  cursor: pointer;
  /* font-family: "ProximaNova-Semibold";
  border-radius: 8px;
  background: #05338c;
  padding: 12px;
  height: 36px; */
}

.headerlinksactive:hover {
  color: #fff;
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
  border-radius: 8px;
  background: #05338c;
  padding: 12px;
  height: 36px;
}

.orangegradient {
  background: rgb(237, 105, 45);
  background: linear-gradient(
    90deg,
    rgba(237, 105, 45, 1) 0%,
    rgba(255, 183, 99, 1) 100%
  );
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 12px;
}

.white-bg {
  background: #fff;
  border: 1pt solid #d9dbe3;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 12px;
}

.listcontent .ant-popover-content {
  width: 288px;
}

.listcontent .ant-popover-inner {
  padding: 24px 20px 0;
}

.list-menu-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nine-dots-container .list-menu-area {
  justify-content: flex-start;
}

.list-menu-icon p {
  font-size: 18px;
  font-style: normal;
  font-family: "ProximaNova-Extrabold";
  line-height: 25.2px;
  color: #fff;
}

.list-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: #000;
}

.list-menu-item:hover {
  color: #000;
}

.list-menu-item .list-item-title {
  font-size: 12px;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  line-height: 14px;
  width: 80px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
}

.disabledText {
  color: #8c8c8c !important;
}

.headerStyle {
  padding: 0 32px;
  background: #0642b5;
}

.subheaderStyle {
  margin-top: 55px;
  padding: 0 32px;
  width: 100vw;
  background: #fff;
  position: sticky;
  top: 56px;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 10;
}

.subheaderStyle:hover {
  /* overflow-x: scroll; */
}

.subheaderStyle::-webkit-scrollbar {
  display: none;
}

.styled-coming-soon {
  font-size: 40px;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  margin-top: 200px;
  margin-left: 600px;
}

/* notification */
.notify-sec {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 0 10px;
}

.notifyhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;
  padding: 0px 16px;
}

.notify-sec img {
  width: 40px;
  object-fit: contain;
  margin-right: 10px;
}

.notify-btn button {
  width: 125px;
  border: 1px solid #0642b5;
  background: #fff;
  padding: 5px 16px 4px 16px;
  border-radius: 4px;
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.notify-btn a.notify_re {
  background: transparent;
  color: #000;
  border-color: #000;
  margin-right: 0;
}

.notify_txt span {
  margin: 0;
  color: #1976d2;
  font-size: 12px;
  line-height: 17px;
  font-family: "ProximaNova-Semibold";
  display: table;
}

.notify_txt p {
  margin: 0;
  color: #000000;
  font-size: 13px;
  line-height: 24px;
  max-height: 48px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notify_txt p b {
  font-family: "ProximaNova-Bold";
}

.notifyhead p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
  font-family: "ProximaNova-Semibold";
}

.notifyhead p.read-text {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
  font-family: "ProximaNova-Semibold";
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.notifyhead p.read-text span.anticon svg {
  width: 14px;
  margin: 0 0 1px 3px;
}

.notify-sec .notify_txt {
  margin: 0 0 5px;
}

.notify-date p.date {
  position: absolute;
  right: 0;
  top: 0px;
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 14px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.notify-date p.date span {
  margin: 0 0 2px 5px;
  line-height: initial;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #095dff;
}

.notify-list {
  display: table;
  width: 100%;
  position: relative;
}

.notify-sec {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 0 15px;
  border-bottom: 1px solid #e9eaeb;
  padding: 0 0 15px;
}

.notify-sec:last-child {
  border-bottom: 0px solid;
  margin-bottom: 0;
}

/* end of notification */
.dots-btn {
  display: flex;
  align-items: center;
}

.logo-area {
  display: flex;
}

.logo-area > .altimetriklogo {
  margin-right: 16px;
}

.logo-area > img:last-child {
  margin-right: 0;
}

.headerTabs {
  margin-left: 0;
}

.list-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comingSoonHeaderStyle {
  border-radius: 32px;
  background-color: #fff0e6;
  color: #ff6701 !important;
  font-family: "ProximaNova-Semibold";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  padding: 2px 8px;
  margin-left: 8px;
}

.comingSoonHeaderStyle:hover {
  color: #ff6701;
}

.helpDeslIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin: auto auto;
}

.nine-dots-icon-container-image {
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  border-radius: 40px;
}

.nine-dots-icon-container-image .nine-dot-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.nine-dots-icon-container-image:hover {
  background: #05338c;
}

.my-profile-content .logout-section {
  padding: 16px 12px;
}

.logo-area .logo-separator {
  width: 1px;
  height: 32px;
  flex-shrink: 0;
  background-color: #ffffff;
  align-self: center;
  margin-right: 16px;
}

.dex-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.dex-logo-container .dex-logo {
  height: 32px;
  width: 32px;
}

.ant-popover {
  position: fixed;
}

.megamenu-section .ant-popover-content {
  margin-right: 0;
}

.megamenu-myspace-section .megamenu {
  max-height: 500px;
}

.megamenu-myspace-section .megamenu-leftarea {
  height: auto;
}

.megamenu-myspace-section .ant-popover-inner {
  width: 500px !important;
}

.megamenu-section .ant-popover-arrow {
  display: none;
}

.megamenu-section .ant-popover-inner {
  padding: 0.1px;
  width: 720px;
  background-clip: unset;
}

.megamenu-leftarea {
  background: rgba(230, 239, 255, 0.7);
  width: 372px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 24px;
}

.megamenu-section {
  top: 56px !important;
}
.megamenu-section-notification {
  top: 96px !important;
}

.megamenu-leftarea h6 {
  margin: 0 0 16px;
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}

.featured-section,
.myspacefeatured-section {
  display: flex;
  align-items: center;
  height: auto;
  padding: 0px 0;
  background: none;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 24px;
  text-align: left;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  cursor: pointer;
}
.featured-section {
  cursor: pointer;
}

a.featured-section:hover p,
.myspacefeatured-section:hover p {
  color: #0642b5;
}

.featured-section:last-child,
.myspacefeatured-section:last-child {
  margin-bottom: 0;
}

.rounded-white-bg {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.featured-section p,
.myspacefeatured-section p {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  margin: 0;
}

.featured-section span,
.myspacefeatured-section span {
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
}

.megamenu {
  display: flex;
}

.megamenu-rightarea {
  width: calc(100% - 150px);
  padding: 24px;
}

.megamenu-rightarea-width {
  width: 750px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.workMenu-section {
  display: flex;
  margin-bottom: 9px;
  align-items: center;
  padding: 12px;
  min-width: 200px;
  max-width: 250px;
}

.workMenu-section-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}

.workmenu-main {
  max-height: 342px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  width: 400px;
}

.workMenu-section p {
  margin: 0;
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.workMenu-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.workMenu-section:hover {
  border-radius: 8px;
  background: rgba(230, 239, 255, 0.7);
}

.megamenu-overlay {
  position: relative;
}

.megamenu-overlay::after {
  content: "";
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 56px;
  left: 0;
  z-index: 111;
}
.megamenu-overlay-notification {
  position: relative;
}
.megamenu-overlay-notification::after {
  content: "";
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 96px;
  left: 0;
  z-index: 111;
}

.megamenu-section .ant-popover-inner {
  width: auto !important;
  border-radius: 0px 0px 16px 16px;
}

.menupopover-overlay::after {
  content: "";
  background: rgb(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 111;
}

.menupopover-overlay .ant-layout .ant-layout-header {
  position: unset;
}

.menupopover-overlay .headerPopoveractive {
  position: relative;
  z-index: 9999;
  background: #0642b5;
  height: 56px;
  display: flex;
}

.menupopover-overlay .popperwhiteactive {
  position: relative;
  z-index: 9999;
  background: #fff;
  height: 36px;
  width: 36px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.menupopover-overlay .popperwhiteactive svg path {
  fill: #454545;
}

.walkthrough-popover .ant-popover-content {
  width: 275px;
  margin-right: 0;
}

.header-popover-first {
  display: flex;
  justify-content: space-between;
}

.header-popover-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 5px;
}

.header-popover-pagination {
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
}

.header-popover-second {
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  margin-bottom: 10px;
}

.header-popover-third {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-popover-third button {
  padding: 0;
}

.header-popover-third button:first-child {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  line-height: 16.8px;
  border: 0;
  background: transparent;
  box-shadow: none;
}

.header-popover-third button:last-child,
.header-popover-third button:last-child:hover {
  border-radius: 4px;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  line-height: 16.8px;
  width: 75px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .duplicate-header-container {
  position: absolute !important;
  width: 100%;
  z-index: 111;
} */

.workmenu-main-india {
  max-height: 332px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  /* width: 600px; */
  gap: 0px 24px;
}

.workmenu-main-usa {
  max-height: 322px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  /* width: 600px; */
}
.workmenu-main-canada {
  max-height: 332px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  /* width: 600px; */
}

.workmenu-main-uruguay {
  max-height: 242px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  /* width: 600px; */
}

.notify-sec .avatar-container {
  margin-right: 10px;
}
.notifycontent .ant-popover-content {
  width: 388px;
  min-height: fit-content;
  max-height: 500px;
  overflow-x: auto;
  box-shadow: 0px 4px 10px #d5d5d5;
  border-radius: 8px;
}
.notifycontent .ant-popover-content::-webkit-scrollbar {
  width: 6px;
}
.notifycontent .ant-popover-content::-webkit-scrollbar-track {
  display: none;
}
.empty-notifications {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 388px;
}
.empty-notification-image {
  width: 113px;
  height: 123px;
}
.empty-notification-text {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
.notification-container {
  height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #f8e4b5;
  color: #805d09;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.goal_setting_animation {
  width: 40px;
  height: 40px;
}
