.leader-filter-container {
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 14px 16px;
  justify-content: space-between;
}
.search-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.searchBarContainer {
  display: flex;
  padding: 12px 8px;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #bfbfbf;
  background: #fff;
  height: 35px;
}
.search-input-leader {
  border: none !important;
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  width: 240px;
}
.search-input-leader:focus-visible {
  border: none;
  outline: none !important;
}
.leader-filter-container .clear-all-btn {
  color: #8c8c8c;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  cursor: pointer;
  align-self: center;
  padding: 5px 8px;
}
.leader-filter-container .clear-all-btn-active {
  color: #0642b5 !important;
}
.leader-filter-container .filter-container {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.leader-filter-container .filter-button {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
}
.leader-filter-container .filter-opened {
  border: 1px solid #0642b5;
}
.leader-filter-container .filter-active-color {
  border: 1px solid #0642b5;
  background: #e6efff !important;
}
.filter-button-container .arrow {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}
.filter-data-container-leader {
  width: 238px;
  max-height: 331px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
}
.filter-data-container-leader .filter-data-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
.filter-data-container-leader .search-bar-filter {
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;
  height: 32px !important;
  padding: 1px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-data-container-leader .search-bar-filter .input-search-filter {
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  height: 34px;
  display: flex;
  width: 180px;
  border: none;
  padding: 0px 12px;
}
.filter-data-container-leader
  .search-bar-filter
  .input-search-filter:focus-visible {
  outline: none;
}
.filter-data-container-leader .searchBar-icon {
  height: 22px;
  width: 22px;
}
.filter-data-container-leader .filter-items-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  flex: 1;
}
.filter-data-container-leader .filter-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.filter-data-container-leader .check-box {
  height: 20px;
  width: 20px;
}
.filter-data-container-leader .check-box-text {
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.filter-data-container-leader .filter-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.filter-data-container-leader .reset-btn {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  cursor: pointer;
}
.filter-data-container-leader .reset-btn-inactive {
  color: #8c8c8c !important;
}
.filter-data-container-leader .apply-btn {
  border-radius: 8px;
  background: #bfbfbf;
  padding: 8px 12px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  cursor: pointer;
}
.filter-data-container-leader .apply-btn-active {
  background-color: #0642b5 !important;
  color: #ffffff !important;
}
.filterChildItems {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.filter-item .expand-holder {
  display: flex;
  flex: 1;
  justify-content: end;
}
.expand-holder .expand-filter {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-right: 5px;
}
.leader-filter-container .ant-popover {
  z-index: 1;
}
