.preview_tsm_modal.ant-modal-content {
  display: flex;
  min-width: 606px;
  min-height: 416px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}
.preview_timesheet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  margin-bottom: 20px;
}
.preview_timesheet_time {
  color: #0a0a0a;
  font-family: "Proximanova-Regular";
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
}
.preview_work_timesheet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  margin-bottom: 16px;
}
.preview_dual_work_timesheet {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 8px;
  background: #f8f8f8;
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}
.preview_buttons_timesheet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}
.preview_work_display {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #f8f8f8;
  color: #333;
  gap: 10px;
  padding: 16px;
}
.add_manager {
  display: flex;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.timesheet_submit {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.preview_company_title {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  padding: 10px;
}
.preview_total_tsm_manager {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  padding: 10px;
}
.preview_total_timebox {
  display: flex;
  flex-direction: row;
}
.preview_daily_day {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  min-width: 10px;
}
.preview_daily_day_comment {
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}
.add_manager_delete {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
}
.timesheet_submit_edit {
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: rgba(255, 255, 255, 0);
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
}
.preview_time_module {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.preview_display_status {
  display: flex;
  padding: 7px 12px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 10px;
  border-radius: 8px;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
}
.preview_display_status.Approved {
  color: #008000;
  background: #e6f2e6;
}
.preview_display_status.Pending {
  color: #a5780c;
  background: #fdf6e7;
}
.preview_display_status.Rejected {
  color: #cc0000;
  background: #fae6e6;
}
.preview_display_status.Draft {
  color: #858585;
  background: #ededed;
}
/* popups approve reject */
.approval-popup-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  align-items: center;
}
.timesheet-head {
  color: #0a0a0a;
  font-family: "Proximanova-Bold";
  font-size: 24px;
  font-style: normal;
  line-height: 28px;
}
.timesheet-body {
  font-family: "Proximanova-regular";
  font-size: 16px;
  color: #0a0a0a;
}
.timesheetimg {
  width: 180px;
  height: 120px;
}
.confirm-button {
  display: flex;
  width: 163px;
  height: 40px;
  padding: 5px, 12px 4px, 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #0642b5;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
  border: none;
  cursor: pointer;
}
.cancel-button {
  display: flex;
  width: 163px;
  height: 40px;
  padding: 5px 12px 4px 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px;
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: #ffffff;
  cursor: pointer;
}
.tsm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.reject-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.approval-tsm .ant-modal-content {
  width: 400px;
  height: fit-content;
}
.reject-tsm .ant-modal-content {
  width: 385px;
  height: fit-content;
}
.give_reason_tsm {
  color: #0a0a0a;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: -16px;
  align-self: flex-start;
}
.confirm-button:disabled {
  background-color: #e2e2e2;
  color: #858585;
  cursor: not-allowed;
  opacity: 0.6;
}
.preview-manager-comments {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.preview-manager-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 10px;
  margin-top: 16px;
}
.preview-manager-box-comments {
  color: #5c5c5c;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.cancel-button-footer {
  display: flex;
  width: 103px;
  height: 40px;
  padding: 5px 12px 4px 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px;
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba 255, 255, 255, 0;
  cursor: pointer;
}
