.commentTextArea-checkin .ant-form-item .ant-form-item-label > label {
  flex-direction: row-reverse !important;
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.commentTextArea-checkin .ant-form-item-control-input-content > textarea {
  color: #595959;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.commentTextArea-checkin .btn-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.commentTextArea-checkin .ant-form-item .ant-form-item-label > label::before {
  position: absolute;
  top: 0;
  right: -10px;
}
.commentTextArea-checkin .btn-holder button.ant-btn-primary:disabled {
  background: #E2E2E2;
  border-radius: 8px;
  padding: 5px 12px;
  color: #858585;
  border-color: #E2E2E2;
}
.commentTextArea-checkin .btn-holder button.ant-btn-primary:disabled span {
  color: #858585;
}
.commentTextArea-checkin .btn-holder button.ant-btn-primary {
  background: #0642B5;
  border-radius: 8px;
  padding: 5px 12px;
  border-color: #0642B5;
}
.commentTextArea-checkin .btn-holder button.ant-btn-primary:hover {
  background: #0642B5;
  border-radius: 8px;
  padding: 5px 12px;
  border-color: #0642B5;
}