.quarterly-checkinEmployee .all-commentsText {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom: 16px;
  display: none !important;
}
.quarterly-checkinEmployee .all-commentsText:nth-child(2) {
  display: block !important;
}
.quarterly-checkinEmployee .all-commentsText:first-child {
  display: block !important;
}
.centerItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 227px;
}
.quarterly-checkin-error-main {
  height: 80px;
  width: 80px;
  margin-bottom: 8px;
}
.something-went-wrong-text-quarterly-checkin {
  color: #454545;
  text-align: center;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; /* 140% */
}
.something-went-wrong-sub-header {
  color: #8c8c8c;
  text-align: center;
  margin-top: 4px;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.z-index-outer-div {
  position: relative;
  z-index: 999;
}
.quaterly-review-container {
  background: #fff;
  width: 90vw;
  padding: 24px;
  margin-top: 20px;
  border-radius: 8px;
}
.commentTextArea-checkin .ant-form-item .ant-form-item-label > label::after {
  display: none;
}
.quarterly-reivew-margin {
  margin-left: 0px !important;
  margin-bottom: 16px;
}
.highlighted-link {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-left: 5px;
  cursor: pointer;
}
