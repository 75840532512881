.left-side {
  margin-left: 40px;
}
.team-performanceContainer {
  margin-top: 95px;
}
.year-selection .ant-space-item {
  color: #e65a14;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.performanceContainer .asp-form-conatiner {
  display: flex;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
}
