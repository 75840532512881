.dex-success-toast-msg {
  border-radius: 4px;
  background: #fff !important;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14),
    0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  width: 377px !important;
  padding: 12px !important;
  border-left: 3pt solid #008000;
  .ant-notification-notice-message {
    display: block;
  }
  .ant-notification-notice-with-icon {
    .ant-notification-notice-icon {
      position: absolute !important;
      top: 10px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ant-notification-notice-message {
    margin-inline-start: 30px !important;
    color: #008000 !important;
    font-family: "ProximaNova-Semibold";
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 6px;
  }
  .ant-notification-notice-description {
    margin-inline-start: 30px !important;
    color: #474747 !important;
    font-family: "ProximaNova-Regular";
    font-size: 16px !important;
    line-height: 20px;
    width: 85%;
    display: flex;
  }
  .ant-notification-notice-close {
    position: absolute !important;
    top: 12px !important;
    &:hover {
      background: transparent !important;
    }
  }
}
.dex-error-toast-msg {
  border-radius: 4px;
  background: #fff !important;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14),
    0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  width: 377px !important;
  padding: 12px !important;
  border-left: 3pt solid #c00;
  .ant-notification-notice-message {
    display: block;
  }
  .ant-notification-notice-with-icon {
    .ant-notification-notice-icon {
      position: absolute !important;
      top: 10px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ant-notification-notice-message {
    margin-inline-start: 30px !important;
    color: #c00 !important;
    font-family: "ProximaNova-Semibold";
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 6px;
  }
  .ant-notification-notice-description {
    margin-inline-start: 30px !important;
    color: #474747 !important;
    font-family: "ProximaNova-Regular";
    font-size: 16px !important;
    line-height: 20px;
    width: 85%;
    display: flex;
  }
  .ant-notification-notice-close {
    position: absolute !important;
    top: 12px !important;
    &:hover {
      background: transparent !important;
    }
  }
}
