.absent-request-top-container {
    padding: 60px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    box-sizing: border-box;
}

.absent-request-top-container .absent-request-page-navigation {
    margin-top: 20px;
    font-family: "ProximaNova-Regular";
    font-size: 13px;
    line-height: 19.6px;
    text-align: left;

}

.absent-request-top-container .absent-request-previous-page {
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    line-height: 19.6px;
    text-align: left;
    color: #1976D2;
    padding: 4px 2px;
    border-radius: 5px;
    margin: 8px 0;
}

.absent-request-top-container .absent-request-previous-page:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.absent-request-top-container .absent-request-header {
    font-size: 24px;
    font-weight: 700;
    font-family: "ProximaNova-Bold";
    color: #000000;
    margin: 20px 0 16px;
    line-height: 28.8px;
}

.absent-request-top-container .absent-request-table-container {
    border-radius: 8px;
    padding: 8px;
    background-color: #ffffff;
    min-height: 70vh;
}
.error-leave-empty{
    display: flex;
    justify-content: center;
    align-self: center;
    height: 60vh;
}

.absent-request-table-container .absent-request-table-filter-container {
    background-color: #fff;
    padding: 25px 10px;
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
}

.absent-request-table-search-container {
    border: 1px solid black;
    border-radius: 32px;
    display: flex;
    align-items: center;
    padding: 10px 12px;
    width: 264px;
    gap: 8px;
}

.absent-request-table-search-icon {
    width: 20px;
    height: 20px;
}

.absent-request-table-filter-container .absent-request-table-search {
    height: 20px;
    width: 240px;
    border-radius: 32px;
    border: none;
}

.absent-request-table-filter-container .absent-request-table-search:focus {
    outline: none;
}

.absent-request-table-button-parent {
    display: flex;
}

.absent-request-button-pending {
    width: 84px;
    height: 40px;
    padding: 6px 16px;
    gap: 10px;
    border-radius: 32px 0px 0px 32px;
    font-family: "ProximaNova-Regular";
    font-size: 13px;
    line-height: 14px;
    text-align: left;
    border: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
}

.absent-request-button-approved {
    width: 93px;
    height: 40px;
    padding: 6px 16px;
    gap: 10px;
    font-family: "ProximaNova-Regular";
    font-size: 13px;
    line-height: 14px;
    text-align: left;
    border: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
}

.absent-request-button-rejected {
    width: 87px;
    height: 40px;
    padding: 6px 16px;
    gap: 10px;
    border-radius: 0px 32px 32px 0px;
    font-family: "ProximaNova-Regular";
    font-size: 13px;
    line-height: 14px;
    text-align: left;
    border: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
}


.absent-request-button-pending.PENDING {
    background: #E6EFFF;
    border: 1px solid #0642B5;
    color: #0642B5;
}

.absent-request-button-approved.APPROVED {
    background: #E6EFFF;
    border: 1px solid #0642B5;
    color: #0642B5;
}

.absent-request-button-rejected.REJECTED {
    background: #E6EFFF;
    border: 1px solid #0642B5;
    color: #0642B5;
}


.absent-request-table-container .ant-table-wrapper table {
    border: none;
}

.absent-request-table-container .ant-table-thead tr th {
    background-color: #f3f3f3 !important;
    border-bottom: none !important;
}

.absent-request-table-container .ant-table-wrapper .ant-table-tbody>tr>td {
    border-bottom: 1px solid #e8e8e8 !important;
    background-color: #fff !important;
    padding: 25px 16px;
}

.absent-request-top-container .absent-request-table {
    border-radius: 20px;
    border: none;
    padding: 8px;
}

.absent-request-table-container .absent-request-table-row {
    color: #474747;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
}

.absence-request-date-column {
    min-width: 210px;
    max-width: 220px;
}

.absence-request-leave-type {
    width: 190px;
    max-width: 190px;
}

.absence-request-reason-column {
    min-width: 200px;
    max-width: 200px;
}

.absence-request-comment-column {
    width: 400px;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.absence-request-status-column {
    max-width: 170px;
    width: 170px;
}

.absence-request-action-column {
    max-width: 90px;
}

.absent-request-status-Approved {
    display: inline-flex;
    padding: 7px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 35px;
    width: 85px;
    border-radius: 8px;
    color: #008000;
    background-color: #CCE6CC;
}

.absent-request-status-Pending {
    display: inline-flex;
    padding: 7px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: fit-content;
    border-radius: 8px;
    color: #A5780C;
    background-color: #FDF6E7;
}
.absent-request-status-Pending.Cancellation {
    display: inline-flex;
    white-space: nowrap;
    height: 35px;
    padding: 7px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    border-radius: 8px;
    color: #A5780C;
    background-color: #FDF6E7;
}

.absent-request-status-Rejected {
    display: inline-flex;
    padding: 7px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: #fae6e6;
    color: #cc0000;
}

.absent-request-status-text {
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 18px;
    text-align: left;
}

.absent-request-action-buttons-container {
    display: flex;
    gap: 5px;
}

.absent-request-action-button {
    border: 1px solid #0642B5;
    color: #0642B5;
    padding: 5px 5px;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    cursor: pointer;
}
.pagination-timesheet-content-container{
    padding: 10px;
}

/* Delete Icon */
/* .absent-request-action-button-delete {
    filter: invert(12%) sepia(60%) saturate(7258%) hue-rotate(210deg) brightness(78%) contrast(90%);
    border: 1px solid #000;
    padding: 5px 5px;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    color: #0642B5;
    cursor: pointer;
} */