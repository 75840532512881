.compotency-inner-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
}

.compotency-inner-section h4 {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin: 0 0 4px;
}

.compotency-inner-section ol {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0 0 0 24px;
}

.compotency-inner-section p {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.compotency-inner-section .ant-form-item-required::after {
  content: none !important;
}

.compotency-inner-section .ant-form-item .ant-form-item-label > label::after {
  content: none;
  align-content: initial;
}

.compotency-inner-section .ant-form-item .ant-form-item-label > label {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
}

.compotency-inner-section .ant-form-item .ant-form-item-label label:before {
  top: 5px;
  position: absolute;
  right: -12px;
}

.compotency-inner-section .ant-form-item-row {
  display: flex;
  flex-direction: column;
}

.compotency-inner-section .ant-form-item-label {
  text-align: start;
  margin-bottom: 0;
}

.compotency-inner-section .ant-form-item {
  margin-bottom: 0;
}

/* .custom-rating .ant-rate-star-first .anticon-star svg {
  stroke: black;
  stroke-width: 30pt;
  fill: #fff;
} */

.texteditor-section {
  position: absolute;
  top: -4px;
  right: 0;
  display: flex;
}

.texteditor-section button,
.text-editor-section .ql-formats button,
.text-editor-section span.ql-color {
  width: 32px !important;
  height: 32px !important;
  border-radius: 5px;
  background: #f0f0f0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  border: 0 !important;
  margin-right: 0;
}

.text-editor-section .ql-formats button.ql-active,
.text-editor-section span.ql-color.ql-expanded {
  background: #d8e5ff !important;
}

.text-editor-section
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label {
  border-color: transparent !important;
}

.texteditor-section button:hover {
  border: 0;
  color: transparent !important;
  border-color: transparent !important;
  background: #f0f0f0;
}

.compotency-card {
  margin-bottom: 16px;
}

.footer-btn-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  position: fixed;
  bottom: 0px;
  background: #fff;
  width: 100%;
  left: 0;
  padding: 16px 60px;
}
.footer-btn-right {
  display: flex;
  align-items: center;
}

.footer-btn-right .ant-form-item {
  margin-bottom: 0px;
}

.footer-btn-right button {
  margin-left: 16px;
}

.compotency-popup .ant-modal-content {
  width: 368px;
}

.compotency-popup .ant-modal-body h5 {
  font-size: 20px;
  margin: 0 0 8px;
}

.compotency-popup .ant-modal-body p {
  margin: 0 auto 16px;
}

.compotency-popup .ant-modal-body .button-section .ant-btn-default {
  width: 143px;
  border-radius: 4px;
  border: 1px solid #095dff;
  margin-right: 16px;
  font-size: 14px;
}

.compotency-popup .ant-modal-body .button-section .ant-btn-primary {
  width: 143px;
  border: 1px solid #095dff;
  font-size: 14px;
}

.stepper-section {
  border-radius: 8px;
  background: #fff;
  padding: 24px 40px;
  margin-bottom: 24px;
}

.layoutContainer .ant-steps-item-wait .ant-steps-icon,
.layoutContainer .ant-steps-item-process .ant-steps-icon {
  display: none;
}

.layoutContainer .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 81px !important;
}

.layoutContainer .ant-steps-item-icon {
  position: relative;
  margin-inline-start: 65px !important;
}

.layoutContainer .ant-steps-item-process .ant-steps-item-icon::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 0;
  background: #095dff;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.layoutContainer .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #095dff !important;
  border-color: #095dff !important;
}

.layoutContainer
  .ant-steps-item-finish
  .ant-steps-item-icon
  .ant-steps-finish-icon
  svg {
  fill: #fff;
}

.layoutContainer .ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff !important;
  border-color: #095dff !important;
}

.layoutContainer .ant-steps-item-finish .ant-steps-item-title {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.layoutContainer .ant-steps-item-wait .ant-steps-item-title {
  color: #8c8c8c !important;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.layoutContainer .ant-steps-item-title div {
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px !important;
}

.layoutContainer .ant-steps.ant-steps-label-vertical .ant-steps-item-title {
  white-space: nowrap;
}

.layoutContainer .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  width: 166px;
}

.layoutContainer
  .ant-steps.ant-steps-label-vertical
  .ant-steps-item-content
  .ant-steps-item-title {
  font-family: "ProximaNova-Semibold";
}

.layoutContainer {
}

.layoutContainer .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff !important;
  border-color: #8c8c8c !important;
}

.layoutContainer
  .ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.layoutContainer
  .ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #8c8c8c;
}

.competency-heading-section {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.competency-heading-section h1 {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  line-height: 28.8px;
  width: 65%;
  margin: 0;
}

.progress-section {
  width: 35%;
  display: flex;
  align-items: flex-end;
}

.progress-section p {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  margin: 0 8px 0 0;
  width: 20%;
}

.progress-section .ant-progress-outer,
.progress-section .ant-progress-inner,
.progress-section .ant-progress-bg {
  height: 12px !important;
}

.progress-section .ant-progress-line {
  margin: 0;
}

.progress-section .ant-progress-text {
  color: #000 !important;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.progress-section .ant-progress-inner,
.progress-section .ant-progress-bg {
  border-radius: 4px;
}

.layoutContainer .slick-prev {
  left: 5px;
  height: 32px;
  width: 32px;
  z-index: 11;
}

.layoutContainer button.slick-arrow {
  top: 40%;
}

.layoutContainer .slick-prev::before {
  content: url(../../../themes/assets/images/svg/goals/arrowleft.svg);
  height: 32px;
  width: 32px;
}

.layoutContainer .slick-next {
  right: 10px;
  height: 32px;
  width: 32px;
  z-index: 11;
}

.layoutContainer .slick-next::before {
  content: url(../../../themes/assets/images/svg/goals/arrowright.svg);
  height: 32px;
  width: 32px;
}

.hide-element {
  display: none !important;
}

.text-editor-section .ant-col,
.text-editor-section .ant-form-item .ant-form-item-control-input {
  position: unset;
}

.text-editor-section .ql-toolbar {
  position: absolute;
  top: -45px;
  right: 0;
  border: 0;
  padding: 0;
}

/* .ql-formats .ql-bold svg:first-child {
  display: none;
} */
.text-editor-section .ql-snow .ql-color-picker .ql-picker-label,
.text-editor-section .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 0;
}

.text-editor-section .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}

.text-editor-section .ql-container.ql-snow {
  border: 1px solid #d9d9d9;
  height: 96px;
}

.text-editor-section .ql-snow .ql-stroke {
  stroke: #231f20;
  stroke-width: 1px;
}

/* .text-editor-section .ql-formats span {
  position: relative;
} */
.text-editor-section .ql-formats button {
  position: relative;
}

.text-editor-section .ql-formats span svg,
.text-editor-section .ql-formats button svg {
  display: none;
}

.text-editor-section .ql-formats .ql-color span.ql-picker-label::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon.svg");
  position: absolute;
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
}

.text-editor-section
  .ql-formats
  .ql-color.ql-expanded
  span.ql-picker-label::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon_active.svg");
}

.text-editor-section .ql-formats .ql-bold::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon1.svg");
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
}

.text-editor-section .ql-formats .ql-bold.ql-active::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon1_active.svg");
}

.text-editor-section .ql-formats .ql-underline::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon2.svg");
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
}

.text-editor-section .ql-formats .ql-underline.ql-active::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon2_active.svg");
}

.text-editor-section .ql-formats .ql-list:first-child::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon3.svg");
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
}

.text-editor-section .ql-formats .ql-list:first-child.ql-active::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon3_active.svg");
}

.text-editor-section .ql-formats .ql-list:last-child::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon4.svg");
  position: absolute;
  top: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
}

.text-editor-section .ql-formats .ql-list:last-child.ql-active::after {
  content: url("../../../themes/assets/images/svg/goals/texteditoricon4_active.svg");
}

.text-editor-section .ql-snow .ql-picker.ql-expanded .ql-picker-label::after {
  z-index: 2;
}

/* .text-editor-section .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  margin-top: 88px;
} */
.text-editor-section .ql-snow .ql-color-picker.ql-color .ql-picker-item {
  border-radius: 15px;
  margin: 0 31px 16px 0;
  width: 24px;
  height: 24px;
  position: relative;
  border: 0px solid;
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:nth-child(3n) {
  margin-right: 0;
}

.text-editor-section .ql-snow .ql-color-picker.ql-color .ql-picker-item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  border-color: rgb(0, 0, 0);
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:hover:before {
  opacity: 1;
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:nth-child(2):before {
  border-color: rgb(43, 93, 169);
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:nth-child(3):before {
  border-color: rgb(145, 0, 0);
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:nth-child(4):before {
  border-color: rgb(16, 102, 107);
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:nth-child(5):before {
  border-color: rgb(181, 73, 1);
}

.text-editor-section
  .ql-snow
  .ql-color-picker.ql-color
  .ql-picker-item:nth-child(6):before {
  border-color: rgb(116, 103, 4);
}

.text-editor-section .ql-snow .ql-color-picker .ql-picker-options {
  padding: 45px 16px 12px 16px;
  width: 168px;
  margin-top: 7px !important;
  margin-left: 100px;
  height: 129px;
}

.text-editor-section .ql-picker-options::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
}

.text-editor-section .ql-picker-options::after {
  content: "Select a Color";
  position: absolute;
  top: 10px;
  left: 10px;
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  line-height: 16.8px;
}

.compotency-inner-section .ant-form-item-row li.ant-rate-star svg {
  width: 20px;
  height: 20px;
  stroke: #262626;
  stroke-width: 50pt;
  fill: #fff;
}

.compotency-inner-section
  .ant-form-item-row
  li.ant-rate-star.ant-rate-star-full
  svg {
  stroke: #ffad09;
  fill: #ffad09;
}

.stepper-section .ant-steps-item {
  padding-right: 55px;
}
.stepper-section .ant-steps-item:last-child {
  padding-right: 0;
}
