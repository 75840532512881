.learning-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding: 32px 60px;
  height: 100vh;
  gap: 32px;
}
.learning-container .title-learning {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}
.learning-container .card-container-learning {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
}
.learning-container .cards-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.learning-container .card-container-learning {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(206, 211, 218, 0.45);
  width: 170px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 13px 24px;
  align-items: center;
  justify-content: center;
  gap: 0px;
  cursor: pointer;
}
.learning-container .icon-learning {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  object-fit: contain;
}
.learning-container .card-title-learning {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-top: 8px;
  align-self: center;
}
