.smart-game-container {
  padding: 50px;
  padding-top: 80px;
  height: 100vh;
}
.smart-game-container .smart-game-title {
  color: #0a0a0a;
  font-family: "ProximaNova-Bold";
  font-weight: 700;
  font-size: 24px;
}

.smart-game-container .smart-game-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex: 1;
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-evenly;
  height: 95%;
  width: 100%;
  gap: 10px;
}
.smart-game-container .smart-game-content .play-game-card {
  background-color: #f0ecfe;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  /* gap: 10px; */
}
.smart-game-container .smart-game-content .play-game-card .game-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  padding-right: 30px;
  align-items: flex-end;
}
.smart-game-container
  .smart-game-content
  .play-game-card
  .game-card-content
  .game-card-title {
  font-family: "ProximaNova-SemiBold";
  font-weight: 700;
  font-size: 32px;
  color: #2d1a68;
}
.smart-game-container
  .smart-game-content
  .play-game-card
  .game-card-content
  .game-card-desc {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 400;
  font-family: "ProximaNova-Regular";
}
.smart-game-container
  .smart-game-content
  .play-game-card
  .game-card-content
  .game-card-btn {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  background-color: #0642b5;
  padding: 7px 12px 7px 12px;
  font-family: "ProximaNova-Regular";
  border-radius: 8px;
  width: 90px;
  cursor: pointer;
}
.smart-game-container .smart-game-content .smart-desc {
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  font-size: 16px;
  color: #474747;
  line-height: 24px;
  flex: 0.2;
}
.smart-game-container .smart-game-content .smart-cards {
  display: flex;
  gap: 15px;
  flex: 1;
}
.smart-game-container .smart-game-content .check-goals-card {
  background-color: #fff7e6;
  border-radius: 8px;
  /* width: 100%; */
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  flex: 1;
}
.smart-game-container
  .smart-game-content
  .check-goals-card
  .check-goals-card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.smart-game-container .smart-game-content .check-goals-card .vijay-intro {
  font-family: "ProximaNova-SemiBold";
  font-size: 16px;
  font-weight: 600;
  color: #0a0a0a;
}
.smart-game-container .smart-game-content .check-goals-card .vijay-desc {
  font-size: 16px;
  font-weight: 400;
  font-family: "ProximaNova-Regular";
  color: #1f1f1f;
}
.smart-game-container .smart-game-content .check-goals-card .smart-text {
  color: #e65a14;
}
.smart-game-container
  .smart-game-content
  .check-goals-card
  .check-my-goals-btn {
  color: #0642b5;
  font-size: 14px;
  font-weight: 600;
  font-family: "ProximaNova-SemiBold";
  border: 1px solid #0642b5;
  border-radius: 8px;
  padding: 7px 12px 7px 12px;
  width: 140px;
  cursor: pointer;
}
.smart-game-container .smart-game-content .smart-card-container {
  background-color: #f8f8f8;
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  /* height: 164px; */
  padding: 15px;
  flex: 1;
}
.smart-game-container
  .smart-game-content
  .smart-card-container
  .smart-card-desc {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  /* line-height: 14px; */
  /* text-align: justify; */
}
.smart-game-container
  .smart-game-content
  .smart-card-container
  .smart-card-footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.smart-game-container
  .smart-game-content
  .smart-card-container
  .smart-card-letter {
  height: 52px;
  width: 52px;
  border-radius: 100%;
  color: #000000;
  font-family: "ProximaNova-Bold";
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.smart-game-container
  .smart-game-content
  .smart-card-container
  .smart-card-title {
  font-weight: 600;
  font-size: 16px;
  font-family: "ProximaNova-Regular";
}
