.wrapper {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 70px 50px;
  box-sizing: border-box;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  .link-highlight {
    color: #1976d2;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .form {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    justify-content: space-between;
    .form-container {
      background-color: #fff;
      border-radius: 8px;
      padding: 20px 25px;
    }
    .date-picker {
      width: 100%;
      height: 40px;
      cursor: pointer;
    }
    .input {
      color: #333333;
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      height: 40px;
    }
    .ant-picker-input {
      color: #1f1f1f;
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }
    .ant-picker-outlined:hover {
      border-color: #c7c7c7;
    }
    .ant-input-outlined:hover,
    .ant-input-outlined:focus {
      border-color: #c7c7c7;
      outline: 0;
      box-shadow: none;
    }
    .ant-form-item .ant-form-item-label > label {
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    .uploaded {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 100%;
      background-color: #f5f5f5;
      border-radius: 8px;
      padding: 0px 10px;
      .left {
        display: flex;
        align-items: center;
      }
    }
    .upload {
      width: 100%;
      display: flex;
      border-style: dashed;
      border-radius: 8px;
      height: 40px;
      border-color: #858585;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      border-width: 1px;
      cursor: pointer;
    }
    .upload-txt {
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #5c5c5c;
    }
  }
  .form-footer {
    background-color: #fff;
    width: 100%;
    height: 72px;
    position: absolute;
    bottom: 0;
    margin: 0px -50px;
    .btn-container {
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .btn {
        height: 40px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #c7c7c7;
        margin-top: 15px;
        margin-right: 15px;
        cursor: pointer;
      }
      .cancel {
        color: #5c5c5c;
        background-color: #fff;
      }
      .save {
        border: 1px solid #0642b5;
        color: #0642b5;
      }
      .next-enabled {
        color: #fff;
        background-color: #0642b5;
        border: none;
      }
      .next-disabled {
        color: #858585;
        background-color: #e2e2e2;
        border: none;
        pointer-events: none;
      }
    }
  }
  .preview-program-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow: scroll;
    margin: 20px 0px;
    .title {
      font-family: "ProximaNova-SemiBold";
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #1f1f1f;
    }
    .small-title {
      font-size: 14px;
    }
    .small {
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      line-height: 18px;
    }
    .light {
      font-family: "ProximaNova-Regular";
      font-weight: 400;
      color: #474747;
    }
    .very-light {
      color: #676767;
    }
    .space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .section {
      margin: 10px 0px;
    }
    .section2 {
      padding-bottom: 80px;
    }

    .info-section {
      background-color: #fff;
      border-radius: 8px;
      padding: 30px 25px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .last-section {
      display: flex;
    }
    .flex {
      flex: 1;
    }
    .bc-card {
      border: 1px solid #e8e8e8;
      padding: 15px;
      padding-bottom: 0px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .bc-info-section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
    .bc-padding {
      padding: 0px;
      padding-left: 10px;
    }
  }
}
.spacing {
  padding: 0px 60px;
}
.not-found-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  height: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center {
  text-align: center;
}
