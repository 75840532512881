.form_year-detail {
  display: flex;
  gap: 24px;
}

.form_year-detail-inn {
  display: table;
  border: 1px solid #d9dbe3;
  border-radius: 4px;
  padding: 16px;
}

.ant-card-body .form_year-detail-inn h1 {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin: 0 0 16px;
  font-family: "ProximaNova-Bold";
}

.year_detail-cont {
  display: flex;
  align-items: flex-start;
  margin: 0 0 19px;
}

.year_detail-cont .year_detail-data p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #454545;
  font-family: "ProximaNova-Semibold";
}

.year_detail-cont .year_detail-data span {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
}

.year_detail-cont img {
  margin: 5px 12px 0 0;
}

.year_detail-cont .year_detail-data {
  display: table;
  margin: 0;
}

.form_year-detail-inn button.ant-btn {
  background: #095dff;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_year-detail-inn button.ant-btn span {
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
}

.no-year {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  padding: 78px 0;
  margin-top: 16px;
  margin-right: 55px;
}

.no-year .title {
  color: #454545;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; /* 140% */
}

.no-year .sub-title {
  color: #8c8c8c;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px; /* 140% */
  margin-top: 4px;
}

.no-year p {
  margin-bottom: 0;
  margin-top: 0;
}

.loading-asp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  height: 60vh;
  margin-top: 12px;
}

.card_list-evalution {
  position: relative;
}

.card_list-evalution .badge_card {
  border-radius: 4px;
  background: #d1e4f6;
  display: inline-flex;
  height: 32px;
  padding: 6px 16px;
  position: absolute;
  top: 0;
  left: 0;
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}
.custom-fill-btn.ant-btn-primary:disabled {
  background-color: #d9d9d9 !important;
  cursor: pointer !important;
}
.custom-fill-btn.ant-btn-primary:disabled span {
  color: #8c8c8c;
}
.competancy-evaluation-asp .ant-progress-text::after {
  color: #000000;
}
.navigation-text-icon {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  gap: 8px;
}
.icon-navigation {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  object-fit: contain;
}
.navigation-text-icon .navigation-text {
  color: #858585;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: pre-wrap;
}
.highlighted-link {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-left: 5px;
  cursor: pointer;
}
