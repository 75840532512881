  .card_head_title {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: "ProximaNova-Regular";
    color: "#474747";
    text-align: left;
  }
  .readMoreContent {
    overflow: hidden;
    position: relative;
    margin: 0 0 12px;
  }
  .summaryText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
