/* leave type balance card */
.leave-type-balance-card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  flex: 1;
  height: 100%;
  padding: 0px 10px;
}

.leave-type-balance-card-container .leave-type-balance-card {
  display: flex;
  align-items: center;
  gap: 16px;
}

.leave-type-balance-card-container .leave-type-image {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leave-type-balance-card-container .leave-type-balance-card-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.leave-type-balance-card-container .leave-type-title {
  font-size: 16px;
  font-weight: 600;
  font-family: "ProximaNova-SemiBold";
  color: #1f1f1f;
  line-height: 22.4px;
}

.leave-type-balance-card-container .leave-type-balance-days {
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  font-weight: 700;
}

.leave-type-balance-card-container .leave-type-balance-days-text {
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  font-size: 16px;
  color: #454545;
}

.leave-type-balance-card-container .leave-type-balance-card-divider {
  height: 60px;
  border: 1px solid #d9d9d9;
  margin: 0px 10px;
}

/* holiday-quote-card */
.holiday-quote-card-container {
  position: relative;
  height: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.holiday-quote-card-container .holiday-quote-card-background {
  background: linear-gradient(180deg, #ffffff 25.95%, #b4ceff 91.04%);
  height: 75%;
  width: 92%;
  border-radius: 8px;
  display: flex;
  gap: 10px;
}

.holiday-quote-card-container .manholdinglapotop {
  position: absolute;
  top: 5;
  bottom: 0;
  width: 106px;
  height: 106px;
}

.holiday-quote-card-container .holiday-quote-card-text {
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: #000000;
  padding: 0 17px 0 50px;
  display: flex;
  align-items: center;
}

.holiday-quote-card-container .holiday-quote-card-image {
  width: 106px;
  height: 106px;
}

/* absence request card */
.absence-requests-card-container {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px 15px;
  height: auto;
  flex: 1;
}

.leave-management-top-container
  .leave-management-right-container
  .leave-management-absence-request-card {
  flex: auto;
}

.space-between-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.side-heading {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.view-all-button {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #0642b5;
}

.absence-requests-card-container
  .absence-requests-card-holiday-details-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 20px;
  flex: 1;
  height: 100%;
}

.absence-requests-card-container
  .absence-request-holiday-details-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .absence-requests-card-container
  .absence-request-holiday-details-card-container:hover {
    background-color: gray;
  } */
.absence-requests-card-container
  .absence-request-holiday-details-card-text-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.absence-requests-card-container
  .absence-request-holiday-details-card-holiday-type-text {
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  font-size: 12px;
  color: #232a3d;
}

.absence-requests-card-container .absence-request-dates-text {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.absence-requests-card-container .absence-request-leave-type-status-text {
  font-family: "ProximaNova-Semibold";
  font-weight: 600;
  font-size: 13.72px;
  border-radius: 7.82px;
  text-align: center;
  align-content: center;
  padding: 10px 20px;
}
.absence-request-leave-type-status-text.Pending {
  color: #5c3d2e;
  background: #faeecf;
}
.absence-request-leave-type-status-text.Approved {
  color: #008000;
  background: #cce6cc;
}
.absence-request-leave-type-status-text.Rejected {
  color: #cc0000;
  background: #fae6e6;
}
.absence-request-leave-type-status-text.Pending.Cancellation {
  color: #5c3d2e;
  background: #faeecf;
}

.absence-requests-card-container .absence-request-card-divider {
  border: 1px solid #bfbfbf;
  opacity: 0.5;
}

/* holiday carousel card */

.holiday-carosuel-main-container
  .carousel-containers
  .holiday-carousel-card
  .carousel-bg-img {
  aspect-ratio: 1147 / 370;
  border-radius: 8px;
  height: auto !important;
  width: 100%;
  cursor: pointer;
}
.holiday-carosuel-main-container
  .slick-slider.carousel-containers.slick-initialized {
  height: auto !important;
  width: 32vw;
  cursor: pointer;
}

.indicators-container {
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
}

.indicator {
  width: 8px;
  height: 8px;
  top: 267px;
  left: 1024px;
  border-radius: 8px;
}

.active-indicator {
  background-color: #0642b5;
}

.inactive-indicator {
  background-color: #d9dbe3;
}

/* upcoming-holidays-card-container */
.upcoming-holidays-main-card-container-error {
  border-radius: 8px;
  flex: 1;
  height: 100%;
}

.upcoming-holidays-main-card-container {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 15px;
  flex: 1;
  height: 100%;
}

.upcoming-holidays-main-card-container a.view-all-button:hover {
  color: #0642b5;
}

.upcoming-holidays-main-card-container .upcoming-holidays-cards-container {
  display: flex;
  height: 90%;
  flex-direction: column;
  gap: 2px;
  gap: 20px;
  margin-top: 20px;
}

/* leave request card */
.leave-request-card-container {
  background-color: #ffffff;
  padding: 16px 24px;
  border-radius: 8px;
  height: 100%;
  flex: 1;
}

.leave-request-card-container .leave-request-card-body-header {
  display: flex;
  gap: 20px;
  padding: 16px 0px 15px;
  flex: 1;
}

.leave-request-card-container .leave-request-card-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-bottom: 20px;
  position: relative;
}

.leave-request-card-container .leave-request-card-calendar-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.leave-request-card-container .leave-request-card-calendar-view-top {
  flex: 0.8;
}

.leave-request-card-container .leave-request-card-legends-container {
  display: flex;
  flex: 0.4;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}

.leave-request-card-container .leave-request-card-legends-text-container {
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
}

.leave-request-card-container .leave-request-card-legend-color {
  height: 6px;
  width: 6px;
  border-radius: 6px;
}

/* form styles */
.leave-request-card-container .select-type-input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 10px;
}

.leave-request-card-container .input-text {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
}

.leave-request-card-container .form-item-text {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 19.6px;
}

.leave-request-card-container .mandatory {
  color: #cc0000;
}

.leave-request-card-container .form-item-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.leave-request-card-container .form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding-right: 5px;
  max-height: 60vh;
  overflow-y: auto;
}
.leave-request-card-container .form-container::-webkit-scrollbar {
  width: 4px;
  border-radius: 8px;
}
.leave-request-card-container .form-container::-webkit-scrollbar-track {
  border-radius: 8px;
}

.leave-request-card-container .calendar-input-box {
  flex: 1;
}

.leave-request-card-container .calendar-input-text-container {
  display: flex;
  gap: 10px;
  flex: 0.8;
}

.leave-request-card-container .calendar-form-item-container {
  display: flex;
  gap: 24px;
}

.calendar-form-item-parent {
  display: flex;
  flex-direction: column;
}

.leave-request-card-container .requesting-days-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 10px;
  background-color: #f0f0f0;
  height: 40px;
  flex: 0.2;
}

.leave-request-card-container .comments-input-box {
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: inherit;
  height: 87px;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 10px;
}

.leave-request-card-container .leave-request-card-footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.leave-request-card-container .clear-all-btn {
  border: 1px solid #bfbfbf;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-weight: 600;
  color: #5c5c5c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

.leave-request-card-container .inactive-preview-btn {
  width: 143px;
  height: 40px;
  border-radius: 8px;
  background-color: #d9d9d9;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-weight: 600;
  color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leave-request-card-body .react-calendar {
  flex: 1;
  border: none;
  width: 100%;
}

.leave-request-card-body .react-calendar__navigation__label {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 600;
  line-height: 26.58px;
  text-align: center;
  color: #000;
  background: #fff;
}

.leave-request-card-body .react-calendar__month-view__weekdays__weekday {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 14px;
  color: #454545;
  text-transform: capitalize;
  /* flex: none !important; */
  padding: 0;
  /* margin: 0 13px; */
}

.leave-request-card-body .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  /* width: 45px; */
  display: flex;
  justify-content: center;
}
.leave-request-card-body .react-calendar__tile {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
}

.react-calendar .react-calendar__tile {
  position: relative;
}
.react-calendar .react-calendar__tile .leave-request-card-legend-color {
  position: absolute;
  bottom: 10px;
}
.react-calendar .react-calendar__tile abbr {
  height: 45px;
}
.ant-modal-mask.leave-type-bg-enter.leave-type-bg-enter-active.leave-type-bg {
  background: #000 !important;
  opacity: 0.7;
}
.ant-modal-mask.leave-type-bg-leave.leave-type-bg-leave-active.leave-type-bg {
  background: transparent !important;
}
.leave-request-card-body .react-calendar__tile abbr {
  width: 50px !important;
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leave-request-card-body .react-calendar__tile--now {
  background-color: #0642b5;
  border-radius: 100%;
  color: #fff;
  min-height: 60px;
  min-width: 60px;
}

.leave-request-card-body
  .react-calendar__tile--now
  .leave-request-card-legend-color {
  display: none;
}

.leave-request-card-body
  .react-calendar__month-view__days__day--neighboringMonth {
  color: #bfbfbf;
}

.leave-request-card-body .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #fff;
}

.leave-request-card-body .react-calendar__tile--active {
  background-color: #fff;
  color: #000;
}

.leave-request-card-body .react-calendar__tile--active:enabled:focus {
  background-color: #fff;
}

.leave-request-card-body .react-calendar__navigation button:enabled:hover {
  background-color: #fff;
}

.leave-request-card-body .react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}

.leave-request-card-body
  .react-calendar
  .react-calendar__navigation
  button:disabled {
  background-color: #fff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: red;
  color: #000;
}

.leave-request-card-body .react-calendar .react-calendar__tile:disabled {
  background-color: #fff;
  color: #bfbfbf;
}

.leave-request-card-body .react-calendar .react-calendar__tile--rangeStart,
.leave-request-card-body
  .react-calendar
  .react-calendar__tile--rangeEnd:enabled:hover,
.leave-request-card-body
  .react-calendar
  .react-calendar__tile--rangeEnd:enabled:focus,
.leave-request-card-body
  .react-calendar
  .react-calendar__tile--rangeStart:enabled:hover,
.leave-request-card-body
  .react-calendar
  .react-calendar__tile--rangeStart:enabled:focus,
.leave-request-card-body .react-calendar .react-calendar__tile--rangeStart,
.leave-request-card-body .react-calendar .react-calendar__tile--rangeEnd {
  background-color: #fff;
}

.react-calendar__tile--now:enabled:hover {
  background-color: #cc0000;
}

.absence-requests-card-holiday-details-conatiner .leave_error_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
}

.absence-requests-card-holiday-details-conatiner .leave_error_image {
  width: 125px;
  height: 125px;
  flex-shrink: 0;
}

.absence-requests-card-holiday-details-conatiner .leave_error_title {
  color: #595959;
  text-align: center;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.leave-request-card-container .leave-request-card-body .leave_error_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.leave-request-card-container .leave-request-card-body .leave_error_image {
  width: 195px;
  height: 195px;
  flex-shrink: 0;
}

.leave-request-card-container .leave-request-card-body .leave_error_title {
  color: #000;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  /* 81.25% */
  letter-spacing: 0.066px;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  border: 2px solid #0642b5;
  background-color: white;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: blue;
}

.leave-request-fractionality-container {
  display: flex;
  gap: 8px;
  width: 100%;
}

.leave-request-fractionality-option {
  flex: 1;
  white-space: nowrap;
}

.leave-request-card-body .react-calendar .react-calendar__tile--now abbr {
  border: 1px solid #0642b5;
  border-radius: 50%;
  color: #fff;
  cursor: not-allowed;
}

.leave-request-card-body .react-calendar .react-calendar__tile--now:hover abbr {
  border: 1px solid #0642b5;
  border-radius: 50%;
  color: #fff;
}

.leave-request-card-body .react-calendar__tile--now:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #0642b5;
}

.quick-apply-floater-landing {
  padding: 5px 10px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.qa-floater-lb-checkbox {
  background: #e6f2e6;
  border-radius: 8px;
  margin-top: 10px;
  padding: 13px;
}

.qa-floater-lb-check-txt {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  color: #454545;
}

.qa-floater-lb-check .ant-checkbox-wrapper {
  margin-top: 10px;
}

.qa-floater-lb-check-date {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  color: #1f1f1f;
}

.qa-floater-lb-check-type-name {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 16.8px;
  color: #454545;
  margin-left: 10px;
}

.qa-floater-lb-check span.ant-checkbox span.ant-checkbox-inner {
  border: 1.5px solid #757a87;
  border-radius: 4px;
  width: 16px;
  height: 16px;
}

.select-type-input-box.ant-input-affix-wrapper .ant-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  font-family: "ProximaNova-Regular";
  color: #8c8c8c;
  margin: 0;
}

.leave-request-card-container
  .select-type-input-box
  textarea#select-type-input-box {
  padding: 0;
}

.qa-floater-lb-check
  .ant-checkbox-wrapper:hover
  span.ant-checkbox.ant-checkbox-checked
  span.ant-checkbox-inner {
  background-color: #0634b5;
}

.paternity-error-container {
  background: #fae6e6;
  padding: 10px 10px;
  border-radius: 8px;
  width: 280px;
  display: flex;
  align-self: center;
  margin-bottom: 10px;
  align-items: center;
}

.paternity-error-text {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: #000000;
  flex-wrap: wrap;
  line-height: 19.6px;
  margin-left: 10px;
}

.attachment-main {
  border: 2px dashed #d5d8e2;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.attachment-input {
  display: none;
}

.after-attachment-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 8px 16px 8px 25px;
  background-color: #f5f5f5;
}

.after-attachment-sub {
  display: flex;
  justify-content: left;
  position: relative;
  align-items: center;
  gap: 15px;
  margin-top: 8px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0;
  width: 100%;
}

.preview-modal .content-view .after-attachment-sub {
  padding: 16px;
  width: auto;
}

.attachment-file-icon {
  height: 20px;
  width: 20px;
  align-items: center;
}

.attachment-file-icon-div {
  background-color: #ffffff;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.no-attachment-text {
  color: #262626;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
}

.attached-file-main {
  margin-bottom: 10px;
}

.attached-file-name {
  margin-bottom: 10px;
}

.attached-file-size {
  color: #595959;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
}

.add-attachment-text {
  color: #595959;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.close-cross-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.close-cross-icon img {
  cursor: pointer;
}

.file-attachment-size-restrict {
  color: #cc0000;
  font-size: 12px;
  line-height: 16px;
  font-family: "ProximaNova-Regular";
}

.gender-validations {
  color: #cc0000;
  font-size: 12px;
  line-height: 16px;
  font-family: "ProximaNova-Regular";
  margin-top: -15px;
}

.disabled-preview-btn {
  background-color: #d9d9d9 !important;
  color: #454545 !important;
}

.leave-request-error-container {
  background: #fae6e6;
  padding: 8px;
  border-radius: 8px;
  width: inherit;
  display: flex;
  align-items: center;
  gap: 16px;
}

.leave-request-error-container p {
  margin: 0 !important;
}

.leave-request-error-message-container {
  display: flex;
  flex-direction: column;
}

#leave-request-error-title {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #000000;
  flex-wrap: wrap;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 8px;
}

#leave-request-error-desc {
  line-height: 1.6;
  font-weight: 400;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
}

.leave-type-error-wrapper {
  margin-top: 16px;
}

.expected-delivery-date-input {
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  background: #fff;
  display: flex;
  height: 40px;
  padding: 14px 8px 14px 12px;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.expected-delivery-date-text {
  color: #8c8c8c;
  display: flex;
  align-items: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

.maternity-leave-warning {
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  padding: 15px;
  border-radius: 8px;
  background-color: #e6efff;
  color: #0642b5;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.maternity-leave-warning.short-term-warning {
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
  line-height: 18px;
  margin: 5px 0 0;
}
.leave-request-error-container {
  background: #fae6e6;
  padding: 8px;
  border-radius: 8px;
  width: inherit;
  display: flex;
  align-items: center;
  gap: 16px;
}

.leave-request-error-container p {
  margin: 0 !important;
}

.leave-request-error-message-container {
  display: flex;
  flex-direction: column;
}

#leave-request-error-title {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #000000;
  flex-wrap: wrap;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 8px;
}

#leave-request-error-desc {
  line-height: 1.6;
  font-weight: 400;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
}

.leave-type-error-wrapper {
  margin-top: 16px;
}

.emptyleave_data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.emptyleave_data img {
  width: 105px;
}
.emptyleave_data p {
  margin: 6px 0 0;
  font-size: 16px;
  font-family: "ProximaNova-SemiBold";
  width: 100%;
  text-align: center;
  color: #000;
}
.emptyleave_data span {
  font-size: 14px;
  color: #000;
  width: 400px;
  text-align: center;
  line-height: 20px;
  font-family: "ProximaNova-Regular";
  margin: 8px 0 0;
}
