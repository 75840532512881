.parent_pagination_icons {
  margin-left: 8px;
}

.pagination_left_icon {
  cursor: pointer;
}

.pagination_right_icon {
  margin-left: 15px !important;
  cursor: pointer;
}

.pagination-bg {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #b0b3ba !important;
  border-top: none !important;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
