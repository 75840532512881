.application-preview-container {
  margin-top: 60px;
  padding: 16px 60px;
}

.application-preview-container .achivements-container {
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.achivements-container .pdf-scroll-holder {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  overflow-x: scroll;
}

.achivements-container .pdf-scroll-holder::-webkit-scrollbar {
  display: none;
}

.achivements-container .section-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.application-preview-container .breadcrumb-container {
  margin-bottom: 18px;
}

.application-preview-container .achivement-card-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

/* Achivement Data Card */
.achivement-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.achivement-data .achivement-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.achivement-data .achivement-desc {
  color: #333;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/* PDF Card */
.pdf-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 363px;
}

.pdf-card-container .pdf-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pdf-card-container .pdf-image {
  padding: 5px 7px;
  background-color: #fff;
  border-radius: 8px;
  height: 35px;
  object-fit: contain;
}

.pdf-card-container .pdf-name-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.pdf-card-container .pdf-card-name {
  color: #282a37;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  width: 240px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.pdf-card-container .pdf-card-size {
  color: #595959;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}

.pdf-card-container .close-btn-pdf {
  height: 24px;
  width: 24px;
  object-fit: contain;
  cursor: pointer;
}

/* Preview Modal */
.preview-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.preview-modal .preview-modal-img {
  height: 120px;
  width: 120px;
}

.preview-modal .preview-modal-text {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}

.preview-modal .preview-modal-desc {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.preview-modal .preview-modal-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 32px;
}

.preview-modal .preview-btn {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 8px;
  cursor: pointer;

  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.preview-modal .preview-btn-cancel {
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #000;
}

.preview-modal .preview-btn-next {
  color: #fff;
  background: #0642b5;
}

.preview-modal .preview-modal-points {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 8px 0px 16px;
  background: #E65A14;
  color: #FFF;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  line-height: 24px;
}