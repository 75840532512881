.timesheet-shift-box-container {
  display: flex;
  border-radius: 8px;
  background-color: #ffffff;
  /* width: 250px; */
  border: #c7c7c7 solid 1px;
  justify-content: space-between;
}
.timesheet-shift-box {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.timesheet-shift-box-container .timesheet-shift-box .row-shift-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.timesheet-shift-box-container .text-shift-title {
  color: #0A0A0A;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  padding-left: 20px;
  padding-bottom: 15px;
}
.div-active{
  border: #0642B5 solid 1px !important
}
