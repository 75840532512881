.goal_active-otr {
  margin: 140px 60px 0 40px;
}

.goal_active {
  margin: 0;
  background: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goal_active h1 {
  margin: 0 0 15px;
  display: table;
  width: 100%;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.goal_active ul li span {
  width: 100%;
  display: table;
  font-size: 24px;
  line-height: 28px;
  font-family: "ProximaNova-Semibold";
}

.goal_active ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.goal_active ul li span.title {
  width: 100%;
  display: table;
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNova-Regular";
}

.goal_active ul li {
  margin: 0 50px 0 0;
  position: relative;
  display: table;
}

.goal_active ul li:before {
  content: "";
  position: absolute;
  right: -25px;
  top: 50%;
  width: 2px;
  height: 40px;
  background: #d9dbe3;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.goal_active ul li:last-child:before {
  background: transparent;
}

.goal_year-drop a.ant-dropdown-trigger img {
  display: table;
}

.goal_year-drop a.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  color: #2b3348;
  border: 1px solid #000;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0 16px 0 0;
}

.goal_year-drop a.ant-dropdown-trigger .ant-space-item {
  display: table;
  margin: 0;
  color: #2b3348;
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Semibold";
}

.goal_year-drop button.ant-btn {
  padding: 6px 16px;
  height: auto;
}

.goal_year-drop {
  display: flex;
  margin: -30px 0 0;
}

span.title-miles {
  font-size: 16px;
  color: #232a3d;
  font-family: "ProximaNova-Semibold";
  margin-bottom: 9px;
  width: 210px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goal_year-drop a.quality-btn {
  margin: 0 16px 0 0;
}

.goal_year-drop a.quality-btn button.ant-btn {
  font-size: 16px;
  line-height: 22px;
  padding: 8px 16px;
  border-color: #0642b5;
  background: #e6efff;
  color: #2b3348;
  font-family: "ProximaNova-Semibold";
}

.goal_checkin-otr {
  border-radius: 8px;
  background: #fff;
  padding: 63px 43px;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.goal_checkin-card {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  padding: 16px;
}
.goal_card_img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.goal_card_img img {
  width: 100%;
  height: 100%;
}
.goal_card_quater {
  color: #1976d2;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
  margin-bottom: 16px;
}
.goal_card_title {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.goal_card_para {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  margin-bottom: 16px;
}
.review_btn {
  border-radius: 4px;
  background: #0642b5;
  width: 88px;
  height: 32px;
  padding: 5px 16px 4px 16px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}
.cascaded-table tr {
  position: relative;
}
.cascaded-table tr td {
  padding: 34px 16px 16px !important;
}
.cascaded-by-area {
  top: 0;
  left: -48px;
  position: absolute;
  background: #fdefe8;
  padding: 5px 12px;
  color: #e65a14;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  line-height: 16.8px;
}

.cascade-draft-by-area {
  top: 0;
  right: 55px;
  position: absolute;
  background: #e8e8e8;
  padding: 5px 12px;
  color: #5c5c5c;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  line-height: 16.8px;
  text-transform: capitalize;
}

.contribute-by-area {
  background: #FFF7E6;
  color: #B57B06;
}

.draft-by-area {
  background: rgb(156, 159, 169);
  color: #fff;
}


.Draft {
  border-radius: 8px;
  background: #f0f0f0;
  width: 64px;
  height: 32px;
  color: #8c8c8c;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}
.goal-status-btn {
  color: #fff;
}
