.reaction_popup .ant-modal-content {
  padding: 0;
}
.reaction_popup .ant-modal-close .ant-modal-close-x span svg {
  fill: #000;
}
.reaction_popup .ant-modal-header {
  padding: 16px;
  border-bottom: 1pt solid #e9eaeb;
  margin-bottom: 0;
}
.tabs {
  display: flex;
  height: 50px;
  margin-bottom: 8px;
}
.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0;
  background: #fff;
}
.tab.active {
  border: 0;
  border-bottom: 3pt solid #0642b5;
}
.panel {
  display: none;
}
.panel.active {
  display: block;
}
.profile-pic {
  position: relative;
  margin-right: 16px;
}
.profile-picture {
  height: 40px;
  width: 40px;
  border-radius: 40px;
}
.profile-reactions {
  position: absolute;
  bottom: 5px;
  right: 0;
  height: 16px;
  width: 16px;
}
.profile-pic-area {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 9px;
}
.reaction_popup .ant-modal-footer {
  display: none;
}
.tabs button {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  cursor: pointer;
}
.tabs button > img {
  margin-right: 8px;
}
.profile-name {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
}
.reaction_popup .panels {
  height: 400px;
  overflow-y: auto;
}
.reaction_popup .ant-modal-title {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  line-height: 24px;
}
.icons {
  width: 16px;
  height: 16px;
}
