.application-page {
  display: flex;
  margin-top: 60px;
  padding: 24px 60px;
  flex-direction: column;
}
.application-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 16px;
}
.profile-section {
  flex-direction: row;
  display: flex;
}
.profile-section .form-user-details {
  margin-left: 10px;
}
.profile-section .user-name {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0a0a0a;
}
.profile-section .user-designation {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #6f6f6f;
}
.form-text-field .inputField {
  height: 40px !important;
  gap: 10px;
  border-radius: 8px;
  border-width: 1px;
  border-color: #c7c7c7;
  background-color: #f8f8f8;
  outline: none;
  border-style: solid;
  margin-top: 10px;
  flex: 1;
  width: 100% !important;
  padding: 10px 15px 10px 10px;
}
.form-text-field .inputFieldEditable {
  background-color: #ffffff;
}
.form-basic-details-card {
  margin-top: 25px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
}
.form-basic-details-card .title-basic-detail {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1f1f1f;
}
.form-text-fields-card {
  flex-direction: column;
  display: flex;
  margin-top: 10px;
}
.text-field-title-section {
  display: flex;
  flex-direction: row;
}
.form-text-field {
  margin: 15px 15px 0px 0px;
}
.text-field-title {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #0a0a0a;
  /* max-height: calc(3 * 1.5 * 14px); */
  text-overflow: ellipsis;
  /* margin-right: 10px; */
}
.project-detsils {
  margin-top: 25px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
}
.project-detsils .project-detail-title {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1f1f1f;
  margin-bottom: 15px;
}
.layoutContainer .ant-steps-item-process .ant-steps-item-icon::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 0;
  background: red;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.project-detsils .ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "ProximaNova-Regular";
  display: flex;
  width: 100%;
  text-align: initial;
}

.projectDetailSteps:where(.css-dev-only-do-not-override-ni1kz0).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #0642b5;
  gap: 0px;
  opacity: 0px;
  position: relative;
  float: inline-end;
  width: 200%;
  height: 200%;
  border-radius: 200px;
  transition: all 0.5s;
}
.projectDetailSteps:where(
    .css-dev-only-do-not-override-ni1kz0
  ).ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  top: 24px;
  inset-inline-start: 0;
  margin: -5px;
  padding: 16px 0 8px;
  height: 90%;
}
.projectDetailSteps .ant-steps-item-title {
  color: #000;
  text-align: justify;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
}
.projectDetailSteps,
:where(.css-dev-only-do-not-override-ni1kz0).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #454545;
}

.projectDetailSteps,
:where(.css-dev-only-do-not-override-ni1kz0).ant-steps
  .ant-steps-item-subtitle {
  display: flex;
  margin-left: 0px;
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.projectDetailSteps .ant-steps-item-description {
  white-space: pre-wrap;
}

.footer-sticky-application-form {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
  width: 100%;
  /* position: fixed; */
  /* flex-direction: row; */
}
.footer-sticky-buttons-container {
  height: 72px;
  width: 100% !important;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  position: fixed;
  padding-right: 60px;
  bottom: 0px;
  z-index: 999;
  justify-content: flex-end;
}
.footer-sticky-edit-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border-color: #0642b5;
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  background: #fff;
  margin-right: 20px;
  min-width: 120px;
}
.footer-sticky-apply-btn {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0642b5;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  cursor: pointer;
  border-width: 0px;
  min-width: 120px;
}
.btn-disablled {
  background: #e2e2e2;
  color: #858585;
  cursor: not-allowed;
}
.attachment-dotted-border {
  margin-top: 25px;
  border-radius: 8px;
  border-color: #d5d8e2;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}
.attach-file-img {
  align-self: center;
  width: 32px;
  height: 32px;
}

.attachment-outer-border,
:where(
    .css-dev-only-do-not-override-ni1kz0
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  /* width: 134px; */
  height: 102px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
}
.no_attachment_uploaded {
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  color: #262626;
  margin-top: 10px;
}
.click_to_add_file {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #595959;
  margin-top: 5px;
}
.achievements-highlights {
  color: #0a0a0a;
  margin-top: 25px;
}
.highlights-input {
  border-radius: 8px;
  border: 1px;
  outline: none;
  border-style: solid;
  margin-top: 10px;
  width: 100% !important;
  padding: 10px 10px 60px 10px;
  border-color: #c7c7c7;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #292929;
}
.certificate-containet {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 20px 0px 20px 0px;
  gap: 10px;
  overflow-x: auto;
}
.certificate-containet::-webkit-scrollbar {
  width: 10px;
  scrollbar-width: none;
}
.click-here-to-add {
  flex-direction: row;
  text-align: center;
  align-items: center;
  display: flex;
  width: "100%";
  justify-content: center;
}
.click-here-to-add span {
  color: #0642b5;
  margin-left: 5px;
  cursor: pointer;
  margin-top: 6px;
}
.title-basic-detail img {
  width: 16px;
  height: 16px;
  margin-left: 2px;
}
.popover-certificate {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.title-basic-detail .ant-space-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkbox-email {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 16px;
  align-items: center;
  /* justify-content: center; */
}
.checkbox-email .check-box {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1.5px solid #8f8f8f;
  cursor: pointer;
}
.checkbox-email .checkbox-text {
  color: #292929;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.checkbox-email .checkbox-active {
  background-color: #0642b5;
  border-color: #0642b5;
}

.check-box .checkboximg {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
