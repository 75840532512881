.certify_otr-field {
  display: table;
  width: 100%;
  position: relative;
  margin: 0 0 0;
  background: #fff;
  border-radius: 8px;
  padding: 24px 24px 0;
}

.Seperation-page-title {
  margin-bottom: 20px;
}

.certify_otr-field .ant-col.ant-form-item-label {
  display: table;
  width: 100%;
  text-align: left;
}

.certify_otr-field .ant-col.ant-form-item-control {
  width: 100%;
  display: table;
}

.certify_otr-field .ant-col.ant-form-item-control input {
  border: 1px solid #bfbfbf;
  height: 40px;
  border-radius: 4px;
  padding: 14px 12px;
}
.certify_otr-field .ant-col.ant-form-item-control input:hover,
.certify_otr-field .ant-col.ant-form-item-control input:focus {
  border: 1px solid #bfbfbf;
  box-shadow: none;
}

.certify_otr-field .ant-col.ant-form-item-control input::placeholder {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 20px;
}

.certify_otr-field .ant-col.ant-form-item-control textarea {
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  padding: 14px 16px;
  resize: none;
}
.certify_otr-field .ant-col.ant-form-item-control textarea:hover,
.certify_otr-field .ant-col.ant-form-item-control textarea:focus {
  border: 1px solid #bfbfbf;
  box-shadow: none;
}

.certify_otr-field .ant-col.ant-form-item-control textarea::placeholder {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 20px;
}

.certify_otr-field .ant-col.ant-form-item-label label {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-family: "ProximaNova-Regular";
}

.certify_otr-field .ant-col.ant-form-item-label label:before {
  position: absolute;
  right: -7px;
  top: 3px;
  color: #cc0000 !important;
  font-size: 11px !important;
  margin: 0 !important;
}

.certify_otr-field h4 {
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin: 0 0 16px;
  font-family: "ProximaNova-Semibold";
}

.head_title button {
  background: transparent;
  border: 0px solid;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.head_title button img {
  width: 17px;
}

.certify_otr-field .ant-form-item.effective-date {
  float: left;
  width: 50%;
  margin-bottom: 0;
}

.ant-form-item.expirydate {
  margin-bottom: 0;
}

.certify_otr-field .ant-form-item.effective-date .ant-picker {
  width: 60%;
  border: 1px solid #bfbfbf;
  height: 40px;
  border-radius: 4px;
  padding: 14px 12px;
}

.certify_otr-field .ant-form-item.effective-date .ant-picker input {
  border: 0px solid;
  padding: 0;
  height: auto;
}

.certify_otr-field .ant-form-item.expirydate .ant-picker {
  width: 60%;
  border: 1px solid #bfbfbf;
  height: 40px;
  border-radius: 4px;
  padding: 14px 12px;
}
.certify_otr-field .ant-form-item.expirydate .ant-picker.ant-picker-disabled {
  background: transparent;
}
.certify_otr-field
  .ant-form-item.effective-date
  .ant-picker
  span.ant-picker-suffix {
  font-size: 24px;
  color: #2b3348;
}

.certify_otr-field .ant-form-item .ant-picker span.ant-picker-suffix {
  cursor: pointer;
  pointer-events: initial;
}

.certify_otr-field .ant-form-item.expirydate .ant-picker input {
  border: 0px solid;
  padding: 0;
  height: auto;
}
.certify_otr-field
  .ant-form-item.expirydate
  .ant-picker
  span.ant-picker-suffix {
  font-size: 24px;
  color: #2b3348;
}

.certify_otr-field button.ant-btn {
  border: 1px solid #000;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 148px;
  color: #0642b5;
  background: transparent;
  font-family: "ProximaNova-Semibold";
}
.certify_otr-field .ant-col.ant-form-item-label label span.anticon {
  position: absolute;
  right: -15px;
  color: #454545;
  top: -2px;
}
.certify_otr-field
  .ant-form-item:nth-child(2)
  .ant-row
  .ant-col.ant-form-item-label
  label
  span.anticon {
  right: -23px !important;
}
.certify_otr-field .ant-col.ant-form-item-label label span.anticon svg {
  width: 10px;
}
.ant-form-item.add-certify {
  display: table;
  margin: 0;
}

.ant-form-item.add-certify button.ant-btn {
  background: transparent;
  border: 0px solid;
  height: auto;
  box-shadow: none;
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  outline: none;
  outline-offset: 0;
  justify-content: start !important;
}

.ant-form-item.add-certify button.ant-btn:focus {
  box-shadow: none;
}

.ant-form-item.add-certify button.ant-btn div {
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.ant-form-item.add-certify button.ant-btn span {
  font-size: 14px;
  padding-left: 5px;
  line-height: 20px;
}

.ant-form-item.certify-back {
  float: left;
  margin-right: 20px;
  margin-bottom: 0;
}

.head_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head_title span.anticon.anticon-delete {
  color: #1f1f1f !important;
}

.btn_otr-sec {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px 32px 10px 16px;
}

.certify_btn-otr {
  display: flex;
}

.ant-form-item.certify-prev {
  margin: 0;
}

/* delete popup */
.ant-modal.delete_popup-inn .ant-modal-content {
  border: 1px solid #d9dbe3;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  width: 380px;
}

.ant-modal.delete_popup-inn .ant-modal-content button.ant-modal-close {
  display: none;
}

.ant-modal.delete_popup-inn .ant-modal-content h2 {
  color: #000000;
  font-size: 20px;
  line-height: 24px;
  font-family: "ProximaNova-Semibold";
}

.ant-modal.delete_popup-inn .ant-modal-content p {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
  padding: 0 15px;
}

.ant-modal.delete_popup-inn .ant-modal-content .ant-modal-footer {
  display: table;
  margin: 0 auto;
}

.ant-modal.delete_popup-inn .ant-modal-content button {
  border: 1px solid #2b3348;
  border-radius: 4px;
  width: 155px;
  height: 40px;
  margin-right: 8px;
  font-size: 16px;
  line-height: 22px;
  padding: 0;
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
}

.ant-modal.delete_popup-inn .ant-modal-content button.ant-btn-primary {
  color: #fff;
}

.certify_btn-otr button.ant-btn {
  border: 1px solid #bfbfbf;
  padding: 9px 16px;
  height: auto;
  width: 148px;
  border-radius: 4px;
  color: #454545;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
}

/* .ant-form-item.certify-prev button.ant-btn {
    border-color: #F0F0F0;
    background: #F0F0F0;
    color: #8C8C8C;
} */
.certify_otr-field .ant-form-item:last-child {
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 24px;
  padding-top: 24px;
}

.certify_otr-field:nth-last-child(2) .ant-form-item:last-child {
  border-bottom: 0px solid;
}

.head_title button:last-child {
  position: absolute;
  right: 60px;
}

/* popup popover */
.ant-popover.ant-popconfirm {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-popover.ant-popconfirm:before {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  top: 0;
  left: 0;
}

.ant-popover.ant-popconfirm .ant-popover-content {
  width: 374px;
  text-align: center;
}

.ant-popover.ant-popconfirm .ant-popover-content .ant-popover-inner {
  padding: 16px;
  border: 1px solid;
  border-radius: 4px;
}

.ant-popover.ant-popover-hidden {
  display: none;
}

.ant-popover .ant-popover-content .ant-popconfirm-message-title {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  font-family: "ProximaNova-Semibold";
  display: table;
  margin: 0 0 10px;
}

.ant-popover .ant-popover-content span.ant-popconfirm-message-icon {
  display: none;
}

.ant-popover .ant-popover-content .ant-popconfirm-description {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-family: "ProximaNova-Regular";
}

.ant-popover .ant-popover-content .ant-popconfirm-buttons {
  display: flex;
  margin: 15px auto 0;
  align-items: center;
  justify-content: space-between;
}

.ant-popover .ant-popover-content .ant-popconfirm-buttons button.ant-btn {
  width: 155px;
  padding: 10px 16px;
  height: auto;
  border-radius: 4px;
  border: 1px solid #000;
  color: #0642b5;
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Semibold";
  margin: 0;
}
.ant-popover
  .ant-popover-content
  .ant-popconfirm-buttons
  button.ant-btn.ant-btn-primary {
  background: #0642b5;
  border-color: #0642b5;
  color: #fff;
}

.ant-modal-root .ant-modal-mask {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
}

/* back popup */
.backpopup .ant-modal-content {
  width: 374px;
  display: table;
  margin: 0 auto;
}

.backpopup .ant-modal-content button.ant-modal-close {
  display: none;
}

.backpopup .ant-modal-content .ant-modal-header {
  display: none;
}
.backpopup .ant-modal-content h5 {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 15px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.backpopup .ant-modal-content p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  color: #000;
  font-family: "ProximaNova-Regular";
  margin-bottom: 16px;
}
.backpopup .button-section {
  justify-content: flex-start;
}

.btn_otr-sec.edit-certify {
  justify-content: right;
}

.single-button-section {
  justify-content: center !important;
}
.expirydate .ant-picker-input:hover .anticon-close-circle > svg,
.effective-date .ant-picker-input:hover .anticon-close-circle > svg {
  width: 16px;
  height: 16px;
}
.ant-picker-panel-container {
  border-radius: 4px;
  border: 1px solid #b1b4bb;
}
.custom-tooltip .ant-tooltip-content {
  width: 325px;
}
.second-custom-tooltip .ant-tooltip-content {
  width: 425px;
}
.third-custom-tooltip .ant-tooltip-content {
  width: 255px;
}

/* upload */
.certify_otr-field .ant-form-item.document-upload {
  margin-top: 0;
  margin-bottom: 0;
}
.certify_otr-field .ant-form-item.document-upload label {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}
.certify_otr-field .ant-form-item.document-upload .ant-form-item-label {
  padding: 0;
}
.certify_otr-field .ant-form-item.document-upload .ant-upload-list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.certify_otr-field .document-upload .ant-upload.ant-upload-select {
  width: 100%;
  order: 1;
  margin: 0;
  height: auto;
}
.certify_otr-field
  .document-upload
  .ant-upload.ant-upload-select
  span.ant-upload {
  border: 2px dashed #757a87;
  width: 100%;
  padding: 24px 83px 24px 83px;
  border-radius: 2px;
}
.certify_otr-field
  .document-upload
  .ant-upload.ant-upload-select
  span.ant-upload
  button {
  width: 100%;
  padding: 0;
}
.certify_otr-field
  .document-upload
  .ant-upload-select
  span.ant-upload
  .upload-dis {
  font-size: 14px;
  color: #1f1f1f;
  line-height: 20px;
  font-family: "ProximaNova-Semibold";
}
.certify_otr-field
  .document-upload
  .ant-upload-select
  span.ant-upload
  .upload-dis
  span {
  font-size: 14px;
  color: #0642b5;
  line-height: 20px;
  font-family: "ProximaNova-Semibold";
}
.certify_otr-field
  .document-upload
  .ant-upload-select
  span.ant-upload
  .upload-margin {
  font-size: 12px;
  color: #8c8c8c;
  line-height: 17px;
  font-family: "ProximaNova-Regular";
  margin-top: 10px;
}
.certify_otr-field
  .ant-form-item.document-upload
  .ant-upload-list
  .ant-upload.ant-upload-select:hover {
  border-color: transparent;
}
.certify_otr-field
  .ant-form-item.document-upload
  .ant-upload-list
  .ant-upload-list-item-container {
  order: 2;
  margin: 12px 12px 0 0;
  width: 253px;
  height: 180px;
}
.document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item {
  padding: 0;
  border-radius: 4px;
  border-color: #d9d9d9;
}
.certify_otr-field
  .document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item:before {
  opacity: 0.6;
  width: 100%;
  height: 45px;
  background: #000;
  bottom: 0;
  border-radius: 2px 2px 4px 4px;
}
.certify_otr-field
  .document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item:hover:before {
  opacity: 0.6;
}
.certify_otr-field
  .document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item
  span.ant-upload-list-item-name {
  color: #fff !important;
  z-index: 9;
  bottom: 9px;
  left: 12px;
  width: 80%;
  text-align: left;
  padding: 0;
  display: block;
  position: absolute;
}
.certify_otr-field
  .document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item
  span.ant-upload-list-item-actions {
  opacity: 1;
  bottom: 8px;
  right: 7px;
  width: auto;
  text-align: left;
  height: 24px;
  left: auto;
}
.certify_otr-field
  .ant-form-item.document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  button.ant-btn {
  width: 23px;
  background: transparent;
  margin-left: 7px;
}
.certify_otr-field
  .document-upload
  .ant-upload-list-item
  span.ant-upload-list-item-actions
  a {
  display: none;
}
.certify_otr-field
  .document-upload
  .ant-upload-list-item
  span.ant-upload-list-item-actions
  button.ant-btn {
  padding: 0;
}
/*.certify_otr-field .document-upload .ant-upload-list-item span.ant-upload-list-item-actions button.ant-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    background: url("../../themes/assets/images/svg/Uploadclose.svg");
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}*/
.certify_otr-field
  .document-upload
  .ant-upload-list-item
  span.ant-upload-list-item-actions
  button.ant-btn
  svg {
  /* display: none; */
}
.document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail {
  width: 100%;
  height: 100%;
}
.document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  span.anticon {
  width: 100%;
  height: 100%;
}
.document-upload
  .ant-upload-list
  .ant-upload-list-item-container
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  span.anticon
  svg {
  width: 100%;
  height: 100%;
}

.certify_otr-field .ant-form-item.instruct_name {
  float: left;
  width: 50%;
  margin-bottom: 0;
}
.certify_otr-field .ant-form-item.instruct_name .ant-form-item-control-input {
  width: 60%;
  height: 40px;
  border-radius: 2px;
  padding: 0;
}
.certify_otr-field .ant-form-item.cerify_skills .ant-form-item-control-input {
  height: auto;
  border-radius: 2px;
  padding: 0;
}
.certify_otr-field
  .ant-form-item.cerify_skills
  .ant-form-item-control-input
  .ant-select.ant-select-in-form-item {
  width: 60% !important;
  display: table;
}
.certify_otr-field
  .ant-form-item.cerify_skills
  .ant-form-item-control-input
  .ant-select.ant-select-in-form-item
  .ant-select-selector {
  height: 40px;
  position: relative;
  padding: 0;
  border-radius: 4px;
}
.certify_otr-field
  .ant-form-item.cerify_skills
  .ant-form-item-control-input
  .ant-select.ant-select-in-form-item
  .ant-select-selector:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url(../../themes/assets/images/svg/SkillsSearch.svg);
}
.certify_otr-field
  .ant-form-item.cerify_skills
  .ant-select-selector
  span.ant-select-selection-placeholder {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 20px;
  left: 45px;
}
.certify_otr-field
  .ant-form-item.cerify_skills
  .ant-form-item-control-input
  .skills_list-inn {
  float: left;
  border: 1px solid #b1b4bb;
  background: #f0f0f0;
  border-radius: 20px;
  padding: 8px;
  width: 80px;
  text-align: center;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 12px 0 0;
}
.certify_otr-field .cerify_skills .ant-select-selection-overflow-item {
  display: none;
}
.certify_otr-field .cerify_skills span.ant-select-arrow {
  display: none;
}
.certify_otr-field
  .cerify_skills
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: block;
  width: 100%;
}
.certify_otr-field
  .cerify_skills
  .ant-select-selection-overflow-item-suffix
  .ant-select-selection-search {
  margin: 0;
  left: 0;
  width: 100% !important;
  padding-left: 42px;
  padding-right: 10px;
}
.certify_otr-field
  .cerify_skills
  .ant-select-selection-overflow-item-suffix
  .ant-select-selection-search
  input {
  border: 0px solid;
}
.certify_otr-field .ant-form-item-control-input span.ant-tag {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: flex;
  margin: 12px 12px 0 0;
  background: #f0f0f0;
  min-width: 80px;
  font-family: "ProximaNova-Regular";
  align-items: center;
  flex-wrap: wrap;
  float: left;
  justify-content: center;
}
.certify_otr-field .ant-form-item-control-input span.ant-tag svg {
  font-size: 16px;
  color: #000;
  border: 1px solid;
  border-radius: 50%;
  padding: 2px;
  margin-left: 4px;
}
.ant-select-item-empty {
  padding: 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: #454545;
  font-family: "ProximaNova-Regular";
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  border-radius: 4px;
}

.skill_dropdown-otr {
  display: table;
  width: 50%;
  margin: 0 0 24px;
}
.skill_dropdown-otr .ant-form-item.cerify_skills {
  border: 0px solid;
  padding: 0;
  margin: 0 0 12px;
}
.skill_dropdown-otr span.ant-tag {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 6px 8px 7px;
  background: #f0f0f0;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNova-Regular";
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}
.skill_dropdown-otr span.ant-tag span.anticon {
  color: #000000;
  border: 1px solid #000000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  font-family: "ProximaNova-Semibold";
}
.certify_otr-field .ant-form-item.document-upload span.ant-upload-wrapper {
  width: 100%;
}
.certify_otr-field .ant-form-item.document-upload .ant-form-item-label {
  margin: 0 0 5px;
}

.file_error {
  color: red;
}
.headingWrapper {
  background: #fff;
  height: 101px;
  left: 0;
  margin: 0 !important;
  position: fixed;
  top: 55px;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  display: flex !important;
  justify-content: start;
  padding: 16px 0px 17px 60px;
  flex-direction: column;
  align-items: flex-start !important;
}
.headingWrapper .Seperation-page-title {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  line-height: 28.8px;
  margin-bottom: 0;
}

.certify_main-otr {
  margin: 80px 60px 20px;
}

.certification-page-title {
  margin-top: 12px !important;
}

.certify_main-otr .ant-form-item .ant-form-item-label > label::after {
  display: none;
}

.hidestricky_breadcrump .Seperation-page-title {
  margin-top: 20px;
  font-size: 24px;
}
