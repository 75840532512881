:where(.css-dev-only-do-not-override-1fviqcj).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1fviqcj).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  font-weight: 600;
  text-align: start;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
  font-family: "ProximaNova-bold";
}

.publish-to-prod-btn {
  background-color: rgb(9, 93, 255);
  color: rgb(255, 255, 255);
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
}
.publishedbutton {
  background-color: green;
  color: rgb(255, 255, 255);
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
}
.edit-button {
  background-color: rgb(9, 93, 255);
  color: rgb(255, 255, 255);
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  margin-right: 10px;
}

.delete-button {
  background-color: rgb(9, 93, 255);
  color: rgb(255, 255, 255);
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #000 !important;
  font-family: "ProximaNova-Semibold";
}

/* .ant-tabs-tab {
    border-bottom: 1px solid red;
 } */

.ant-tabs-tab-active {
  border: 2px solid #b0b3ba !important;
  border-bottom: 0 !important;
  background-color: #f3f8ff !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important;
  font-family: "ProximaNova-Semibold";
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #b0b3ba !important;
}

table {
  border-color: #b0b3ba;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0px !important;

  .ant-table-cell {
    border-start-start-radius: 0px !important;
    border-start-end-radius: 0px !important;
  }
}

/* .table_structure {
    margin-left: 15px;
    margin-right: 15px;
} */

.ant-table-pagination {
  display: none !important;
}

table thead tr th {
  border-bottom: 2px solid #b0b3ba !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  transition: background 0.2s, border-color 0.2s;
  border-bottom: 1px solid #b0b3ba !important;
}
.custom-text-area .ant-input {
  height: 100px !important;
}

.table-edit-icon {
  font-size: 20px;
}

.table-delete-icon {
  font-size: 20px;
}

.table_title_sorticon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "ProximaNova-Semibold";
}

.ant-table-column-sorter {
  display: none;
}

.hide-filter-icon {
  .ant-table-filter-trigger {
    display: none;
  }
}

.hide-filter-sorter {
  .ant-table-column-sorter {
    display: none;
  }
}

.hide-filter-sorter {
  .ant-table-filter-column {
    cursor: none;
    pointer-events: none;
  }
}

.filterBlue-svg path {
  fill: blue;
}

.ant-table-wrapper .ant-table-tbody tr td b {
  color: #2b3348;
  font-size: 14px;
  font-family: "ProximaNova-Light";
}
.notification_Details {
  font-size: 20px;
}
.contentStyle {
  width: 55% !important;
}
