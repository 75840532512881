.my-activities-wrapper {
  width: 100%;
  height: 100vh;
  margin: auto;
  padding: 70px 50px 90px 50px;

  ::-webkit-scrollbar {
    display: none;
  }
  .link-highlight {
    color: #1976d2;
  }
  .capsule {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 32px;
    .tab {
      border: 1px solid #dbdbdb;
      padding: 12px 20px;
    }
    .left-tab {
      border-radius: 32px 0px 0px 32px;
    }
    .right-tab {
      border-radius: 0px 32px 32px 0px;
    }
    .tab-active {
      border: 1px solid #0642b5;
      color: #0642b5;
      font-weight: 600;
      font-family: "ProximaNova-SemiBold";
    }
  }

  .body-section {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    overflow: scroll;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 10px;
  }
}
