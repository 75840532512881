.action-status-text-title {
  width: 85%;
  margin-top: 10px;
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
}
.action-status-main-view {
  border-radius: 8px;
  background-color: #f8f8f8;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tracker-row-view {
  display: flex;
  flex-direction: row;
}
.tracker-line-view {
  display: flex;
  position: relative;
  flex-direction: column;
}

.dot-view {
  background-color: #0642b5;
  height: 5px;
  width: 5px;
  border-radius: 2.5px;
  position: absolute;
  left: 0;
  top: 20%;
  transform: translateY(-50%);
  /* margin-top: 10px; */
}
.dot-view-sub {
  background-color: #dbdbdb;
  height: 5px;
  width: 5px;
  border-radius: 2.5px;
  position: absolute;
  left: 0;
  top: 20%;
  transform: translateY(-50%);
  /* margin-top: 10px; */
}

.line-view {
  background-color: #dbdbdb;
  height: 35px;
  width: 2px;
  position: absolute;
  left: 1px;
  top: 30%;
}

.tracker-row-view {
  padding-left: 10px;
  position: relative;
  padding-top: 3px;
}
.tracker-row-view:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 5px;
  height: 5px;
  background: #dbdbdb;
  border-radius: 50%;
}
.tracker-row-view:nth-child(2):before {
  background: #0642b5;
}
.tracker-row-view:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 18px;
  width: 1px;
  height: 80%;
  background: #dbdbdb;
}

.tracker-row-view:last-child:after {
  opacity: 0;
}

.action-status-text {
  display: flex;
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
  font-size: 15px;
}
.tracker-row-view:nth-child(2) .action-status-text {
  font-family: "ProximaNova-Semibold";
}

.action-status_date {
  display: flex;
  width: 163px;
}
.action-status-text-grey {
  color: #5c5c5c;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  padding-left: 3px;
  width: 260px;
}

.tracker-row-view:nth-child(2) .action-status-text-grey {
  font-family: "ProximaNova-Semibold";
}
.apprval_tab_parent .status-row-view button.status-sub-view.selected {
  background-color: #e6efff !important;
  color: #0642b5 !important;
}
