body::-webkit-scrollbar {
  display: none;
}
.innovation-wrapper {
  width: 100%;
  margin: 0;
  padding: 70px 50px;
  box-sizing: border-box;

  .overview-outer-wrapper {
    margin: 20px 0;
  }

  .overview-header {
    margin-bottom: 20px;
  }

  .center {
    text-align: center;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row-center {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .body {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    gap: 25px;
    .carousel {
      background-color: #0634b5;
      display: flex;
      flex-direction: column;
    }

    .create-challenge-card {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 58px;
      background-color: #fff7e6;
      border-radius: 8px;

      .rb-lg {
        margin-bottom: -20px;
      }
      .rb-sm {
        margin-left: -20px;
        margin-top: -20px;
      }
      .link {
        color: #0634b5;
        text-decoration: underline;
      }
    }

    .activities {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .activities-card-section {
        display: flex;
        gap: 30px;
        .nodata-found-section {
          margin: 0 auto;
        }
      }
      @media only screen and (max-width: 768px) {
        .activities-card-section {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .explore-teams {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      border-radius: 8px;
      overflow: hidden;
      height: 180px;
      .text-section {
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 40px;
        .btn {
          color: #0642b5;
          border: 1px solid #0642b5;
          width: 160px;
          padding: 10px;
          border-radius: 8px;
          text-align: center;
        }
      }
    }
    .ideas {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .ideas-card-section {
        display: flex;
        gap: 30px;
        .nodata-found-section {
          margin: 0 auto;
        }
      }
      @media only screen and (max-width: 768px) {
        .ideas-card-section {
          display: flex;
          flex-direction: column;
        }
      }
    }
      .famebook {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 10px;
        .famebook-card-section {
          display: flex;
          gap: 30px;
          .nodata-found-section {
            margin: 0 auto;
          }
        }
        @media only screen and (max-width: 768px) {
          .famebook-card-section {
            display: flex;
            flex-direction: column;
          }
        }
    }
    .innovators-view {
      display: flex;
      gap: 30px;
      .testimonial-cards {
        display: flex;
        flex: 1;
      }
    }
    .innovation-helpdesk {
      background: linear-gradient(90deg, #ddd6f3 0%, #faaca8 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 60px;
      padding: 0px 80px;
      .mascot {
        background: linear-gradient(
          90deg,
          rgba(255, 15, 0, 0.15) -27%,
          rgba(255, 122, 0, 0.15) 73%
        );
        height: 238.8px;
        width: 238.8px;
        background-color: #0634b5;
      }
      .text-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .explore-more {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding: 15px 20px;
      a {
        text-decoration: underline;
      }
    }
  }
  .tab {
    padding: 8px 18px;
    border-radius: 30px;
  }
  .active-tab {
    background-color: #e6efff;
  }
  .inactive-tab {
    background-color: #ededed;
  }
  .tab-row {
    gap: 10px;
  }
}

// .ant-checkbox .ant-checkbox-inner {
//   border: 1.5px solid #8f8f8f;
//   height: 24px;
//   width: 24px;
//   border-radius: 4px;
// }

.ant-checkbox-disabled .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  background-color: #0634b5;
  border-color: #0634b5;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #8f8f8f !important;
}

.tab {
  padding: 6px 16px;
  border-radius: 30px;
}
.active-tab {
  background-color: #e6efff;
}
.inactive-tab {
  background-color: #ededed;
}
.tab-row {
  gap: 10px;
}
.custom-form-item {
  margin-bottom: 16px;

  .ant-col {
    label {
      color: #0a0a0a;
      font-family: "ProximaNova-Regular";
      font-size: 14px;
      line-height: 18px;
    }
    label::after {
      content: none;
    }
    label::before {
      color: #c00 !important;
      top: -3px;
      position: relative;
    }
    .ant-select-single,
    .ant-input {
      height: 40px;
    }
    .ant-input,
    .ant-select-selection-item {
      color: #1f1f1f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .ant-input::placeholder,
    .ant-select-selection-placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    .ant-input::-ms-input-placeholder,
    .ant-select-selection-placeholder {
      color: #8f8f8f;
    }
    .ant-select-selector {
      border-radius: 8px;
      border: 1pt solid #c7c7c7;
    }
    .ant-input {
      border: 1pt solid #c7c7c7;
    }
    .ant-form-item-control-input-content textarea {
      height: 100px !important;
      padding: 12px 11px;
    }
  }
}
.dex-row-detail-section {
  .dex-row-detail-title {
    color: #1f1f1f;
    font-family: "ProximaNova-SemiBold";
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 4px;
  }
  .dex-row-detail-para {
    color: #474747;
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 16px;
  }
}
.dex-row-detail-section:last-child .dex-row-detail-para {
  margin: 0;
}
.submit-challenge-popup {
  width: 374px !important;
  .ant-modal-content {
    padding: 16px;
  }
  .submit-challenge-popup-img {
    width: 90px;
    height: 80px;
    display: block;
    margin: 0 auto 20px;
  }
  .button-section button.ant-btn-default {
    border: 1px solid #c7c7c7 !important;
    color: #5c5c5c !important;
    border-radius: 8px !important;
  }
  .button-section button.ant-btn-primary {
    border-radius: 8px !important;
  }
}
.dex-heading-edit-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    background: transparent;
    border: 0;
    &:hover {
      background: transparent !important;
    }
  }
}
.dex-preview-page-status {
  position: absolute;
  top: 25px;
  right: 24px;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 18px;
  padding: 7px 10px;
  border-radius: 8px;
}
// .ant-checkbox-disabled .ant-checkbox-inner,
// .ant-checkbox-checked .ant-checkbox-inner {
//   width: 24px;
//   height: 24px;
//   background-color: #0634b5;
//   border-color: #0634b5;
// }
// .ant-checkbox-wrapper:hover .ant-checkbox-inner,
// .ant-checkbox:hover .ant-checkbox-inner,
// .ant-checkbox-input:focus + .ant-checkbox-inner {
//   border-color: #8f8f8f !important;
// }
