.row-main-header .ant-col {
  border-bottom: 1px solid #ccc;
  padding: 10px 0 10px 15px;
  background: #fff;
  color: #1f1f1f;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Semibold";
}
.row-sub-header .ant-col {
  border-bottom: 1px solid #ccc;
  padding: 10px 0 10px 15px;
  background: #fff;
  color: #1f1f1f;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}
.calculated-form-container {
  margin: 13px 0;
}
.rating-row {
  width: 210px !important;
}
.calculated-form-container ol {
  padding-left: 15px;
}