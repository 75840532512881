.opportunity-detail {
  margin-top: 60px;
  padding: 16px 60px;
  margin-bottom: 80px;
}

.opportunity-detail-page {
  height: 100vh !important;
  overflow-y: scroll;
}

.opportunity-detail-page::-webkit-scrollbar {
  display: none;
}

.space-top {
  margin-top: 16px;
  margin-bottom: 30px;
}

.job-main-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.job-title-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.job-title-data {
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.job-desc-data {
  color: #5c5c5c;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.info-card-holder {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.job-additional-info {
  border-radius: 8px;
  background: #fff;
  margin-top: 16px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.data-title {
  color: #333;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom: 8px;
}

.data-info {
  color: #5c5c5c;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.footer-opportunity {
  height: 72px;
  width: 100% !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  padding-right: 55px;
  bottom: 0px;
  z-index: 999;
}

.footer-btn-opportunity {
  background-color: #0642b5;
  width: 120px;
  height: 40px;
}

.job-banner-section {
  margin-bottom: 42px;
  position: relative;
}

.job-banner-img {
  height: 150px;
  width: 100%;
}

.job-banner-img img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.company-logo-img {
  width: 120px;
  height: 120px;
  background: #f5f5f5;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  bottom: -32px;
  left: 52px;
}

.company-logo-img img {
  width: 100%;
  height: 100%;
  border: 1.5pt solid #e2e2e2;
  border-radius: 50%;
  padding: 15px;
  object-fit: contain;
}

.job-status {
  position: absolute;
  right: 0;
  top: 0;
  height: 32px;
  padding: 6px 16px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  border-radius: 0px 8px;
  background-color: red;
  color: white;
}

.job-status-alert-area .ant-alert {
  border: 0;
  border-radius: 8px;
  background: #fae6e6;
  margin-top: 16px;
  padding: 10px 16px;
}

.job-status-alert-area .ant-alert-content {
  margin-left: 8px;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.detail-ijp {
  font-size: 20px !important;
}