.quarterReview .quarterTitle {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  margin-bottom: 8px;
}

.quarterReview .commentCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  background-color: #f0f0f0;
  border-radius: 8px;
  gap: 20px;
}

.medium:first-child {
  margin-right: 0;
}
.quarterReview .commentCardContainerManager {
  flex-direction: row-reverse;
}
