.waitingResponseCard {
  border-radius: 4px;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.waitingResponseCard .waitingResImage {
  width: 66.001px;
  height: 66px;
  flex-shrink: 0;
  object-fit: contain;
}
.waitingResponseCard .subText {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
