.ebook-card-container > div:first-child {
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
  height: 110px;
}

.recently-card-container > div:first-child {
  display: flex;
  justify-content: flex-start;
  padding: 2px !important;
  align-items: center;
}

.allbook-card-container > div:first-child {
  display: flex;
  justify-content: flex-start;
  padding: 0px !important;
  align-items: center;
}

.image-section {
  position: relative;
  background-color: rgb(230 90 20 / 50%);
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  width: 110px;
  height: 110px;
}
.image-section img {
  position: absolute;
  bottom: 0;
  right: 5px;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
}
h5 {
  margin-top: 5px;
  margin-bottom: 0;
}

.ebook-card-container .ant-card-body::after,
.ebook-card-container .ant-card-body::before {
  content: none;
}

.ebook-card-title {
  padding-left: 16px;
}

.ebook-card-title {
  width: 75%;
}

.card-image-section {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.card-image-section img {
  width: 100%;
}

.allbook-card-container {
  align-items: center;
  display: flex;
  padding: 10px;
  width: 100%;
}

.allbook-card-container .author {
  margin-top: 30px;
  display: inline-block;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  color: #000;
}

.ebook-card-title .title {
  margin-top: 15px;
  font-size: 18px;
  font-family: "ProximaNova-Semibold";
}
.ebook-card-title h5 {
  font-size: 16px;
  font-family: "ProximaNova-Thin";
}
.ant-space.recently-card_otr {
  width: 100%;
}
.ant-space.recently-card_otr .ant-space-item {
  width: 50%;
}
.ant-space.recently-card_otr .ant-space-item .ant-card.recently-card-container {
  width: 100%;
}
.ant-space.allbook-card_otr .ant-space-item {
  width: 100%;
}
.ant-space.recently-card_otr
  .ant-space-item
  .ant-card.recently-card-container
  .ant-card-body {
  padding: 10px 10px !important;
}
.recently-card_otr
  .ant-space-item
  .ant-card.recently-card-container
  .ant-card-body
  h4 {
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  color: #000;
}
.recently-card_otr
  .ant-space-item
  .ant-card.recently-card-container
  .ant-card-body
  h5 {
  font-size: 14px;
  font-family: "ProximaNova-Thin";
  color: #2b3348;
}
.allbook-card-container h4 {
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  color: #000;
}
.allbook-card-container h5 {
  font-size: 14px;
  font-family: "ProximaNova-Thin";
  color: #505768;
}
.ebooks-arrows-main {
  background: white;
  padding: 5px;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.ebooks-arrows-area {
  padding-left: 160px;
  padding-right: 160px;
  display: flex;
  justify-content: space-between;
}
.left-arrow-img {
  margin-top: 5px;
}
.right-arrow-img {
  margin-top: 5px;
}
.ebooks-left-arrow {
  border: 1px solid #000000;
  border-radius: 4px;
  background: none;
  cursor: pointer;
}
.ebooks-right-arrow {
  border: 1px solid #000000;
  border-radius: 4px;
  background: none;
  cursor: pointer;
}
.ebooks-pages-count {
  margin-top: 5px;
  font-family: "ProximaNova-Semibold";
  /* font-size: 20px; */
  line-height: 24px;
}
.breadcrumb-ebooks-detail {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-left: 150px;
  margin-bottom: 10px;
}
.ebooks-menu-icon {
  margin-right: 150px;
  cursor: pointer;
}
.ebooks-content-main {
  margin-left: 150px;
  margin-right: 150px;
  height: 500px;
  overflow-y: scroll;
}
.ebooks-iframe {
  border: none;
  background: #ffffff;
  height: 1000px;
}

.ebooks-left-arrow:disabled,
.ebooks-right-arrow:disabled {
  cursor: not-allowed;
}

.ebook-whole-section {
  margin-top: 140px;
}
