.custom-toast-area {
  display: flex;
  width: 381.365px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2),
    0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.14);
  position: fixed;
  right: 25px;
  top: 100px;
  padding: 12px;
  justify-content: space-between;
  z-index: 999;
}

.custom-left-area {
  display: flex;
  align-items: flex-start;
}

.skills-extracted {
  border-left: 4px solid #008000;
}

.skills-extracted .toast-msg-area h4 {
  color: #008000;
}

.no-skills-extracted {
  border-left: 4px solid #c00;
}

.no-skills-extracted .toast-msg-area h4 {
  color: #c00;
  margin: 0;
}
.draft-skills-extracted {
  border-left: 4px solid #1976D2;
}

.draft-skills-extracted .toast-msg-area h4 {
  color: #1976D2;
}

.applied-successfully {
  border-left: 4px solid #008000;
}

.applied-successfully .toast-msg-area h4 {
  color: #008000;
}

.toast-msg-area h4 {
  margin: 0 0 8px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.toast-msg-area p {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #000;
  margin: 0;
}

.toast-img-area {
  margin-right: 8px;
}

.close-btn-area button {
  background: none;
  border: 0;
  cursor: pointer;
}