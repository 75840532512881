.QR_scan {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ant-modal.modal-container {
  padding: 0;
  height: 100%;
  top: 0;
}

.ant-modal.modal-container .ant-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  height: auto;
}

.appqr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.appqr img:first-child {
  margin: 0 0 8px;
}

.googleqr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.googleqr img:first-child {
  margin: 0 0 8px;
}

.ant-modal.modal-container .ant-modal-footer {
  display: none;
}

.modal-container .ant-modal-title {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-family: "ProximaNova-Bold";
}

.modal-container button.ant-modal-close svg {
  width: 18px;
}

.modal-container button.ant-modal-close:hover {
  background: transparent;
}

.modal-container .ant-modal-content {
  padding: 0;
}

.modal-container .ant-modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #e9eaeb;
  margin: 0;
}

.modal-container .ant-modal-body {
  padding: 16px 24px 30px;
}

.modal-container .ant-modal-body .pop_text-bold {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 5px;
  font-family: "ProximaNova-Semibold";
  color: #000;
}

.modal-container .ant-modal-body p {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 16px;
  color: #454545;
  font-family: "ProximaNova-Regular";
}

.leave-type-modal .ant-modal-content {
  width: auto;
}

.modal-body-container {
  display: grid;
  gap: 15px;
  flex: 1;
}

.leave-type-body-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(4, 1fr); */
  gap: 16px;
  flex: 1;
}

.modal-body-container .custom-modal-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 16px 12px;
  cursor: pointer;
  width: 180px;
}

.modal-body-container .custom-modal-card-container img {
  margin-bottom: 8px;
}

.modal-body-container .modal-card-title {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #454545;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}

.modal-card-title-fest {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #454545;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* reason type modal */
.reason-type-modal .ant-modal-content {
  width: auto;
}

.reason-type-body-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px;
  flex: 1;
}

/* calendar modal */
.select-dates-modal .ant-modal-content {
  width: 328px;
}

.react-calendar.select-range-calendar {
  width: 328px;
  background: white;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  border: none;
  padding: 16px;
}

.react-calendar.select-range-calendar .react-calendar__tile--now {
  border: 1.5px solid #0642b5;
  background-color: #fff;
  color: #000;
  border-radius: 100%;
}

.react-calendar.select-range-calendar .react-calendar__tile {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.react-calendar.select-range-calendar .react-calendar__month-view__weekdays {
  color: #757a87;
  font-weight: 400;
  font-size: 14px;
}

.react-calendar.select-range-calendar abbr {
  text-decoration: none;
}

.react-calendar.select-range-calendar
  .react-calendar__navigation
  button:disabled {
  background-color: #fff;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
}

.react-calendar.select-range-calendar
  .react-calendar__navigation
  button:enabled:hover {
  background-color: #fff;
}

.select-dates-modal .select-dates-modal-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 0px 8px 0px #14141414;
  padding: 0px 16px;
  padding-bottom: 20px;
}

.select-dates-modal .select-dates-modal-dates-text {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #1f1f1f;
}

.select-dates-modal .select-dates-modal-no-of-dates {
  color: #454545;
}

.select-dates-modal .select-dates-modal-button {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 14px;
  padding: 9px 16px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  background-color: #0642b5;
  color: #fff;
  border: none;
}

.select-dates-modal .select-dates-modal-button-disabled {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 14px;
  padding: 9px 16px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: #bebebe;
  color: #000;
}

.select-dates-modal .ant-modal-body {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.react-calendar .react-calendar__tile--active {
  background-color: #e6efff;
}

.react-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__tile--active:enabled:focus {
  background-color: #e6efff;
}

.react-calendar .react-calendar__tile--active:enabled:hover {
  background-color: #e6efff;
}

.react-calendar .react-calendar__tile--rangeStart,
.react-calendar .react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar .react-calendar__tile--rangeEnd:enabled:focus,
.react-calendar .react-calendar__tile--rangeStart:enabled:hover,
.react-calendar .react-calendar__tile--rangeStart:enabled:focus,
.react-calendar .react-calendar__tile--rangeStart,
.react-calendar .react-calendar__tile--rangeEnd {
  background-color: #0642b5;
  border-radius: 100%;
}

.select-range-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day
  abbr {
  display: flex;
  width: 100%;
  height: 100%;
  background: #0642b5;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.select-range-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day
  abbr {
  display: flex;
  width: 100%;
  height: 100%;
  background: #0642b5;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.select-range-calendar
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #0642b5;
  border-radius: 100%;
}

.select-range-calendar
  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
  background-color: #e6efff;
  border-radius: 0%;
  padding: 0;
  border: none;
}

.select-range-calendar
  .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day
  abbr {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid #0642b5;
}

.select-range-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
  color: #fff;
  background-color: #e6efff;
  padding: 0 !important;
  border-radius: 100% 0 0 100%;
}

.select-range-calendar
  .react-calendar__month-view__days
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  color: #fff;
  background-color: #e6efff;
  padding: 0 !important;
  border-radius: 0 100% 100% 0;
}

.preview-modal .ant-modal-content {
  width: 584px;
  height: 438px;
}

.preview-modal .heading-text {
  color: #000000;
}

.preview-modal .main-view {
  color: #000000;
}

.preview-modal .devider-line {
  background-color: #e9eaeb;
  height: 1px;
  width: 100%;
}

.preview-modal .date-range-view {
  border: 1px solid #d9d9d9;
  width: 540px;
  /* height: 75px; */
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  /* padding-left: 10px;
  padding-right: 10px; */
  justify-content: space-between;
  align-self: center;
  flex-wrap: wrap;
}

.date-range-view-sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selected-floater-type {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fdefe8;
  padding: 5px;
  border-radius: 8px;
  margin-top: 10px;
}

.selected-floater-type-sub {
  margin: 10px;
}

.selected-floater-type-day {
  font-family: "ProximaNova-Semibold";
  color: #262626;
  font-size: 14px;
  line-height: 19.6px;
}

.selected-floater-type-date {
  font-family: "ProximaNova-Regular";
  color: #1f1f1f;
  font-size: 14px;
  line-height: 19.6px;
  margin-left: 10px;
}

.selected-floater-type-btn {
  display: flex;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.selected-floater-type-txt {
  font-family: "ProximaNova-Semibold";
  color: #e65a14;
  font-size: 12px;
  line-height: 18.6px;
}

.preview-modal .main-view {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.preview-modal .left-title-text {
  color: #595959;
  size: 14px;
  font-family: "ProximaNova-Regular";
}

.preview-modal .left-title-text_comment {
  color: #595959;
  size: 14px;
  font-family: "ProximaNova-Regular";
  margin-top: 10px;
}

.preview-modal .left-date-title-text {
  color: #000000;
  size: 16px;
  font-family: "ProximaNova-Semibold";
}

.preview-modal .middle-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.preview-modal .middle-view-divider {
  height: 1px;
  width: 80px;
  background-color: #0642b5;
}

.preview-modal .middle-days-view {
  width: 61px;
  height: 28px;
  border-radius: 24px;
  background: #e6efff;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.preview-modal .middle-days-text {
  color: #0642b5;
  size: 12px;
  font-family: "ProximaNova-Semibold";
}

.preview-modal .content-view {
  border: 1px solid #d9d9d9;
  width: 540px;
  /* height: 193px; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* padding-left: 10px;
  padding-right: 10px; */
  margin-top: 15px;
  align-self: center;
  padding: 10px;
}

.preview-modal .content-description {
  color: #000000;
  size: 18px;
  font-family: "ProximaNova-Regular";
}

.preview-modal .left-column-view-content {
  margin-top: 15px;
}

.preview-modal .buttons-row-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  padding-left: 13px;
  padding-right: 13px;
  /* align-items: flex-end; */
}

.preview-modal .cancel-button-view {
  display: flex;
  width: 117px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.preview-modal .button-row-right-view {
  display: flex;
  flex-direction: row;
}

.preview-modal .button-row-right-view-margin {
  margin-left: 15px;
}

.preview-modal .text-cancel {
  color: #454545;
  size: 14px;
  font-family: "ProximaNova-Semibold";
}

.preview-modal .text-edit {
  color: #0642b5;
  size: 14px;
  font-family: "ProximaNova-Semibold";
}

.preview-modal .text-edit-view {
  border-color: #0642b5;
}

.preview-modal .text-submit {
  color: #ffffff;
  size: 14px;
  font-family: "ProximaNova-Semibold";
}

.preview-modal .text-submit-view {
  background-color: #0642b5;
  border-color: transparent;
}

.preview-modal .ant-modal-body {
  padding: 16px 0px;
}

.modal-success-preview .ant-modal-body {
  padding: none;
}

.modal-success-preview .ant-modal-content {
  width: 584px;
  height: 438px;
}

.ant-modal.modal-container1 {
  padding: 0;
  height: 100%;
  top: 0;
}

.ant-modal.modal-success-container .ant-modal-content {
  height: auto;
  width: 400px;
}

.ant-modal.modal-success-container .ant-modal-footer {
  display: none;
}

.modal-success-preview button.ant-modal-close svg {
  display: none;
}

.modal-success-preview button.ant-modal-close {
  display: none;
}

.success-modal-text {
  text-align: center;
  color: #000000;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  line-height: 24px;
}

.success-modal-text1 {
  text-align: center;
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
}

.success-modal-middle-content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.success-popup-btn {
  background-color: #0642b5;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
}

.success-popup-btn .text-close-btn {
  color: #ffffff;
  size: 14px;
  font-family: "ProximaNova-Semibold";
  line-height: 18px;
  text-align: center;
}

.success-popup-right-top {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e65a14;
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 16px;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.quick-apply-floater-left {
  background: #fae6e6;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  width: 528px;
  margin-left: 12px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.quick-apply-floater-left-txt {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: #000000;
  line-height: 19.6px;
  margin-left: 10px;
}

.buttons-row-view .disabled {
  background-color: #e2e2e2;
  cursor: not-allowed;
}

.disabled .text-submit {
  color: #858585;
}

.modal-container-titlebar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.modal-container-status-APPROVED {
  display: inline-flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 13.5px;
  height: 20px;
  justify-content: center;
  width: 60px;
  border-radius: 8px;
  color: #008000;
  background-color: #cce6cc;
}

.modal-container-status-PENDING {
  display: inline-flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 20px;
  justify-content: center;
  width: 60px;
  border-radius: 8px;
  font-weight: 400;
  color: #a5780c;
  font-size: 13.5px;
  background-color: #fdf6e7;
}

.modal-container-status-REJECTED {
  display: inline-flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13.5px;
  gap: 10px;
  height: 20px;
  width: 60px;
  border-radius: 8px;
  background-color: #fae6e6;
  color: #cc0000;
}

.cancel-leave-modal-main.ant-modal .ant-modal-content {
  width: 26vw;
  height: fit-content;
  padding: 20px 16px 10px 16px;
}

.cancel-leave-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.cancel-leave-image {
  width: 60px;
  height: 60px;
}

.cancel-leave-title {
  color: #000;
  text-align: center;
  font-family: "Proximanova-Semibold";
  font-size: 24px;
  font-style: normal;
  line-height: 28.8px;
}

.cancel-leave-content {
  color: #000;
  text-align: center;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.cancel-leave-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}

.cancel-leave-back-button {
  display: flex;
  min-width: 135px;
  min-height: 30px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  /* 140% */
  cursor: pointer;
}

.cancel-leave-confirm {
  display: flex;
  min-width: 135px;
  min-height: 30px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #0642b5;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  cursor: pointer;
}

.relations-container {
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  width: 352px;
}

.relation {
  padding: 16px 28px 16px 16px;
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.modal-container.relations-modal .ant-modal-body {
  padding: 0px !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  border: 2px solid #0642b5;
  background-color: white;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: blue;
}

.relations-container {
  display: flex;
  flex-direction: column;
  /* overflow: scroll; */
  width: 352px;
}

.relation {
  padding: 16px 28px 16px 16px;
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.modal-container.relations-modal .ant-modal-body {
  padding: 0px !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  border: 2px solid #0642b5;
  background-color: white;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: blue;
}

.singleDatePopUp-calendar {
  margin: 20px;
}

.singleDatePopUp-calendar .react-calendar__tile--now {
  background-color: #fff !important;
  border-radius: 90%;
  border: 0 !important;
  color: #0642b5;
}

.singleDatePopUp-calendar .react-calendar__tile:hover {
  cursor: pointer !important;
}

.singleDatePopUp-calendar .react-calendar__tile--active {
  background-color: #0642b5 !important;
  color: #fff !important;
}

.singleDatePopUp-calendar .react-calendar__month-view__days__day--weekend {
  color: rgb(200, 55, 55);
}
.paternity-error-text-container {
  display: flex;
  flex-direction: column;
}
.paternity-error-title {
  color: #000;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  margin-left: 10px;
}
.drum_image {
  height: 100px;
  width: 100px;
  margin-top: 20px;
}
.psuedo_button{
  width: 100%;
}
