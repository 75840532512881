.quaterly-checkin-btn {
  display: flex;
  justify-content: flex-end !important;
}

.quaterly-checkin-comments h2 {
  font-size: 16px;
  line-height: 22.4px;
  color: #000;
  margin: 0;
  width: 100%;
  font-family: "ProximaNova-Semibold";
}

.quaterly-checkin-comments h4 {
  font-size: 14px;
  line-height: 19.6px;
  color: #000;
  margin: 16px 0 12px;
  width: 100%;
  font-family: "ProximaNova-Semibold";
}

.quaterly_comments_card {
  background-color: #f0f0f0;
  padding: 15px;
}

.quaterly_comments_review {
  font-size: 14px;
  line-height: 16.8px;
  color: #000;
  width: 100%;
  font-family: "ProximaNova-Regular";
}

.quaterly_comments_emp {
  font-size: 10px;
  line-height: 14px;
  color: #8c8c8c;
  width: 100%;
  font-family: "ProximaNova-Regular";
}

.quaterly_comments_meta {
  white-space: pre-wrap;
  padding: 3px;
}

.quaterly-modal-heading {
  color: #090a0a !important;
  text-align: center !important;
  font-family: "ProximaNova-Bold" !important;
  font-size: 24px !important;
  line-height: 28.8px !important;
  margin: 0 auto 20px !important;
  width: 80%;
  text-align: center;
}

.checkin-msg {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom: 32px !important;
}

.submit-first-quarter-checkin-popup .button-section {
  flex-direction: row-reverse;
  justify-content: flex-end !important;
}

.submit-first-quarter-checkin-popup .button-section .ant-btn-default {
  margin-right: 0;
  margin-left: 48px;
  width: 86px !important;
}

.submit-first-quarter-checkin-popup .button-section button {
  width: 167px;
  height: 32px;
  font-family: "ProximaNova-Semibold" !important;
  font-size: 16px !important;
  line-height: 22.4px !important;
}

.modal-Center-Images {
  display: block;
  margin: 0 auto 32px;
  text-align: center;
}

.check-in-goals {
  display: none;
}

.checkin-popup .button-section .ant-btn-default {
  display: none;
}

.checkin-popup .button-section .ant-btn-primary {
  width: 100%;
}

/*goal checkin design*/
.goal_quater-check-inn {
  margin: 140px 60px 60px 40px;
}

.goal_quater-check-inn form.ant-form {
  background: #fff;
  padding: 24px;
  margin: 0 0 20px;
  border-radius: 8px;
}

.goal_quater-check-inn .quaterly-checkin-comments {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  margin: 0 20px 20px;
}

.goal_quater-check-inn h2 {
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

.goal_quater-check-inn h4 {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 20px;
  color: #232a3d;
  font-family: "ProximaNova-Light";
}

.goal_quater-check-inn textarea#textArea {
  font-family: "ProximaNova-Light" !important;
  border-radius: 4px;
  resize: none;
}

.calender_cont-sec {
  display: table;
  margin: 0 auto;
  text-align: center;
  width: 340px;
}

.calender_cont-sec img {
  width: 198px;
  height: 161px;
  object-fit: contain;
}

.calender_cont-sec p {
  font-size: 14px;
  line-height: 17px;
  color: #8c909b;
  margin: 0;
  font-family: "ProximaNova-Light";
}

.goal_quater-check-inn button.ant-btn:disabled {
  background: #8c909b;
  color: #fff;
}

.goal_quater-check-inn .quaterly_comments_card {
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
}

.goal_quater-check-inn .quaterly_comments_card .ant-card-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 10px;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  border: 0px solid;
  min-height: initial;
  border-radius: initial;
}

.goal_quater-check-inn .quaterly_comments_card .quaterly_comments_meta {
  display: table;
  margin: 0;
  padding: 0;
}

.goal_quater-check-inn .ant-card-meta-title {
  font-size: 12px;
  line-height: 22px;
  color: #000;
}

.goal_quater-check-inn .ant-card-meta-description {
  font-size: 10px;
  line-height: 20px;
  color: #000;
}

.goal_quater-check-inn
  .quaterly_comments_card
  .ant-card-meta-detail
  .ant-card-meta-title {
  font-size: 12px;
  line-height: 22px;
  color: #000;
  margin: 0;
}

.goal_quater-check-inn .quaterly_comments_card .ant-card-body {
  padding: 0 !important;
}

.goal_quater-check-inn
  .quaterly_comments_card
  .ant-card-body
  p.quaterly_comments_review {
  font-size: 12px;
  line-height: 17px;
  margin: 0;
}

.goal_quater-check-inn .quaterly-checkin-comments .ant-list-item {
  margin-block-end: 0;
}

.goal_quater-check-inn p.quaterly_comments_review a {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
}
