.reaction_card_wrapper {
  display: table;
  width: 100%;
  position: relative;
  .card_foot-cmd {
    display: table;
    width: 100%;
    position: relative;
  }
  .card_reactioncount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .reactions_card_count {
      position: relative;
      display: flex;
    }
    .imgicons-reactions {
      height: 20px;
      width: 20px;
    }
    .cmd-count {
      cursor: pointer;
    }
  }
  ul {
    list-style: none;
    border-top: 1px solid #e9eaeb;
    padding: 20px 0 0;
    margin: 20px 0 0;
  }
  ul li a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #000;
    position: relative;
    display: flex;
    align-items: center;
    font-family: "ProximaNova-Regular";
    margin-right: 24px;
  }
  ul li a img {
    padding-right: 10px;
  }
  .float-left {
    float: left;
  }
  .reactions {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }
  .reactions .reaction-sub {
    display: flex;
  }

  .reaction-sub img {
    margin-right: 5px;
  }
  .card_reactions img:nth-child(1) {
    position: absolute;
    left: 0px;
    z-index: 4;
    top: 0;
  }
   
  .card_reactions img:nth-child(2) {
    position: absolute;
    left: 10px;
    z-index: 3;
    top: 0;
  }
   
  .card_reactions img:nth-child(3) {
    position: absolute;
    left: 20px;
    z-index: 2;
    top: 0;
  }
   
  .card_reactions img:nth-child(4) {
    position: absolute;
    left: 30px;
    z-index: 1;
    top: 0;
  }
  .card_foot-cmd span.count {
    display: flex;
    font-family: "ProximaNova-Regular";
    margin-right: 8px;
    margin-left: 60px;
}
}
