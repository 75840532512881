.total-weight-green {
  color: #008000;
  font-size: 14px;
  line-height: 19.6px;
  font-family: "ProximaNova-Semibold";
}

.total-weight-red {
  color: #cc0000;
  font-size: 14px;
  line-height: 19.6px;
  font-family: "ProximaNova-Semibold";
}

.total-weight-red {
  color: #e65a14;
}

.performance-body .ant-tabs-tab-active {
  border: 0 !important;
  border-bottom: 0 !important;
  background-color: transparent !important;
}

.performance-header {
  margin: 30px 60px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breadcrum_performance {
  margin-left: 55px;
  margin-right: 55px;
}

.performanceContainer {
  margin: 80px 55px;
}

.performance_success-alert {
  .ant-alert-message {
    margin-left: 15px;
  }
}

.ant-dropdown.goals .ant-dropdown-menu .ant-dropdown-menu-item {
  width: 150px;
  height: 44px;
  flex-shrink: 0;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  font-size: 16px;
  background-color: #fff;
}

.ant-dropdown.goals .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #e6efff;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content:hover {
  color: #095dff;
}

.goals-menu-icon.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content:hover {
  color: inherit;
}

.menu-icon button:first-child {
  display: none;
}

.menu-icon button:nth-child(2) {
  border-start-start-radius: 6px !important;
  border-end-start-radius: 6px !important;
}

h5 {
  margin: 0;
}
.asp-form-heading {
  margin: 17px 0px;
  gap: 10px;
}
.asp-form-heading,
.right-side {
  display: flex;
  align-items: center;
}
.asp-form-heading h5 {
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
  margin: 0;
}

.performance-header .asp-form-heading a {
  font-size: 20px;
  font-family: "ProximaNova-Semibold";
}

.performance-header .asp-form-heading a .ant-space-item {
  display: flex;
  transition: 0.5s;
}

.left-side,
.right-side {
  display: flex;
  align-items: center;
}
.left-side h5 {
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
  margin: 0;
}

.left-side {
  gap: 10px;
}

.right-side {
  gap: 15px;
}

.performance-body {
  margin: 60px 60px !important;
}
.team-performanceContainer .performance-body {
  margin: 40px 60px 60px 40px !important;
}
.today-weight {
  background-color: #fff7e6;
  display: flex;
  padding: 11px 12px 0px 12px;
  position: relative;
  margin-bottom: 23px;
  border-radius: 8px;
}

.weight-center {
  margin-left: 16px;
  padding-bottom: 16px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.success-close-icon {
  position: absolute;
  top: 157px;
  right: 70px;
  cursor: pointer;
}

.empty-goals {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
}

.behind,
.behind:hover {
  color: #c00 !important;
  background-color: #fae6e6 !important;
  box-shadow: none;
}

.completed,
.completed:hover {
  color: #1976d2 !important;
  background-color: #d1e4f6 !important;
}

.on-track,
.on-track:hover {
  color: #008000 !important;
  background-color: #cce6cc !important;
}

.tbl-inner-section .inner-table-milestone {
  display: flex;
  align-items: center;
}

.tbl-inner-section .inner-table-milestone span {
  margin-left: 10px;
}

.tbl-outer-section table thead tr .ant-table-cell {
  background: transparent !important;
  border: 0;
}

.tbl-outer-section table tbody tr .ant-table-cell {
  /* padding: 40px; */
  background: transparent;
}

.tbl-outer-section table thead tr .ant-table-cell::before {
  background: transparent !important;
}

.tbl-outer-section table tbody tr:first-child td {
  border-top: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr:first-child td:first-child {
  border-top-left-radius: 4px !important;
}

.tbl-outer-section table tbody tr:first-child td:last-child {
  border-top-right-radius: 4px !important;
}

.tbl-outer-section table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.tbl-outer-section table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.tbl-outer-section table tbody tr td:last-child {
  text-align: center;
  cursor: pointer;
}
.tbl-outer-section table tbody tr td:first-child {
  border-left: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr td:last-child {
  border-right: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr:last-child td {
  border-bottom: 1px solid #d9d9d9;
}

.tbl-outer-section table thead tr:hover th {
  background: transparent !important;
}

.tbl-outer-section table tbody tr:hover td {
  background: transparent !important;
}

/* start inner section */
.tbl-outer-section .tbl-inner-section table thead tr .ant-table-cell {
  background: #f5f5f5 !important;
  border: 0;
  padding: 16px 16px;
}

.tbl-outer-section .tbl-inner-section table tbody tr .ant-table-cell {
  padding: 16px 16px;
  background-color: #f5f5f5 !important;
  border: 0px solid !important;
}

.tbl-outer-section .tbl-inner-section table thead tr .ant-table-cell::before {
  background: transparent !important;
}

.tbl-outer-section
  .tbl-outer-section
  .tbl-inner-section
  table
  tbody
  tr:first-child
  td {
  border-top: 0 solid #d9d9d9 !important;
  background-color: #f5f5f5 !important;
}

.tbl-outer-section
  .tbl-inner-section
  table
  tbody
  tr:first-child
  td:first-child {
  border-top-left-radius: 0 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:first-child td:last-child {
  border-top-right-radius: 0 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.tbl-outer-section .tbl-inner-section table tbody tr td:first-child {
  border-left: 0 solid #d9d9d9;
  background-color: #f5f5f5;
  width: 50%;
  padding-right: 70px;
}

.tbl-outer-section .tbl-inner-section table tbody tr td:last-child {
  border-right: 0 solid #d9d9d9;
  background-color: #f5f5f5;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td {
  border-bottom: 0 solid #d9d9d9;
  background-color: #f5f5f5;
}

.tbl-outer-section .tbl-inner-section table thead tr:hover th {
  background: #f5f5f5 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:hover td {
  background: #f5f5f5 !important;
}

.tbl-inner-section table tbody tr .ant-table-cell .ant-space-item a {
  color: #0642b5;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
}

/* end inner section */

.form-footer-section {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.expand {
  cursor: pointer;
}

.year-selection,
.smart {
  color: #e65a14;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.milestone-left h3 {
  margin-top: 5px;
}

.info-alert {
  margin-bottom: 16px;
}

.business {
  margin-top: 20px !important;
}

.new-goal .ant-select-selector {
  height: 46px !important;
  padding: 6px 11px !important;
}

.left-side h5 {
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
  margin: 0;
}

.performance-header .left-side a {
  font-size: 20px;
  font-family: "ProximaNova-Semibold";
}

.performance-header .left-side a .ant-space-item {
  display: flex;
  transition: 0.5s;
}

.performance-body .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0642b5 !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn:hover,
.performance-body .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #0642b5 !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: "ProximaNova-Regular";
}

.ant-tabs-content-holder .ant-tabs-tabpane p span {
  color: #000;
  font-family: "ProximaNova-Regular";
}
.ant-tabs-content-holder .ant-tabs-tabpane p span.total-weight-green {
  color: #008000;
  font-family: "ProximaNova-Semibold";
}
.weight-center h5 {
  font-size: 16px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.weight-center p {
  font-size: 14px;
  color: #000;
  font-family: "ProximaNova-Regular";
  display: table;
  width: 85%;
}

.weight-center p span.smart {
  color: #e65a14;
  font-family: "ProximaNova-Semibold";
}

.weight-center button.ant-btn {
  border-color: #000;
  border-radius: 4px;
}

.weight-center button.ant-btn span {
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  color: #0642b5;
}

.today-weight .weight-left {
  display: flex;
}

.business h5.title {
  font-size: 16px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.business .ant-table-content table {
  border: 0px solid;
}

.business .ant-table-content table thead tr th {
  border-bottom: 0px solid #b0b3ba !important;
  font-family: "ProximaNova-Regular";
  color: #262626;
}

.business .ant-table-content table button {
  box-shadow: none;
  font-family: "ProximaNova-Semibold";
}

.inner-table-milestone span.title-mile {
  font-size: 16px;
  color: #232a3d;
  font-family: "ProximaNova-Semibold";
  width: 210px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
}

.inner-table-milestone span.cont-mile {
  font-size: 14px;
  color: #2b3348;
  font-family: "ProximaNova-Regular";
  display: -webkit-box;
  max-width: 270px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-table-milestone img {
  width: 20px;
  margin: 0px 0 0;
}

.inner-table-milestone {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
}

.milestone-dates-section .ant-form-item .ant-picker {
  height: 52px;
}

.milestone-form-details .ant-form-item-label label {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #1f1f1f;
  display: inline;
  position: relative;
}

.milestone-form-details .ant-form-item-required::before {
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 12px !important;
}

.title-delete-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title-delete-section h4 {
  margin: 0;
}

.title-delete-section button {
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0;
  display: flex;
}

.delete-milestone-popup .ant-modal-body h5 {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  line-height: 28.8px;
  margin: 0 auto 20px;
}

.delete-milestone-popup .ant-modal-body p {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 16px;
}

.delete-milestone-popup .button-section {
  display: flex;
  justify-content: center;
}

.delete-milestone-popup {
  width: 400px;
}

.delete-milestone-popup .button-section button {
  width: 48%;
  height: 40px;
}

.delete-milestone-popup .button-section button.ant-btn-default {
  margin-right: 32px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  line-height: 22.4px;
  color: #0642b5;
}

.delete-milestone-popup .button-section button.ant-btn-primary {
  border-radius: 4px;
  border: 1px solid #0642b5;
  background: #0642b5;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  line-height: 22.4px;
  color: #fff;
}

.menu-icon button span.anticon {
  transform: rotate(90deg);
}

.performance-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "ProximaNova-Semibold";
}

/* .tbl-inner-section {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  min-height: 48px !important;
  max-height: 200px !important;
} */
.ant-picker-date-panel {
  width: 303px !important;
}

.ant-picker-content {
  border: none;
}

.performance-body .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
  border: 6px solid #095dff;
}

.performance-body .ant-tabs-nav-wrap .ant-tabs-tab {
  padding-bottom: 9px !important;
}

/* tooltip */
.ant-tooltip-content {
  width: 154px;
  height: 50px;
  background: #fff;
}

.ant-tooltip-content .ant-tooltip-inner {
  font-size: 12px;
  line-height: 17px;
  color: #000;
  background: #fff;
  font-family: "ProximaNova-Regular";
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  bottom: 4px !important;
}

.ant-tooltip-placement-top .ant-tooltip-arrow:before {
  background: #fff;
}

.total-weight-text {
  color: #262626;
  font-size: 14px;
  line-height: 19.6px;
  font-family: "ProximaNova-Regular";
}

.layoutContainer {
  margin: 145px 55px;
}

.common-short-heading {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 16px;
}

.cancel-outline-btn {
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;
  display: flex;
  width: 148px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #454545;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}

.previous-outline-btn {
  border-radius: 4px;
  border: 1px solid #095dff;
  background: #fff;
  width: 140px;
  height: 40px;
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}

.submit-fill-btn {
  border-radius: 4px;
  border: 1px solid #095dff;
  background: #095dff;
  width: 230px;
  height: 40px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}
.goal_fill-inner-btn-sec .ant-form-item {
  margin-bottom: 0px !important;
}

.cascading-alert-area {
  margin-top: 10px;
  margin-bottom: 16px;
}

.cascading-alert-area .ant-alert {
  padding: 10px 16px;
  border-radius: 4px;
  background: #e6efff;
  border-color: #e6efff;
}

.cascading-alert-area .ant-alert .ant-alert-message {
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.cascading-alert-area .ant-alert .ant-alert-action .review-btn {
  color: #1976d2 !important;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  border-radius: 4px;
  border: 1px solid #1976d2;
  background: #ecf3fd;
  box-shadow: none;
}

.cascading-alert-area .ant-alert .ant-alert-action .review-btn span {
  color: #1976d2 !important;
}

.manager-submit-fill-btn {
  border-radius: 4px;
  border: 1px solid #095dff;
  background: #095dff;
  width: 100px;
  height: 40px;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.notificationdisplay {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px !important;
  cursor: pointer;
}

.icon {
  position: relative;
  top: 2px;
}

.imagenotification {
  position: absolute;
  width: 6px;
  height: 5.76px;
  top: 3px;
  left: 7px;
}

.commentimage {
  position: relative;
  height: 18px !important;
  width: 18px !important;
}

.commentsText {
  margin-top: 3px;
  color: #095dff;
  background: #fff;
  border: #fff;
  left: 0px;
  padding-left: 3px !important;
  height: 20px !important;
  cursor: pointer;
}

.notification {
  height: 6px !important;
  width: 6px;
}

.draft-submit-popup-img {
  margin: 0 auto;
  display: block;
}

.submit-goal-popup-img {
  margin: 0 auto;
  display: block;
}

.performance_tab-otr .manager-tabs .p-tabview-nav-container {
  border-bottom: 2pt solid #e8e8e8;
  position: relative;
  z-index: 0;
  margin: 0 0 15px;
  background: transparent;
}

.performance_tab-otr
  .manager-tabs
  .p-tabview-nav-container
  .p-tabview-nav-content {
  position: relative;
  overflow: visible;
}

.performance_tab-otr
  .manager-tabs
  .p-tabview-nav-container
  .p-tabview-nav
  .p-tabview-selected
  a {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
}
.manager-tabs .p-tabview-nav-container .p-tabview-nav {
  list-style: none;
  /* margin-left: -40px; */
  margin-bottom: 0px;
}

.performance_tab-otr .manager-tabs .p-tabview-nav-container .p-tabview-nav {
  height: 27px;
  display: flex;
  gap: 32px;
  list-style: none;
}

.performance_tab-otr
  .manager-tabs
  .p-unselectable-text.p-tabview-selected.p-highlight::after {
  height: 2px;
}
.custom-toast-area {
  background: #fff !important;
  padding: 20px 15px !important;
}
.custom-toast-area .ant-notification-notice-with-icon {
  display: flex;
  align-items: initial;
  flex-direction: column;
}
.custom-toast-area .ant-notification-notice-with-icon {
  display: flex;
  align-items: initial;
  flex-direction: column;
}
.custom-toast-area .ant-notification-notice-icon {
  position: absolute !important;
}
.custom-toast-area .ant-notification-notice-message {
  display: block !important;
  margin-inline-start: 32px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
}
.custom-toast-area .ant-notification-notice-description {
  margin-inline-start: 38px !important;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}
.custom-toast-area .ant-notification-notice-close {
  inset-inline-end: 8px !important;
  position: absolute !important;
  color: #454545 !important;
}
.custom-toast-area.custom-success-toast {
  border-left: 4pt solid #008000;
}
.custom-toast-area.custom-success-toast .ant-notification-notice-message {
  color: #008000;
}
.custom-toast-area.custom-error-toast {
  border-left: 4pt solid #cc0000;
}
.custom-toast-area.custom-error-toast .ant-notification-notice-message {
  color: #cc0000;
}

.custom-toast-area.custom-info-toast {
  border-left: 4pt solid #1976d2;
}
.custom-toast-area.custom-info-toast .ant-notification-notice-message {
  color: #1976d2;
}
