.ant-alert {
  padding: 8px 0 8px 18px;
}
.ant-alert span {
  color: #000 !important;
}
.clearance-module-section .ant-upload-list {
  margin-left: 20px;
}

.clearance-module-section .ant-upload-wrapper {
  width: 50%;
}

.clearance-module-section .ant-space {
  gap: 50px;
}

.clearance-module-section .ant-upload-icon {
  display: none;
}

.sub-header {
  display: grid;
  gap: 0px;
  padding-top: 20px;
}

.card-footer-row {
  justify-content: flex-end;
}

.ant-upload-list-item-name {
  color: #008000 !important;
  font-family: "ProximaNova-Regular";
}

.anticon-download {
  color: #000 !important;
}

.anticon-delete {
  color: #cc0000 !important;
}

.card-footer-section {
  position: sticky;
  bottom: 40px;
}

.ant-notification .ant-notification-notice {
  width: 670px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}

.ant-notification .ant-notification-notice .ant-notification-notice-icon {
  position: unset;
}

.ant-notification-notice-message {
  display: none;
}

.ant-notification-notice-description {
  margin-inline-start: 10px !important;
}

.ant-notification-notice-close {
  position: unset !important;
}

.ant-notification-notice-success {
  background: #008000;
}

.ant-notification-notice-error {
  background: #c00;
}

.ant-notification-notice-close {
  color: #fff;
}

.ant-notification-notice-description {
  color: #fff;
}

.ant-notification-notice-icon-success,
.ant-notification-notice-icon-error {
  color: #fff;
}

.ant-notification-notice-description {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
}

.ant-notification-center {
  top: 100px !important;
  left: 50%;
  bottom: auto !important;
  transform: translate(-50%, -50%);
}

.attachment-disabled,
.attachment-enabled {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}

.marginBottom {
  margin-bottom: 8px;
}

.marginBottom label {
  font-family: "ProximaNova-Regular";
}

.inputFlex {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.styleAlert {
  background-color: #fff;
  border: 1px solid #fff;
}
.attachment-disabled:hover svg path {
  fill: #d9d9d9 !important;
}

.attachment-disabled svg path {
  fill: #d9d9d9 !important;
}

.attachment-enabled:hover svg path {
  fill: #2b3348 !important;
}

.attachment-enabled svg path {
  fill: 2B3348 !important;
}

.ant-upload-list-item-done,
.ant-upload-list-item-done:hover {
  background: #fff;
}

.employeeName {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  line-height: 28.8px;
  margin-bottom: 0 !important;
}

.employeeId {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  font-family: "ProximaNova-Regular";
  margin-top: 5px;
  margin-bottom: 24px;
  color: #2b3348;
}

.page-title {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 32px;
  font-style: normal;
  line-height: 28.8px;
  margin: 0;
}

.card-section {
  border-radius: 4px;
  border: 1px solid #d9dbe3;
  background: #fff;
}

.deduction {
  display: inline-block;
  width: 50%;
  margin-right: 16px;
}

.deduction-attachment-space {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.deduction-attachment-space .ant-input-number {
  width: 50%;
}

.inputFlex .ant-input-number-input-wrap {
  height: 40px;
}

.inputFlex .ant-input-number-input-wrap input {
  height: auto;
  padding: 0;
}

.deduction-attachment-space .ant-upload span button {
  height: 40px;
}

input,
textarea,
.back-btn,
input:hover,
textarea:hover {
  font-family: "ProximaNova-Regular" !important;
}

.attachment-disabled,
.attachment-enabled,
.attachment-disabled:hover,
.attachment-enabled:hover {
  border: 1px solid #757a87 !important;
  font-family: "ProximaNova-Regular";
}

.attachment-enabled,
.attachment-enabled:hover {
  color: #000 !important;
}

.back-btn,
.back-btn:hover {
  border: 1px solid #000 !important;
  color: #095dff;
}

.back-btn,
.submit-btn {
  width: 180px;
  height: 40px;
}

.ant-card-body {
  padding: 24px 28px !important;
}

.ant-card-body h1 {
  margin-top: 0;
  font-family: "ProximaNova-Semibold";
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action {
  opacity: 1 !important;
}

.submit-btn {
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: 22.4px;
}

.control-border {
  border: 1px solid #757a87 !important;
}

.placeholder input::-webkit-input-placeholder,
.placeholder textarea::-webkit-input-placeholder {
  font-family: "ProximaNova-Regular";
  color: #000 !important;
}

.placeholder input::-moz-placeholder,
.placeholder textarea::-moz-placeholder {
  font-family: "ProximaNova-Regular";
  color: #000 !important;
}

.placeholder input::-ms-input-placeholder,
.placeholder textarea::-ms-input-placeholder {
  font-family: "ProximaNova-Regular";
  color: #000 !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-upload {
  width: 100%;
}

.add-attachment {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.add-attachment.ant-btn > .anticon + span {
  margin: 0;
}

.alertText {
  font-family: "ProximaNova-Regular";
  color: #232a3d;
  font-style: normal;
  line-height: 19.6px;
}

.radio-group .ant-radio-inner {
  border: 1px solid #000 !important;
}

.radio-group .ant-radio-checked .ant-radio-inner {
  border: 0px solid #d9d9d9 !important;
}

.ant-modal-confirm-btns button {
  width: 115px;
  height: 32px;
  font-family: "ProximaNova-Regular";
  padding: 5px 32px 4px 32px;
}

.ant-modal-confirm-btns button:first-child,
.ant-modal-confirm-btns button:first-child:hover {
  border: none;
  box-shadow: none;
  color: #095dff;
}

.ant-modal-confirm-title {
  font-family: "ProximaNova-Semibold";
  color: #090a0a;
  font-size: 24px;
  font-style: normal;
  line-height: 28.8px;
}

.ant-modal-confirm-content {
  font-family: "ProximaNova-Regular";
  font-size: 18px;
  font-style: normal;
  line-height: 25.2px;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-name {
  width: 50%;
}

.upload-list .ant-upload-list-item:hover {
  background-color: transparent;
}
.ant-btn-text:not(:disabled):hover {
  background-color: transparent;
}

.ant-upload-list-item-actions button span > svg {
  width: 20px;
  height: 20px;
}
.normal-layout {
  padding: 0 !important;
}
.card-footer-section .ant-card-body {
  padding: 12px 28px !important;
}
.normal-layout main {
  padding: 0 !important;
}
.normal-layout .sub-header {
  padding: 20px 56px 0;
}
.normal-layout .ant-list {
  padding: 0 56px;
}
.clearance-module-section {
  margin: 145px 55px 80px;
}
.clearance-module-section .ant-input-number-disabled,
.attachment-disabled,
.attachment-disabled:hover,
.clearance-module-section .ant-input-affix-wrapper-disabled {
  border: 1px solid #d9dbe3 !important;
  background: #fff;
}
.clearance-module-section .ant-btn-default:disabled {
  background: #fff;
}

.clearance-module-section
  .ant-input-number-disabled
  input::-webkit-input-placeholder,
.clearance-module-section
  .ant-input-affix-wrapper-disabled
  textarea::-webkit-input-placeholder {
  font-family: "ProximaNova-Regular";
  color: #b1b4bb !important;
}

.clearance-module-section
  .ant-input-number-disabled
  input::-webkit-input-placeholder,
.clearance-module-section
  .ant-input-affix-wrapper-disabled
  textarea::-webkit-input-placeholder {
  font-family: "ProximaNova-Regular";
  color: #b1b4bb !important;
}

.clearance-module-section
  .ant-input-number-disabled
  input::-webkit-input-placeholder,
.clearance-module-section
  .ant-input-affix-wrapper-disabled
  textarea::-webkit-input-placeholder {
  font-family: "ProximaNova-Regular";
  color: #b1b4bb !important;
}
