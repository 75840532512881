.popover-button {
  padding: 10px;
  margin: 0px 0px 4px 0px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px; /* 140% */
  border-radius: 8px;
}
.activePopover-button {
  background: #f3f3f3;
  padding: 10px;
  margin: 0px 0px 4px 0px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px; /* 140% */
  border-radius: 8px;
}
