.goal-selection-container {
  display: flex;
  align-items: center;
  justify-content: center;

}
.goal-selection-container .ant-modal-content{
  padding: 30px;
  background-color: #fff;
  opacity: 1;
  border-radius: 8px;
  position: relative;
}
.goal-selection-container .header-goal-selection {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}
.goal-selection-container .sub-header-goal-selection {
  color: #262626;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
}
.goal-selection-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}
.goal-selection-container .closeBtn {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 40px;
  top: 24px;
  cursor: pointer;
}

/* GoalSelection */
.goalSelectionCard {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 196px;
  height: 227px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
}
.goalSelectionCard.disable-card {
  background: #E2E2E2;
  cursor: not-allowed;
}
.goalSelectionCard .goal-selection-img {
  height: 80px;
  width: 80px;
  object-fit: contain;
}
.goalSelectionCard .goal-selection-card-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}
.goalSelectionCard .goal-selection-card-desc {
  color: #595959;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
