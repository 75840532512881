.link-highlight {
  color: #1976d2 !important;
  cursor: pointer;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 20px;
}

.breadcrumb_newgoal ol li span.ant-breadcrumb-link {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000;
}