.topcomponents {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.contents {
  display: table;
  margin: 0;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
}
.titlelist {
  color: #000000;
  font-size: 20px;
  font-family: "ProximaNova-SemiBold";
  margin-bottom: 4px;
  line-height: 24px;
  margin-top: 0;
}

.context {
  color: #505768;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  margin: 0;
}

.search .ant-input {
  width: 297px;
  margin-bottom: 0;
  padding: 10px 8px 10px 35px;
  border-color: #bfbfbf;
  height: 33px;
  font-size: 16px;
  font-family: "ProximaNova-Regular";
  border-radius: 32px;
}

.employeeDisplay {
  margin: 0;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.search .ant-input::placeholder {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
}

.search {
  display: flex;
  position: relative;
}

.search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 10px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* card clickable */
.employeeDisplay button {
  background: transparent;
  border: 0px solid;
  padding: 0;
  margin: 0;
  width: calc((100% - 25px * 2) / 3);
  margin-right: 25px;
}

.employeeDisplay button:nth-child(3n) {
  margin-right: 0;
}

.employeeDisplay button .card {
  width: auto;
  cursor: pointer;
  margin-right: 0;
}

.employee_list_not_found {
  object-fit: contain;
}

.employee_list_not_found_outer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 0 0 50px;
}
.employee_list_not_found_outer_container p.no-data {
  margin: 0;
  color: #000;
  font-size: 18px;
  font-family: "ProximaNova-SemiBold";
}
.employee-list-outer-container {
  min-height: 50vh;
}
