.certification-card {
  border-radius: 8px;
  background: #fff;
  width: 100%;
  margin: 20px auto 60px;
  padding: 24px;
  height: auto;
  position: relative;
}
.certification-emptypage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.certification-emptypage-text {
  margin-bottom: 16px;
}
.certification-emptypage-text {
  width: 25%;
  display: flex;
  line-height: normal;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #595959;
  line-height: 24px;
}
.certification-button .ant-btn-primary {
  display: flex;
  width: 210px;
  padding: 8px 16px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  background: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: 22.4px;
  color: #fff;
  cursor: pointer;
  box-shadow: none;
  outline: 0;
  border: 1px solid #0642b5;
}
.certification-addbutton .ant-btn-primary > img {
  margin-right: 4px;
}
.certifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.certifications-header .Seperation-page-title {
  margin: 0;
  font-size: 24px;
}
.certification-listsection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1.5pt solid #f0f0f0;
  margin-top: 16px;
}
.certification-listsection:first-child {
  margin-top: 0;
}
.certification-listsection:last-child {
  border-bottom: 0;
}
.certification-leftlist {
  width: 80%;
}
.certification-listheading {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin-top: 0;
  margin-bottom: 4px;
}
.certification-listpara {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #454545;
  margin: 0 0 6px;
}
.certification-listdate {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #454545;
  margin: 0 0 16px;
}
.certification-rightlist {
  display: flex;
}
.certification-rightlist button,
.certification-rightlist button:hover {
  background: none !important;
  border: 0;
  box-shadow: none;
  padding: 8px;
  display: flex;
  align-items: center;
}
.certification-rightlist button:hover {
  background: #f5f5f5 !important;
}
.certification-popup .anticon-close > svg {
  fill: #000;
}
.certification-popup .ant-modal-footer {
  text-align: center;
}
.certification-popup .ant-modal-footer .ant-btn-default {
  display: none;
}
.certification-popup .ant-modal-footer .ant-btn-primary {
  width: 162px;
  border-radius: 4px;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin-inline-start: 0;
  height: 40px;
}
.certificate-image {
  display: flex;
  justify-content: center;
  margin: 0 auto 16px;
}
.certification-popup h6 {
  margin: 0;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  font-style: normal;
  line-height: 28.8px;
  margin-bottom: 16px;
  text-align: center;
}
.certification-popup p {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: 22.4px;
  margin: 0 0 24px;
  text-align: center;
}
.certification-popup .button-section {
  justify-content: center;
}
.certification-popup .ant-btn-default {
  display: none;
}
.button-section {
  display: flex;
  justify-content: flex-end;
}
.certification-cardinner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.button-section button {
  height: 40px;
  width: 148px;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}
.button-section .ant-btn-default,
.button-section .ant-btn-default:hover {
  margin-right: 24px;
  color: #0642b5;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
}
.button-section .ant-btn-primary,
.button-section .ant-btn-primary:hover {
  color: #fff;
  border-radius: 4px;
  border: 1px solid #0642b5;
  background: #0642b5 !important;
}
.certification-addbutton .ant-btn-primary {
  width: 172px;
  padding: 8px 10px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  background-color: #0642b5 !important;
  gap: 4px;
}
.certificate-toaster {
  border-radius: 4px;
  background: #e6efff;
  padding: 10px 16px;
  display: flex;
  align-items: center;
}
.certificate-toaster > img {
  margin-right: 8px;
}
.certificate-toaster > span {
  margin: 0;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: normal;
  color: #1976d2;
}

.qrcode-notify {
  display: flex;
  width: 100%;
  position: relative;
  background: #f0ecfe;
  justify-content: space-between;
  padding: 7px 20px;
  align-items: center;
  border-radius: 8px;
  margin: 0 0 10px;
}
.qrcode-notify p {
  font-size: 18px;
  line-height: 25px;
  font-family: "ProximaNova-Regular";
  color: #2d1a68;
  width: 36%;
  margin: 0;
}
.qrcode-notify p span {
  font-family: "ProximaNova-Semibold";
}
.qrcode-img {
  position: relative;
  display: table;
}
.qrcode-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background: #3b2288;
}
.qrcode-img:after {
  content: "or";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #f0ecfe;
  width: 20px;
  height: 20px;
  color: #3b2288;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 3px 0;
  font-family: "ProximaNova-Semibold";
}
.qrcode-img img {
  padding: 0 70px;
}
.qrcode-notify img.close_iconn {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.certify_main-otr {
  margin: 80px 60px 0;
}

.certify_main-otr .certification-card {
  height: initial;
}
.certification-emptypage-title {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  line-height: 25.2px;
}
.ant-notification-topRight .success-Toast {
  width: 381px !important;
  height: 74px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14),
    0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  border-left: 3pt solid #008000;
  padding: 12px !important;
}
.success-Toast .ant-notification-notice-message {
  display: block;
  color: #008000 !important;
  font-family: "ProximaNova-Semibold";
  font-size: 14px !important;
  line-height: 19.6px;
  margin-bottom: 8px !important;
}

.success-Toast .ant-notification-notice-description {
  color: #000 !important;
  font-size: 14px !important;
  font-family: "ProximaNova-Regular";
  line-height: 19.6px;
  margin-inline-start: 40px !important;
}
.success-Toast .ant-notification-notice-with-icon {
  display: block !important;
}
.ant-notification .success-Toast .ant-notification-notice-icon {
  position: absolute !important;
}
.ant-notification .success-Toast .ant-notification-notice-close {
  position: absolute !important;
  top: 12px !important;
  inset-inline-end: 12px !important;
  color: #454545 !important;
}
.ant-notification .success-Toast .ant-notification-notice-close:hover {
  background: none;
}
