.container {
  margin: 0;
  padding: 0;
}
.form-group .ant-select-selector select,
.form-group .ant-form-item-control-input-content input,
.form-group .ant-picker-range {
  padding: 7px 10px !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  border-radius: 8px !important;
}
.form-group ant-picker-input input {
  border-radius: 0px !important;
  border: 0px !important;
  padding: 1px 11px 0px !important;
}
.form-group .ant-select-selector {
  padding: 7px 108px !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  border-radius: 8px !important;
  width: 100%;
  height: 40px !important;
  min-height: 40px !important;
}

.form-group .ant-upload-select {
  width: 345px !important;
  height: 45px !important;
}
.form-group .ant-form-item {
  width: 100% !important;
}

.ant-modal.main_otr-sec {
  width: 65% !important;
}
.form-group {
  padding: 15px 8px;
  width: 100%;
}
.form-group .ant-form-item-row {
  display: flex;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
}
.form-group .ant-form-item-row .ant-col {
  display: table;
  width: 100%;
  margin-right: 20px;
}
.form-group .ant-form-item-row .ant-form-item-label {
  text-align: left;
}
.form-group .ant-form-item {
  margin: 0;
}

.form-group .ant-col .ant-upload-list {
  display: flex;
  margin: 0;
  width: 100%;
}
.form-group .ant-col .ant-upload-list .ant-upload {
  margin: 0;
  border-color: #757a87;
  border-radius: 4px;
  height: 40px !important;
}
.ant-modal.main_otr-sec .ant-modal-title {
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  color: #000;
}
.form-group .ant-form-item label {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #000;
}
.form-group .ant-form-item-row input.ant-input {
  font-family: "ProximaNova-Regular";
  border-color: #000;
  border-radius: 4px !important;
  height: 40px;
  padding: 10px 10px !important;
}
.form-group .ant-form-item .ant-select-selector {
  border-color: #757a87;
  height: 40px !important;
  min-height: 40px !important;
  border-radius: 4px !important;
  box-shadow: none;
  padding: 0 10px !important;
}
.form-group .ant-form-item .ant-select-selector:hover {
  border-color: #757a87;
}
.form-group .ant-form-item-row .ant-picker {
  border-color: #757a87;
  height: 40px;
  border-radius: 4px !important;
  box-shadow: none;
  width: 100%;
}
.ant-modal-footer button.ant-btn {
  font-family: "ProximaNova-Semibold";
  border-color: #000;
  border-radius: 4px;
  width: 140px;
}
.ant-modal-footer button.ant-btn.ant-btn-primary {
  border-color: #0642b5;
  background: #0642b5;
}
.ant-modal.main_otr-sec .ant-modal-body .container.eventpage-popup .ant-col {
  font-family: "ProximaNova-Semibold";
  display: flex;
  margin: 0 0 8px;
}
.form-group .ant-col .ant-upload-list .ant-upload.ant-upload-select {
  width: 100% !important;
}
