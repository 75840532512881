.new-goal {
  margin: 30px 60px 60px;
  position: relative;
  margin-top: 75px;
}

.new-goal .ant-select-selector {
  height: 53px !important;
  padding: 6px 11px 6px 0 !important;
}

.new-goal #form_item_name,
#form_item_weight,
#form_item_status,
#form_item_startDate,
#form_item_endDate {
  padding: 11px 12px 11px 16px !important;
}

.new-goal .weight_section {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  position: relative;
  align-items: end;
}

.new-goal .weight_right_section {
  border: 1px solid #b1b4bb;
  padding: 10px 14px 10px 14px !important;
  background-color: #e6e7e9;
  border-radius: 8px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.empty-milestone span.no-milestone-caption {
  width: 321px;
  text-align: center;
}

.empty-milestone span.no-milestone-caption {
  width: 321px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  font-family: "ProximaNova-Regular";
}

.goal-row-two-section {
  display: inline-flex;
  /* align-items: center; */
  gap: 200px;
  margin-bottom: 13px;
}

.rightpreviewbuttons {
  display: inline-flex;
}

.rightpreviewbuttons
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #0642b5;
}

.goal-row-two-section span.ant-select-selection-item {
  padding-left: 5px !important;
}

form#form_item
  .ant-placeholder.ant-placeholder-in-form-item
  .ant-placeholder-selector {
  padding-left: 16px;
}

.ant-input-number-input-wrap input#form_item_weight {
  padding-left: 0 !important;
}

.ant-picker-input input#form_item_startDate {
  padding-left: 0 !important;
}

.ant-picker-input input#form_item_endDate {
  padding-left: 0 !important;
}

.goal-row-two-section .ant-form-item {
  width: 360px;
}

.goal-row-two-section.dates .ant-form-item {
  width: 360px;
  margin-bottom: 0;
}

.goal-row-two-section.dates .ant-form-item .ant-picker {
  width: 100%;
  padding-left: 0px;
  height: 50px;
}

.button-area {
  display: flex;
  justify-content: space-between;
}

.btn-section {
  display: flex;
  justify-content: flex-end;
}

.btn-section button {
  margin-left: 10px;
}

.milestone-section {
  padding: 24px;
  background: #f5f5f5;
  margin-top: 24px;
  margin-bottom: 24px;
  max-height: 550px;
  overflow-y: auto;
}

.milestone-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.milestone-header-left {
  display: flex;
  flex-direction: column;
}

.milestone-header-left h5 {
  margin: 0;
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
}

.milestone-header-left span {
  font-size: 14px;
  line-height: 20px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
}

.milestone-header-left h5 span.star {
  color: #cc0000;
}

form#form_item .ant-col.ant-form-item-label {
  padding-bottom: 5px;
  /* right: 7px; */
}

form#form_item .certify_otr-field .ant-col.ant-form-item-label {
  right: 0;
}

form#form_item .certify_otr-field .ant-form-item {
  padding-top: 0;
}

.new-goal .milestone-form-details .button-area .ant-form-item.certify-prev {
  padding-top: 0;
  border-bottom: 0px solid;
}

.new-goal .milestone-form-details form#form_item .certify_main-otr-sec {
  padding-bottom: 10px;
}

.milestone-header-left p {
  margin: 0;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.milestone-body-header {
  display: flex;
  gap: 3px;
  align-items: center;
}

.milestone-body-dates {
  display: flex;
  gap: 10px;
}

.milestone-start-date {
  position: relative;
  padding-right: 10px;
}

.milestone-start-date::before {
  height: 15px;
  width: 1px !important;
  background-color: #767b88;
  content: "";
  margin-left: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.milestone-body-section p {
  margin-top: 5px;
}

.empty-milestone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.empty-milestone button.ant-btn span {
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Semibold";
}

.empty-milestone button.ant-btn {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: #0642b5;
}

.empty-milestone .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: #0642b5;
}

.ant-input-number-input-wrap {
  /* padding: 11px 3px 11px 16px !important; */
}

.ant-input-number {
  width: 100%;
  border-radius: 8px;
}

.ant-input-number-handler-wrap:hover {
  display: none;
}

.weight_left_section > .ant-form-item {
  width: auto;
}

.weight_left_section .ant-form-item-control-input {
  width: 310px;
}

.weight_left_section .ant-form-item {
  /* margin: 0; */
}

.weight_left_section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.required {
  color: red;
}

.status-bar {
  width: 360px;
}

.new-goal .ant-card {
  position: unset;
}

.new-goal .ant-card form#form_item label.ant-checkbox-wrapper {
  color: #1f1f1f;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}

.add-milestone-btn {
  position: absolute;
  top: 50px;
  right: 0;
}

.milestone-delete-btn {
  cursor: pointer;
}

.new-goal .ant-select-selection-placeholder {
  color: "#000" !important;
}

.ant-form-item .ant-form-item-label > label {
  flex-direction: row-reverse;
  color: #1f1f1f;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}

.weightSpan {
  display: flex;
  align-self: flex-start;
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin: 5px 0 0;
  bottom: 0;
  position: absolute;
  /* right: 54px; */
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 0px 9px;
}

.new-goal .charCountGoal {
  color: #454545;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-top: -20px;
}

.breadcrumb_newgoal {
  margin-bottom: 20px;
}

.Breadcrumb_new_milestone {
  margin-bottom: 15px;
}

.new-goal .breadcrumb_newgoal h1 {
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
  margin: 10px 0 0;
}

.new-goal form#form_item textarea,
.new-goal form#form_item input,
.new-goal form#form_item {
  font-family: "ProximaNova-Regular" !important;
  /* padding-left: 16px; */
  font-size: 16px;
  min-height: 52px !important;
}

.new-goal
  .certify_otr-field
  .ant-col.ant-form-item-control
  textarea.ant-input-status-error {
  border-color: #ff4d4f;
}

.new-goal .ant-select-selector span.ant-select-selection-placeholder {
  padding-top: 6px;
  font-family: "ProximaNova-Regular" !important;
  padding-left: 11px;
}

.new-goal .ant-select-selector span.ant-select-selection-item {
  padding-top: 2px;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px;
  left: 8px;
}

.new-goal form#form_item textarea::placeholder,
.new-goal form#form_item input::placeholder,
.new-goal form#form_item span.ant-select-selection-placeholder {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #595959;
}

span.ant-placeholder-selection-item,
.ant-placeholder-open span.ant-placeholder-selection-item {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #595959 !important;
}

.new-goal .milestone-form-details form#form_item {
  position: relative;
}

.new-goal .milestone-form-details form#form_item .btn_otr-sec {
  position: absolute;
  top: -168px;
  right: 0;
  left: auto;
  width: auto;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.new-goal
  .milestone-form-details
  form#form_item
  .btn_otr-sec
  .ant-form-item.add-certify {
  padding: 0;
}

.new-goal .milestone-form-details form#form_item .btn_otr-sec button.ant-btn {
  border: 1px solid #0642b5;
  border-radius: 8px;
  padding: 7px 12px;
  width: auto;
  height: 40px;
  background: transparent;
}

.new-goal
  .milestone-form-details
  form#form_item
  .btn_otr-sec
  button.ant-btn
  span {
  color: #0642b5;
}

.new-goal .milestone-form-details .button-area button.ant-btn.fillBtnStyles {
  background: #0642b5;
  border-radius: 8px;
  padding: 7px 12px;
}

.new-goal
  .milestone-form-details
  .button-area
  button.ant-btn.fillBtnStyles
  span {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.new-goal .milestone-form-details .button-area button.ant-btn {
  border-color: #c7c7c7;
  border-radius: 8px;
  padding: 7px 12px;
}

.new-goal .milestone-form-details .button-area button.ant-btn span {
  color: #5c5c5c;
  font-size: 14px;
  line-height: 20px;
}

/* milestone */
.new-goal-milestone .new-goal {
  margin-top: 75px;
}

.goal-row-two-section.dates .ant-form-item .ant-picker input {
  padding-left: 15px;
  font-family: "ProximaNova-Regular" !important;
  font-size: 16px;
  border: 0 !important;
}

.goal-row-two-section.dates .ant-form-item .ant-picker input:hover {
  border: 0;
}

.goal-row-two-section.dates .ant-form-item .ant-picker svg {
  color: #454545;
}

.milestone-dates-section.goal-row-two-section.dates .ant-form-item .ant-picker {
  background: #fff;
}

.new-goal-milestone button.milestone-delete-btn {
  right: 0;
}

span.ant-checkbox span.ant-checkbox-inner:after {
  transform: rotate(34deg) scale(1) translate(-50%, -50%);
  left: 30%;
}

span.ant-checkbox span.ant-checkbox-inner {
  border-radius: 2px;
}

.status-textbox {
  width: 360px;
}

.text-box-main {
  color: #1f1f1f;
  flex-direction: row-reverse;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}

.text-box-main span {
  font-family: SimSun, sans-serif;
  font-size: 16px;
}

.text-box-main input {
  border: none;
}

.text-area-main span {
  font-family: SimSun, sans-serif;
  font-size: 16px;
}

.text-area-main {
  color: #1f1f1f;
  flex-direction: row-reverse;
  font-family: ProximaNova-Regular;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}

.text-area-input {
  border-radius: 8px !important;
}

.api-data-not-found {
  text-align: center;
  font-family: ProximaNova-Bold;
  font-size: 16px;
}

.loading-img {
  display: grid;
  place-items: center;
}

.new-goal .ant-form-item-explain-error {
  display: none;
}

/* .new-goal .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  border-color: #d9d9d9;
} */
.new-goal .ant-form-item .ant-form-item-label > label::after {
  content: none;
}
.headingWrapperContainer {
  position: fixed;
  top: 56px;
  left: 0;
  background: #ffffff;
  z-index: 999;
  margin: 0 !important;
  width: 100%;
  display: block;
  padding: 16px 60px;
}
.headingWrapperContainer-notification {
  position: fixed;
  top: 96px;
  left: 0;
  background: #ffffff;
  z-index: 999;
  margin: 0 !important;
  /* height: 101px; */
  width: 100%;
  display: block;
  padding: 16px 60px;
}
.sticky-title .milestone-form-details .btn_otr-sec {
  position: fixed !important;
  top: 130px !important;
  right: 90px !important;
  z-index: 999;
}
.new-goal .ant-form-item .ant-form-item-label > label::after {
  content: none;
}

.req-sup {
  color: #c00;
  font-family: "ProximaNova-Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  top: 5px;
  position: relative;
  left: 3px;
}
.newgoal-headingwrappercontainer {
  padding: 16px 60px 0;
}
.goal-row-two-section:nth-last-child(2) {
  display: flex;
  flex-wrap: wrap;
  gap: initial;
}
.goal-row-two-section:nth-last-child(2) .milestone-section {
  width: 100%;
}
.milestone-section-details .goal-row-two-section:nth-last-child(2) {
  display: inline-flex;
}
.nogoalcard {
  display: flex;
  flex-direction: column;
  height: 50vh;
  margin: 0 0 20px;
}
.title-delete-goal {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}
.nogoalcard .nogoal-img-container {
  margin-top: 80px;
}
.description-delete-goal {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #595959;
  margin-top: 10px;
}
.nogoal-error-msg-bar {
  background-color: #fae6e6;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 1, 128px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  opacity: 0px;
}
