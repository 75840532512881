.quarterly-checkinEmployee .all-commentsText {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom: 16px;
}

.quarterly-checkinEmployee .all-commentsText:first {
  display: block;
}

.performance-body form.ant-form.commentTextArea-checkin {
  margin: 0 0 16px;
}

.commentCardContainer-manager {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  background-color: #f0f0f0;
  margin-bottom: 32px;
}

.quarterReview {
  margin: 0 0 16px;
}
.manager-comment-card-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin: 16px 0px;
}
.manager-comment-card-container .manager-sub-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: #fff;
  border-radius: 4px;
}
.manager-comment-card-container .manager-employee-comment {
  display: flex;
  flex-direction: column;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  line-height: 16.8px;
}
.manager-comment-card-container .profilepic-checkin {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  margin-right: 8px;
}
.manager-comment-card-container .profile-checkin {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.manager-comment-card-container .profile-checkin .avatar-container {
  margin-right: 8px;
}
.manager-comment-card-container .userData-checkin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.manager-comment-card-container .name-checkin {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
}
.manager-comment-card-container .designation-checkin {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.quarterReview .commentCard-checkin {
  background-color: #fff !important;
}
.performance-header-empDetail {
  margin-left: 25px !important;
}
.error-pop-checkin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh !important;
  background-color: #fff;
  margin: 0px 60px;
  border-radius: 4px;
}
.error-popup-checkin {
  margin-top: 120px !important;
}
.icon-margin {
  margin-left: 65px;
}
.highlighted-link {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-left: 5px;
  cursor: pointer;
}
