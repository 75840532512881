.commentCard-checkin {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 25px;
}
.inner-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
}
.manager-comment {
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
}
