.resume-list {
    display: flex;
    gap: 15px;
}

.resume-card {
    padding: 16px 24px;
    background-color: #FFF;
    width: 100%;
    border-radius: 8px;
}

.resume-card .ant-alert span,
.resume-card .ant-alert-message {
    color: #0642B5 !important;
}

.resume-card .ant-upload-select {
    width: 100% !important;
    padding: 43px 16px;
    /* height: auto; */
    height: 175px !important;
}

.download-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-detail-section {
    display: flex;
    gap: 10px;
    align-items: center;
}

.resume-list p {
    margin: 0;
}

.download-section {
    margin-top: 24px;
}

.file-upload-section p:nth-child(1) {
    font-family: 'ProximaNova-Semibold';
}

.download-section .file-detail-section p:nth-child(2) {
    margin-top: 4px;
}

.download-section img {
    cursor: pointer;
}

.resume-card .upload-title::after {
    content: '*';
    color: red;
}

.resume-card h4 {
    margin-bottom: 16px;
    font-family: 'ProximaNova-Semibold';
    font-size: 20px;
}

.attachment-card {
    background-color: #f5f5f5;
    padding: 12px 16px;
    border-radius: 8px;
}

.attachment-card .download-section {
    margin-top: 0;
}

.upload_btn {
    height: 40px;
    width: 120px;
}

.enabled_btn,
.enabled_btn:hover {
    background: #05338C !important;
}

.resume-card .ant-alert-content {
    padding: 12px;
    font-size: 16px;
}