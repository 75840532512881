.achievements-outer-container {
  display: flex;
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.trophy-img {
  width: 24px;
  height: 24px;
  left: 10px;
  margin-left: 10px;
}
.achievements-outer-container .achievements-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: #1f1f1f;
  margin-left: 20px;
}
.achievements-outer-container .achivement-inside-container {
  flex-direction: row;
  /* align-items: center; */
  display: flex;
  margin: 0px 10px;
}
.achivement-inside-container span {
  color: #0642b5;
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
}
.achivement-inside-container .achivements-description {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #6f6f6f;
}
