.search-add-parent {
  padding: 0;
}
.table-search-box {
  width: 365px;
  height: 33px;
  margin-right: 5px;
}

.table-addnew-btn {
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
  font-size: 15px;
  background-color: white;
  border: 1px solid #b0b3ba;
  padding: 7px 13px 6px 11px;
  border-radius: 5px;
}

.edit-delete-icons-parent {
  display: flex;
  /* justify-content: space-between; */
  cursor: pointer;
}

.tabs-content {
  margin-top: 70px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 200px;
  .ant-tabs-nav {
    margin: 0;
  }
}
.tabs-table-parent {
  margin-top: 140px;
}

.tabs-styles {
  font-family: "ProximaNova-bold";
  padding: 20px 0px 0px 16px;
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      border: none;
      border-bottom: 1px solid #b0b3ba;
      background: none;
    }
    .ant-tabs-tab-active {
      border-bottom: none !important;
      border-left: 1px solid #b0b3ba !important;
      border-top: 1px solid #b0b3ba !important;
      border-right: 1px solid #b0b3ba !important;
      /* border: 0.5px solid #B0B3BA !important; */
    }
  }
}

.mywork-coming-soon {
  font-size: 40px;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  margin-top: 200px;
  margin-left: 600px;
}

.mywork-calendar {
  border-top: 1px solid #b0b3ba !important;
  margin-top: 140px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}

.calendar-tab-title {
  position: relative;
  background: #f5f5f5;
  margin: 0 !important;
  padding: 15px 0 15px;
  font-size: 24px;
  color: #000;
  font-family: "ProximaNova-Bold";
  margin-left: 105px !important;
}

.calendar-pop-event {
  display: flex;
  justify-content: center;
  margin-left: 1294px;
}
.menuExpanded {
  margin-left: 100px;
  transition: all 0.3s;
}
.menuCollapsed {
  margin-left: 20px;
  transition: all 0.3s;
}

.tabs-label {
  font-family: "ProximaNova-Semibold";
  cursor: pointer;
}
