/* Empty Submitted Goal */
.empty-submitted-goals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65vh;
}
.empty-submitted-goals .submitted-goal-img {
  height: 120px;
  width: 120px;
  object-fit: contain;
}
.empty-submitted-goals .submitted-goal-empty-title {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}
.empty-submitted-goals .submitted-goal-empty-subheader {
  color: #595959;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.submitted-goals-loading {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overview_count_main {
  min-height: 60vh;
}
