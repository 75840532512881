.calendar-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border-left: 6px solid #005b00;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 6px 0px rgba(206, 211, 218, 0.45);
  /* border: 2px solid sienna; */
}

.calendar-card-fixed {
  border-left: 6px solid #005b00 !important;
}

.calendar-card-floater {
  border-left: 6px solid #b54901 !important;
}

.calendar-card .date-container {
  padding: 7px 16px;
}

.calendar-card .month-text {
  color: #005b00;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}

.calendar-card .month-text-fixed {
  color: #b54901 !important;
}

.calendar-card .date-text {
  color: #005b00;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  /* 120% */
}

.date-text-fixed {
  color: #b54901 !important;
}

.calendar-card .holiday-details-container {
  flex: 1;
  padding: 9px;
}

.calendar-card .holiday-title {
  color: #005b00;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.calendar-card .holiday-title-fixed {
  color: #b54901 !important;
}

.calendar-card .day-text {
  color: #005b00;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}

.calendar-card .day-text-fixed {
  color: #b54901 !important;
}

.calendar-card .img-container {
  width: 94px;
  height: 76px;
}

.holiday-img {
  width: 94px;
  height: 76px;
  object-fit: cover;
}

/* Holiday Calendar */
.holiday-calender-container {
  width: 100%;
  padding-bottom: 40px;
  min-height: 600px;
  /* height: 360px; */
}

.holiday-calender-container .title-calendar {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  margin-bottom: 16px;
}

.holiday-calender-container .header-text {
  display: flex;
  justify-content: space-between;
  padding: 0px 19px;
}

.holiday-calender-container .header-text .ant-select-selector {
  width: 93px;
  border-radius: 32px;
  border: 1px solid #bfbfbf;
  font-size: 12px;
  line-height: 17px;
  color: #454545;
  font-family: "ProximaNova-Regular";
}

.holiday-calender-container
  .header-text
  .ant-select-selector
  input::placeholder {
  font-size: 12px;
  line-height: 17px;
  color: #454545;
}

.holiday-calender-container .header-text span.ant-select-arrow {
  transform: translateY(-35%);
  -webkit-transform: translateY(-35%);
  font-size: 16px;
  color: #454545;
}

.calender
  .holiday-calender-container
  .header-text
  .holiday-calendar-dropdown:hover
  .ant-select-selector {
  border-color: initial;
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.holiday-calender-container .cards-container {
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: 0px 19px;
  height: 100%;
  /* overflow-y: auto; */
}

.holiday-calendar-dropdown {
  /* border: 1px solid #bfbfbf;
  background-color: #fff;
  height: 32px;
  padding: 8px 12px; */
}

.calendar-section-leftside {
  display: flex;
  gap: 20px;
  justify-content: start;
}

.holiday-calendar-link,
.holiday-calendar-link:hover {
  color: #0642b5;
  text-align: right;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.skelton_image {
  width: "24px";
  height: "24px";
}
