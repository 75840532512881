.ant-modal.smart-goals-modal {
  width: fit-content;
  min-width: 601px;
  height: 387px;
  flex-shrink: 0;
}
.ant-modal.smart-goals-error-modal {
  min-width: 601px;
  height: 387px;
  flex-shrink: 0;
}
.ant-modal.smart-goals-error-modal .ant-modal-content {
  padding: 24px;
  width: fit-content;
  min-width: 501px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "ProximaNova-Regular";
}
.ant-modal.smart-goals-modal .ant-modal-content {
  padding: 24px;
  width: fit-content;
  min-width: 601px;
}
.ant-modal.smart-goals-modal.completed .ant-modal-content {
  background: #f0ecfe;
}
.ant-modal.smart-goals-modal.completed .ant-modal-header .ant-modal-title {
  background: #f0ecfe;
}

.smart-goals-modal .ant-modal-close svg {
  width: 18px;
  color: #000;
  height: 18px;
}
.smart-goals-modal-items {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.smart-titles {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px; /* 140% */
}
.smart-goals-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.smart-goals-input-box {
  display: flex;
  align-items: center;
  min-height: 66px;
  width: 372px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background: #fff;
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-right: 8px;
  margin-bottom: 12px;
}
.smart-goals-input-box.false {
  display: flex;
  align-items: center;
  min-height: 66px;
  padding: 24px 24px 16px 16px;
  border-radius: 8px;
  border: 1px solid #cc0000;
  background: #fae6e6;
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-right: 8px;
}
.smart-goals-input-box.true {
  display: flex;
  align-items: center;
  min-height: 66px;
  padding: 24px 24px 16px 16px;
  border-radius: 8px;
  border: 1px solid #008000;
  background: #e6f2e6;
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-right: 8px;
}
.smart-goals-input-box .ant-radio-wrapper {
  color: #0a0a0a;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-right: 8px;
}
.smart-goals-modal-user-input {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: fit-content;
  margin-bottom: 24px;
}
.smart-goals-wizard {
  width: 157.009px;
  height: 237px;
  margin: 23px;
}
.smart-goals-modal-user-input
  .ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border: 1px solid #0642b5;
  background: #0642b5;
}
.smart-goals-modal .in-progress-step {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0642b5;
  border-radius: 32px;
}
.in-progress-step .inprogress-step-inner {
  height: 14px;
  width: 14px;
  border-radius: 15px;
  background-color: #0642b5;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background: #0642b5;
  height: 2px;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background: #0642b5;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.smart-goals-modal
  .ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon {
  display: none;
}
.steps-container .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0642b5;
  border-color: #0642b5;
}
.steps-container .ant-steps .ant-steps-item-finish .ant-steps-item-icon svg {
  color: #fff;
}
.smart-goals-modal .ant-steps.ant-steps-vertical > .ant-steps-item {
  flex: 0 auto !important;
}
.smart-goals-modal .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border: 2px solid #bfbfbf;
  background: #fff;
}
.smart-goals-modal
  .ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #bfbfbf !important;
  height: 2px;
}
.smart-goals-modal
  .ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #bfbfbf !important;
  height: 2px;
}
.smart-goals-modal .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 16.3px;
  padding: 0 17px;
}
.smart-goals-modal .ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
  margin-inline-start: 0px;
}
.smart-goals-modal .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 112px;
  margin-left: 10px;
  text-align: start;
}
.smart-goals-modal .ant-steps .ant-steps-item-container:nth-last-child(1) {
  outline: none;
  width: 40px !important;
}
.smart-goals-modal {
  border-radius: 8px;
  background: #f0ecfe;
}

.smart-goal-completion {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
.smart-goal-completion-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.smart-goal-completion-buttons {
  display: flex;
  justify-content: space-between;
}
.smart-goal-completion-playagain {
  display: flex;
  width: 143px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background: #fff;
  color: #454545;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; 
  cursor: pointer;
}
.smart-goal-completion-close {
  display: flex;
  width: 144px;
  height: 40px;
  padding: 9px 16px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: none;
  background: #0642b5;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; 
  cursor: pointer;
}
.smart-goals-modal .ant-modal-header .ant-modal-title {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; 
}

