.modal-closePopUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid;
}

.close-popup-checkin .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-popup-checkin .ant-modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 420px;
  height: 173px;
}
.close-popup-checkin .close-popup-checkin {
  flex-shrink: 0;
}
.close-popup-checkin .closePopUpTitle {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 8px;
}
.close-popup-checkin .subtitle {
  color: #454545;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.close-popup-checkin .ant-modal-body {
  margin-bottom: 17px;
}
.close-popup-checkin .ant-modal-footer button.ant-btn {
  width: 120px;
  height: 40px;
}
.close-popup-checkin .ant-modal-footer {
  gap: 16px;
}

.close-popup-checkin .ant-modal-footer button.ant-btn.cancelBtn {
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 8px 16px;
  color: #454545;
  width: 143px;
}
.close-popup-checkin .ant-modal-footer button.ant-btn.cancelBtn:hover {
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 8px 16px;
  color: #454545;
}
.close-popup-checkin .ant-modal-footer button.ant-btn.cancelBtn span {
  color: #454545;
}
.close-popup-checkin .ant-modal-footer button.ant-btn.submitBtn {
  border: 1px solid #0642B5;
  background: #0642B5;
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
  width: 143px;
}
.close-popup-checkin .ant-modal-footer button.ant-btn.submitBtn:hover {
  border: 1px solid #0642B5;
  background: #0642B5;
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
}
.close-popup-checkin .ant-modal-footer button.ant-btn.submitBtn span {
  color: #fff;
}

.close-popup-checkin .cancelBtn > span {
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; /* 140% */
}
