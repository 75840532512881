.tsm_approval_footer_lm {
  display: flex;
  border-radius: 4px;
  height: 56px;
  padding: 4px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 0px solid #dbdbdb;
  background: #e6efff;
  box-shadow: 0px -3px 23px -7px rgba(0, 0, 0, 0.11);
  position: sticky;
  bottom: 0;
}

.tsm_footer_title_lm {
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.tsm_footer-buttons_lm {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.timesheet_submit_lm {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.cancel-button-footer_lm {
  display: flex;
  width: 103px;
  height: 40px;
  padding: 5px 12px 4px 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px;
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba 255, 255, 255, 0;
  cursor: pointer;
}
