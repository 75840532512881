.point-dashboard {
  margin-top: 120px;
  margin: 144px 60px;
}
.point-dashboard .point-dashboard-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 20px;
}
.point-dashboard .point-dashboad-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  flex: 1;
}
