.ant-modal-footer-buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.export-modal {
  display: flex;
  width: 1280px;
  height: 820px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
}

#Seperation-page-title .landing-page-footer .export-button {
  display: inline-flex;
  height: 40px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid, #c7c7c7;
  background: transparent;
  color: #5c5c5c;
  font-size: 14px;
}
#Seperation-page-title .landing-page-footer .export-button:hover {
  border: 1px solid, #c7c7c7;
  background: transparent;
}

.ant-picker {
  width: 100%;
}
.modal-subtext {
  display: flex;
  align-items: center;
  color: #474747;
  font-size: 16px;
  font-family: "ProximaNova-Regular";
  margin: 0 0 15px;
}

.custom-close-icon {
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 375px;
  align-self: stretch;
  width: 24px;
}
.ant-picker-outlined {
  width: 100%;
}

.Calender-icon {
  width: 20px;
  height: 20px;
}
.custom-calendar-dropdown .ant-picker-panel {
  background-color: #fff; /* Background color */
  font-family: "ProximaNova-Regular";
}
.custom-calendar-dropdown .ant-picker-header-super-prev-btn,
.custom-calendar-dropdown .ant-picker-header-super-next-btn {
  display: none;
}
.custom-calendar-dropdown .ant-picker-cell {
  border-bottom: none !important;
}
.custom-calendar-dropdown .ant-picker-week-panel-row {
  border-bottom: none !important;
}

.custom-calendar-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #1d72b8;
  color: white;
}
.custom-calendar-dropdown .ant-picker-header {
  border-bottom: none !important;
}
.custom-calendar-dropdown .ant-picker-header {
  border-bottom: none;
}
.custom-input {
  font-family: "ProximaNova-Regular";
}

.custom-calendar-dropdown .ant-picker-header {
  border-bottom: none !important;
  font-family: "ProximaNova-Regular";
}

.custom-calendar-dropdown .ant-picker-content th {
  width: 30px;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
}

.start-date-label {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  margin: 0;
}
.exporttimesheet_modal .ant-form-item-label label {
  position: relative;
}
.End-Date-label {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.e-mail {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.landing-page-footer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 10px;
}
.export-footer-button {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  gap: 100px;
  align-self: stretch;
  margin-top: 20px;
}
.footer-download-button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.footer-email-button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.hidestricky_breadcrump .Seperation-page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-page-footer .export-button span {
  color: #5c5c5c;
  font-size: 14px;
  font-family: "ProximaNova-SemiBold";
}
.exporttimesheet_modal .ant-modal-content button.ant-modal-close {
  background: transparent;
}
.exporttimesheet_modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: "ProximaNova-Bold";
}
.exporttimesheet_modal .ant-modal-content .ant-modal-header {
  margin: 0 0 5px;
}
form.export-popup-form-otr
  .ant-form-item
  .ant-form-item-label
  label.ant-form-item-required:before {
  position: absolute;
  top: -2px;
  right: -13px;
  color: #cc0000;
  font-size: 14px;
}
form.export-popup-form-otr .ant-form-item .ant-form-item-label label:after {
  display: none;
}
form.export-popup-form-otr .ant-form-item .ant-picker.custom-input {
  border-color: #c7c7c7;
  height: 40px;
  border-radius: 8px;
  box-shadow: none;
}
form.export-popup-form-otr input#email.custom-input {
  border-color: #c7c7c7;
  height: 40px;
  border-radius: 8px;
  box-shadow: none;
}
form.export-popup-form-otr input#email.custom-input {
  border-color: #c7c7c7;
  height: 40px;
  border-radius: 8px;
  box-shadow: none;
}
.ant-form-item.export-form-field_otr {
  margin: 0 0 25px;
}
.ant-form-item.export-form-field_otr:last-child {
  margin: 0;
}
.exporttimesheet_modal .ant-modal-footer button.ant-btn.ant-btn-default {
  border-color: #dbdbdb;
  border-radius: 8px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exporttimesheet_modal .ant-modal-footer button.ant-btn.ant-btn-default span {
  font-size: 14px;
  font-family: "ProximaNova-SemiBold";
}

/* calender-popup */
.custom-input-popup button.ant-picker-header-super-prev-btn,
.custom-input-popup .ant-picker-header-super-next-btn {
  display: none;
}
.custom-input-popup button span.ant-picker-prev-icon:before,
.custom-input-popup button span.ant-picker-next-icon:before {
  font-size: 24px;
  width: 7px;
  height: 7px;
  border-block-width: 2px 0;
  border-inline-width: 2px 0;
  color: #0a0a0a;
}
.custom-input-popup .ant-picker-body table th {
  border: 0px solid !important;
  height: 45px;
}
.custom-input-popup .ant-picker-body table td {
  border: 0px solid !important;
  height: 45px;
}
.custom-input-popup .ant-picker-body {
  padding-top: 0 !important;
}
.ant-picker-dropdown.custom-input-popup .ant-picker-panel-container {
  border: 0px solid !important;
  box-shadow: 0px 3px 6px 0px #ced3da73 !important;
  padding: 10px !important;
}
.custom-input-popup .ant-picker-header-view button {
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
}
