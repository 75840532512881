.comments_popup {
  width: 600px !important;
  height: 509px !important;
}

.comments_popup .ant-modal-content {
  padding: 0;
}

.comments_popup .ant-modal-close .ant-modal-close-x span svg {
  fill: #000;
}

.comments_popup .ant-modal-header {
  padding: 16px;
  border-bottom: 1pt solid #e9eaeb;
  margin-bottom: 0;
}

.comments_popup .ant-modal-footer {
  display: none;
}

.comments_popup .panels {
  height: 371px;
  width: 600px;
  overflow-y: auto;
}

.comments_popup .ant-modal-title {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  line-height: 24px;
}

.commentCard {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 12px;
  overflow: hidden;
}

.bottomBorder {
  border-bottom: 1px solid #e9eaeb;
}

.paddingLeft {
  padding-left: 50px;
}

.profile {
  flex: 3;
  display: flex;
  justify-content: center;
}

.commentContent {
  flex: 17;
}

.commentOptions {
  flex: 2;
  display: flex;
  justify-content: center;
  position: relative !important;
  overflow: hidden;
}

.profileImage {
  height: 40px;
  width: 40px;
  border-radius: 40px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
}

.seperatorDiv {
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background-color: #757a87;
  margin-right: 4px;
  margin-left: 4px;
}

.time {
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}

.content {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  color: "#000";
  margin-top: 5.5px;
  color: #000;
}

.statistics {
  margin-top: 8.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.likeIcon {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.likecard {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
  justify-content: center;
  cursor: pointer;
}

.viewAll {
  color: var(--dex-colors-brand-primary, #0642b5);
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  margin-top: 12px;
  cursor: pointer;
}

.optionsClass {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.commentBar {
  min-height: 82px;
  border-top: 1px solid #e9eaeb;
  display: flex;
  align-items: center;
}

.commentBar .text_area-otr img {
  display: flex;
  align-self: flex-end;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.commentBar img {
  width: 40px;
}

.commentBar {
  display: flex;
  align-items: center;
  padding: 0 15px 0 25px;
}

.commentBar .text_area-otr {
  min-height: 40px;
  /* max-height: 150px; */
  width: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_area-otr .textBox {
  min-height: 40px;
  max-height: 150px;
  border: none;
  box-shadow: none;
  font-family: "ProximaNova-Regular" !important;
}

.text_area-otr {
  border-radius: 4px;
  border: 1px solid var(--neutral-neutral-neutral-6, #bfbfbf);
  background: var(--colors-surface-white, #fff);
}

.hideAll {
  color: var(--dex-colors-brand-primary, #0642b5);
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  cursor: pointer;
}

.optionModal {
  width: 140px !important;
  height: 81px !important;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
  /* display: flex;
  justify-content: space-between; */
}

.optionModal .ant-modal-footer {
  display: none;
}

.optionModal .ant-modal-close svg {
  display: none;
}

.optionModal .ant-modal-content {
  padding: 0;
}

.editBox {
  border-bottom: 1px solid #d8dae3;
  width: 140px !important;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.deleteBox {
  width: 140px !important;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.optionText {
  color: var(--dex-colors-surface-dark, #000);
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 16px !important;
}

.optionModal .ant-popover-arrow {
  display: none;
}

.optionModal .ant-popover-inner {
  padding: 0px;
}

.optionModal .ant-dropdown-menu {
  padding: 0;
  width: 140px !important;
  height: 81px !important;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
}

.optionModal .ant-dropdown-menu-title-content {
  color: var(--dex-colors-surface-dark, #000);
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.optionModal .ant-dropdown-menu-item-divider {
  padding: 0px !important;
}

.likeText {
  color: var(--colors-surface-black, #000);
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.likeTextActive {
  color: var(--brand-light-theme-primary, #0642b5);
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.likeText:hover {
  text-decoration: underline;
}

.likeTextActive:hover {
  text-decoration: underline;
}

.replyCard {
  min-height: 100px;
  /* background-color: red; */
  padding-left: 72px;
  margin-top: 12px;
  padding-bottom: 12px;
  /* border-bottom: 1px solid #e9eaeb; */
  display: flex;
  justify-content: row;
}

.profile-pic {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin-right: 16px;
}

.cancelButton span {
  color: var(--brand-light-theme-primary, #0642b5);
  font-family: "ProximaNova-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.cancelButton {
  height: 23px !important;
  width: 63px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid var(--colors-surface-black, #000);
  margin-top: 12px;
}

.cancelButton:hover {
  border: 1px solid var(--colors-surface-black, #000) !important;
}

.replyName {
  color: #000;
  font-family: "ProximaNova-SemiBold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
}

.text_area-reply {
  /* min-height: 40px; */
  width: 428px;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--neutral-neutral-neutral-6, #bfbfbf);
  background: var(--colors-surface-white, #fff);
  margin-top: 8px;
}

.text_area-reply .ant-input {
  min-height: 40px;
  max-height: 120px !important;
}

.text_area-reply img {
  width: 24px;
  height: 24px;
}

.text_area-reply img {
  display: flex;
  align-self: flex-end;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
}

.text_area-reply textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
  padding-left: 8px;
  color: var(--neutral-neutral-neutral-13, #000);
  font-family: "ProximaNova-Regular" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  /* 140% */
}

.editButtons {
  display: flex;
  flex-direction: row;
}

.submitButton span {
  color: var(--colors-surface-white, #fff);
  font-family: "ProximaNova-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.submitButton {
  height: 23px !important;
  width: 93px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #0642b5;
  margin-top: 12px;
  margin-right: 16px;
}

.submitButton:hover {
  border: 1px solid var(--colors-surface-black, #000) !important;
}

.deleteConfirmText {
  color: var(--neutral-neutral-neutral-9, #454545);
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}

.deleteModal .ant-modal-title {
  color: var(--neutral-neutral-neutral-13, #000);
  font-family: "ProximaNova-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.deleteModal .ant-modal-content {
  width: 600px !important;
}

.deleteModal.ant-modal {
  width: 600px !important;
  display: flex;
  align-items: center;
  padding: 163px 0px;
}

.text_area-reply .ant-input:focus {
  border: none;
  outline: 0px none transparent;
}

.text_area-reply .ant-input::-webkit-scrollbar {
  display: none;
}

.commentBar .text_area-otr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text_area-reply .ant-input:focus {
  box-shadow: none;
}

.text_area-otr .ant-input::-webkit-scrollbar {
  display: none;
}

.text_area-reply .comment-box {
  min-height: 40px;
  max-height: 120px;
}

.sendReply {
  cursor: pointer;
}

.sendComment {
  cursor: pointer;
}

.textBox::placeholder {
  color: #595959;
}

.comment-box::placeholder {
  color: #595959;
}
.deleteModal .ant-modal-header {
  margin-bottom: 15px;
}
.deleteModal .modalTitleDivider {
  height: 2px;
  background-color: #e9eaeb;
  margin: 0px -20px 15px -20px;
}
