.form-container {
  margin: 0 0;
  padding: 0;
  font-family: "ProximaNova-Semibold";
}
.custom-form-group .ant-form-item-row {
  display: flex;
  flex-direction: column;
}
.custom-form-group .ant-form-item-row .ant-form-item-label {
  text-align: left;
}
.custom-form-group .ant-form-item {
  margin: 0;
}
.custom-form-group {
  padding: 24px 16px;
}
.auto-form-class .ant-select-selector .ant-select-selection-search input {
  border: 0px !important;
}
.file-upload .ant-form-item-control {
  min-height: auto;
}
.custom-form-group .ant-select-selector,
.custom-form-group .ant-form-item-control-input-content input,
.custom-form-group .ant-picker-range {
  border-radius: 4px;
  border: 1px solid #757a87 !important;
  background: #fff;
}
.date-custom-form-group .ant-form-item-control-input-content input {
  border: 0 !important;
}
.custom-form-group .ant-picker-range {
  width: 100%;
  height: 40px;
}
.custom-form-group .ant-upload-select {
  width: 100% !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: 1px solid #757a87 !important;
  background: #fff !important;
}
.custom-form-group .ant-select-selector,
.custom-form-group .ant-form-item-control-input,
.custom-form-group .ant-input {
  width: 100%;
  height: 40px !important;
  min-height: 40px !important;
}
.custom-form-group .ant-upload > div {
  display: flex;
  align-items: center;
}
.custom-form-group .ant-upload > div > div {
  margin: 0 !important;
}
.modal-spacing {
  margin-bottom: 80px;
}
/* .ant-modal form.main_otr-sec {
    width: 50% !important;
    font-family: "ProximaNova-Semibold";
} */
.ant-modal .ant-modal-body .main_otr-sec .ant-col {
  font-family: "ProximaNova-Semibold";
}
.ant-modal .main_otr-sec button.ant-modal-close {
  /* background: rgb(230,90,20,0.3);
    width: 35px;
    height: 35px;
    border-radius: 2px;
    color: #f00; */
  display: flex;
  align-items: center;
  justify-content: center;
  top: 30px;
}
.ant-modal
  .ant-modal-body
  .main_otr-sec
  form.ant-form.input_table
  .ant-form-item {
  width: auto;
  display: flex;
  float: none;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
.ant-modal.ant-modal-confirm-confirm .ant-modal-confirm-content {
  max-width: 100% !important;
}
.ant-modal .main_otr-sec .ant-modal-header {
  margin: 10px 0 0;
  border-bottom: 1px solid #e9eaeb;
  padding: 0 0 20px;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-form-item {
  float: left;
  width: calc((100% - 20px * 1) / 2);
  margin-right: 20px;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-form-item:nth-child(2n) {
  margin-right: 0;
}
.ant-modal
  .ant-modal-body
  .main_otr-sec
  .ant-form-item
  .ant-col.ant-form-item-label {
  display: table;
  width: 100%;
  text-align: left;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-form-item input.ant-input {
  color: #b1b4bb;
  font-size: 14px;
  font-family: Proxima Nova;
  font-weight: 400;
  line-height: 19.6px;
  padding: 7px 15px;
  border-color: #757a87;
  font-family: "ProximaNova-Semibold";
  border-radius: 4px;
  box-shadow: none;
}
.ant-modal
  .ant-modal-body
  .main_otr-sec
  .ant-form-item
  input.ant-input::placeholder {
  color: #757a87;
}
.ant-modal
  .ant-modal-body
  .main_otr-sec
  .ant-form-item
  .ant-input-number
  input {
  border-color: #757a87;
}
.ant-modal
  .ant-modal-body
  .main_otr-sec
  .ant-form-item
  .ant-input-number
  input::placeholder {
  color: #757a87;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-form-item .ant-input-number {
  width: 100%;
  border-radius: 4px;
  border-color: #757a87;
  box-shadow: none;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-form-item label:after {
  display: none;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-col input {
  height: 40px;
}
.ant-modal .ant-modal-body .main_otr-sec .ant-col .ant-select-selector {
  border-radius: 4px;
  height: 40px;
  border-color: #757a87;
}
/* Input-Table */
form.ant-form.input_table h1 {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 20px;
}
form.ant-form.input_table {
  display: table;
  width: 100%;
  position: relative;
}
form.ant-form.input_table table {
  display: table;
  width: 100%;
  position: relative;
  border: 1px solid #757a87;
  border-collapse: collapse;
}
form.ant-form.input_table table th {
  border: 1px solid #757a87;
  margin: 0;
  padding: 0 15px;
}
form.ant-form.input_table table td {
  border: 1px solid #757a87;
  margin: 0;
  padding: 0;
}
.ant-modal.main_otr-sec
  .ant-modal-body
  form.ant-form.input_table
  table
  td
  .ant-form-item {
  float: none;
  width: 50%;
  margin: 10px auto;
}
.ant-modal.main_otr-sec
  .ant-modal-body
  form.ant-form.input_table
  table
  td
  .ant-form-item
  input.ant-input-number-input {
  height: 30px;
}
.ant-modal.main_otr-sec
  .ant-modal-body
  form.ant-form.input_table
  table
  td
  .ant-form-item
  input.ant-input {
  height: 30px;
  border: 1px solid #757a87;
}
.ant-modal.main_otr-sec
  .ant-modal-body
  form.ant-form.input_table
  table
  td
  .ant-form-item
  .ant-input-number {
  border-radius: 4px;
}
form.ant-form.input_table table th button {
  color: #fff;
  background-color: #0642b5;
  border: 1px solid #0642b5;
  padding: 8px 35px;
  border-radius: 5px;
  margin: 5px 0;
  cursor: pointer;
}
form.ant-form.input_table table td span.anticon.anticon-delete {
  display: table;
  margin: 0 auto;
  cursor: pointer;
}
/* End of Input-Table */
.ant-modal.ant-modal-confirm-confirm {
  width: 55% !important;
}
.policy-hubRight {
  margin-left: 100px;
  transition: all 0.3s;
}
.policy-hub {
  margin-left: 20px;
  transition: all 0.3s;
}
