.activity-container {
  background-color: #ffffff;
  border-radius: 8px;
  height: auto;
  margin-top: 15px;
  box-shadow: 1px 1px 1px 1px rgba(206, 211, 218, 0.8);
  position: relative;
  overflow: hidden;
  padding: 10px;
  cursor: pointer;
}
.activity-container .txt-activity-date {
  color: #0a0a0a;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
}
.activity-container .activity-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px;
  padding-left: 0px;
}
.activity-container .activity-row-view .txt-activity-working-hr {
  color: #474747;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
}
.activity-container .status-view {
  border-bottom-left-radius: 8px;
  position: absolute;
  padding: 5px;
  z-index: 1;
  top: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.txt-activity-status {
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
}
.txt-activity-date-notsubmitted{
  color: #0a0a0a;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
}