.dropdown-container .ant-btn {
  background: #f8f8f8;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 8px;
}

.dropdown-container .ant-space.ant-space-gap-col-small {
  column-gap: 8px;
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.dropdown-container {
  /* flex: 1; */
  margin-top: 10px;
}

.dropdown-container .ant-space-item {
  width: 100%;
  text-align: left;
  color: #0a0a0a;
}
.dropdown-container .ant-space-item .ant-select {
  width: 100%;
}
.dropdown-container .ant-space-item .ant-select svg {
  color: #474747;
}
.dropdown-container .ant-space-item span.anticon {
  justify-content: flex-end;
  width: 100%;
}
