body {
  font-family: "ProximaNova-Regular" !important;
  margin: 0;
  overflow-x: hidden;
}
.common-card-font {
  font-family: "ProximaNova-Regular";
}
.dex-title {
  font-family: "ProximaNova-SemiBold";
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  margin: 16px 0px;
}
.dark-black-text {
  color: #1f1f1f;
}
.black-text-2 {
  color: #1a1b1d;
}
.gray600 {
  color: #5c5c5c;
}
.gray650 {
  color: #3d3d3d;
}
.gray450 {
  color: #8f8f8f;
}
.gray800 {
  color: #0a0a0a;
}
.black-text {
  color: #000000;
}
.blue-text {
  color: #0642b5;
}
.xs-text {
  font-size: 12px;
}
.sm-text {
  font-size: 14px;
}
.lg-text {
  font-size: 18px;
}
.md-text {
  font-size: 16px;
}
.xl-text {
  font-size: 24px;
}

.normal-text {
  font-family: "ProximaNova-Regular";
  font-weight: 400;
}
.semibold-text {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
}
.bold-text {
  font-family: "ProximaNova-Bold";
  font-weight: 700;
}
.cursor-pointer {
  cursor: pointer;
}
.dex-header-spacing {
  margin: 72px 60px 84px;
}
