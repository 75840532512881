.leave-management-top-container {
  padding: 60px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  box-sizing: border-box;
}

.leave-management-top-container .leave-management-main-heading {
  font-size: 24px;
  font-weight: 700;
  font-family: "ProximaNova-Bold";
  color: #000000;
  margin: 20px 0 16px;
  line-height: 28.8px;
}

.leave-management-top-container .leave-management-body-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex: 1;
}

.leave-management-top-container .leave-management-left-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}

.leave-management-top-container .leave-management-top-left-conatiner {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex: 0.2;
}

.leave-management-top-container .leave-management-right-container {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: space-between;
  gap: 10px;
}

.leave-management-top-container .leave-management-leave-balance-card {
  width: 55%;
}

.leave-management-top-container .leave-management-holiday-quotecard {
  width: 45%;
}

.leave-management-top-container .leave-management-leave-request-card {
  flex: 1;
}

.leave-management-top-container .leave-management-holiday-carousel-card {
  flex: 0.2;
}

.leave-management-top-container .leave-management-upcoming-holidays-card {
  flex: 0.45;
}

.leave-management-top-container .leave-management-absence-request-card {
  flex: 0.35;
  margin-top: 12px;
}

.leave-management-top-container
  .leave-management-top-left-conatiner
  .leave-management-leave-balance-card
  .ant-skeleton.ant-skeleton-element {
  display: flex;
  gap: 15px;
  width: 30vw;
  height: 20vh;
}

.leave-management-top-container
  .leave-management-top-left-conatiner
  .leave-management-holiday-quotecard
  .ant-skeleton.ant-skeleton-element {
  width: 100%;
  height: 20vh;
}

.leave-management-top-container
  .leave-management-leave-request-card
  .ant-skeleton.ant-skeleton-element {
  width: 100%;
  height: 70vh;
}

.leave-management-top-container
  .leave-management-right-container
  .ant-skeleton.ant-skeleton-element {
  width: 100%;
  height: 100%;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon img {
  position: relative;
  top: 4px;
}

.tooltip {
  visibility: hidden;
  width: 210px;
  background-color: #ffffff;
  color: #1f1f1f;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 125%;
  left: 90px;
  transform: translateX(-50%);
  z-index: 1;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 11%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
