.challeng-goal {
  margin: 80px 60px 0 60px;
}

.challeng-goal .breadcrumb_newgoal h1 {
  margin: 14px 0 0;
  font-size: 24px;
  line-height: 29px;
  font-family: "ProximaNova-Bold";
}

.challeng-goal .breadcrumb_newgoal p {
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNova-Regular";
}

.contribute-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contribute-head h1 {
  font-size: 18px;
  line-height: 25px;
  font-family: "ProximaNova-Semibold";
  color: #000;
}

.contribute-head .search input.ant-input {
  width: 379px;
}

.challeng-goal span.goals-total {
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNova-Regular";
  color: #1f1f1f;
  margin: 0 0 12px;
  display: table;
}

.breadcrumb_preview ol li span.ant-breadcrumb-link {
  font-family: "ProximaNova-Regular";
}

.challeng-goal span.goals-total span {
  font-family: "ProximaNova-Semibold";
}

.goals_card-otr {
  display: flex;
  flex-wrap: wrap;
}

.goals_text {
  width: calc((100% - 24px * 2) / 3);
  margin-right: 24px;
  margin-bottom: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 17px 16px;
}

.goals_text:nth-child(3n) {
  margin-right: 0;
}

.goals_text-title {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-family: "ProximaNova-Semibold";
  margin: 0 0 10px;
}

.goals_text-designation {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-family: "ProximaNova-Regular";
  margin: 0 0 10px;
}
.goals_text-designation span {
  font-family: "ProximaNova-Semibold";
}
.contributor-profile {
  display: flex;
  position: relative;
  margin: 0;
  height: 50px;
}
.avatar-with-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  background-color: #f0f0f0;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 25px;
  left: 34px;
  z-index: 9;
}
.ant-tooltip.tooltip-profile-otr {
  z-index: 9;
}
.tooltip-profile-otr .ant-tooltip-arrow {
  bottom: 0 !important;
}
.tooltip-profile-otr .ant-tooltip-content {
  width: auto;
  height: auto;
}
.tooltip-profile .ant-avatar-group {
  margin: 0 5px;
}
.tooltip-profile .ant-avatar-group span.ant-avatar,
.tooltip-profile img {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  font-size: 12px;
}
.tooltip-profile .ant-avatar-group span.ant-avatar-string {
  font-size: 12px;
}
.contributor-profile img,
.contributor-profile .ant-avatar-group {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 25px !important;
}
.contributor-profile span.contribute_title {
  display: table;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #454545;
  font-family: "ProximaNova-Regular";
}
.contributor-profile img:first-child,
.contributor-profile .ant-avatar-group:first-child {
  position: absolute;
  left: 0px;
  z-index: 1;
  top: 0;
}

.contributor-profile img:nth-child(2),
.contributor-profile .ant-avatar-group:nth-child(2) {
  position: absolute;
  left: 13px;
  z-index: 2;
  top: 0;
}

.contributor-profile img:nth-child(3),
.contributor-profile .ant-avatar-group:nth-child(3) {
  position: absolute;
  left: 23px;
  z-index: 3;
  top: 0;
}

.contributor-profile img:nth-child(4),
.contributor-profile .ant-avatar-group:nth-child(4) {
  position: absolute;
  left: 33px;
  z-index: 4;
  top: 0;
}
.contributor-profile img:nth-child(5),
.contributor-profile .ant-avatar-group:nth-child(5) {
  position: absolute;
  left: 33px;
  z-index: 4;
  top: 0;
}
.contributor-profile .ant-avatar-group span.ant-avatar {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
}
.contributor-profile .ant-avatar-group span.ant-avatar span.ant-avatar-string {
  font-size: 12px;
}
.contribut-pf-otr {
  font-size: 14px;
  line-height: 20px;
  color: #454545;
  font-family: "ProximaNova-Regular";
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.goals_text button.ant-btn {
  float: right;
  display: flex;
  border: 0px solid;
  padding: 0;
  margin: 0;
  box-shadow: none;
  align-items: center;
  justify-content: center;
}

.goals_text button.ant-btn div {
  box-shadow: none;
}

.goals_text button.ant-btn span {
  font-size: 16px;
  line-height: 22px;
  color: #262626;
  font-family: "ProximaNova-Semibold";
}

.contribute_view-otr {
  display: table;
  margin: 50px 0 0;
  width: 100%;
}

.contribute_view-otr .goal-preview-header {
  margin: 0;
}

.contribute_view-otr .goal-preview-header h3.preview-main-title {
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  padding: 16px 0;
}

.contribute_view-otr .preview-goal-main {
  border-radius: 8px;
  padding: 24px 24px 40px;
}

.contribute_view-otr .preview-goal-main h5 {
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Semibold";
}

.contribute_view-otr .preview-goal-main p {
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  white-space: pre-wrap;
}

.contribute_view-otr .preview-goal-main .status-section p {
  font-family: "ProximaNova-Semibold";
}

.contribute_view-otr .preview-goal-main .milestone-section {
  border-radius: 8px;
  padding-bottom: 27px;
}

.contribute_view-otr .milestone-section h5 {
  color: #1f1f1f;
}

.contribute_view-otr .milestone-section .milestone-header-left span {
  font-size: 14px;
  line-height: 20px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
}

.contribute_view-otr .empty-milestone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.contribute_view-otr .empty-milestone span {
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  width: 330px;
  text-align: center;
  font-family: "ProximaNova-Regular";
}

.contribute_view-otr .preview-goal-main .button-area {
  margin-top: 20px;
}

/* Edit_Goal */
.new-goal.contribute_edit-goal {
  margin: 140px 60px 60px;
}

.new-goal.contribute_edit-goal label.ant-form-item-required:before {
  right: -13px;
  position: absolute;
  top: 0;
  color: #cc0000 !important;
}

.challeng-goal nav.ant-breadcrumb ol {
  align-items: center;
}

.empty-milestone span.empty-milestone_cont {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  width: 321px;
  text-align: center;
}

.breadcrumb_newgoal ol {
  align-items: center;
}

.milestone-section .milestone-header-left span.requird-star {
  color: #cc0000;
  font-size: initial;
  line-height: initial;
}
