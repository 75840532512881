.cardcomponents {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: flex;
  flex-direction: row;
  padding: 16px;
  min-width: 350px;
  max-width: 425px;
  flex-grow: 1;
  cursor: pointer;
}

.cardcomponents .image-Container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image {
  width: 47px;
  height: 47px;
  border-radius: 47px;
}

.text {
  flex: 7;
  margin-left: 10px;
}
.text .name {
  text-align: left;
  color: #000;
  font-family: "ProximaNova-SemiBold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
.text .designation {
  text-align: left;
  color: #757a87;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.employee-name-apprasial {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appraisal-quarter {
  height: 23px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
  background: #f0f0f0;
}
.employee-first-letter {
  width: 47px;
  height: 47px;
  border-radius: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
}
.appraisal-year-end {
  padding: 10px;
  width: auto;
}
