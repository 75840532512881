.main_container {
  margin: 80px 55px;
}

.hidestricky_breadcrump .Seperation-page-title {
  margin-top: 20px;
  font-size: 24px;
}

.Seperation-page-title {
  margin-bottom: 20px;
}

.main-sub-container {
  margin: 0px 0px;
}

.main-sub-container .caledar-main-view {
  margin-top: 20px;
  flex: 1;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.main-sub-container .caledar-main-view-row-view {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.main-sub-container .caledar-main-view .content-view {
  flex: 1;
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  margin-top: 25px;
}

.main-sub-container .caledar-main-view .content-view .shift-type-view {
  margin-top: 20px;
  flex: 1;
}

.main_container .top-row-view {
  display: flex;
  flex-direction: row;
}

.main_container .top-row-view .column-view {
  display: flex;
  /* flex: 0.7; */
  flex-direction: column;
  width: 76%;
}

.main_container .top-row-view .column-view .devider-view {
  height: 1px;
  background-color: #e2e2e2;
  width: 100%;
}

.main_container .top-row-view .column-view .button-view {
  padding: 30px;
  padding-right: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

.main_container .sub-row-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main_container .sub-row-view .ant-list.ant-list-split {
  width: 100%;
}

.main_container .top-row-view .sub-row-right-view {
  width: 26%;
}

.main-sub-container .sub-row-right-view .my-activity-view {
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 30px;
  padding: 20px;
}

.my-activity-view .my-activity-row-view-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.my-activity-text {
  color: #0a0a0a;
  font-size: 18px;
  font-family: "ProximaNova-Semibold";
  padding-right: 10px;
}

.my-activity-view .my-activity-view-all-txt {
  align-self: center;
  justify-content: center;
  padding-top: 20px;
  display: flex;
  color: #0642b5;
  align-items: center;
  cursor: pointer;
  font-family: "ProximaNova-Semibold";
}

.mandatory {
  color: #cc0000;
}

.txt-mandatory {
  color: #0a0a0a;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
}

.holiday-quote-card-container-timesheet {
  position: relative;
  /* height: 100%; */
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  flex: 1;
  padding: 10px;
  margin-left: 30px;
}

.holiday-quote-card-background-timesheet {
  background: linear-gradient(180deg, #ffffff 25.95%, #b4ceff 91.04%);
  height: 75%;
  width: 92%;
  border-radius: 8px;
  display: flex;
  gap: 10px;
}

.manholdinglapotop-timesheet {
  /* position: absolute; */
  /* top: 5; */
  /* bottom: 0; */
  width: 65px;
  height: 52px;
}

.holiday-quote-card-text-timesheet {
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  font-size: 13px;
  line-height: 16.8px;
  color: #000000;
  max-lines: 3;
  /* padding-left: 50px;
  display: flex;
  align-items: center; */
}

.holiday-quote-card-image-timesheet {
  width: 65px;
  height: 52px;
}

.timesheet-box img {
  width: 32px;
}

.timesheet-shift-box img {
  width: 20px;
}

.empty-activity-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.no-timesheet-text {
  color: #0a0a0a;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  margin-top: 5px;
}

.no-timesheet-text-msg {
  color: #6f6f6f;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  text-align: center;
  margin-top: 5px;
}

.create-timesheet-title-text {
  color: #000000;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
}

.clone-main-container {
  background-color: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
}

.clone-sub-container {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.clone-icon-title {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding-left: 10px;
}

.clone-toggle-btn {
  margin: 11px;
}

.clone-toggle-btn .ant-switch.ant-switch-checked {
  background: #0642b5;
}

.clone-toggle-btn
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0642b5;
}

.clone-toggle-btn .ant-switch {
  background: #8f8f8f;
}

.clone-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 18px;
}

.timesheet-container {
  padding: 16px;
  border-radius: 8px;
  /* background-color: #fff; */
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
}

.timesheet-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 16px;
}

.week-selector {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  justify-content: center;
  margin-top: 20px;
}

.week-selector span {
  margin-right: 8px;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  display: flex;
  align-items: center;
}

.clone-calender-week .dropdown-container {
  margin-top: 0;
}

.clone-calender-week .week-selector img {
  margin-right: 5px;
}

.calendar-icon {
  color: #1890ff;
  font-size: 16px;
}

.week-label {
  font-size: 14px;
  color: #666;
}

.hours-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}

.hours-row {
  display: flex;
  justify-content: space-between;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  color: #333;
}

.total-hours {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-top: 12px;
  margin-bottom: 16px;
}

.total-hours-value {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
}

.clone-button {
  align-items: center;
  justify-content: center;
  border: 1px solid #0642b5;
  border-radius: 8px;
  color: #0642b5;
  background: transparent;
  font-family: ProximaNova-Semibold;
  font-size: 14px;
  height: 40px;
}

.clone-button-view {
  display: flex;
  justify-content: flex-end;
}

.clone-button-view
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #0642b5;
  color: #0642b5;
  background: transparent;
}

.landingpage-middle-content-scroll {
  width: 58%;
  height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
}

.landingpage-middle-content-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
}

.landingpage-middle-content-scroll::-webkit-scrollbar {
  width: 6px;
  color: #858585;
}

.clone-hr-line {
  border-top: 1px solid #e2e2e2;
}

.clone-daily-comment {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
}

.clone-overall-comment {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
}

.clone-daily-comment-res {
  color: #5c5c5c;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  line-height: 20px;
}

.clone-comment-day {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
}

.clone-overall-comment-res {
  color: #5c5c5c;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  line-height: 20px;
}

.tsm_input_create_timesheet {
  color: #000000;
  font-size: 15px;
  font-family: "ProximaNova-Regular";
  padding: 10px;
}

.noDataMessage {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  text-align: center;
}

.button-view #create-timesheet-button.ant-btn-primary {
  background-color: #0642b5;
}

.ant-btn-primary:disabled {
  background-color: #e2e2e2 !important;
}

.ant-tooltip.tooltip-tsm-calendar {
  white-space: nowrap;
  width: fit-content !important;
}

.ant-tooltip.tooltip-tsm-calendar .ant-tooltip-content {
  white-space: nowrap;
  width: fit-content !important;
  height: auto;
}

.clone-create-spin-load {
  width: 110px;
}

#create-timesheet-button .ant-spin .ant-spin-dot-holder {
  color: #fff;
}
