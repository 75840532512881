/* Activity History */
.activity-container {
  height: 676px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.activity-container .header-section-activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 18px;
  margin-bottom: 0px !important;
}
.month_search-pointer .leadersearch {
  box-shadow: none;
  height: 30px;
}
.activity-container .activity-container-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.activity-container .content-container-activity {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.activity-container .content-container-activity-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
  justify-content: center;
}

.activity-container .activity-section {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  border-bottom: 2px solid #d9d9d9;
}

.activity-container .activity-section-header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.activity-container .activity-section-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}
.activity-container .activity-section-points {
  color: #008000;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  padding: 4px 8px;
  background-color: rgba(204, 230, 204, 0.5);
  border-radius: 24px;
}
.activity-container .card-activity {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 14px 24px;
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.activity-container .title-one {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  flex: 1;
}
.activity-container .title-two {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  flex: 1;
}
.activity-container .title-two span {
  color: #595959;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.activity-container .title-three {
  color: #008000;
  text-align: right;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  flex: 1;
}
.activity-container .card-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.activity-container .load-more-button {
  cursor: pointer;
  margin: 16px 17px;
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  align-self: center;
}

.no-data-container {
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-data-container .activity-empty-image {
  height: 160px;
  width: 160px;
}
.no-data-container .no-data-activity-title {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}
.no-data-container .sub-title-no-activity {
  color: #595959;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.month_search-pointer .ant-picker {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  margin-right: 12px;
  align-items: center;
  width: 87px;
  justify-content: space-around;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}
.month_search-pointer .ant-picker .ant-picker-input {
  cursor: pointer;
}
.month_search-pointer .ant-picker input {
  cursor: pointer;
}
.month_search-pointer .ant-picker input::placeholder {
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.month_search-pointer .ant-picker svg {
  color: #454545;
}
.point-histroy-mnt .ant-picker-header span.ant-picker-super-prev-icon {
  left: 15px;
}
.point-histroy-mnt .ant-picker-header span.ant-picker-super-next-icon {
  right: 15px;
}
.point-histroy-mnt .ant-picker-header span.ant-picker-super-prev-icon:after {
  display: none;
}
.point-histroy-mnt .ant-picker-header span.ant-picker-super-next-icon:after {
  display: none;
}
.point-histroy-mnt.ant-picker-dropdown .ant-picker-body table td {
  border: 0px solid !important;
}
.point-histroy-mnt.ant-picker-dropdown
  .ant-picker-body
  table
  tr
  td.ant-picker-cell
  .ant-picker-cell-inner {
  width: 74px;
  height: 32px;
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-family: "Proximanova-Regular";
  border-radius: 32px;
}
.point-histroy-mnt.ant-picker-dropdown
  .ant-picker-body
  table
  tr
  td.ant-picker-cell:hover
  .ant-picker-cell-inner {
  width: 74px;
  height: 32px;
  background: #0642b5 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Proximanova-Regular";
  border-radius: 32px;
}
.point-histroy-mnt.ant-picker-dropdown
  .ant-picker-body
  table
  tr
  td.ant-picker-cell-disabled:before {
  background: transparent;
}
.point-histroy-mnt.ant-picker-dropdown
  .ant-picker-body
  table
  tr
  td.ant-picker-cell-disabled
  .ant-picker-cell-inner {
  color: #8c8c8c;
}
.ant-picker-header span.ant-picker-super-prev-icon {
  left: 15px;
}
.ant-picker-header span.ant-picker-super-next-icon {
  right: 15px;
}
.ant-picker-header span.ant-picker-super-prev-icon:after {
  display: none;
}
.ant-picker-header span.ant-picker-super-next-icon:after {
  display: none;
}
.activity-section-points-red {
  background: #fae6e6 !important;
  color: #c00 !important;
}
.title-three-red {
  color: #c00 !important;
}
.month_search-pointer .points-active {
  border: 1px solid #0642b5 !important;
  background: #e6efff !important;
  width: 105px !important;
}
