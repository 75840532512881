.avatar-mainComment {
  /* margin-right: 5px; */
  display: flex;
  /* margin-top: 2px; */
  /* align-self: baseline; */
}
.sendCommentMainComment {
  margin-right: 10px;
  display: flex;
  align-self: flex-end;
  margin-bottom: 10px;
  cursor: pointer;
}
