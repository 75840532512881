.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-layout .ant-layout-header {
  height: auto !important;
  color: rgba(0, 0, 0, 0.88);
  line-height: 0px !important;
  background: #fdfdfd;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.04),
    0px 0px 8px rgba(20, 20, 20, 0.08);
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 111;
}

.ant-layout .ant-layout-footer {
  text-align: center;
  color: #2b3348;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.04),
    0px 0px 8px rgba(20, 20, 20, 0.08);
}

.ant-layout .ant-layout-content {
  text-align: start !important;
}

.ant-steps-item-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #505768;
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Light.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova-Thin";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Thin.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Semibold.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Bold.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova-Black";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Black.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url(../src/themes/assets/fonts/Proximanova/ProximaNova-Extrabold.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url(../src/themes/assets/fonts/Inter/Inter-SemiBold.ttf);
  font-weight: normal;
}

button:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
  transition: none !important;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

button.ant-btn div {
  box-shadow: none !important;
}

.notification-helpdesk-top {
  margin-top: 40px;
}

.p-tabview-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1 1 auto;
}