.calendar_tabs_container {
  margin: 120px 60px 60px;
}

.performance-body .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #095dff !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn:hover,
.performance-body .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #095dff !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: "ProximaNova-Regular";
}

.calendar_card_container {
  display: flex;
  flex-wrap: wrap;
  height: 288px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

.calendar_main {
  display: flex;
}

.calendar_img {
  width: 100%;
  height: 100%;
  object-position: center;
}

.calendar_date_main {
  margin-bottom: 5px;
}

.calendar_month {
  margin-left: 13px;
  line-height: 0px;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  text-align: center;
}

.calendar_week {
  margin-left: 13px;
  line-height: 0px;
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  text-align: center;
}

.calendar_day {
  margin-left: 13px;
  line-height: 0px;
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 32px;
  text-align: center;
}

.calendar_name {
  margin-left: 20px;
  margin-top: 20px;
  line-height: 20px;
  color: #000;
  font-family: "ProximaNova-SemiBold";
  font-size: 20px;
}

.calendar_name p {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.header_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.holiday-tab .p-tabview .p-tabview-nav {
  border-bottom: 3px solid #f0f0f0;
  margin-bottom: 24px;
}

.holiday-tab .p-tabview-nav-container {
  background: none;
}

.holiday-tab .p-unselectable-text {
  margin-right: 16px;
  padding-bottom: 8px;
}

.holiday-tab .p-unselectable-text.p-tabview-selected {
  border-bottom: 4px solid #0642b5;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.holiday-tab .p-unselectable-text.p-tabview-selected .p-tabview-title {
  color: #0642b5;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
}

.holiday-tab .p-unselectable-text .p-tabview-title {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.holiday-tab .p-tabview-nav-link {
  display: inline-block;
}

.holiday-country-select {
  width: 200px !important;
  height: 40px;
}

.holiday-country-select .ant-select-selector {
  height: 40px !important;
  padding: 4px 11px !important;
  border-radius: 4px !important;
  border: 1px solid #bfbfbf !important;
}

.holiday-country-select .ant-select-selection-item {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.calendar_img_container {
  width: 100%;
  height: 183px;
}

.leave-type-icon {
  margin-right: 8px;
}

.holiday-dropdown-open {}

.country-city-dropdown-section {
  display: flex;
  gap: 20px;
}

.calendar_img_container .ant-skeleton {
  height: 100%;
  width: 100%;
  display: flex !important;
}

.calendar_img_container span.lazy-load-image-background.blur.lazy-load-image-loaded {
  height: 100%;
  width: 100%;
}