.holiday-box-container {
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: 30px;
  padding: 20px;
  /* width: 330px; */
}
.holiday-box-container .holiday-box-top-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.holiday-box-container .holiday-border-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-top: 15px;
  justify-content: space-between;
  overflow: hidden;
}

.holiday-box-container .past-holiday-text {
  color: #0a0a0a;
  font-size: 18px;
  font-family: "ProximaNova-Semibold";
}

.holiday-box-container .view-all-text {
  color: #0642b5;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  cursor: pointer;
}
.holiday-box-container .holiday-border-view .month-column-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.holiday-box-container .holiday-border-view .month-holday-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.holiday-box-container .holiday-border-view .month-column-view .txt-month {
  color: #5c5c5c;
  font-size: 12px;
  font-family: "ProximaNova-Regular";
}
.holiday-box-container .holiday-border-view .month-column-view .txt-date {
  color: #0a0a0a;
  font-size: 24px;
  font-family: "Inter-SemiBold";
}

.holiday-box-container .holiday-border-view .holiday-column-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0;
}
.holiday-box-container .holiday-border-view .holiday-column-view .txt-holiday {
  color: #0a0a0a;
  font-size: 14px;
  font-family: "ProximaNova-SemiBold";
}
.holiday-box-container
  .holiday-border-view
  .holiday-column-view
  .holiday-day-row-view
  .txt-day {
  color: #5c5c5c;
  font-size: 12px;
  font-family: "ProximaNova-Regular";
}

.holiday-box-container .holiday-border-view .holiday-column-view .holiday-day-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.holiday-box-container
  .holiday-border-view
  .holiday-column-view
  .holiday-day-row-view
  .txt-holiday-type {
  color: #1e685a;
  font-size: 12px;
  font-family: "ProximaNova-Semibold";
  margin-left: 5px;
}

.holiday-box-container .holiday-border-view .logo-image {
  width: 92px;
  height: 74px;
  align-self: center;
}
