.errorSubContainer{
    display: flex;
    flex-direction: column;
    width: 395px;
    align-items: center;
    justify-content: center;
    height: 198px;
}
.errorHeading{
    font-family: "ProximaNova-Semibold";
    font-size: 16px;
    text-align: center;
    line-height: 13px;
}
.errorSubHeading{
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    text-align: center;
    color: #000000;
    line-height: 19px;
}
.errorImage{
    height: 125px;
    width: 125px;
}