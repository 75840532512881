.preview_tsm_modal.ant-modal-content {
  display: flex;
  min-width: 606px;
  min-height: 416px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}
.preview_timesheet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  margin-bottom: 20px;
}
.preview_timesheet_time {
  color: #0a0a0a;
  font-family: "Proximanova-Regular";
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
}
.preview_work_timesheet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  margin-bottom: 16px;
}
.preview_dual_work_timesheet {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 8px;
  background: #f8f8f8;
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}
.preview_buttons_timesheet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}
.preview_buttons_timesheet button.add_manager_delete,
.preview_buttons_timesheet .timesheet_submit-delete {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.preview_work_display {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #f8f8f8;
  color: #333;
  gap: 10px;
  padding: 16px;
}
.add_manager {
  display: flex;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.timesheet_submit {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.preview_company_title {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  padding: 10px;
}
.preview_total_tsm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  padding: 10px;
}
.preview_daily_day {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  min-width: 10px;
  max-width: fit-content;
  max-height: fit-content;
}
.add_manager_delete {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
}
.timesheet_submit_edit {
  border-radius: 8px;
  border: 1px solid #0642b5;
  background: rgba(255, 255, 255, 0);
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
}
.preview_time_module {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.preview_display_status {
  display: flex;
  padding: 7px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
}
.preview_display_status.Approved {
  color: #008000;
  background: #e6f2e6;
}
.preview_display_status.Pending {
  color: #a5780c;
  background: #fdf6e7;
}
.preview_display_status.Rejected {
  color: #cc0000;
  background: #fae6e6;
}
.preview_display_status.Draft {
  color: #858585;
  background: #ededed;
}
.preview_total_tsm.overall {
  padding: 0px;
}
.preview_total_tsm_content {
  color: #5c5c5c;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  line-height: 20px;
}
.preview_overall_tsm {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 10px;
}
.tsm_preview_divider {
  min-width: 95%;
  margin: 5px;
}
.preview_day_daily {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
}
.preview-tsm-borderbox {
  border-radius: 8px;
  max-height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.preview-tsm-borderbox::-webkit-scrollbar {
  width: 7px;
}
.preview-tsm-borderbox::-webkit-scrollbar-track {
  display: none;
}
.preview_daily_day .comments {
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: calc(100% - 50px);
}

.timesheet_page .timesheet_table .performance_tab-otr ul.p-tabview-nav {
  margin-left: 0;
}
.leave-holiday-preview-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leave-holiday-preview-text {
  color: #008000;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  margin-left: 5px;
  width: 300px;
}
.leave-holiday-preview-text-pto {
  color: #a5780c;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  margin-left: 5px;
  width: 300px;
}
.preview-project-division {
  padding: 10px 5px;
  border-radius: 8px;
  background: #f8f8f8b2;
}
.timesheet_submit-delete {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.timesheet_submit-delete-transparent {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: #ffffff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
