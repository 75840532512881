.ant-modal-footer-buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.export-modal {
  display: flex;
  width: 1280px;
  height: 820px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
}

#Seperation-page-title .landing-page-footer .export-button {
  display: inline-flex;
  height: 40px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid, #c7c7c7;
  background: transparent;
  color: #5c5c5c;
  font-size: 14px;
}
#Seperation-page-title .landing-page-footer .export-button:hover {
  border: 1px solid, #c7c7c7;
  background: transparent;
}

.ant-picker {
  width: 100%;
}
.modal-subtext {
  display: flex;
  align-items: center;
  color: #474747;
  font-size: 16px;
  font-family: "ProximaNova-Regular";
  margin: 0 0 15px;
}

.custom-close-icon {
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 375px;
  align-self: stretch;
  width: 24px;
}
.ant-picker-outlined {
  width: 100%;
}

.Calender-icon {
  width: 20px;
  height: 20px;
}
.custom-calendar-dropdown .ant-picker-panel {
  background-color: #fff; /* Background color */
  font-family: "ProximaNova-Regular";
}
.custom-calendar-dropdown .ant-picker-header-super-prev-btn,
.custom-calendar-dropdown .ant-picker-header-super-next-btn {
  display: none;
}
.custom-calendar-dropdown .ant-picker-cell {
  border-bottom: none !important;
}
.custom-calendar-dropdown .ant-picker-week-panel-row {
  border-bottom: none !important;
}

.custom-calendar-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #1d72b8;
  color: white;
}
.custom-calendar-dropdown .ant-picker-header {
  border-bottom: none !important;
}
.custom-calendar-dropdown .ant-picker-header {
  border-bottom: none;
}
.custom-input {
  font-family: "ProximaNova-Regular";
}

.custom-calendar-dropdown .ant-picker-header {
  border-bottom: none !important;
  font-family: "ProximaNova-Regular";
}

.custom-calendar-dropdown .ant-picker-content th {
  width: 30px;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
}

.start-date-label {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  margin: 0;
}
.exporttimesheet_modal .ant-form-item-label label {
  position: relative;
}
.End-Date-label {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.e-mail {
  color: #262626;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.landing-page-footer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 10px;
}
.export-footer-button {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  gap: 100px;
  align-self: stretch;
  margin-top: 20px;
}
.footer-download-button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.footer-email-button {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.hidestricky_breadcrump .Seperation-page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-page-footer .export-button span {
  color: #5c5c5c;
  font-size: 14px;
  font-family: "ProximaNova-SemiBold";
}
.exporttimesheet_modal .ant-modal-content button.ant-modal-close {
  background: transparent;
}
.exporttimesheet_modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 20px;
  line-height: 24px;
  color: #0a0a0a;
  font-family: "ProximaNova-Bold";
}
.exporttimesheet_modal .ant-modal-content .ant-modal-header {
  margin: 0 0 5px;
}
form.export-popup-form-otr
  .ant-form-item
  .ant-form-item-label
  label.ant-form-item-required:before {
  position: absolute;
  top: -2px;
  right: -13px;
  color: #cc0000;
  font-size: 14px;
}
form.export-popup-form-otr .ant-form-item .ant-form-item-label label:after {
  display: none;
}
form.export-popup-form-otr .ant-form-item .ant-picker.custom-input,
form.export-popup-form-otr .ant-form-item .ant-select.custom-input {
  border-color: #c7c7c7;
  height: 40px;
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
}
form.export-popup-form-otr input#email.custom-input {
  border-color: #c7c7c7;
  height: 40px;
  border-radius: 8px;
  box-shadow: none;
}
form.export-popup-form-otr input#email.custom-input {
  border-color: #c7c7c7;
  height: 40px;
  border-radius: 8px;
  box-shadow: none;
}
.ant-form-item.export-form-field_otr {
  margin: 0 0 25px;
}
.ant-form-item.export-form-field_otr:last-child {
  margin: 0;
}
.exporttimesheet_modal .ant-modal-footer button.ant-btn.ant-btn-default {
  border-color: #dbdbdb;
  border-radius: 8px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exporttimesheet_modal .ant-modal-footer button.ant-btn.ant-btn-default span {
  font-size: 14px;
  font-family: "ProximaNova-SemiBold";
}

/* calender-popup */
.custom-input-popup button.ant-picker-header-super-prev-btn,
.custom-input-popup .ant-picker-header-super-next-btn {
  display: none;
}
.custom-input-popup button span.ant-picker-prev-icon:before,
.custom-input-popup button span.ant-picker-next-icon:before {
  font-size: 24px;
  width: 7px;
  height: 7px;
  border-block-width: 2px 0;
  border-inline-width: 2px 0;
  color: #0a0a0a;
}
.custom-input-popup .ant-picker-body table th {
  border: 0px solid !important;
  height: 45px;
}
.custom-input-popup .ant-picker-body table td {
  border: 0px solid !important;
  height: 45px;
}
.custom-input-popup .ant-picker-body {
  padding-top: 0 !important;
}
.ant-picker-dropdown.custom-input-popup .ant-picker-panel-container {
  border: 0px solid !important;
  box-shadow: 0px 3px 6px 0px #ced3da73 !important;
  padding: 10px !important;
}
.custom-input-popup .ant-picker-header-view button {
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
}

/*export-manager-report*/
.custom-input-popup .ant-picker-panels .ant-picker-panel:last-child {
  display: none;
}
.custom-input-popup
  .ant-picker-panels
  .ant-picker-panel
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}
.ant-space.range_sec-otr {
  width: 100%;
}
.ant-space.range_sec-otr .ant-picker-input {
  position: relative;
  width: 80px;
}
form.export-popup-form-otr
  .ant-form-item
  .ant-picker.custom-input
  .ant-picker-active-bar {
  display: none;
}
form.export-popup-form-otr
  .ant-form-item
  .ant-picker.custom-input
  span.ant-picker-suffix {
  position: absolute;
  right: 10px;
}

.hidestricky_breadcrump
  .Seperation-page-title
  .landing-page-footer
  button.manager_export-btn {
  display: flex;
  width: 146px;
  height: 40px;
  align-items: center;
  justify-content: space-evenly;
}

.export-search-field input.ant-input::placeholder {
  color: #6f6f6f;
}
.export-search-field span.ant-input-prefix svg {
  color: #6f6f6f;
  font-size: 18px;
}
.export-popup-form-otr.ant-modal.exporttimesheet_modal.admin-export-data
  .export-popup-form-otr
  span.ant-select-arrow
  .anticon-search {
  display: none;
}

.export-popup-form-otr.ant-select-item.ant-select-item-option {
  position: relative;
  padding-left: 32px;
  height: 48px;
  align-items: center;
}
.export-popup-form-otr.ant-select-item.ant-select-item-option
  .ant-select-item-option-content {
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  color: #1f1f1f;
}
.export-popup-form-otr.ant-select-item.ant-select-item-option:before {
  content: "";
  position: absolute;
  background: url(../../themes/assets/images/svg/Checkbox-empty.svg);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 15px;
  height: 15px;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
.export-popup-form-otr.ant-select-item.ant-select-item-option.ant-select-item-option-selected:before {
  content: "";
  position: absolute;
  background: url(../../themes/assets/images/svg/Checkbox-checked.svg);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 15px;
  height: 15px;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
.export-popup-form-otr .export-form-field_otr span.ant-select-selection-item {
  background: #ededed;
  height: 32px;
  border-radius: 30px;
  padding: 3px 14px 4px;
  margin: 6px 10px 6px 6px;
  font-family: "ProximaNova-Regular";
}
.export-popup-form-otr
  .export-form-field_otr
  span.ant-select-selection-item
  span.ant-select-selection-item-content {
  margin-right: 7px;
}
.export-popup-form-otr
  .export-form-field_otr
  span.ant-select-selection-item
  svg {
  color: #1f1f1f;
}
.export-popup-form-otr
  .export-form-field_otr
  .ant-select.ant-select-outlined.ant-select-show-search {
  height: 40px !important;
}
.export-popup-form-otr
  .export-form-field_otr
  .ant-select.ant-select-outlined.ant-select-show-search.ant-select-status-success {
  height: 48px !important;
}
.export-popup-form-otr
  .export-form-field_otr
  .ant-select.ant-select-outlined.ant-select-show-search
  .ant-select-selector {
  border-radius: 8px;
}
.export-popup-form-otr
  .export-form-field_otr
  .ant-select-selection-overflow-item-rest
  span.ant-select-selection-item {
  background: #e6efff;
  color: #0642b5;
  font-family: "ProximaNova-SemiBold";
}
.export-popup-form-otr
  .export-form-field_otr
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
  display: none;
}
.export-popup-form-otr.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: #f3f3f3;
}
.export-popup-form-otr.ant-select-item.ant-select-item-option.ant-select-item-option-selected
  span.ant-select-item-option-state {
  opacity: 0;
}
.export-search-field span.ant-input-affix-wrapper.ant-input-outlined {
  border-radius: 32px;
}
.export-search-field input.ant-input {
  height: 35px;
}
.export_custom-fields span.search-txt {
  font-size: 14px;
  color: #5c5c5c;
  padding: 5px 0 5px 10px;
  display: flex;
  font-family: "ProximaNova-SemiBold";
}

.selected_value-otr {
  display: flex;
  border: 1px solid #c7c7c7;
  flex-wrap: wrap;
  padding: 8px 8px;
  border-radius: 8px;
  gap: 15px;
}
.selected_value {
  background: #ededed;
  font-family: "ProximaNova-Regular";
  border-radius: 30px;
  padding: 6px 31px 1px 16px;
  position: relative;
  width: 86px;
  height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1f1f1f;
  font-size: 14px;
}
.selected_value.select_overflow_item {
  background: #e6efff;
  color: #0642b5;
  padding: 6px 16px 1px 16px;
  width: auto;
  font-family: "ProximaNova-SemiBold";
}
.selected_value img {
  position: absolute;
  right: 10px;
  width: 18px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.select-project-options-container {
  /* position: absolute; */
  position: relative;
  width: 100%;
}
.select-project-options {
  position: absolute;
  z-index: 100;
  width: 480px;
  height: 250px;
  background: #fff;
  border: 1px solid #5c5c5c;
  overflow-y: scroll;
  box-shadow: 0px 5px 10px 0px #ced3dacc;
  border-radius: 8px;
  border-color: #fff;
}
.checkbox-project-options {
  width: 100%;
}
.checkbox-input-project-search {
  color: #1f1f1f;
  font-family: "Proximanova-Regular";
  font-size: 25px;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin: 0px;
}
.main-projects-container {
  padding: 0px 6px;
}
.select-project-individual {
  padding: 8px;
  gap: 5px;
  display: flex !important;
  align-items: center;
}
.select-placeholder-project-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.select-placeholder-project-select {
  color: #c7c7c7;
  font-family: "Proximanova-Regular";
}
