.preview-addgoal-title-btn-main {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.contribute_view-otr .empty-milestone {
  margin: 0 0 70px;
}
.preview-addgoal-btn {
  background-color: #ffffff00;
  color: #0642b5;
  border: 1px solid #0642b5;
  margin: 15px;
  height: 40px;
  border-radius: 8px;
}
.review-addgoal-title {
  /* font-family: Proxima Nova; */
  font-weight: bold;
  font-size: 16px;
}
.preview-milestones {
  font-size: 14px;
  line-height: 19.6px;
  color: #000000;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.preview-milestones-dates {
  display: table;
  margin: 5px;
  font-size: 14px;
  line-height: 19.6px;
  color: #1f1f1f;

  font-family: "ProximaNova-Regular";
}
.preview-milestones-name {
  display: table;
  margin: 5px;
  font-size: 14px;
  line-height: 19.6px;
  color: #000000;
  font-family: "ProximaNova-Regular";
}
.milestones_breadcrumb {
  margin-top: 67px;
}
.rendermilestone-main .certify_otr-field .ant-form-item:last-child {
  padding-top: 0;
}
.break-into-milestone-btn .ant-btn-primary {
  border-radius: 4px;
  border: 1px solid #0642b5;
  background-color: red;
}
.edit-icon {
  width: 20px;
  height: 20px;
  margin-bottom: -4px;
}
.edit-btn-text {
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  line-height: 19.6px;
}
.milestone-section
  .preview-addgoal-title-btn-main
  button.preview-addgoal-btn:hover {
  background: transparent;
}
.certify_main-otr-sec .certify_otr-field button:last-child {
  right: 6px;
}
.preview-milestones-target {
  margin-right: 20px;
}
.preview-milestones-actual {
  margin-left: 15px;
}
.preview-milestones-border {
  height: 20px;
  width: 1px;
  background: #333333;
  display: inline-block;
  position: relative;
  top: 5px;
}
.preview-addgoal-title-btn-main div p:last-child {
  margin: 0 0 8px;
}
.preview-addgoal-title {
  color: #333 !important;
  font-family: "ProximaNova-SemiBold" !important;
  margin: 0 0 5px;
}
.delete-milestone-popconfirm .ant-popconfirm-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.delete-milestone-popconfirm .ant-popconfirm-message-icon {
  display: block !important;
  margin-bottom: 20px;
}
.delete-milestone-popconfirm .ant-popconfirm-title {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 24px !important;
  line-height: 28.8px !important;
  margin-bottom: 20px;
}
.delete-milestone-popconfirm .ant-popconfirm-description {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px;
}
