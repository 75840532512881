.empty-review-comments {
  padding: 60px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty-review-comments .empty-review-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95.45px;
  height: 80px;
  flex-shrink: 0;
  margin-bottom: 8px;
}
.empty-review-comments .no-review-comment-title {
  color: #8c8c8c;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
