.total-weight-green {
  color: #008000;
}

.total-weight-red {
  color: #e65a14;
}

.performance-body .ant-tabs-tab-active {
  border: 0 !important;
  border-bottom: 0 !important;
  background-color: transparent !important;
}

.performance-header {
  margin: 30px 60px 20px 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team-performanceContainer {
  margin-top: 95px;
}

.breadcrum_performance {
  margin-top: 145px;
  margin-left: 55px;
  margin-right: 55px;
}

.performance_success-alert {
  .ant-alert-message {
    margin-left: 15px;
  }
}

.menu-icon button:first-child {
  display: none;
}

.menu-icon button:nth-child(2) {
  border-start-start-radius: 6px !important;
  border-end-start-radius: 6px !important;
}

h5 {
  margin: 0;
}

.left-side,
.right-side {
  display: flex;
  align-items: center;
}

.left-side {
  gap: 10px;
}

.right-side {
  gap: 15px;
}

.performance-body {
  margin: 30px 60px 20px 0px !important;
}

.today-weight {
  background-color: #fff7e6;
  display: flex;
  padding: 11px 12px 0px 12px;
  position: relative;
  margin-bottom: 23px;
  border-radius: 8px;
}

.weight-center {
  margin-left: 16px;
  padding-bottom: 16px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.success-close-icon {
  position: absolute;
  top: 157px;
  right: 70px;
  cursor: pointer;
}

.empty-goals {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
}

.behind,
.behind:hover {
  color: #c00 !important;
  background-color: #fae6e6 !important;
  box-shadow: none;
}

.completed,
.completed:hover {
  color: #1976d2 !important;
  background-color: #d1e4f6 !important;
}

.on-track,
.on-track:hover {
  color: #008000 !important;
  background-color: #cce6cc !important;
}

.tbl-inner-section .inner-table-milestone {
  display: flex;
  align-items: center;
}

.tbl-inner-section .inner-table-milestone span {
  margin-left: 10px;
}

.tbl-outer-section table thead tr .ant-table-cell {
  background: transparent !important;
  border: 0;
}

.tbl-outer-section table tbody tr .ant-table-cell {
  /* padding: 40px; */
  background: transparent;
  max-width: 30px;
}

.tbl-outer-section table thead tr .ant-table-cell::before {
  background: transparent !important;
}

.tbl-outer-section table tbody tr:first-child td {
  border-top: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr:first-child td:first-child {
  border-top-left-radius: 4px !important;
}

.tbl-outer-section table tbody tr:first-child td:last-child {
  border-top-right-radius: 4px !important;
}

.tbl-outer-section table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.tbl-outer-section table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.tbl-outer-section table tbody tr td:first-child {
  border-left: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr td:last-child {
  border-right: 1px solid #d9d9d9;
}

.tbl-outer-section table tbody tr:last-child td {
  border-bottom: 1px solid #d9d9d9;
}

.tbl-outer-section table thead tr:hover th {
  background: transparent !important;
}

.tbl-outer-section table tbody tr:hover td {
  background: transparent !important;
}

/* start inner section */
.tbl-outer-section .tbl-inner-section table thead tr .ant-table-cell {
  background: #f5f5f5 !important;
  border: 0;
  padding: 16px 16px;
}

.tbl-outer-section .tbl-inner-section table tbody tr .ant-table-cell {
  padding: 16px 16px;
  background-color: #f5f5f5 !important;
  border: 0px solid !important;
}

.tbl-outer-section .tbl-inner-section table thead tr .ant-table-cell::before {
  background: transparent !important;
}

.tbl-outer-section
  .tbl-outer-section
  .tbl-inner-section
  table
  tbody
  tr:first-child
  td {
  border-top: 0 solid #d9d9d9 !important;
  background-color: #f5f5f5 !important;
}

.tbl-outer-section
  .tbl-inner-section
  table
  tbody
  tr:first-child
  td:first-child {
  border-top-left-radius: 0 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:first-child td:last-child {
  border-top-right-radius: 0 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.tbl-outer-section .tbl-inner-section table tbody tr td:first-child {
  border-left: 0 solid #d9d9d9;
  background-color: #f5f5f5;
  width: 50%;
  padding-right: 70px;
}

.tbl-outer-section .tbl-inner-section table tbody tr td:last-child {
  border-right: 0 solid #d9d9d9;
  background-color: #f5f5f5;
}

.tbl-outer-section .tbl-inner-section table tbody tr:last-child td {
  border-bottom: 0 solid #d9d9d9;
  background-color: #f5f5f5;
}

.tbl-outer-section .tbl-inner-section table thead tr:hover th {
  background: #f5f5f5 !important;
}

.tbl-outer-section .tbl-inner-section table tbody tr:hover td {
  background: #f5f5f5 !important;
}

.tbl-inner-section table tbody tr .ant-table-cell .ant-space-item a {
  color: #095dff;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
}

/* end inner section */

.form-footer-section {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.expand {
  cursor: pointer;
}

.year-selection,
.smart {
  color: #e65a14;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.milestone-left h3 {
  margin-top: 5px;
}

.info-alert {
  margin-bottom: 16px;
}

.business {
  margin-top: 20px;
}

.new-goal .ant-select-selector {
  height: 46px !important;
  padding: 6px 11px !important;
}

.left-side h5 {
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
  margin: 0;
}

.performance-header .left-side a {
  font-size: 20px;
  font-family: "ProximaNova-Semibold";
}

.performance-header .left-side a .ant-space-item {
  display: flex;
  transition: 0.5s;
}

.performance-body .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #095dff !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn:hover,
.performance-body .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #095dff !important;
}

.performance-body .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: "ProximaNova-Regular";
}

.ant-tabs-content-holder .ant-tabs-tabpane p span {
  color: #000;
  font-family: "ProximaNova-Regular";
}

.weight-center h5 {
  font-size: 16px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.weight-center p {
  font-size: 14px;
  color: #000;
  font-family: "ProximaNova-Regular";
  display: table;
  width: 85%;
}

.weight-center p span.smart {
  color: #e65a14;
  font-family: "ProximaNova-Semibold";
}

.weight-center button.ant-btn {
  border-color: #000;
  border-radius: 4px;
}

.weight-center button.ant-btn span {
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  color: #095dff;
}

.today-weight .weight-left {
  display: flex;
}

.business h5.title {
  font-size: 16px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.business .ant-table-content table {
  border: 0px solid;
}

.business .ant-table-content table thead tr th {
  border-bottom: 0px solid #b0b3ba !important;
  font-family: "ProximaNova-Regular";
  color: #262626;
}
.business .ant-table-content table thead tr th:last-child {
  text-align: center;
}
.business .ant-table-content table button {
  box-shadow: none;
  font-family: "ProximaNova-Semibold";
}

.inner-table-milestone span.title-mile {
  font-size: 16px;
  color: #232a3d;
  font-family: "ProximaNova-Semibold";
  width: 210px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-table-milestone span.cont-mile {
  font-size: 14px;
  color: #2b3348;
  font-family: "ProximaNova-Regular";
  display: -webkit-box;
  max-width: 270px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-table-milestone img {
  width: 16px;
  margin: 3px 0 0;
}

.inner-table-milestone {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
}

.milestone-dates-section .ant-form-item .ant-picker {
  height: 52px;
}

.milestone-form-details .ant-form-item-label label {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  color: #1f1f1f;
  display: inline;
  position: relative;
}

.milestone-form-details .ant-form-item-required::before {
  position: absolute;
  right: -10px;
  top: 0;
  font-size: 12px !important;
}

.title-delete-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title-delete-section h4 {
  margin: 0;
}

.title-delete-section button {
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0;
  display: flex;
}

.delete-milestone-popup .ant-modal-body h5 {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  line-height: 28.8px;
  margin: 0 auto 20px;
}

.delete-milestone-popup .ant-modal-body p {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 16px;
}

.delete-milestone-popup .button-section {
  display: flex;
  justify-content: center;
}

.delete-milestone-popup {
  width: 400px;
}

.delete-milestone-popup .button-section button {
  width: 48%;
  height: 40px;
}

.delete-milestone-popup .button-section button.ant-btn-default {
  margin-right: 32px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  line-height: 22.4px;
  color: #095dff;
}

.delete-milestone-popup .button-section button.ant-btn-primary {
  border-radius: 4px;
  border: 1px solid #095dff;
  background: #095dff;
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  line-height: 22.4px;
  color: #fff;
}

.menu-icon button span.anticon {
  transform: rotate(90deg);
}

.performance-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "ProximaNova-Semibold";
}
/* .tbl-inner-section {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    min-height: 48px !important;
    max-height: 200px !important;
  } */
.ant-picker-date-panel {
  width: 303px !important;
}
.ant-picker-content {
  border: none;
}
.manager-tabs .p-tabview-nav-container {
  border-bottom: 2pt solid #f0f0f0;
  background: transparent;
}
.manager-tabs .p-tabview-nav-container .p-tabview-nav {
  height: 27px;
  display: flex;
  gap: 16px;
}
/* .manager-tabs .p-tabview-nav-container .p-tabview-nav .p-unselectable-text {
  border-bottom: 2pt solid #f0f0f0;
} */
/* .manager-tabs .p-tabview-nav-container .p-tabview-nav .p-unselectable-text {
  border-bottom: 2pt solid #f0f0f0;
} */
.manager-tabs .p-tabview-nav-container .p-tabview-nav .p-unselectable-text a {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
}

.manager-tabs .p-unselectable-text {
  position: relative;
}
.manager-tabs .p-unselectable-text.p-tabview-selected.p-highlight::after {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  background: #0642b5;
  height: 4px;
  z-index: 111;
}
.manager-tabs .p-tabview-nav-container .p-tabview-nav .p-tabview-selected a {
  color: #0642b5;
}
