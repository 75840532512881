.text-editor .ql-editor {
  height: 97px;
  padding: 14px 12px 14px 16px;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;
  font-family: "ProximaNova-Regular";
  font-style: normal;
}
.ql-editor.ql-blank::before {
  font-style: normal !important;
}
.text-editor .quill {
  border: none !important;
}
.text-editor .ql-container {
  border: none !important;
}

.text-editor .ql-toolbar {
  border: none !important;
  display: flex;
  justify-content: flex-end;
}
.text-editor .ql-picker-label {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  padding-top: 3px !important ;
}
.text-editor .ql-color {
  margin-right: 12px;
  height: 32px !important;
  width: 32px !important;
}
.text-editor .ql-formats {
  margin-right: 0px !important;
}
.text-editor .ql-bold {
  height: 32px !important;
  width: 32px !important;
  margin-right: 12px;
  padding: 4px !important;
}

.text-editor .ql-underline {
  height: 32px !important;
  width: 32px !important;
  margin-right: 12px;
  padding: 4px !important;
}

.text-editor .ql-list {
  height: 32px !important;
  width: 32px !important;
  margin-right: 12px;
  padding: 4px !important;
}
