.view-goal {
  margin: 90px 60px 60px;
}

.row-section {
  margin-bottom: 16px;
}

.row-section h5 {
  margin: 0;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  color: #000;
}

.milestone-section {
  padding: 24px;
  background: #f5f5f5;
  margin-top: 24px;
}

.milestone-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.milestone-header-left {
  display: flex;
  flex-direction: column;
}

.milestone-body-header {
  display: flex;
  gap: 3px;
  align-items: center;
}

.milestone-body-dates {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  color: #000;
}

.milestone-body-section:last-child .milestone-body-dates {
  margin-bottom: 0;
}

.milestone-start-date {
  position: relative;
  padding-right: 10px;
}

.milestone-start-date::before {
  height: 15px;
  width: 1px !important;
  background-color: #767b88;
  content: "";
  margin-left: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.milestone-body-section p {
  margin-top: 12px;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  color: #333333;
}

.view-status-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.status-section {
  /* background: #cce6cc; */
  width: 89px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-goal.smart-goal_preview .status-section {
  background: #cce6cc;
}
.status-section p {
  margin: 0;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
}

.milestone-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin: 0;
}

.milestone-title span {
  color: #c00;
}

.milestone-body-header h5 {
  margin: 0;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 19.6px;
}

.milestone-body-header img {
  margin-right: 8px;
}

.button-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.contribute_view-otr .preview-goal-main .button-area.single-btn {
  justify-content: flex-end;
}
.outlineBtnStyles {
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  background: #fff;
  margin-right: 24px;
  width: 148px;
  height: 42px;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.fillBtnStyles {
  border-radius: 8px;
  background: #0642b5;
  width: 148px;
  height: 42px;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

button.fillBtnStyles:disabled {
  background: #f0f0f0;
  color: #8c8c8c;
  border-radius: 4px;
  border-color: #f0f0f0;
}

.view-page-comment-btn,
.view-page-comment-btn:hover {
  border-radius: 8px;
  border: 1px solid #0642b5 !important;
  background: transparent !important;
  width: 155px;
  height: 40px;
  padding: 7px;
  color: #0642b5 !important;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}
