.game_sect-otr {
  margin: 84px 60px 24px 60px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  min-height: 750px;
}
.game_sect-otr-notification {
  margin-top: 120px;
}

.leadersection {
  height: 700px;
  background: #fff;
  margin-right: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 65vw;
}
.game_sect-otr .gamification-user-profile {
  height: 80vh;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex: 3;
  flex-direction: column;
}

.megaleaderboard {
  color: #000;
  font-family: "Proximanova-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 16px;
}

.leaderboardash {
  display: flex;
  flex-direction: row;
}

.leadersearch {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 12px 16px 12px 16px;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.leadersearch_component {
  width: 40%;
}

.leaderboard_search {
  display: flex;
  position: relative;
  width: 232px;
  height: 32px;
  border-radius: 32px;
  padding-left: 30px;
  border: 1px solid #bfbfbf;
  background: url("../../../../../themes/assets/images/gamification/png/Icon.png")
    no-repeat 5px center #fff;
  background-size: 20px 20px;
  color: #454545;
  font-family: " Proximanova-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.input .leaderboard_search::after {
  border: 1px solid #bfbfbf;
  background: #000;
}

.leadersearch img {
  position: absolute;
  top: 177px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 85px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.leader_buttons {
  flex-grow: 1;
  justify-content: flex-end;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}

.account_leaderboard {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  margin-right: 12px;
  align-items: center;
  width: auto;
  justify-content: space-around;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
}

.account_leaderboard.clicked {
  border: 1px solid #0642b5;
  background: #e6efff;
}

.account_title {
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
  cursor: pointer;
}
.up_account {
  left: 120px !important;
}

.account_title:hover {
  color: #454545;
}

.country_title {
  color: #454545;
  left: 12px;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  cursor: pointer;
}

.country_title:hover {
  color: #454545;
}

.club_title {
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  cursor: pointer;
}

.club_title:hover {
  color: #454545;
}

.country_leaderboard {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  margin-right: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

.country_leaderboard.clicked {
  border: 1px solid #0642b5;
  background: #e6efff;
}

.club_leaderboard {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  margin-right: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
}

.club_leaderboard.clicked {
  border: 1px solid #0642b5;
  background: #e6efff;
}

.leaderboard_search.ant-input {
  padding: 6px 12px 6px 40px;
}

.leaderboard_search .img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.searchicongame {
  display: flex;
  color: #454545;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.clear_game {
  padding: 3px 16px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: solid #fff;
  color: #8c8c8c;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  /* 140% */
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}
.clear_game_active {
  padding: 3px 16px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: solid #fff;
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  /* 140% */
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.complete_leaderboard {
  height: 80%;
  overflow-y: auto;
}
.complete_leaderboard::-webkit-scrollbar {
  width: 4px;
  background: #fff;
}
.complete_leaderboard::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}
.complete_leaderboard::-webkit-scrollbar-track {
  display: none;
}
.current_user_leader_top {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 16%;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 24px 16px 12px 16px;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.current_user_leader_bottom {
  width: 100%;
  height: 60px;
  position: sticky;
  top: 90%;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 24px 16px 12px 16px;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}
.current_user_leader_bottom .current_user_tile {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.12px 16px 2.12px 40px;
  margin-bottom: 12px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
}
.current_user_tile.Ruby {
  background: linear-gradient(86deg, #520000 2.98%, #cd0000 97.02%);
}
.current_user_tile.Jade {
  background: linear-gradient(86deg, #031 2.98%, #00b512 97.02%);
}
.current_user_tile.Amber {
  background: linear-gradient(86deg, #622400 2.98%, #d8902e 97.02%);
}
.current_user_tile.Turquiose {
  background: linear-gradient(86deg, #004969 2.98%, #00b9e8 97.02%);
}
.dropdown_game {
  position: relative;
}

.gamifiction-otr {
  display: flex;
  background: #fff;
  border-radius: 8px;
  height: 700px;
  padding: 16px;
  padding-bottom: 32px;
  flex-wrap: wrap;
  position: relative;
  font-family: "Proximanova-Semibold";
  min-width: 458px;
  align-items: center;
  justify-content: center;
}

.gamifiction-otr img.edit_icon {
  position: absolute;
  right: 16px;
  top: 12px;
}
.badges_otr-sec {
  width: 458px;
  padding: 16px;
}

.badges_otr-sec h1 {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.profile_title-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px;
}

.profile_title-sec img {
  display: table;
  margin: 0 auto;
}

.profile_title-sec h1 {
  display: table;
  width: 100%;
  text-align: center;
  margin: 12px 0 5px;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

.profile_title-sec p.profile_desc {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  color: var(--Neutral-Neutral-neutral-11, #1f1f1f);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  word-wrap: normal;
  word-break: break-word;
  text-align: center;
  width: 350px;
}

.game_reward-pts {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.game_reward-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 80px;
  margin: 0 20px;
  align-items: center;
}

.game_reward-list:before {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  width: 1px;
  height: 32px;
  background: #8c8c8c;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.game_reward-list:last-child:before {
  opacity: 0;
}

.game_reward-pts .game_reward-list img {
  display: table;
  margin: 0 auto 6px;
}

.badges_cont-otr {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* align-items: baseline; */
  gap: 16px;
  /* height: 380px; */
}
.badges_active_display {
  display: flex;
  flex-direction: row;
  gap: 32px;
  height: auto;
  flex-wrap: wrap;
  width: 100%;
  max-height: 600px;
  overflow: auto;
}

.badges_cont-otr .badges_list {
  display: flex;
  flex-direction: column;
  /* width: calc((100% - 16px * 2) / 3);
  margin-right: 16px; */
  text-align: center;
  /* margin-bottom: 16px; */
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 12px;
  flex: 33.33%;
  flex-grow: 1;
  max-width: 30%;
  height: 160px;
}

.badges_cont-otr .badges_list:nth-child(3n) {
  margin-right: 0;
}

.badges_list .badge_image {
  width: 60px;
  display: table;
  height: 60px;
  margin: 0 auto;
}

.badges_list img {
  display: table;
  margin: 0 auto 2px;
  width: 60px;
}

.badges_list span {
  display: table;
  width: 100%;
  text-align: center;
  margin: 0 0 4px;
  color: #0d1321;
  font-family: "ProximaNova-Regular";
  font-size: 10px;
  line-height: 14px;
}

.badges_list span.date {
  color: #515869;
}

.badges_cont-otr .badges_list h4 {
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}

/* locked badges */
.badges_detail-sec {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  padding: 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 750px;
  width: 100vw;
  align-self: center;
  gap: 16px;
}
.badges_detail-sec-notification {
  margin-top: 120px;
}
.badges-screen-sub-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
.badge-title-screen {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}

.badges_detail-otr {
  flex: 5;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 700px;
}
.badges_detail-sec.badges_detail-inn .badges_detail-otr {
  display: block;
}
.locked-badges {
  background: #fff;
  padding: 20px 24px 24px;
  border-radius: 8px;
  flex: 1;
}

.badge_title-sec.leaderboard_header {
  padding: 0;
  margin: 0 0 16px;
}

.badge_title-sec h1 {
  font-family: "Proximanova-Bold";
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin: 0;
}

.badge_title-sec.leaderboard_header button.badge_btn {
  padding: 0;
  cursor: pointer;
}

.badges_lock-otr {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  gap: 28px;
}

.badges_lock-otr .badges-lock_list {
  display: flex;
  flex-direction: column;
  width: 122px;
  /* height: 158px; */
  /* margin-right: 14px; */
  text-align: center;
  /* margin-bottom: 24px; */
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px 16px;
  background: #fcfcfc;
  cursor: pointer;
  flex-wrap: wrap;
}

.badges_lock-otr .badges-lock_list:nth-child(3n) {
  /* margin-right: 14px; */
}
.badges_lock-otr .badges-lock_list:nth-child(4n) {
  /* margin-right: 14px; */
}
.locked-badges.locked-badges-right-icon .badges-lock_list {
  width: calc((100% - 24px * 2) / 3);
}
.locked-badges.locked-badges-right-icon .badges-lock_list:nth-child(3n) {
  margin-right: 0;
}
.locked-badges.locked-badges-right-icon .badges-lock_list:nth-child(4n) {
  /* margin-right: 14px; */
}
.badges_lock-otr .badges-lock_list h4 {
  font-size: 10px;
  line-height: 14px;
  color: #000;
  font-family: "Proximanova-Semibold";
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.badges_lock-otr .badges-lock_list span {
  font-size: 12px;
  line-height: 17px;
  color: #000;
  font-family: "Proximanova-Semibold";
}

.badge-otr_image img {
  width: 82px;
}

.empty-badges {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  margin: -60px 0 0;
  align-items: center;
}

.empty-badges img {
  width: 160px;
}

.my-empty-badges {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.my-empty-badges img {
  width: 160px;
}

.badges_detail-otr h1 {
  font-family: "Proximanova-Bold";
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin: 0;
  width: 100%;
}

.empty-badges h3 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #1a1c22;
  font-family: "Proximanova-Semibold";
}

.empty-badges p {
  display: table;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
}
.my-empty-badges h3 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #1a1c22;
  font-family: "Proximanova-Semibold";
}

.my-empty-badges p {
  display: table;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
}
.badges_lock-right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 2;
  height: 700px;
}
.progressbar {
  width: 470px;
}

.badges_blog {
  background: linear-gradient(90.12deg, #34e89e -47.63%, #0f3443 94.85%);
  border-radius: 8px;
  width: 470px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 149px;
}
.badges_detail-sec .ant-carousel {
  width: 100%;
}
.badges_blog p {
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  font-family: "Proximanova-Semibold";
  margin-left: 24px;
  display: table;
  padding-right: 10px;
}

.locked-badges_detail-otr {
  display: flex;
  flex-wrap: wrap;
  margin: 140px 60px 80px;
}

.lock_badge-filter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 16px;
}

.lock_badge-filter h1 {
  font-family: "Proximanova-Bold";
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin: 0;
}

.filter_btn {
  display: flex;
}
.locked_clear_game {
  padding: 3px 16px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  color: #8c8c8c;
  border: none;
  background: none;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  /* 140% */
  cursor: pointer;
}
.locked_clear_game_active {
  padding: 3px 16px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: none;
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  /* 140% */
  cursor: pointer;
}

.lock_badge-filter button.apply_button_game {
  background: #0642b5;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  width: auto;
  font-weight: 600;
  line-height: 16.8px;
  /* 140% */
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.locked-badges_list-otr .badges_lock-otr {
  justify-content: space-around;
}

.locked-badges_detail-otr .badges-lock_list {
  border: 0px solid;
  width: initial;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(14.28% - 30px);
  cursor: pointer;
}

.locked-badges_detail-otr .badges-lock_list:nth-child(3n) {
  /* margin-right: 15px; */
}

.locked-badges_detail-otr .badges-lock_list:nth-child(7n) {
  /* margin-right: 15px; */
}

.locked-badges_detail-otr .badges-lock_list .badge-otr_image img {
  width: 100px;
}

/* .filter_btn .ant-select {
    border-radius: 20px;
    padding: 8px 12px;
    border: 1px solid #D9D9D9;
} */

.filter_btn .ant-select .ant-select-selector {
  width: auto;
  height: auto;
  background: transparent;
  border-radius: 20px;
  padding: 2px 12px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
}

.filter_btn
  .ant-select
  .ant-select-selector
  span.ant-select-selection-placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #454545;
  font-family: "ProximaNova-Regular";
}

.filter_btn .ant-select .ant-select-selector span.ant-select-selection-item {
  font-size: 14px;
  line-height: 20px;
  color: #454545;
  font-family: "ProximaNova-Regular";
}

.filter_btn .ant-select .ant-select-selector span.ant-select-selection-search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.locked-badges_detail-otr
  .lock_badge-filter
  .filter_btn
  .ant-select
  .ant-select-selector {
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.locked-badges_detail-otr
  .lock_badge-filter
  .filter_btn
  .ant-select:hover
  .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.locked-badges_detail-otr .filter_btn .ant-select .ant-select-selector input {
  height: initial;
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.filter_btn .ant-select {
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.filter_btn .ant-select::-webkit-scrollbar {
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.filter_btn .ant-select:hover {
  border-color: #d9d9d9;
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}
.badge_head-title-inn.badge_title-sec.leaderboard_header {
  height: 10vh;
}
.badges_detail-sec.badges_detail-inn
  .badge_head-title-inn.badge_title-sec.leaderboard_header {
  height: 5vh;
  align-items: center;
}
.badge_head-title-inn.badge_title-sec.leaderboard_header h1 {
  width: auto;
}

.badges_active-inn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 32px;
}

.badges_lock-otr.badges_active-otr .badges-lock_list {
  width: 140px;
  height: 192px;
  align-items: center;
  justify-content: center;
}

.badges_lock-otr.badges_active-otr .badges-lock_list:nth-child(7n) {
  margin-right: 0;
}

.badges_lock-otr.badges_active-otr {
  margin: 140px 60px 80px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: auto;
}

.module_leaderboard {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  margin-right: 12px;
  align-items: center;
  width: auto;
  justify-content: space-around;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
}

.module_title {
  color: #454545;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
  cursor: pointer;
}
.gamificationprofilepic .ant-avatar-group span.ant-avatar {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.profile_club_leaderboard {
  width: 30px;
  height: 30px;
  padding: 0px;
  margin-bottom: 10px;
}

.profile_gameclub_size {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.profile_clubname_leaderboard {
  color: #000;
  text-align: center;
  font-family: "Proximanova-Semibold";
  margin-top: 10px;
}

.ant-modal.badges_popup-cont .ant-modal-content p {
  background: #f5f5f5;
  margin: 0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #454545;
  height: auto;
  text-align: center;
  padding: 9px 15px;
  font-family: "ProximaNova-Regular";
}

.ant-modal.badges_popup-cont .ant-modal-title {
  font-family: "Proximanova-Bold";
  position: relative;
  z-index: 1;
  text-align: center;
}

.ant-modal.badges_popup-cont .ant-modal-footer button.ant-btn.ant-btn-default {
  display: none;
}

.ant-modal.badges_popup-cont .ant-modal-footer button.ant-btn.ant-btn-primary {
  width: 100%;
  margin: 0;
  height: 40px;
  background: #0642b5;
  padding: 9px 16px 8px;
  border-radius: 4px;
}

.ant-modal.badges_popup-cont
  .ant-modal-footer
  button.ant-btn.ant-btn-primary
  span {
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  font-family: "Proximanova-Semibold";
}
.badges_popup-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badges_popup-cont .ant-modal-content {
  padding: 88px 16px 24px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.ant-modal.badges_popup-cont button.ant-modal-close {
  display: none;
}

.ant-modal.badges_popup-cont {
  top: 0;
}

.placeholder_img {
  position: relative;
  top: -125px;
}
.placeholder_img .lazy-load-image-background.blur.lazy-load-image-loaded {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 150px;
}
.badges_popup-cont .ant-modal-content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 150px;
}

.badges_popup-cont .ant-modal-content img.placeholder {
  width: 200px;
}

/* add a parent class , this is affecting other designs */

/* .slick-slider.slick-initialized {
    width: 470px;
    margin-left: 24px;
    border-radius: 8px;
} */

.contribut_slide .contentStyle {
  margin: 0;
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background: #fff;
  width: 100% !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 30px;
}

.contribut_slide .contentStyle img {
  width: 33%;
}

.slick-slider.contribut_slide li {
  width: auto !important;
  height: auto !important;
}

.slick-slider.contribut_slide li button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d9d9d9;
  opacity: 1;
}

.slick-slider.contribut_slide li.slick-active button {
  background: #0642b5;
}

.slick-slider.contribut_slide li button:before,
.slick-slider.contribut_slide li button:after {
  opacity: 0;
}

.contribut_slide .contentStyle p {
  margin: 0 0 10px;
  width: 240px;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  font-family: "Proximanova-Semibold";
  color: #000;
}

.contribut_slide .contentStyle p span {
  color: #ff6701;
}

.range_slide {
  margin: 0 0 0 20px;
  width: 100%;
}

.range_otr-sec {
  display: flex;
  align-items: center;
  line-height: initial;
}

.line-border {
  color: #000;
  display: flex;
  line-height: initial;
  align-items: center;
  width: 100%;
  background: #d9d9d9;
  border-radius: 4px;
  margin-right: 15px;
}

.range_otr-sec span {
  color: #8c8c8c;
  font-size: 16px;
  line-height: 22px;
  font-family: "Proximanova-Semibold";
}
.empty-badges-profile {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Skeleton */
.leader-board-skeleton-parent {
  padding: 15px;
  gap: 24px;
  overflow: auto;
}
.skeleton-leader-board-top-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leader-board-top-skeleton {
  width: 150px !important;
}
.leader-board-top-skeleton-card {
  width: 100% !important;
  height: 56px !important;
}
.leader-board-detail-skeleton-profile {
  flex-direction: column !important;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 550px;
  flex-wrap: nowrap;
  display: flex;
  background: #fff;
  border-radius: 8px;
  height: 700px;
  padding: 16px;
  padding-bottom: 32px;
  align-items: center;
}
.user-info-skeleton-card {
  width: 250px !important;
  height: 25px !important;
}
.profile-skeleton-user-data {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.skeleton-badge-leader-board {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  /* width: 500px !important; */
}
.skelton-badge-leader-board-card {
  height: 155px !important;
  width: 110px !important;
}
.user-card-badge-title-skeleton {
  width: 40% !important;
}
