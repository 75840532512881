.opportunities-page {
  margin-top: 60px;
  padding: 24px 60px;
}

.opportunities-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 16px;
}

.ooprtunities-list {
  border-radius: 8px;
  border: 1px #ededed;
  background: #fff;
  padding: 24px;
}

.opportunity-card {
  position: relative;
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #fff;
  display: flex;
  padding: 20px;
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.logo-container {
  width: 52px;
  height: 52px;
  border-radius: 52px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-details {
  margin-left: 16px;
}

.job-title {
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 270px;
  margin-bottom: 8px;
}

.job-desc {
  color: #5c5c5c;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 270px;
  margin-bottom: 8px;
}

.info-card {
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 30px;
  background: #ededed;
}

.info-icon {
  height: 16px;
  width: 16px;
}

.info-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.info-cards-gap {
  gap: 12px;
  flex-wrap: wrap;
}

.empty-data-opportunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 309px;
}

.empty-data-img {
  width: 100px;
  height: 100px;
}

.empty-data-title {
  color: #0a0a0a;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.empty-data-desc {
  color: #6f6f6f;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.empty-data-card-holder {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.logo-company {
  height: 36px;
  width: 36px;
  object-fit: contain;
  border-radius: 36px;
}

.upload-resume-banner {
  padding: 0 40px;
  border-radius: 8px;
  background: linear-gradient(90deg, #e6ebff 0%, #e6fffa 100%);
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.upload-resume-banner-right {
  height: 154px;
}

.upload-resume-banner-right img {
  height: 100%;
}

.upload-resume-banner-left h5 {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  line-height: 24px;
  margin: 16px 0 4px;
}

.upload-resume-banner-left p {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 20px;
  width: 80%;
  margin: 0 0 12px;
}

.upload-resume-banner-btn,
.upload-resume-banner-btn:hover {
  width: 120px;
  height: 40px;
  display: flex;
  padding: 5px 12px 4px 12px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
  background: #0642b5 !important;
}

.ijp-opportunities-tabs .p-tabview-selected .p-tabview-nav-link {
  font-family: "ProximaNova-Semibold" !important;
}

.ijp-opportunities-tabs .p-tabview-nav-link {
  padding: 4px;
}

.ijp-opportunities-tabs .p-tabview-nav-container {
  margin-bottom: 20px;
}

.primary-skills span:first-child {
  font-family: "ProximaNova-Semibold";
  color: #5c5c5c;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}

.primary-skills span:last-child {
  color: #5c5c5c;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  margin-left: 4px;
}

.opportunities-breadcrumb {
  margin-bottom: 16px;
}

/* Progress Bar */
.progress-bar-container {
  margin-bottom: 24px;
}

.progress-bar-container .step-desc {
  color: #c00;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.progress-bar-container .progress-bar-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.progress-bar-container .progress-bar-container {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.progress-bar-container .progres-bar-data {
  margin-top: 8px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  overflow-y: auto;
}
.progress-bar-container .progres-bar-data::-webkit-scrollbar {
  display: none;
}

.progres-bar-data .progress-stepicon {
  height: 24px;
  width: 24px;
}

.progres-bar-data .btn-progress {
  position: absolute;
  z-index: 99;
  top: 0px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: #fff;
  top: calc(50% - 26px);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14));
  background-color: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progres-bar-data .nxt-btn-progress {
  right: 24px;
}

.progres-bar-data .prev-btn-progress {
  left: 24px;
}

.progres-bar-data .arrow-progress {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.progres-bar-data .step-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  width: 110px;
  text-align: center;
  margin-left: 25px;
}

.progress-bar-container
  .ant-steps.ant-steps-label-vertical
  .ant-steps-item-icon {
  margin-inline-start: 47px !important;
}

.progress-bar-container .ant-steps-item-icon > .ant-steps-icon {
  display: flex;
  align-items: center;
}

.progress-bar-container .ant-steps-item-content {
  margin-right: 0px !important;
}

.progres-bar-data .ant-steps-item-title {
  text-align: left;
  margin-left: -25px;
}

.status {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  padding: 6px 16px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
}

.applied_by {
  position: absolute;
  bottom: 16px;
  right: 24px;
  padding: 6px 16px;
  border-radius: 16px;
  border: 1px solid #0642b5;
  color: #0642b5;
}

.selected {
  color: #008000 !important;
  background-color: #e6f2e6 !important;
}

.submitted {
  color: #0642b5 !important;
  background-color: #e8f1fb !important;
}

.under_review {
  color: #4d2caf !important;
  background-color: #bba6fb66 !important;
}

.on_hold {
  color: #a5780c !important;
  background-color: #fdf6e7 !important;
}

.rejected {
  color: #cc0000 !important;
  background-color: #fae6e6 !important;
}
.shortlisted {
  color: #61574b !important;
  background-color: #f8f0e7 !important;
}
.proposal_denied {
  color: #a5780c !important;
  background-color: #fdf6e7 !important;
}
.proposal_expired {
  color: #a5780c !important;
  background-color: #fdf6e7 !important;
}

.interview_scheduled {
  color: #d5366a !important;
  background-color: #fdebf1 !important;
}

.chip-alignment-interview {
  margin-right: 184px !important;
}

.info-message {
  margin-bottom: 12px;
}

.info-message .ant-alert-content {
  margin-left: 8px;
}

.disabled-card {
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-card .logo-company {
  filter: grayscale(100%);
}

.alert-margin {
  margin-bottom: 16px;
}

.chip-alignment {
  margin-right: 128px;
}
.file-type-warning {
  margin-top: 4px;
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.lottie-progress {
  height: 24px;
  width: 24px;
  border: 2px solid #0642b5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 24px;
}
.lottie-progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}
