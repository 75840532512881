.commentCard-checkin {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fcfcfc;
  padding: 16px;
  justify-content: space-between;
  flex: 1;
}

.commentCard-checkin .comment-checkin {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-bottom: 0;
}

.commentCard-checkin .clip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.read_more-cont button {
  background: transparent;
  border: 0px solid;
  padding: 0;
  margin: 0 0 15px;
  color: #095dff;
  cursor: pointer;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
}

.commentCard-checkin .profile-checkin {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.commentCard-checkin .profilepic-checkin {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  margin-right: 8px;
}

.commentCard-checkin .userData-checkin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
}

.commentCard-checkin .name-checkin {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
}

.commentCard-checkin .designation-checkin {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.commentCard-checkin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-checkin .avatar-container {
  margin-right: 8px;
}
