.spacing {
  margin: 28px 50px;
  .bread-crumb {
    padding: 50px 16px 28px 4px;
  }

  .gradient {
    padding: 6.4px 6.4px 6.4px 6.4px;
    background: linear-gradient(90deg, #0f0c29 0%, #302b63 48.5%, #24243e 100%);
    border-radius: 8px;
    height: 200px;
    position: relative;
  }
  .gradient-left-view {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 0;
    margin: 12px;
  }
  .gradient-left-status_button {
    border-radius: 8px;
    color: #0642b5;
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    display: flex;
    height: 32px;
    padding: 4px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e6efff;
  }
  .gradient-left-dot_button {
    border-radius: 8px;
    border: 1px solid #0642b5;
    background: #e8f1fb;
    margin-left: 8px;
    padding: 6.4px 6.4px;
    justify-content: center;
    align-items: center;
  }
  .event_container {
    text-align: center;
    position: absolute;
    color: #fff;
    width: 100%;
    padding-top: 40px;
  }
  .list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 46px;
    margin-bottom: 46px;
  }
  .center_view {
    position: absolute;
    margin: auto;
    width: 70%;
    transform: translate(-50%, -50%);
    top: 95%;
    left: 50%;
  }
  .event_detail_container {
    border-radius: 8px;
    background: linear-gradient(
      91deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.7) 100%
    );
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(16px);
    flex: 1;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .label {
    color: #0a0a0a;
    font-family: "ProximaNova-SemiBold";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  .labelName {
    color: #5c5c5c;
    font-family: "ProximaNova-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }
  .event_detail_subContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .divider {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .spacing {
    margin: 4px;
    .bread-crumb {
      padding: 70px 8px 18px 8px;
    }
    .list-container {
      grid-template-columns: repeat(1, 1fr);
    }
    .event_detail_subContainer {
      grid-template-columns: repeat(3, 1fr);
    }
    .label {
      font-size: 12px;
    }
    .labelName {
      font-size: 12px;
    }
    .event_detail_container {
      padding: 10px;
    }
    .gradient-left-view {
      margin: 2px 8px;
    }
  }
}
