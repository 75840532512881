.timesheet_activity_page {
  margin: 100px 60px 20px 60px;
}
.timesheet_activity_page .ant-breadcrumb {
  margin-bottom: 20px;
}
.timesheet_page {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.timesheet_activities {
  display: flex;
  align-items: center;
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 24px;
  font-style: normal;
  line-height: 28.8px;
}
.timesheet_table {
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  height: fit-content;
}
.activities_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.timesheet_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.timesheet_seach {
  display: flex;
  flex-direction: row;
  width: 264px;
  height: 40px;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  border: 1px solid #c7c7c7;
  background: #fff;
}
.activities_title_text {
  display: flex;
  align-items: center;
}
.all_table_activities .ant-table-wrapper table {
  border: none;
}
.all_table_activities .ant-table-thead tr th {
  background: #f3f3f3 !important;
  border-bottom: none !important;
}
.all_table_activities .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8 !important;
  background: #fff !important;
  padding: 25px 16px;
}
.activities_table {
  color: #f3f3f3;
}
.check .ant-table-cell {
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
}
.timesheet_regular {
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 14px;
}
.timesheet_status {
  display: flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #ededed;
  width: fit-content;
  color: #858585;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
}
.timesheet_status.Pending {
  display: flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fdf6e7;
  width: fit-content;
  color: #a5780c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
}
.timesheet_status.Approved {
  display: flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #e6f2e6;
  width: fit-content;
  color: #008000;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
}
.timesheet_status.Not.Submitted {
  display: flex;
  padding: 7px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #e8f1fb;
  width: fit-content;
  color: #1976d2;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
}
.timesheet_status.Rejected {
  background: #fae6e6;
  color: #cc0000;
}
.timesheet_action_icon {
  display: flex;
  color: #000;
  margin-left: 10px;
  cursor: "pointer";
  width: 20px;
  height: 20px;
}
.timesheet_action_span:active {
  border: 1px solid #0642b5;
  border-radius: 4px;
  color: #0642b5;
  background: #000;
}
.timesheet_icon_span {
  cursor: pointer;
}
.timesheet_seach .anticon.anticon-calendar {
  width: 20px;
  height: 20px;
  font-size: 16px;
}
.error_table_tsm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error_table_img {
  width: 160px;
  height: 160px;
}
.error_table_header {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px; /* 140% */
}
.error_table_content {
  color: #6f6f6f;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.ant-modal.preview_delete-modal .ant-modal-content {
  width: 425px;
}
.preview_delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 374px;
}
.preview_delete_title {
  color: #000;
  text-align: center;
  font-family: "Proximanova-Bold";
  font-size: 24px;
  line-height: 28.8px;
}
.preview_delete_content {
  color: #474747;
  text-align: center;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.preview_buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.pagination-timesheet {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.pagination-timesheet-text {
  color: #474747;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  line-height: 19.6px;
  margin-left: 15px;
}
.timesheet_table .ant-card-body {
  padding-bottom: 10px !important;
}
/* .timesheet_table .pagination-timesheet {
  padding: 0 50px 24px 28px;
} */
.timesheet_table .pagination-timesheet {
  padding: 10px 28px 24px 28px;
  color: #1f1f1f;
  font-size: 14px;
  font-family: "Proximanova-Regular";
}
.timesheet_page .timesheet_table .performance_tab-otr {
  border: 0px solid;
}
.timesheet_table .pagination-timesheet .ant-select.ant-select-outlined {
  height: 40px;
}
.timesheet_table .pagination-timesheet .ant-select-selector {
  height: 40px;
  width: 65px;
  border-color: #c7c7c7;
  color: #262626;
}
.timesheet_table .pagination-timesheet span.ant-select-arrow svg {
  color: #474747;
}
.timesheet_table .pagination-timesheet span.pagination_right_icon {
  margin-left: 25px !important;
}
.timesheet_table .pagination-timesheet span.parent_pagination_icons span svg {
  color: #474747;
}
.timesheet_table
  .pagination-timesheet
  span.parent_pagination_icons
  span.disabled
  svg {
  color: #6f6f6f;
}
