.goalplan-selection-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.goalplan-selection-container .ant-modal-content {
  /* padding: 10px; */
  background-color: #fff;
  opacity: 1;
  border-radius: 8px;
  position: relative;
}
.goalplan-selection-container .header-goal-selection {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}
.goalplan-selection-container .sub-header-goal-selection {
  color: #000;
  margin: 8px 0px;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.goalplan-selection-container .ant-radio-checked .ant-radio-inner {
  border-color: #0642b5;
  background-color: #0642b5;
}
.goalplan-button-container button.outline-button {
  width: 155px;
  height: 40px;
  padding: 7px 12px 7px 12px;
  gap: 4px;
  border-radius: 8px 8px 8px 8px;
  border: 1px 0px 0px 0px !important;
  color: #5c5c5c;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
}
.fillbtn-next {
  color: #ffffff !important;
  border: 0px;
  background: #0642b5 !important;
}
.fillbtn-next-inactive {
  color: #858585;
  border: 0px;
  background: #e2e2e2;
}
.nogoal-img-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.nogoal-img-container .nogoal-plan-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  align-self: center;
}
.nogoal-img-container .nogoal-text {
  text-align: center;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}
