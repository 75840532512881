.approval-tsm_lm .ant-modal-content {
  width: 400px;
  height: fit-content;
}
.approval-popup-modal_lm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  align-items: center;
}
.timesheetimg_lm {
  width: 180px;
  height: 120px;
}
.timesheet-head_lm {
  color: #0a0a0a;
  font-family: "Proximanova-Bold";
  font-size: 24px;
  font-style: normal;
  line-height: 28px;
}
.timesheet-body_lm {
  font-family: "Proximanova-regular";
  font-size: 16px;
  color: #0a0a0a;
}
.tsm-buttons_lm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.confirm-button_lm {
  display: flex;
  width: 163px;
  height: 40px;
  padding: 5px, 12px 4px, 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #0642b5;
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  line-height: 18px;
  border: none;
  cursor: pointer;
}
.cancel-button_lm {
  display: flex;
  width: 163px;
  height: 40px;
  padding: 5px 12px 4px 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px;
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: #ffffff;
  cursor: pointer;
}
