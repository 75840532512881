.main_container_approval {
  margin: 80px 55px;
  /* background-color: #ffffff;
  padding: 20px; */
}

.hidestricky_breadcrump .Seperation-page-title {
  margin-top: 20px;
  font-size: 24px;
}

.Seperation-page-title {
  margin-bottom: 20px;
}

.main-sub-container {
  margin: 0px 0px;
}

.apprval_tab_parent {
  background-color: #ffffff;
  padding: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  flex: 1;
  display: flex;
}

.manager-tabs-tsm {
  flex: 1;
}

.manager-tabs-tsm .p-tabview-nav-container {
  border-bottom: 1px solid #f0f0f0;
  background: transparent;
}

.manager-tabs-tsm .p-tabview-nav-container .p-tabview-nav {
  height: 27px;
  display: flex;
  gap: 16px;
  list-style-type: none;
}

.manager-tabs-tsm
  .p-tabview-nav-container
  .p-tabview-nav
  .p-unselectable-text
  a {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 15px;
  line-height: 19.6px;
}

.manager-tabs-tsm .p-unselectable-text {
  position: relative;
}

.manager-tabs-tsm .p-unselectable-text.p-tabview-selected.p-highlight::after {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  background: #0642b5;
  height: 5px;
  z-index: 11;
}

.manager-tabs-tsm
  .p-tabview-nav-container
  .p-tabview-nav
  .p-tabview-selected
  a {
  color: #0642b5;
}

.ant-input-affix-wrapper-lg {
  border-radius: 32px;
  width: 264px;
}

.tab-panel-container {
  margin-top: 15px;
}

.tab-panel-container .tab-panel-row-view_lm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.right-group-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.group-week-text {
  color: #0a0a0a;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  margin-right: 10px;
}

.status-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.status-sub-view {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.check_buttons_tsm {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.check_true_button {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #008000;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.check_false_button {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #cc0000;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.timesheet_panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.all_manager_activities .ant-table-wrapper table {
  border: none;
}

.all_manager_activities .ant-table-thead tr th {
  background: #f3f3f3 !important;
  border-bottom: none !important;
}

.all_manager_activities .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8 !important;
  background: #fff !important;
  padding: 16px;
}

.manager_activities_table {
  color: #f3f3f3;
}

.status-sub-view.Approved {
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
}

.status-sub-view.Pending {
  border: 1px solid #c7c7c7;
  border-radius: 20px 0px 0px 20px;
}

.status-sub-view.Pending:hover {
  background: #e6efff;
  color: #0642b5;
  border-radius: 20px 0px 0px 20px;
}

.status-sub-view.Pending.selected.leave {
  background-color: #e6efff;
  /* Change background color when selected */
  color: #0642b5;
  /* Text color changes to white */
  border: 1.5px solid #0642b5;
  border-radius: 20px 0px 0px 20px;
}

.status-sub-view.Approved {
  border: 1px solid #c7c7c7;
}

.status-sub-view.Approved:hover {
  background: #e6efff;
  color: #0642b5;
}

.status-sub-view.Approved.selected.leave {
  background-color: #e6efff;
  color: #0642b5;
  border: 1.5px solid #0642b5;
}

.status-sub-view.Rejected {
  border: 1px solid #c7c7c7;
}

.status-sub-view.Rejected:hover {
  background: #e6efff;
  color: #0642b5;
}

.status-sub-view.Rejected.selected.leave {
  background-color: #e6efff;
  color: #0642b5;
  border: 1.5px solid #0642b5;
}

.status-sub-view.Not.Submitted {
  border: 1px solid #c7c7c7;
  border-radius: 0px 20px 20px 0px;
}
.status-sub-view.Rejected.leave {
  border: 1px solid #c7c7c7;
  border-radius: 0px 20px 20px 0px;
}
.status-sub-view.Not.Submitted:hover {
  background: #e6efff;
  color: #0642b5;
  border-radius: 0px 20px 20px 0px;
}

.status-sub-view.Not.Submitted.selected.leave {
  background-color: #e6efff;
  color: #0642b5;
  border: 1.5px solid #0642b5;
  border-radius: 0px 20px 20px 0px;
}

.all_manager_activities .ant-checkbox span.ant-checkbox-inner {
  border-radius: 4px;
}

.all_manager_activities .ant-checkbox .ant-checkbox-inner {
  border: 1.5px solid #8f8f8f;
}

.all_manager_activities .ant-checkbox-checked .ant-checkbox-inner {
  background: #0642b5;
}

.all_manager_activities .ant-checkbox-checked .ant-checkbox-inner:hover {
  background: #0642b5;
}

.all_manager_activities .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.right-group-view .ant-switch.ant-switch-checked {
  background: #0642b5;
}

.right-group-view
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0642b5;
}

.tsm_approval_footer {
  display: flex;
  border-radius: 4px;
  height: 56px;
  padding: 4px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 0px solid #dbdbdb;
  background: #e6efff;
  box-shadow: 0px -3px 23px -7px rgba(0, 0, 0, 0.11);
  position: sticky;
  bottom: 0;
}

.tsm_footer_title {
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.tsm_footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.manager_tsm_container {
  display: flex;
  gap: 10px;
}

.manager_tsm_titles {
  display: flex;
  flex-direction: column;
}

.timesheet_manager_titles {
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.manager_week_tsm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.manager_week_date_title {
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.manager_week_tsm:nth-child(n + 2) .manager_week_date_title {
  margin-top: 16px;
}

.manager-tsm-board-top-skeleton.ant-skeleton-button {
  width: 90vw !important;
  height: 60vh !important;
}

.timesheet_status.Rejected {
  border-radius: 8px;
  background: #fae6e6;
  color: #cc0000;
  font-family: "Proximanova-Semibold";
  line-height: 18px;
}

.nodata-manager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.preview-project-division {
  margin-bottom: 10px;
}

.manager-action-sendreminder {
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 5px;
}

.all_manager_activities .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #fff;
}

.all_manager_activities .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #fff;
}
.remindar-loading-view {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manager_loading_notsubmitted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
}
.pagination-leave-management {
  padding: 10px 10px 0px 10px;
}
.noleave_data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.noleave_data img {
  width: 105px;
}
.noleave_data p {
  margin: 12px 0 0;
  font-size: 18px;
  font-family: "Proximanova-Semibold";
  width: 100%;
  text-align: center;
}
.empty_view_lm {
  flex: 1;
}
.leave_management_comment {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  max-height: 150px;
}
