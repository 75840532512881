.create-business-challenge-section {
  .create-business-challenge-title {
    color: #0a0a0a;
  }
  .ant-row {
    position: relative;
  }
  .character-limit-styles {
    position: absolute;
    bottom: -5px;
    right: 5px;
    color: #8f8f8f;
    font-family: "ProximaNova-Regular";
    font-size: 14px;
    line-height: 18px;
  }
  .link-highlight {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    &:hover {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
    }
  }
}
.business-challenge-detail-page {
  position: relative;
}
