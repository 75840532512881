.leaderboard_header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.leader_game {
  flex-shrink: 0;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  height: 485px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 12px;
}

.leaderboard_header .leaderboard_title {
  display: flex;
  width: 140px;
  height: 31px;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
  flex-shrink: 0;
  color: #000;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  /* 140% */
}

.leaderboard_header .button {
  padding: 5px 0px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: #fff;
  background: #fff;
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  cursor: pointer;
}
.leadertile {
  margin-bottom: 14px;
}

.user_tile {
  display: inline-flex;
  align-items: center;
  padding: 2.12px 16px 2.12px 40px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: space-between;
  background: #f5f5f5;
  width: 100%;
}

.current_user_tile {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.12px 16px 2.12px 40px;
  margin-bottom: 12px;
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  background: url("../../../../themes/assets/images/gamification/tab_background/noclub-bg.png");
}
.current_user_tile.Ruby {
  background: url("../../../../themes/assets/images/gamification/tab_background/ruby-bg.png");
}
.current_user_tile.Jade {
  background: url("../../../../themes/assets/images/gamification/tab_background/jade-bg.png");
}
.current_user_tile.Amber {
  background: url("../../../../themes/assets/images/gamification/tab_background/amber-bg.png");
}
.current_user_tile.Turquoise {
  background: url("../../../../themes/assets/images/gamification/tab_background/turquoise-bg.png");
}
.current_user_tile.Sapphire.Club {
  background: url("../../../../themes/assets/images/gamification/tab_background/sapphire-bg.png");
}
.rank_display {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  width: 11.49px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.current_rank_display {
  color: #fff;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  width: 11.49px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.lead_user_display {
  display: flex;
  padding-left: 119px;
  justify-content: center;
  align-items: center;
}

.username_lead {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  width: 150px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  line-height: 19.6px !important;
}

.current_username_lead {
  color: #fff;
  font-family: "ProximaNova-Semibold";
  width: 142px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 19.6px !important;
}

.user_profile_image {
  position: relative;
}

.profile_game {
  width: 31.368px;
  height: 31.765px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  background: #fff;
  border: solid #d5d5d5;
  margin-right: 13.54px;
}

.user_flag {
  position: absolute;
  width: 11.8px;
  height: 11.8px;
  top: 20px;
  left: 20px;
}

.points_display {
  color: #1f1f1f;
  display: block;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  left: 0px;
  justify-content: center;
  line-height: 19.6px;
  display: flex;
  width: 70px;
  height: 32px;
  padding: 8px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

.current_points_display {
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  display: flex;
  width: 70px;
  height: 32px;
  margin-left: 8px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #f5f5f5;
}

.leaderboard_coin {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.userclub_leader {
  display: flex;
  justify-content: flex-start;
  width: 203px;
}

.club_display {
  color: #1f1f1f;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
}

.current_club_display {
  color: #fff;
  display: flex;
  align-items: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  padding: 3px 8px 3px 8px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  font-weight: 400;
  line-height: 19.6px;
  /* 140% */
  border-radius: 32px;
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
}

.user_clubimg {
  margin-left: 4px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

/* Skeleton */
.leaderboard-landing-skeleton {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  gap: 12px;
  border-radius: 8px;
  background: #fff;
  height: 485px;
}
.leader-board-landing-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leader-card-skeleton {
  height: 55px !important;
  width: 100% !important;
}

/* Skeleton */
.earned-badge-skeleton {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 400px;
}
.earned-badge-skeleton-top {
  display: flex;
  justify-content: space-between;
}
.badges-skeleton-earned {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.badge-skeleton-container {
  height: 155px !important;
  width: 120px !important;
}

.skeleton-contribute-bg {
  background: #fff !important;
  gap: 20px;
  justify-content: flex-start;
}
.contribute-skeleton {
  height: 20px !important;
  width: 200px !important;
}
.row-skeleton {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.badges-detail-skeleton {
  display: flex;
  margin-top: 140px !important;
  margin: 40px;
  flex-direction: column;
  gap: 30px;
}
.detail-skeleton-top-badges {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-skeleton-viewall {
  width: 150px !important;
}
.skeleton-detail-badges {
  display: flex;
  justify-content: flex-start;
  gap: 32px;
}
.detail-skeleton-badges {
  height: 155px !important;
  width: 120px !important;
}
.empty-badges-container {
  height: 50vh;
  width: 90vw;
  align-self: center;
  margin: auto;
}
