.comment_field input {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  resize: none;
}

.whole-section.create_cheet-otr .headerlinks.headerlinksactive {
  padding: 0 12px;
}

.whole-section.create_cheet-otr {
  background: #f5f5f5;
  display: table;
  width: 100%;
}

.whole-section.create_cheet-otr .main_container {
  margin-top: 21px;
  background: #f5f5f5;
}

.create-date-otr {
  display: flex;
  margin: 0 0 15px;
  background: #fff;
  border-radius: 8px;
  padding: 10px 24px;
}

.dropdown-otr {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.create-date-otr span {
  margin-right: 8px;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  display: flex;
  align-items: center;
}

.create-date-otr span img {
  margin-right: 10px;
}

.create-date-otr .dropdown-container {
  margin: 0;
}

/* .create_data_list-otr form.ant-form {
    background: #fff;
    border-radius: 8px;
    padding: 16px 24px;
} */
.create-date-otr .dropdown-container .ant-select-selector {
  background: #f8f8f8;
  border-color: #f8f8f8 !important;
  border-radius: 8px;
  width: 210px;
  box-shadow: none !important;
}

.create-date-otr
  .dropdown-container
  .ant-select-selector
  span.ant-select-selection-item {
  font-size: 14px;
  font-family: "ProximaNova-Regular";
}

.create_data_list-otr form.ant-form table {
  background: #fff;
  border: 0px solid;
  font-family: "ProximaNova-Regular";
}

.create_data_list-otr form.ant-form table th.ant-table-cell {
  border: 0px solid !important;
}

/* .create_data_list-otr form.ant-form table thead.ant-table-thead {
    background: #F3F3F3;
} */
.create_data_list-otr form.ant-form table th.ant-table-cell {
  border: 0px solid !important;
  background: transparent !important;
  padding: 8px 12px !important;
  color: #333333;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
}

.create_data_list-otr form.ant-form table th.ant-table-cell::before {
  opacity: 0;
}

.create_data_list-otr form.ant-form table th.ant-table-cell:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px;
}

.create_data_list-otr form.ant-form table th.ant-table-cell:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px;
  /* text-align: right; */
}

.create_data_list-otr td.ant-table-cell:last-child input {
  /* text-align: right; */
}

.create_data_list-otr
  form.ant-form
  table
  tbody.ant-table-tbody
  td.ant-table-cell {
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  color: #333333;
  border: 0px solid !important;
  width: 350px;
  padding: 10px 16px;
  background: transparent;
}

.create_data_list-otr
  form.ant-form
  table
  tbody.ant-table-tbody
  td.ant-table-cell:last-child {
  width: 30px;
}

.ant-col.action-icons_otr {
  display: flex;
}

.ant-col.action-icons_otr button.ant-btn {
  border: 0px solid;
  padding: 0 3px;
  width: 24px;
  height: auto;
  box-shadow: none;
  min-width: initial;
}

.create_data_list-otr form.ant-form table tfoot {
  z-index: 0;
}

.create_data_list-otr form.ant-form table tfoot td.ant-table-cell {
  border: 0px solid;
}

.create_data_list-otr td.ant-table-cell span.ant-select-selection-item {
  color: #333333;
}

/* .create_data_list-otr form.ant-form table tbody.ant-table-tbody td.ant-table-cell:first-child {
    width: 244px;
}
.create_data_list-otr form.ant-form table tbody.ant-table-tbody td.ant-table-cell:nth-child(2) {
    width: 244px;
} */
/* .create_data_list-otr form.ant-form table tbody.ant-table-tbody td.ant-table-cell:nth-child(3) {
    width: 343px;
}
.create_data_list-otr form.ant-form table tbody.ant-table-tbody td.ant-table-cell:last-child {
    width: 204px;
} */
.create_data_list-otr
  tbody.ant-table-tbody
  td.ant-table-cell
  .ant-form-item
  .ant-select-selector {
  border-color: #c7c7c7 !important;
}

.create_data_list-otr
  tbody.ant-table-tbody
  td.ant-table-cell
  .ant-form-item:hover
  .ant-select-selector {
  border-color: #c7c7c7 !important;
}

.create_data_list-otr
  tbody.ant-table-tbody
  td.ant-table-cell
  .ant-form-item
  svg {
  color: #474747;
  width: 10px;
}

.create_data_list-otr
  form.ant-form
  table
  tbody.ant-table-tbody
  td.ant-table-cell
  .ant-picker {
  padding: 0 10px;
  width: 100%;
}

form.ant-form table tbody.ant-table-tbody td input {
  border-color: #c7c7c7 !important;
  height: 40px;
}

form.ant-form table tbody.ant-table-tbody td input::placeholder {
  color: #8f8f8f;
}

.create_data_list-otr td.ant-table-cell .ant-form-item {
  margin: 0;
}

.create_data_list-otr td.ant-table-cell .ant-select {
  height: 42px;
}

.create-date-otr .dropdown-container svg {
  color: #474747;
  width: 10px;
}

.create-date-otr .dropdown-container span.ant-select-arrow {
  right: 0;
}

.create-date-otr .dropdown-container .ant-select.ant-select-outlined {
  height: 40px;
}

.footer_sec-otr {
  padding: 0 55px;
  width: 100%;
  height: 72px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
}

.main-footer_container {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  background: transparent;
  font-family: "ProximaNova-Semibold";
}

.footer_sec {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_sec .button-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_sec .button-view button.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-family: ProximaNova-Semibold;
  font-size: 14px;
  border-color: #0642b5;
  color: #0642b5;
  background: transparent;
}

.footer_sec .button-view button.ant-btn.heightlight {
  border-color: #0642b5;
  color: #0642b5;
  background: transparent;
}

.footer_sec .button-view button.ant-btn.preview {
  background: #e2e2e2;
  border-color: #e2e2e2;
  color: #858585;
  cursor: not-allowed;
}

.footer_sec .button-view button.ant-btn:hover {
  border-color: #0642b5;
  color: #0642b5;
  background: transparent;
}

.footer_sec .button-view button.ant-btn.preview.ant-btn-default:hover {
  background: #e2e2e2;
  border-color: #e2e2e2;
  color: #858585;
  cursor: not-allowed;
}

.footer_sec p.total-hours {
  color: #333333;
  font-size: 14px;
}

.main-footer_container p {
  color: #6f6f6f;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
}

.add-cmd-otr {
  display: flex;
}

.add-cmd-otr .add_btn-otr {
  margin: 0 15px 0 0;
}

.add-cmd-otr a {
  color: #5c5c5c;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  display: flex;
  align-items: center;
}

.add-cmd-otr a span.anticon {
  margin: 0 10px 0 0;
}

.add-cmd-otr a img {
  width: 24px;
  margin: 0 10px 0 0;
}

.create_data_list-otr
  form.ant-form
  table
  td.ant-table-cell
  span.ant-picker-suffix {
  width: 20px;
}

.create_data_list-otr
  form.ant-form
  table
  td.ant-table-cell
  span.ant-picker-suffix
  img {
  width: 20px;
}

.create_data_list-otr form.ant-form .table_form-otr {
  border-bottom: 0px solid #e8e8e8;
  background: #fff;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 0 0 15px;
}

.create_data_list-otr
  form.ant-form
  .table_form-otr
  label.ant-checkbox-wrapper
  span.ant-checkbox-inner {
  border: 2px solid #8f8f8f;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

label.ant-checkbox-wrapper {
  color: #5c5c5c;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  padding: 0 0;
}

.create_data_list-otr
  form.ant-form
  table
  tbody.ant-table-tbody
  td.ant-table-cell
  button.ant-btn {
  border: 0px solid;
  background: transparent;
  box-shadow: none;
}

.total_hr-otr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
}

.total_hr-otr1 {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: #6f6f6f !important;
}

.total_hr-otr span {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  color: #474747;
}

.cmd_time_model button.ant-modal-close:hover {
  background: transparent;
}

.cmd_time_model button.ant-modal-close svg {
  width: 14px;
  height: auto;
  color: #474747;
}

.cmd_time_model .project-title-otr {
  display: flex;
  align-items: center;
}

.cmd_time_model .project-title-otr span {
  display: flex;
  align-items: center;
  margin: 10px 30px 15px 0;
}

.cmd_time_model .project-title-otr span img {
  margin-right: 10px;
}

.cmd_time_model .ant-form-item .ant-row {
  display: flex;
  flex-wrap: wrap;
}

.cmd_time_model .ant-form-item .ant-row .ant-col {
  width: 100%;
  text-align: left;
}

.cmd_time_model .ant-form-item .ant-row .ant-col label {
  font-size: 14px;
  color: #0a0a0a;
  font-family: "ProximaNova-Regular";
}

.cmd_time_model .ant-form-item .ant-row .ant-col label:after {
  display: none;
}

.cmd_time_model .ant-form-item .ant-row .ant-col textarea#Comment {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  border-color: #c7c7c7;
  height: 90px;
  border-radius: 8px;
  resize: none;
  color: #1f1f1f;
  padding: 10px;
}

.cmd_time_model .ant-form-item .ant-row .ant-col textarea::placeholder {
  color: #8f8f8f;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
}

.cmd_time_model .ant-modal-footer {
  display: flex;
  justify-content: space-between;
}

.cmd_time_model .ant-modal-footer {
  display: flex;
  justify-content: space-between;
}

.cmd_time_model .ant-modal-footer button.ant-btn-default {
  border-color: #c7c7c7;
  border-radius: 8px;
  width: 120px;
  height: 40px;
  color: #5c5c5c;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
}

.cmd_time_model .ant-modal-footer button.ant-btn-default:hover {
  border-color: #c7c7c7;
  color: #5c5c5c;
}

.cmd_time_model .ant-modal-footer button.ant-btn-primary {
  border-color: #e2e2e2;
  background: #e2e2e2;
  border-radius: 8px;
  width: 120px;
  height: 40px;
  color: #858585;
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  box-shadow: none;
}

.cmd_time_model .ant-modal-footer button.ant-btn-primary:hover {
  border-color: #e2e2e2;
  background: #e2e2e2;
  color: #858585;
}

.cmd_time_model_valid .ant-modal-footer button.ant-btn-primary {
  background: #0642b5 !important;
  color: #fff !important;
}

.timesheet-row {
  display: flex;
}

.create_data_list-otr form.ant-form .table_col-list .project_title-main {
  color: #0a0a0a;
  font-size: 16px;
  margin: 0 0 10px;
  font-family: "ProximaNova-Semibold";
}

.create_data_list-otr form.ant-form .timesheet_row-main {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 20px;
}

.create_data_list-otr form.ant-form .timesheet_row-main.timesheet_row-sub {
  margin-top: -10px;
}

form.ant-form .timesheet_row-main p {
  width: 450px;
  margin: 0 20px 0 0;
  color: #0a0a0a;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
}

form.ant-form .timesheet_row-main .ant-form-item {
  width: 450px;
  margin: 0 20px 0 0;
  height: 40px;
}

form.ant-form .timesheet_row-main .ant-form-item .ant-picker {
  width: 100%;
  height: 40px;
  border-color: #c7c7c7;
}

.create_data_list-otr form.ant-form .timesheet_row-main button.ant-btn {
  padding: 0;
  border: 0px solid;
  box-shadow: none;
  width: 24px;
}

.create_data_list-otr form.ant-form .overall_cmd-otr {
  border-top: 1px solid #e8e8e8;
  margin: 20px 0 0;
}

.create_data_list-otr form.ant-form .overall_cmd-otr p {
  font-size: 14px;
  color: #474747;
  font-family: "ProximaNova-Regular";
}

.create_data_list-otr form .timesheet_row-main .ant-select {
  height: 40px;
}

.create_data_list-otr
  form
  .timesheet_row-main
  .ant-select
  .ant-select-selector {
  border-color: #c7c7c7;
  height: 40px;
}

.create_data_list-otr
  form
  .timesheet_row-main
  .ant-select:hover
  .ant-select-selector {
  border-color: #c7c7c7 !important;
}

.create_data_list-otr
  form
  .timesheet_row-main
  .ant-select
  .ant-select-selector
  input {
  height: 40px;
}

.leave-holiday-text {
  color: #008000;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  width: 300px;
}

.demo-box {
  width: 210px;
  height: 38px;
  background: #f8f8f8;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.total_hr-otr.less span {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  color: red !important;
}

.total_hr-otr1.less {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: red !important;
}

.date-picker-input {
  cursor: pointer;
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
}

.calendar-popup {
  position: absolute;
  top: 55px;
  left: 0;
  z-index: 999;
  margin-top: 4px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.total_hr-otr.less span {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  color: red !important;
}

.total_hr-otr1.less {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: red !important;
}
.timesheet_icon_span {
  cursor: pointer;
}

.timesheet_action_icon {
  display: flex;
  color: #000;
  margin-left: 10px;
  cursor: "pointer";
  width: 20px;
  height: 20px;
}
