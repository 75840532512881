.copy-goal {
  margin-top: 20px;
}
.copy-goal-button-bar {
  display: flex;
  width: 100%;
  height: 72px;
  background: #fff;
  box-shadow: 0px -3px 6px 0px rgba(206, 211, 218, 0.45);
  align-items: center;
  justify-content: space-between;
  padding: 0px 65px;
  position: fixed;
  bottom: 0;
}
.copy-goals-layout {
  margin-bottom: 125px !important;
}
.copy-goal-selected-text {
  color: #474747;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.selected-goals-action .primary-btn-copy .ant-btn-color-primary {
  border: 1px solid #0642b5 !important;
  background: #0642b5 !important;
  color: #fff;
}
.selected-goals-action button.primary-btn-copy {
  width: 93px;
  height: 32px;
  border-radius: 8px;
  font-family: "ProximaNova-SemiBold";
  font-size: 12px;
  line-height: 17px;
}
.active-copy-goals {
  border: 1px solid #0642b5 !important;
  background: #0642b5 !important;
  color: #fff;
}
.total-weight-cpy {
  color: #333;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
  display: flex;
}
.total-weight-cpy div {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.total-weight-cpy .exceed {
  color: red;
}
.total-weight-cpy .within {
  color: #008000;
}
