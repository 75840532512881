.manager-overview-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.performance-header-empDetail .ant-space-item img {
  display: block;
}

.manager-overview-container .year-end-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #e8f1fb80;
  border-radius: 4px;
}
.year-end-popup .popup-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.year-end-popup .popup-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
}
.year-end-popup .pop-up-heading {
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}
.year-end-popup .year-end-button {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  background-color: transparent;
  border: 1px solid #0642b5;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.manager-overview-container .steps-container {
  width: 100%;
  border-radius: 8px;
  background: rgba(243, 243, 243, 0.7);
  padding: 13px 16px;
}
.manager-overview-container .step-container-title {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom: 16px;
}
.manager-overview-container .overview-data-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.overview-data-container .pie-chart-container {
  display: flex;
  min-width: 518px;
  height: 430px;
  flex: 2;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background: #fff;
  padding: 16px;
  flex-direction: column;
}
.pie-chart-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.pie-chart-header {
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
.activities-container {
  display: flex;
  height: 430px;
  flex: 3;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background: #fff;
  min-width: 300px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
}
.activities-modal .modal-container {
  border: 0px solid !important;
  padding: 4px 0px 0px 4px !important;
}
.activities-modal .activities-container-data::-webkit-scrollbar {
  width: 6px;
}
.activities-modal .activities-container-data::-webkit-scrollbar-track {
  background: transparent;
  background-color: #fff;
}
.activities-modal .modal-activities-title {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}
.activities-modal .close-cion {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.overview-data-container .view-all-activities {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  cursor: pointer;
}
.overview-data-container .pie-chart-data-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 53px;
}
.activities-container-data {
  height: 100%;
  display: flex;
  overflow: auto;
}
.pie-chart-container .pie-chart-data-container {
  height: 244px;
  width: 244px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.pie-chart-container .pie-chart-center-text {
  display: flex;
  color: #1f1f1f;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100px;
  word-wrap: break-word;
  position: absolute;
  max-height: 48px;
}
.pie-chart-container .pie-chart-detial {
  width: 188px;
  height: 100%;
  margin-left: 21px;
  padding: 16px 24px;
  border-radius: 8px;
  background: #f5f5f5;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pie-chart-container .pie-chart-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pie-chart-container .dot-pie {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: #008000;
}

.pie-chart-container .on-track {
  background-color: #1976d2 !important;
}
.pie-chart-container .not-started {
  background-color: #454545 !important;
}
.pie-chart-container .behind {
  background-color: #e65a14 !important;
}
.pie-chart-container .postponed {
  background-color: #e8a911 !important;
}
.pie-chart-container .cancelled {
  background-color: #cc0000 !important;
}

.pie-chart-container .pie-label-text {
  color: #474747;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.pie-chart-container span {
  font-family: "ProximaNova-Semibold";
  font-weight: 600;
}
.activities-container-data .activity-icon-step {
  height: 16px;
  width: 16px;
  background-color: #b3cdff;
  border-radius: 16px;
  align-self: center;
}
.manager-overview-container .not-started-step {
  border: 2px solid #bfbfbf;
  height: 32px;
  width: 32px;
  border-radius: 32px;
}

.manager-overview-container .in-progress-step {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0642b5;
  border-radius: 32px;
}
.in-progress-step .inprogress-step-inner {
  height: 14px;
  width: 14px;
  border-radius: 15px;
  background-color: #0642b5;
}
.manager-overview-container .tab-pie {
  display: flex;
  flex-direction: row;
  width: 300px;
}
.manager-overview-container .tab-left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.manager-overview-container .tab-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.manager-overview-container .tab-pie .tab {
  display: flex;
  border: 1px solid #dbdbdb;
  background: #fff;
  padding: 4px;
  flex: 1;
  align-self: center;
  justify-content: center;
  text-align: center;
  color: #474747;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
.manager-overview-container .tab-pie .active {
  border-color: #0642b5 !important;
  background-color: #e6efff !important;
  color: #0642b5 !important;
  font-family: "ProximaNova-Semibold";
}

/* antd-step-changes */
.steps-container .ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
  margin-inline-start: 0px;
}
.steps-container .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  text-align: left !important;
}
.manager-overview-container .steps-container .ant-steps-item-content{
 width: auto !important;
}

.steps-container .ant-steps-item-content > .ant-steps-item-title {
  text-align: left !important;
}

.manager-overview-container .steps-container .ant-steps-item-content > .ant-steps-item-title {
  white-space: nowrap !important;
  color: #1F1F1F !important;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: 22.4px; /* 140% */
}

.manager-overview-container .steps-container .ant-steps-item-content > .ant-steps-item-description{
  white-space: nowrap !important;
  color: #1A1C22 !important;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  line-height: 16.8px;
}
.steps-container .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 16px;
}
.steps-container
  .ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #0642b5 !important;
}
.steps-container .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0642b5;
  border-color: #0642b5;
}
.steps-container .ant-steps .ant-steps-item-finish .ant-steps-item-icon svg {
  color: #fff;
}
.steps-container .ant-steps .ant-steps-item-tail::after {
  background-color: #bfbfbf !important;
}

.activities-container-data
  .ant-steps
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  align-items: center;
  display: flex;
  justify-content: center;
}
.activities-container-data
  .ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #b3cdff;
}

.activities-container-data
  .ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #b3cdff;
}
.manager-overview-container .ant-steps.ant-steps-vertical > .ant-steps-item {
  flex: 0 auto !important;
}
.empty-notification {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-notification .no-notification-img {
  height: 120px;
  object-fit: contain;
}
.empty-notification .no-notificaiton-title {
  color: #0a0a0a;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.empty-notification .no-notification-sub-title {
  color: #858585;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
