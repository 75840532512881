.container {
  margin: 37px 67px 67px 67px;
}

.goal-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-goal-main {
  background-color: white;
  padding: 15px;
}

.preview-goal-milestones {
  background-color: #f5f5f5;
  /* padding: 15px; */
}

.preview-cancel-addgoal {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px 0px 0px;
  gap: 20px;
}

.breadcrumb_preview {
  margin-top: 0;
}

.preview-main-title {
  font-family: ProximaNova-Bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.commentdialog.ant-modal {
  width: 606px !important;
}

.commentheading {
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  color: #000;
  font-weight: 700;
  line-height: 28.8px;
  /* 120% */
}

.commentview {
  color: #232a3d;
  font-family: "Proximanova-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.goalcontent {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.goalcontent > p {
  flex: 1 1 100%;
  margin-bottom: 2px;
}

.goalcontent > p:nth-child(2) {
  flex: 1 1 65%;
}

.goalcontent > p:nth-child(3) {
  flex: 1 1 30%;
}

.managercomment {
  display: flex;
  width: 146px;
  height: 40px;
  padding: 9px 16px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #095dff;
  background: #fff;
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.viewcomment {
  display: inline-flex;
  height: 40px;
  padding: 8px 16px 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #0642b5;
  background: #fff;
  color: #0642b5;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.remainingCharacters {
  text-align: right;
  color: red;
}

.comment-character-count {
  color: red;
}

.preview-goal-sub {
  display: flex;
  justify-content: space-between;
}

.preview-goal-Status {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 20px;
}

.preview-goal-sub .row-section {
  width: 100%;
}

.status-section.behind-bg {
  background: #fae6e6;
  color: #cc0000;
  padding: 6px 16px;
  height: auto;
  width: 80px;
}

.contribute_view-otr .preview-goal-main .status-section.behind-bg p {
  color: #cc0000;
}

.status-section.completed-bg {
  padding: 6px 16px;
  height: auto;
  width: 110px;
}

.contribute_view-otr .preview-goal-main .status-section.completed-bg p {
  /* color: #1976d2; */
}
.status-section.cancelled-bg {
  background: #e8f1fb;
  color: #1976d2;
  padding: 6px 16px;
  height: auto;
  width: 110px;
}

.contribute_view-otr .preview-goal-main .status-section.cancelled-bg p {
  color: #1976d2;
}

.status-section.notstarted-bg {
  background: #9c9fa9;
  color: #fff;
  padding: 6px 16px;
  height: auto;
  width: 122px;
}

.contribute_view-otr .preview-goal-main .status-section.notstarted-bg p {
  color: #fff;
}

.status-section.ontrack-bg {
  background: #cce6cc;
  color: #008000;
  padding: 6px 16px;
  height: auto;
  width: 102px;
}

.contribute_view-otr .preview-goal-main .status-section.ontrack-bg p {
  color: #008000;
}
.status-section.postponed-bg {
  background: #fff7e6;
  color: #e8a911;
  padding: 6px 16px;
  height: auto;
  width: 102px;
}
.contribute_view-otr .preview-goal-main .status-section.postponed-bg p {
  color: #e8a911;
}
.button-area.edit-btn-view button.fillBtnStyles {
  background: #0642b5;
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 20px;
}
.comments-input-box-style {
  background-color: #bfbfbf;
  text-align: justify;
}
.commentview textarea {
  resize: none;
}
