.main_container_approval {
  margin: 80px 55px;
  /* background-color: #ffffff;
    padding: 20px; */
}

.hidestricky_breadcrump .Seperation-page-title {
  margin-top: 20px;
  font-size: 24px;
}

.Seperation-page-title {
  margin-bottom: 20px;
}

.main-sub-container {
  margin: 0px 0px;
}

.apprval_tab_parent {
  background-color: #ffffff;
  padding: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
  flex: 1;
  display: flex;
}

.manager-tabs-tsm {
  flex: 1;
}

.manager-tabs-tsm .p-tabview-nav-container {
  border-bottom: 1px solid #f0f0f0;
  background: transparent;
}

.manager-tabs-tsm .p-tabview-nav-container .p-tabview-nav {
  height: 27px;
  display: flex;
  gap: 16px;
  list-style-type: none;
}

.manager-tabs-tsm
  .p-tabview-nav-container
  .p-tabview-nav
  .p-unselectable-text
  a {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 15px;
  line-height: 19.6px;
}

.manager-tabs-tsm .p-unselectable-text {
  position: relative;
}

.manager-tabs-tsm .p-unselectable-text.p-tabview-selected.p-highlight::after {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0;
  width: 100%;
  background: #0642b5;
  height: 5px;
  z-index: 11;
}

.manager-tabs-tsm
  .p-tabview-nav-container
  .p-tabview-nav
  .p-tabview-selected
  a {
  color: #0642b5;
}

.ant-input-affix-wrapper-lg {
  border-radius: 32px;
  width: 264px;
}

.tab-panel-container {
  margin-top: 15px;
}

.tab-panel-container .tab-panel-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.right-group-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.group-week-text {
  color: #0a0a0a;
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  margin-right: 10px;
}

.status-row-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.status-sub-view {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.check_buttons_tsm {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

button.delegate_manager_leave.check_delegate-icon {
  display: flex;
  width: 35px;
  height: 35px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}
button.delegate_manager_leave.check_delegate-icon img {
  width: 20px;
}

.check_true_button {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #008000;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.check_false_button {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #cc0000;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.timesheet_panel.timesheet_admin-panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.all_manager_activities .ant-table-wrapper table {
  border: none;
}

.all_manager_activities .ant-table-thead tr th {
  background: #f3f3f3 !important;
  border-bottom: none !important;
}

.all_manager_activities .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8 !important;
  background: #fff !important;
  padding: 16px;
}

.manager_activities_table {
  color: #f3f3f3;
  min-width: 90vw;
  cursor: pointer;
}

.status-sub-view.Approved {
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
}

.status-sub-view.Pending {
  border: 1px solid #c7c7c7;
  border-radius: 20px 0px 0px 20px;
}

.status-sub-view.Pending:hover {
  background: #e6efff;
  color: #0642b5;
  border-radius: 20px 0px 0px 20px;
}

.status-sub-view.Pending.selected {
  background-color: #e6efff;
  /* Change background color when selected */
  color: #0642b5;
  /* Text color changes to white */
  border: 1.5px solid #0642b5;
  border-radius: 20px 0px 0px 20px;
}

.status-sub-view.Approved {
  border: 1px solid #c7c7c7;
}

.status-sub-view.Approved:hover {
  background: #e6efff;
  color: #0642b5;
}

.status-sub-view.Approved.selected {
  background-color: #e6efff;
  color: #0642b5;
  border: 1.5px solid #0642b5;
}

.status-sub-view.Rejected {
  border: 1px solid #c7c7c7;
}

.status-sub-view.Rejected:hover {
  background: #e6efff;
  color: #0642b5;
}

.status-sub-view.Rejected.selected {
  background-color: #e6efff;
  color: #0642b5;
  border: 1.5px solid #0642b5;
}

.status-sub-view.Not.Submitted {
  border: 1px solid #c7c7c7;
  border-radius: 0px 20px 20px 0px;
}

.status-sub-view.Not.Submitted:hover {
  background: #e6efff;
  color: #0642b5;
  border-radius: 0px 20px 20px 0px;
}

.status-sub-view.Not.Submitted.selected {
  background-color: #e6efff;
  color: #0642b5;
  border: 1.5px solid #0642b5;
  border-radius: 0px 20px 20px 0px;
}

.all_manager_activities .ant-checkbox span.ant-checkbox-inner {
  border-radius: 4px;
}

.all_manager_activities .ant-checkbox .ant-checkbox-inner {
  border: 1.5px solid #8f8f8f;
}

.all_manager_activities .ant-checkbox-checked .ant-checkbox-inner {
  background: #0642b5;
}

.all_manager_activities .ant-checkbox-checked .ant-checkbox-inner:hover {
  background: #0642b5;
}

.all_manager_activities .ant-checkbox .ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.right-group-view .ant-switch.ant-switch-checked {
  background: #0642b5;
}

.right-group-view
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0642b5;
}

.tsm_approval_footer {
  display: flex;
  border-radius: 4px;
  height: 56px;
  padding: 4px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 0px solid #dbdbdb;
  background: #e6efff;
  box-shadow: 0px -3px 23px -7px rgba(0, 0, 0, 0.11);
  position: sticky;
  bottom: 0;
}

.tsm_footer_title {
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

.tsm_footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.manager_tsm_container {
  display: flex;
  gap: 10px;
}

.manager_tsm_titles {
  display: flex;
  flex-direction: column;
}

.timesheet_manager_titles {
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.manager_week_tsm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.manager_week_date_title {
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.manager_week_tsm:nth-child(n + 2) .manager_week_date_title {
  margin-top: 16px;
}

.manager-tsm-board-top-skeleton.ant-skeleton-button {
  width: 90vw !important;
  height: 60vh !important;
}

.timesheet_status.Rejected {
  border-radius: 8px;
  background: #fae6e6;
  color: #cc0000;
  font-family: "Proximanova-Semibold";
  line-height: 18px;
}

.nodata-manager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.preview-project-division {
  margin-bottom: 10px;
}

.manager-action-sendreminder {
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 5px;
}

.all_manager_activities .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #fff;
}

.all_manager_activities .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #fff;
}
.remindar-loading-view {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manager_loading_notsubmitted {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
}
.profile_sec {
  gap: 10px;
}
span.profile_sec {
  display: flex;
  align-items: center;
}
span.profile_sec img {
  margin-right: 10px;
}

.apprval_tab_parent.emp_tm-otr {
  flex-wrap: wrap;
}

.admin_page-title .admin-search_action {
  margin-left: 10px;
}
.admin_page-title .admin-search_action span.ant-input-group-addon {
  display: none;
}
.admin_page-title .admin-search_action input.ant-input {
  padding-left: 27px;
  font-size: 14px;
}
.admin_page-title .admin-search_action input.ant-input::placeholder {
  color: #6f6f6f;
  font-size: 14px;
}
.main_container_approval
  .admin_page-title
  .admin-search_action
  span.ant-input-outlined {
  position: relative;
  width: 264px;
  height: 40px;
  border-radius: 32px;
  border-color: #c7c7c7;
}
.main_container_approval
  .admin_page-title
  .admin-search_action
  span.ant-input-outlined:before {
  display: none;
}
.admin_page-title
  .admin-search_action
  span.ant-input-outlined
  span.ant-input-suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 12px;
  margin: 0;
}
.profile_details .profile_text {
  color: #0a0a0a;
  font-family: "Proximanova-Semibold";
}
.profile_details .profile_desc {
  color: #5c5c5c;
}
.timesheet_panel.timesheet_admin-panel
  .all_manager_activities
  td.ant-table-cell {
  color: #333;
}
.admin_page-title {
  display: flex;
  align-items: center;
}
/* .timesheet_panel.timesheet_admin-panel .all_manager_activities tbody tr.ant-table-row {
  cursor: pointer;
} */
.emp_profile-detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 15px;
}
.emp_profile-detail button {
  font-family: "Proximanova-Semibold";
  border: 1px solid #0642b5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  background: #fff;
  color: #0642b5;
  font-size: 14px;
  cursor: pointer;
}
.emp_profile-detail button img {
  margin-right: 10px;
  width: 24px;
}
.timesheet_panel.timesheet_admin-panel td button.download-icon {
  background: transparent;
  border: 0px solid;
  padding: 0;
  cursor: pointer;
}
.timesheet_panel.timesheet_admin-panel td .status-btn {
  background: transparent;
  border: 0px solid;
  padding: 0;
}
.approvals-fin-breadcrumb {
  margin-bottom: 20px;
}
.approval-fin-preview {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
}
.approvals-fin-borderbox {
  display: flex;
  border-radius: 8px;
}
.preview-project-division-approvals-fin {
  padding: 10px 5px;
  border-radius: 8px;
  background: #fff;
}
.preview_work_display-fin {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  color: #333;
  gap: 10px;
  padding: 16px;
}
.preview_dual_work_timesheet-fin {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  color: #333;
  font-family: "Proximanova-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}
.preview_finance_timesheet {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  margin-bottom: 20px;
}
.download-button-fin-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
.download-button-fin {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  cursor: pointer;
}
.revert-button-fin {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #0642b5;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #0642b5;
  cursor: pointer;
}
button.ant-btn.manager_export-btn.admin_export-btn {
  width: 146px;
  height: 40px;
}
.profile_text.admin {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.profile_desc.admin {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
.projectname_admin {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
}
.admin-fin-pagination {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.no-tsm-admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.avatar-admin-profile {
  width: 30px;
  height: 30px;
}
.no-data-admin-img {
  width: 100px;
  height: 100px;
}
.no-tsm-admin-title {
  color: #0a0a0a;
  text-align: center;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
}
.no-tsm-admin-content {
  color: #6f6f6f;
  text-align: center;
  font-family: "Proximanova-Regular";
  font-size: 14px;
  font-style: normal;
}
.no-admin-total-container {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.revert-button-row-view {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.reject_btn {
  display: flex;
  height: 40px;
  width: 120px;
  padding: 5px 12px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  background: rgba(255, 255, 255, 0);
  color: #5c5c5c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.timesheet_submit_btn {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 5px 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}
.preview_buttons_timesheet_admin {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 5px 12px;
}
