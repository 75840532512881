.x-axis-potential {
  writing-mode: vertical-lr;
  text-align: left;
  white-space: nowrap;
  padding: 90px 8px 0 25px;
  transform: rotate(180deg);
}
.top-arrow-x-axis {
  margin-bottom: 2px;
  margin-right: 2px;
}
.bottom-arrow-x-axis {
  margin-top: 2px;
}
.y-axis-perfomance {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-arrow-y-axis {
  margin-top: 2px;
  margin-right: 2px;
}
.right-arrow-y-axis {
  margin-top: 5px;
  margin-left: 2px;
}
.rank-selected-input {
  background-color: #f0f0f0;
  height: 40px;
  width: 418px;
  border-radius: 4px;
  padding: 14px 12px 14px 12px;
  gap: 8px;
  border: 1px solid #bfbfbf;
  color: #1f1f1f;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}
.rank-selected-input::placeholder {
  color: #1f1f1f;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}

.potential_indicator {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 8px;
}

.cards_container {
  display: flex;
  margin-top: 15px;
  .ant-card-body::before {
    content: "";
  }
  .ant-card-body {
    display: flex;
  }

  .card_content {
    display: flex;
    padding: 12px;
    height: 76px;
    cursor: pointer;
  }

  .ant-col {
    margin-bottom: 12px;
  }
}
.card_wrapper {
  border-radius: 5px;
  border: 1px solid grey;
  margin-left: 5px;
}
.overlay {
  height: 100%;
  width: 95%;
  position: absolute;
  z-index: 10;
  opacity: 0.1;
}
.potential-rating-note {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  color: #454545;
}
.potential_chart_title {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 19.6px;
  color: #1f1f1f;
}
.potential_chart_desc {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  color: #454545;
}
.rank-selected-title {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.p2p-container {
  margin: 85px 55px !important;
}
.p2p-container .p2p-breadcrumb {
  margin-bottom: 16px;
}
.p2p-container .p2p-stepper {
  margin-bottom: 24px;
}
.p2p-container .layoutContainer {
  margin: 0px 0px !important;
}

.loadingP2P {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 60vh;
  background-color: #fff;
  margin-top: 12px;
}

.ant-progress-text svg {
  display: none;
}
.ant-progress.ant-progress-status-success .ant-progress-bg {
  background-color: #1677ff;
}
.compotency-inner-section .ant-col.ant-form-item-control {
  flex: 0;
}
.custom-rank .ant-input-outlined[disabled] {
  color: #1f1f1f;
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: pointer;
  opacity: 1;
}
