.react-calendar {
  flex: 1;
  border: 0px transparent;
  height: auto;
  width: auto;
  margin-top: 10px;
}
.react-calendar .calendar-view {
  border-width: 0px;
  flex: 1;
}
.react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  background-color: transparent;
}
.react-calendar .react-calendar__tile:hover {
  background-color: transparent;
}
.react-calendar .react-calendar__tile::selection {
  background-color: transparent;
}
.react-calendar .leave-request-card-legends-container-indicator {
  display: flex;
  /* flex: 0.1; */
  flex-direction: row;
  /* justify-content: space-between; */
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
  /* gap: 20px; */
}
.react-calendar .leave-request-card-legends-text-container-indicator {
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  margin-right: 25px;
}
.react-calendar .leave-request-card-legend-color-indicator {
  height: 6px;
  width: 6px;
  border-radius: 6px;
  border: solid 1px;
}
/* .leave-request-card-body .react-calendar {
  flex: 1;
  border: none;
  width: 100%;
} */

.react-calendar .react-calendar__navigation__label {
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 600;
  line-height: 26.58px;
  text-align: center;
  color: #000;
  background: #fff;
}
.react-calendar .react-calendar__month-view__weekdays__weekday {
  font-family: "ProximaNova-SemiBold";
  font-weight: 600;
  font-size: 14px;
  color: #454545;
  text-transform: capitalize;
  flex: 0%;
}
.react-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar .react-calendar__tile {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
  /* cursor: not-allowed; */
}

.react-calendar .react-calendar__tile abbr {
  width: 100%;
  height: 100%;
  max-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.react-calendar .react-calendar__tile abbr:hover {
  background: none;
}
.react-calendar .react-calendar__tile--now abbr {
  border: 1px solid #0642b5;
  border-radius: 50%;
}
/* .react-calendar .react-calendar__tile--now {
  flex: 0 0 8.2857% !important;
  border-radius: 50%;
  border: 1px solid #0642b5;
  color: #0a0a0a;
  margin: 0 15px;
} */

/* .react-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: #bfbfbf;
  user-select: none; 
  -webkit-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
} */
.react-calendar .react-calendar__tile--active {
  background-color: #fff;
  /* color: #000; */
}
.react-calendar .react-calendar__tile--active:enabled:focus {
  background-color: #e6efff;
}
.react-calendar .react-calendar__navigation button:enabled:hover {
  background-color: #fff;
}
.react-calendar .react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}

.react-calendar .react-calendar__navigation button:disabled {
  background-color: #fff;
}
.react-calendar .react-calendar__tile:disabled {
  background-color: #fff;
  color: #bfbfbf;
}
.calendar-view.react-calendar .react-calendar__tile--rangeStart {
  background: linear-gradient(to left, #e6efff 50%, #fff 50%);
  border-radius: 100% 0 0 100%;
  color: #fff;
}
.calendar-view.react-calendar .react-calendar__tile--rangeStart abbr {
  background: #0642b5;
  border-radius: 100%;
  color: #fff;
}
.calendar-view.react-calendar .react-calendar__tile--rangeEnd {
  background: linear-gradient(to right, #e6efff 50%, #fff 50%);
  border-radius: 0 100% 100% 0;
  color: #fff;
}
.calendar-view.react-calendar .react-calendar__tile--rangeEnd abbr {
  background: #0642b5;
  border-radius: 100%;
  color: #fff;
}
.calendar-view.react-calendar .react-calendar__tile--active {
  background: #e6efff;
}
.calendar-view
  .react-calendar__tile--now
  .react-calendar__month-view__days__day:enabled:hover {
  background: none;
}
.calendar-view
  .react-calendar__tile--now
  .react-calendar__month-view__days__day:enabled {
  background: transparent;
}
.calendar-view.react-calendar__tile
  .react-calendar__tile--now
  .react-calendar__tile--active {
  background: #e6efff;
}
.calendar-view
  .react-calendar__tile
  .react-calendar__month-view__days__day::-webkit-scrollbar-thumb {
  background: transparent;
}
.calendar-view
  .react-calendar__tile
  .react-calendar__month-view__days__day:hover {
  background: #000;
}
.calendar-view .react-calendar__tile--now {
  background: none;
}
.calendar-view.react-calendar .react-calendar__tile--rangeStart {
  background: linear-gradient(to left, #e6efff 50%, #fff 50%);
  border-radius: 100% 0 0 100%;
  color: #fff;
}
.calendar-view.react-calendar .react-calendar__tile--rangeEnd {
  background: linear-gradient(to right, #e6efff 50%, #fff 50%);
  border-radius: 0 100% 100% 0;
  color: #fff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: #000;
}
