.dex-fixed-footer-area {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  background: #fff;
  right: 0;
  padding: 16px 60px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px -55px 15px 0px rgba(171, 171, 171, 0),
    0px -35px 14px 0px rgba(171, 171, 171, 0.01),
    0px -20px 12px 0px rgba(171, 171, 171, 0.05),
    0px -9px 9px 0px rgba(171, 171, 171, 0.09),
    0px -2px 5px 0px rgba(171, 171, 171, 0.1);
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  .footer-outline-btn {
    display: flex;
    width: 100px;
    height: 40px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #c7c7c7;
    background: #ffffff;
    color: #5c5c5c;
    font-family: "ProximaNova-SemiBold";
    font-size: 14px;
    line-height: 18px;
  }
  .footer-outline-btn {
    border: 1px solid #c7c7c7 !important;
    background: #ffffff !important;
    color: #5c5c5c !important;
  }
  .footer-fill-btn {
    display: flex;
    width: 100px;
    height: 40px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #0642b5;
    color: #fff;
    font-family: "ProximaNova-SemiBold";
    font-size: 14px;
    line-height: 18px;
  }
  .footer-fill-btn:hover {
    background: #0642b5 !important;
    color: #fff !important;
  }
  .footer-fill-btn.ant-btn-primary:disabled {
    background: #e2e2e2 !important;
    color: #858585 !important;
  }
  .footer-edit-outline-btn {
    display: flex;
    width: 100px;
    height: 40px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-family: "ProximaNova-SemiBold";
    font-size: 14px;
    line-height: 18px;
    border: 1px solid #0642b5 !important;
    background: #ffffff !important;
    color: #0642b5 !important;
  }
  .no-buttons {
    display: none;
  }
}
