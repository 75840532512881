.dropdown_menu{
        position: absolute;
        top: 15px;
        left: -160px;
        z-index: 1000;
        padding: 16px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 238px;
        max-height: 400px;
        overflow: autp;
        border-radius: 4px;
        background:  #FFF; 
}
.ant-menu-light.dropdown_menu.ant-menu-root.ant-menu-vertical{
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
}
.dropdown_state{
    max-height: 250px;
    margin-bottom: 16px;
}
.dropdown_list_items{
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 5px;
}
.dropdown_list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 16px;
}
.dropdown_list_items::-webkit-scrollbar {
    width: 4px;
    background: #fff;
  }
.dropdown_list_items::-webkit-scrollbar-thumb {
    background-color: #aeaeae;
  }
.dropdown_list_items::-webkit-scrollbar-track {
    display: none;
  }
.dropdown-footer{
    display: flex;
    justify-content: space-between;
}
.dropdown_items{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #454545;
    font-family: "Proximanova-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
}
.labelcheckbox{
    display: flex;
    align-items: center;
    gap: 6px;
}
.filtercheckbox{
    width: 20px;
    height: 20px;
}
.filtercheckbox input:checked {
    background: turquoise;
}
.reset_button_game{
    color:  #0642B5;
    border: #FFF;
    background: #FFF;
    box-shadow: none;
    font-family: "Proximanova-Semibold";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.8px; /* 140% */
}
.apply_button_game{
    background: #0642B5;
    color: #FFF;
    font-family: "Proximanova-Semibold";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.8px; /* 140% */
}
.apply_button_game:active{
    background: #0642B5;
}