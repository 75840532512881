.center {
  text-align: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-center {
  display: flex;
  align-items: center;
  gap: 5px;
}

.banner {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 20px 30px;

  .banner-text-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    flex: 0.8;
  }

  .rounded-30 {
    border-radius: 30px;
    padding: 4px 16px;
  }

  .grayscale {
    filter: grayscale(100%);
  }

  .banner-image-section {
    background: linear-gradient(141.02deg, #ffecc6 0%, #ff7373 100%);
    padding: 20px;
    border-radius: 8px;
    flex: 0.2;
    flex-direction: column;

    .rectangle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .center {
      display: flex;
      align-items: center;
    }
  }

  .create-idea-btn {
    background-color: #0642b5;
    color: #fff;
    padding: 12px 5px;
    border-radius: 8px;
    width: 130px;
    text-align: center;
  }

  .gap-50 {
    gap: 50px;
  }
}

.famebook-card {
  width: 100%;
  max-width: 23%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  .famebook-card-skeleton {
    padding: 19px;
  }

  .image-section-famebook {
    position: relative;
    flex: 1;
    gap: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .frame {
      position: absolute;
    }

    .circle {
      height: 80px;
      width: 80px;
      border-radius: 100%;
      margin-left: 18px;
      margin-top: 10px;
    }

    .profile-img {
      height: 100%;
      width: 100%;
      border-radius: 100%;
      margin-top: -25px;
      margin-left: -8px;
    }

    .text-section-framecard {
      display: "flex";
      flex-direction: "column";
      gap: 5px;
    }

    .winner-img {
      height: 100%;
      width: 100%;
    }
  }
}

.employee-cmnts-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 25px;
  flex: 1;

  .top-section {
    display: flex;
  }

  .row {
    display: flex;
    gap: 15px;
    flex: 1;
  }

  .circle {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: #ddd6f3;
  }

  .text-section-empcard {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .desc {
    margin-top: 10px;
  }

  .quotes_img {
    display: flex;
  }
}

.ideas-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 32%;
  cursor: pointer;
  .ideas-card-skeleton {
    padding: 23px 0px;
  }

  .blue-tag {
    background-color: #e8f1fb;
    color: #1976d2;
    padding: 6px 16px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .status-tag {
    padding: 6px 16px;
    border-radius: 8px;
  }

  .divider {
    border: 0.5px solid #d9d9d9;
  }

  .winner-tag {
    background: linear-gradient(
      97.05deg,
      rgba(249, 212, 35, 0.7) 0%,
      rgba(255, 221, 0, 0.7) 30.92%,
      rgba(251, 176, 52, 0.7) 62.86%,
      rgba(255, 236, 115, 0.7) 100%
    );
    padding: 7px 12px;
    border-radius: 8px;
    border: 0.5px solid #ffad09;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.innovation-carousel-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 10px;
  background-color: transparent;
  justify-content: space-between;

  .slick-prev::before,
  .slick-next::before {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }

  .slick-prev::before {
    content: url(../../../themes/assets/images/leftArrow.png);
  }

  .slick-next::before {
    content: url(../../../themes/assets/images/rightArrow.png);
  }

  .slick-prev::after,
  .slick-next::after {
    display: none;
  }

  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px #ced3dacc;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    opacity: 0.5;
    color: #000;
    top: 70px;
    z-index: 10;
  }

  .slick-arrow.slick-next:hover,
  .slick-arrow.slick-prev:hover {
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px #ced3dacc;
    opacity: 1;
  }

  .innovation-carousel-container {
    overflow: hidden;
    background-color: transparent;
  }

  .innovation-indicators-container {
    display: flex;
    gap: 5px;
    align-self: center;

    .indicator-active {
      background-color: #0642b5;
    }

    .indicator-inactive {
      background-color: #dbdbdb;
    }

    .indicator {
      height: 8px;
      width: 8px;
      border-radius: 100%;
    }
  }
}

.nodata-found-section {
  align-self: center;
  .nodata-found-img-area {
    margin-left: 12px;
  }

  .no-data-found-heading {
    line-height: 0;
    text-align: center;
  }

  .no-data-found-sub-heading {
    line-height: 19.6px;
    text-align: center;
  }
}

.dropdown-btn-reuse {
  border-radius: 30px;
  border: 1px solid #c7c7c7;
  height: 40px;

  .drop-down-arrow {
    width: 10px;
    height: 10px;
  }
}

.dropdown-btn-reuse:hover {
  border: 1px solid #c7c7c7 !important;
  color: #1f1f1f !important;
}

.dropdown-button-main-wrapper {
  display: flex;
  justify-content: space-between;

  .dropdown-button-sub-div {
    display: flex;
    justify-content: space-between;
    margin: 15px;
    gap: 15px;
  }
}

.overview-wrapper {
  background-color: #fff;
  padding: 24px 20px;
  border-radius: 8px;

  .overview-inner-wrapper {
    display: flex;
    flex-direction: column;
  }

  .overview-card-wrapper {
    background-color: #ededed;
    padding: 12px 12px 12px 16px;
    border-radius: 8px;
    min-width: 250px;
    //    flex: 1;
  }

  .count {
    color: #000;
  }

  .status {
    color: #000;
  }
  .overview-inner-top-section {
    display: flex;
    justify-content: space-between;
  }

  .overview-tab-wrapper {
    margin-bottom: 24px;
  }

  .overview-tab-wrapper > div {
    padding: 6px 16px;
    border-radius: 30px;
  }

  .overview-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
}

.radio-tabs-main {
  p {
    margin-top: 4px;
  }

  .radio-tabs-explore {
    border-radius: 30px 0px 0px 30px;
    height: 40px;
  }

  .radio-tabs-request {
    border-radius: 0px 30px 30px 0px;
    height: 40px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #e6efff;
    border: 1px solid #0642b5;
    color: #0642b5;
  }

  .ant-radio-button-wrapper {
    color: #0a0a0a;
  }
}

.innovation-view-wrapper {
  .innovation-card-wrapper {
    display: flex;
    background-color: #fff;
    padding: 28px 36px;
    border-radius: 8px;
    flex: 1;
    box-shadow: 0px 3px 6px 0px rgba(206, 211, 218, 0.45);
  }

  .innovation-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  .primary-btn {
    background-color: #0642b5;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
  }

  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .desc {
    color: #474747;
    margin: 10px 0;
    flex: 1;
  }

  .foxIcon {
    margin-right: 25px;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    display: flex;
    flex-direction: column;

    .banner-image-section {
      width: 100%;
    }
  }

  .ideas-card {
    max-width: 100%;
  }

  .famebook-card {
    max-width: 100%;
  }
}
