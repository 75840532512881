.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  height: 40px;
  padding: 10px 0;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  line-height: 16.8px;
}
.whole-section {
  position: relative;
}
.mainContainer {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.onboardingcarousel-img {
  height: 270px;
  width: 287px;
  display: flex;
  justify-content: center;
  margin: 10px auto 25px;
}
.onboardingcarousel img {
  width: 100%;
  height: 100%;
}
.onboardingcarousel-container .carousel-container {
  box-shadow: none;
}
.onboard-modal .ant-modal {
  width: 500px !important;
}
.onboardingcarousel-container .slick-arrow {
  top: unset;
  bottom: 0;
}
.onboard-modal .ant-modal-content {
  height: 536px;
}
.onboard-modal .ant-carousel,
.onboard-modal .onboardingcarousel-container,
.onboard-modal .ant-modal-body,
.onboard-modal .carousel-container,
.onboardingcarousel {
  height: 100% !important;
}
.onboard-modal .ant-carousel .slick-dots li button {
  background: none;
}
.onboard-modal .ant-carousel .slick-dots li {
  width: 8px;
  height: 8px;
  background: #bfbfbf;
  border-radius: 25px;
  opacity: 1;
}
.onboard-modal .ant-carousel .slick-dots li.slick-active {
  background: #0642b5;
  width: 24px;
}
.onboard-modal .ant-carousel .slick-dots {
  bottom: unset;
  top: 58%;
}
.onboardingcarousel h3 {
  text-align: center;
  margin: 55px 0 12px;
  color: #1f1f1f;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  line-height: 24px;
}
.onboardingcarousel p {
  color: #454545;
  text-align: center;
  margin: 0;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
}
.onboardingcarousel-container .slick-next {
  display: flex;
  width: 120px;
  padding: 9px 16px 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin-right: 32px;
  opacity: 1;
}
.onboardingcarousel-container .slick-prev {
  color: #454545;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  margin-left: 32px;
  width: 100px;
  padding: 9px 16px 8px 16px;
  opacity: 1;
}
.onboardingcarousel-container .slick-next:hover {
  background: #0642b5;
  color: #fff;
}
.onboardingcarousel-container .slick-prev:hover {
  color: #454545;
}
.quick-tour-image {
  width: 270px;
  height: 164.765px;
  display: flex;
  margin: 0 auto 10px;
}
.quick-tour-image img {
  width: 100%;
  height: 100%;
}
.quick-tour-heading {
  margin: 0 0 10px;
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  line-height: 24px;
}
.quick-tour-para {
  color: #1f1f1f;
  text-align: center;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 16px;
  width: 65%;
}
.quick-tour-Button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quick-tour-Button button:first-child {
  color: #0642b5;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  border: 0;
  background: transparent;
}
.quick-tour-Button button:last-child {
  background: #0642b5;
  color: #fff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  line-height: 19.6px;
  height: 40px;
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  box-shadow: none;
}
.quick-tour-Button button:last-child:hover {
  background: #0642b5;
  color: #fff;
  border: 0;
  box-shadow: none;
}
.quicktour-modal .ant-modal {
  width: 440px !important;
}
.popover-overlay .main-header {
  display: none;
}
.onboardingcarousel-container .ant-carousel .slick-prev::after,
.onboardingcarousel-container .ant-carousel .slick-next::after {
  content: none !important;
}
.onboardingcarousel-container .slick-dots li button:before,
.onboardingcarousel-container .slick-dots li button:after {
  opacity: 0 !important;
}
.onboardingcarousel-container .ant-carousel .slick-dots li button:hover,
.onboardingcarousel-container .ant-carousel .slick-dots li button:focus {
  opacity: 0;
}
.onboardingcarousel-container .ant-carousel .slick-next::before {
  content: none !important;
}
