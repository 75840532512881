.timesheet-box-otr {
  width: 25%;
}
.timesheet-box-container {
  display: flex;
  border-radius: 8px;
  background-color: #ffffff;
  /* width: 25%; */
}
.timesheet-box {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.timesheet-box-container .timesheet-box .row-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.timesheet-box-container .row-view .text-count {
  color: #0a0a0a;
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
}
.timesheet-box-container .text-title {
  color: #5c5c5c;
  font-size: 16px;
  font-family: "ProximaNova-Regular";
  padding-left: 20px;
  padding-bottom: 15px;
}
