.delegate-header span.ant-input-group-addon {
  background: transparent;
  border: 1px solid #c7c7c7;
  border-right: 0px solid;
  padding-right: 0;
  border-radius: 8px;
}
.delegate-header .delegate_search input.ant-input.ant-input-outlined {
  border-left: 0px solid;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 40px;
  border-color: #c7c7c7;
}
.delegate-header
  .delegate_search
  input.ant-input.ant-input-outlined::placeholder {
  color: #6f6f6f;
}
.delegate-header button.ant-input-search-button {
  display: none;
}
span.ant-input-search.delegate_search {
  margin: 12px 0 0;
}
.preview-modal img.SearchMember {
  width: 100px;
}
.delegate-main-view span {
  width: 100%;
  text-align: center;
  color: #0a0a0a;
  font-size: 18px;
  font-family: "Proximanova-Semibold";
}
.preview-modal.delegate-modal .ant-modal-body {
  height: 330px;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  margin: 0 0 60px;
}
.delegate-main-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -50px 0 0;
}
.preview-modal.delegate-modal .ant-modal-content .main-view {
  width: 100%;
}
.preview-modal.delegate-modal
  .ant-modal-body
  .delegate-main-view.delegate_no-member {
  align-items: center;
  height: 390px;
}
.delegate_no-member-inn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ant-radio-group.delegate_radio-btn-inn label.ant-radio-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e2e4;
  margin: 0 0 20px;
  padding: 0 10px 20px 0;
}
.ant-radio-group.delegate_radio-btn-inn label.ant-radio-wrapper:last-child {
  border-bottom: 0px solid;
  padding-bottom: 0;
}
.ant-radio-group.delegate_radio-btn-inn span.ant-radio.ant-wave-target {
  order: 2;
}
.ant-radio-group.delegate_radio-btn-inn
  span.ant-radio.ant-wave-target
  span.ant-radio-inner {
  border-color: #8f8f8f;
}
.ant-radio-group.delegate_radio-btn-inn
  span.ant-radio.ant-wave-target.ant-radio-checked
  span.ant-radio-inner {
  background-color: #0642b5;
  border-color: #0642b5;
}
span.member_title {
  display: flex;
  align-items: center;
}
span.member_title .ant-avatar-group {
  margin-right: 15px;
}
span.member_title .ant-avatar-group span.ant-avatar {
  width: 48px;
  height: 48px;
}
.member_title-otr .member_title_text {
  font-size: 16px;
  color: #000;
  font-family: "Proximanova-Semibold";
}
.member_title-otr .member_title_designation {
  color: #595959;
  width: 350px;
  font-size: 14px;
  font-family: "Proximanova-Regular";
}
.preview-modal.delegate-modal .ant-modal-body .ant-radio-group {
  overflow: hidden;
  overflow-y: auto;
}
.delegate_otr {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.ant-modal.preview-modal.delegate_prev-otr .preview_buttons_lm {
  justify-content: flex-end;
}
.ant-modal.preview-modal.delegate_prev-otr .preview_conform-btns {
  gap: 25px;
}
.ant-modal.preview-modal.delegate_prev-otr .middle-days-view {
  padding: 3px 12px;
}
.ant-modal.preview-modal.delegate_prev-otr .date-range-view {
  width: 510px;
  padding: 10px 20px;
}
.timesheet_status_lm.Pending,
.timesheet_status_lm.Pending_Cancellation {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fdf6e7;
  width: fit-content;
  color: #a5780c;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
}
