.rating-calculated-title {
  padding: 0 0 16px 0;
}
.calculated-title {
  font-size: 16px;
  line-height: 22.4px;
  font-family: "ProximaNova-Semibold";
  color: #000000;
}
.calculated-rating-btn {
  background-color: #d1e4f6;
  border-radius: 8px;
  padding: 6px 16px 6px 16px;
  border: none;
  width: 90px;
  height: 35px;
  margin-left: 15px;
  font-size: 16px;
  line-height: 22.4px;
  font-family: "ProximaNova-Semibold";
  color: #1976d2;
}
.compotency-card {
  width: 100%;
}
