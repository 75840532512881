.goal-evalution-head h1 {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  margin: 0 0 12px;
  font-family: "ProximaNova-Bold";
}

.goal-evalution-head h1 span {
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  padding-left: 12px;
}

.goal-evalution-title h2 {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
}

.goal-evalution-cont span.title {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  font-family: "ProximaNova-Semibold";
  margin-right: 32px;
  width: 82px;
  float: left;
}

.goal-evalution-cont span {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
}

.goal-evalution-list {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 24px 0 0;
}

.goal-evalution-cont {
  margin: 0 0 16px;
  /* flex: 0 0 48%; */
  flex-grow: 1;
  flex-basis: 50%;
}

.goal-evalution-cont span p {
  margin: 0;
}

.ant-card.card_list-evalution {
  margin: 0 0 16px;
}

.text-editor-section.ant-card.card_list-evalution .ql-toolbar {
  top: 16px;
  right: 25px;
}

.card_list-evalution span.evaluation_badge_card {
  position: absolute;
  left: 0;
  top: 0;
  background: #d1e4f6;
  padding: 6px 16px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
}

.ant-card.card_list-evalution .ant-card-body {
  padding: 48px 24px 24px !important;
  position: relative;
  display: table;
  width: 100%;
}

nav.ant-breadcrumb ol {
  align-items: center;
}

nav.ant-breadcrumb li span.ant-breadcrumb-link .link-highlight {
  font-size: 14px;
  line-height: 20px;
  color: #1976d2;
  font-family: "ProximaNova-Regular";
}

nav.ant-breadcrumb li span.ant-breadcrumb-link {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-family: "ProximaNova-Regular";
}

.goal-evalution-eval .layoutContainer {
  margin: 86px 55px 140px;
}

.ant-card.card_list-evalution.field_label .ant-card-body {
  padding: 24px 24px 20px !important;
  width: 100%;
}

.ant-card.card_list-evalution.field_label .btn-holder {
  display: none;
}

.ant-card.card_list-evalution.field_label form.ant-form .ant-form-item {
  margin: 0;
}

.ant-card.card_list-evalution.field_label .goal-evalution-title h3 {
  line-height: 24px;
  color: #000;
  margin: 0;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-weight: 400;
}

.ant-card.card_list-evalution textarea::placeholder {
  font-family: "ProximaNova-Regular";
}

.goal-evalution-eval button.ant-btn {
  float: right;
  width: 100px;
  font-size: 14px;
  line-height: 20px;
}
.goal-evalution-eval .ant-btn-primary {
  background-color: #0642b5 !important;
}
.goal-evalution-eval .ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.goal-evalution-eval .competency-heading-section {
  margin: 17px 0;
}

.goal-evalution-eval .layoutContainer .slick-prev {
  top: 40%;
}

.goal-evalution-head
  .compotency-inner-section
  .ant-form-item-row
  textarea.ant-input {
  padding: 14px 12px 14px 16px;
}

.goal-evalution-head
  .compotency-inner-section
  .ant-form-item-row
  textarea.ant-input::placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  font-family: "ProximaNova-Regular";
}

.text-editor .ql-editor::before {
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  font-family: "ProximaNova-Regular";
}

.goal-evalution-head
  .card_list-evalution.field_label
  textarea.ant-input::placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  font-family: "ProximaNova-Regular";
}

.goal-evalution-head .goal-evalution-title {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 6px;
}

.goal-evalution-head .goal-evalution-title .texteditor-section {
  position: relative;
  top: -1px;
}

.goal-evalution-eval button.ant-btn.submit-fill-btn {
  margin-left: 16px;
}

.goal_outline-btn-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  position: fixed;
  bottom: 0px;
  background: #fff;
  width: 100%;
  left: 0;
  padding: 16px 60px;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.04),
    0px 0px 8px rgba(20, 20, 20, 0.08);
}

.goal_fill-inner-btn-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goal-evalution-eval button.ant-btn.cancel-outline-btn {
  width: 148px;
  font-size: 16px;
  line-height: 22px;
}

.goal-evalution-head {
  margin: 0 0 24px;
}

.goal-evalution-eval .texteditor-section button.ant-btn {
  width: auto;
  padding: 4px 15px;
}

.goal-evalution-head-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.goal-evalution-head-title .add-milestone-btn {
  position: relative;
  top: auto;
  right: auto;
}

.goal-evalution-head-title .add-milestone-btn button.outlineBtnStyles {
  width: auto;
  padding: 0;
  border: 0px solid;
  background: transparent;
  margin: 0;
  height: auto;
  box-shadow: none;
}

.goal-evalution-head-title .add-milestone-btn button.outlineBtnStyles:focus {
  box-shadow: none;
}

.goal-evalution-head-title .add-milestone-btn button.outlineBtnStyles div {
  box-shadow: none;
  outline: none;
  outline-offset: 0;
}

.goal-evalution-head-title .add-milestone-btn button.outlineBtnStyles span {
  color: #095dff;
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNova-Semibold";
}

.ant-card.card_list-evalution .ant-card-body .card_edit-sec {
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.ant-card.card_list-evalution .ant-card-body .card_edit-sec img {
  width: 24px;
  cursor: pointer;
}

.layoutContainer .steps {
  overflow-x: clip;
  white-space: nowrap;
}

.layoutContainer .steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0642b5 !important;
  border-color: #0642b5 !important;
}
.layoutContainer .steps .ant-steps-item-process .ant-steps-item-icon {
  border-color: #0642b5 !important;
}
.layoutContainer .steps .ant-steps-item-process .ant-steps-item-icon::before {
  background-color: #0642b5 !important;
}
:where(.css-dev-only-do-not-override-1r287do).ant-steps.ant-steps-label-vertical
  .ant-steps-item-tail {
  padding: 0px;
}
.goal-evalution-title .goal-cascade {
  font-family: "ProximaNova-SemiBold";
  font-size: 12;
  font-weight: 600;
  color: #eb5816;
  background-color: #fdefe8;
  border-radius: 16px;
  padding: 5px 15px;
}
.goal-evalution-title .goal-contributed{
  font-family: "ProximaNova-SemiBold";
  font-size: 12;
  font-weight: 600;
  color: #B57B06;
  background-color: #FFF7E6;
  border-radius: 16px;
  padding: 5px 15px; 
}
.goal-evalution-eval .badge_card_category {
  background-color: #d1e4f6;
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; /* 140% */
  padding: 6px 16px;
  border-radius: 4px 0px;
  position: absolute;
  top: 0;
  left: 0;
  /* position: absolute; */
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.delete-goal {
  display: block;
  margin: 0 auto 20px;
}
