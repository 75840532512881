.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-inline-start: 0px !important;
}

.anticon-search {
  color: #000000 !important;
}

.Seperation-page-title {
  margin-top: 40px;
  font-family: "ProximaNova-Bold";
  color: #000;
  font-size: 32px;
  line-height: 28.8px;
}

.pagination-styles {
  background-color: #ffffff;
}

tbody tr td {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  color: #2b3348;
  line-height: 19.6px;
}

thead tr th {
  background: #fff !important;
}

.btn-fonts {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  line-height: 19.6px;
  border: 0;
  box-shadow: none;
}

.pagination-bg {
  background-color: #ffffff;
  padding: 5px;
}

.tabs-styles {
  /* margin-top: 40px; */
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  padding: 0;
}

.tabs-styles div:first-child {
  margin-bottom: 0;
}

/* .action-btn{
background-color: #D1E4F6;
} */

.secondary-avatar {
  font-size: 10px;
  margin: 0;
  font-family: "ProximaNova-Regular";
  color: #95979f;
}

.radio_container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.filter_clear_apply {
  padding: 5px;
  border-top: 1px solid #f0f0f0;
  width: 220px;
}

.clear-btn {
  width: 60px;
  left: 7px;
}

.name_searchbar {
  padding: 5px;
}

.filter_apply_btn {
  margin-left: 130px;
}

.employeename-style {
  font-family: "ProximaNova-Bold";
}

.custom-avatar {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.landing-checkbox-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  padding: 5px;
}

.landing-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  padding: 5px;
}

.hide-filter-icon .ant-table-filter-trigger {
  display: none;
}

.hide-filter-sorter .ant-table-column-sorter {
  display: none;
}

.hide-filter-sorter .ant-table-filter-column {
  cursor: none;
  pointer-events: none;
}

.filterBlue-svg path {
  fill: blue;
}

.parentdiv {
  display: flex;
}

.childdiv {
  display: flex;
  flex-direction: column;
}

.ant-tabs-tab {
  background: none !important;
  border: none !important;
}

:where(.css-dev-only-do-not-override-1fviqcj).ant-input-affix-wrapper
  > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none !important;
  border-radius: 0;
  outline: none;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #000 !important;
}

.ant-tabs-tab-active {
  border: 1px solid #b0b3ba !important;
  border-bottom: 0 !important;
  background-color: #f3f8ff !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important;
  font-family: "ProximaNova-Semibold";
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #b0b3ba !important;
}

table {
  border-color: #b0b3ba;
  border-style: solid;
  border-width: 0 1px 1px 1px;
}

table thead tr th {
  border-bottom: 2px solid #b0b3ba !important;
}

table tbody tr td {
  border-bottom: 1px solid #b0b3ba !important;
}

table {
  border-radius: 0 !important;
}

.ant-table-column-sorter {
  color: black !important;
}

.no_matched_records {
  font-family: "ProximaNova-Regular";
}

.search-card {
  width: 260px;
  padding: 8px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.filterWrapper {
  overflow-y: auto;
  min-height: 48px;
  max-height: 200px;
  margin-top: 3px;
}

.ant-table-column-sorter {
  display: none;
}

.table_title_sorticon {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b0b3ba;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b3ba;
  border-radius: 5px;
}

.unauthorised_user_msg {
  margin-top: 140px;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.8px;
}

.svg_disabled {
  cursor: not-allowed !important;
}

.no_data .ant-table-filter-column {
  pointer-events: none;
  cursor: no-drop !important;
}
.ant-table-empty .ant-table-column-has-sorters {
  cursor: not-allowed !important;
}
.tabs-styles .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: "ProximaNova-Regular";
}
.separation-module-section {
  margin: 145px 55px 80px;
}
