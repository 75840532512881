.smart-goals-section {
  margin: 30px 60px 60px;
  position: relative;
  margin-top: 140px;
}
.smart-goals-section .ant-alert {
  padding: 10px 16px;
  background: #e6efff;
  border-color: #e6efff;
  border-radius: 4px;
}
.smart-goals-section .my-goal-smart-text {
  color: #0a0a0a;
  font-family: "ProximaNova-Semibold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  margin-top: 16px;
}
.smart-goals-section .ant-alert-message {
  color: #1976d2;
  font-size: 14px;
  line-height: 19.6px;
  font-family: "ProximaNova-Semibold";
}
.smart-goals-section .performance-header {
  margin: 0;
}
.smart-goals-section .performance-header h1 {
  margin: 0;
}
/* .smart-goals-section .business .ant-table-content table tbody tr td {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-collapse: collapse;
  border-right: 0 !important;
} */
.table-action {
  display: flex;
  justify-content: center;
}

.view-goal.smart-goal_preview {
  margin-top: 130px;
}
.smart-goal_preview .preview-main-title {
  font-family: ProximaNova-Bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.smart-goal_preview .row-section h5 {
  margin: 0;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22px;
  color: #000;
}
.smart-goal_preview .row-section p {
  margin: 5px 0 0;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  white-space: pre-wrap;
}
.smart-goal_preview .empty-milestone span {
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  font-family: "ProximaNova-Regular";
}
.smart-goals-section .expand {
  display: none;
}
