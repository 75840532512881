.welcome_scr-otr ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}

.welcome_scr-otr ul li {
  width: calc((100% - 26px * 3) / 4);
  margin-right: 26px;
  float: left;
  margin-bottom: 24px;
}

.welcome_scr-otr ul li:nth-child(4n) {
  margin-right: 0;
}

.welcome_scr-otr ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome_scr-otr ul li .lazy-load-image-loaded {
  height: 222px;
  width: 162px;
}

.welcome_scr-otr ul li a {
  display: flex;
}

/* welcome detail */
.card-otr-sec {
  display: table;
  margin: 100px 160px 30px;
}

.card-otr-sec.book-intro-top {
  margin: 170px 160px 30px;
}
.card-otr-sec .card-sec-inn {
  display: flex;
  background: #fff;
  padding: 24px;
}

/* .card-sec-inn img {
  width: 144px;
  margin: 0 32px 0 0;
} */

.card-cont-inn h1 {
  margin: 0 0 6px;
  font-size: 24px;
  line-height: 28px;
  color: #000;
  font-family: "ProximaNova-Semibold";
}

.card-cont-inn span {
  color: #141414;
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Semibold";
}

.card-cont-inn p.sub-txt {
  color: #141414;
  font-size: 16px;
  line-height: 24px;
  font-family: "ProximaNova-Regular";
}

.card-cont-inn p {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-family: "ProximaNova-Regular";
}

.foot_icons-btn {
  background: #fff;
  border-top: 1px solid #e9eaeb;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
}

.foot_icons-btn button.ant-btn {
  border: 0px solid;
  padding: 0;
}

.foot_icons-btn .read_btn button.ant-btn {
  background: #0642b5;
  padding: 5px 16px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Regular";
}

.foot_icons-btn .read_btn button.ant-btn.cont_read {
  display: none;
}

/*----INDEX POPUP----*/
.index-scroll {
  overflow-y: auto;
  /* height: 385px; */ /* Affecting all the places where modal is used  */
}

.page-index-popup {
  width: 400px !important;
}

.page-index-popup .ant-modal-content {
  height: 450px;
  padding: 0;
}

.index-section .index-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 0 25px 0 16px;
}

.index-popup-heading {
  color: #000;
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 16px 12px;
}

.page-index-popup button.ant-modal-close svg {
  width: 18px;
  color: #000;
  height: 18px;
}

.popup-divider {
  height: 1px;
  width: 100%;
  background: #e9eaeb;
  margin-bottom: 12px;
}

.page-index-popup .button-section {
  display: none;
}

.index-link {
  color: #141414;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 24px;
  transition: font-family 0.5s ease;
  transition: color 0.5s ease;
}

.index-link:hover {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  transition: font-family 0.5s ease;
  transition: color 0.5s ease;
}

.ebooks-image {
  line-height: 0 !important;
  /* background: none !important; */
}
.ebooks-shadow-removal {
  background: none !important;
}
.index-active .index-link {
  color: #0642b5;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  line-height: 22.4px;
  transition: font-family 0.5s ease;
  transition: color 0.5s ease;
}

.ebooks_links {
  height: 222px;
  display: flex;
}

.ebooks_links .lazy-load-image-background {
  height: 100%;
}
.ebooks-image-container {
  height: 200px;
  width: 144px;
  margin-right: 20px;
}
.ebooks-image-container .ant-skeleton,
.ebooks-image-container .ant-skeleton-image,
.ebooks-image-container .lazy-load-image-loaded,
.ebooks-image-container .lazy-load-image-loaded > img {
  width: 100% !important;
  height: 100% !important;
}

.card-cont-inn {
  width: calc(100% - 200px);
}
