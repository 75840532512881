.ant-picker-body {
  padding: 9px 11px !important;
}

.ant-picker-panel-container {
  box-shadow: none !important;
}
.container-selected .custom-selected-date {
  background-color: rgb(9, 93, 255) !important;
  color: rgb(255, 255, 255) !important;
}
.container-not-selected .default-color {
  background: transparent !important;
  color: black !important;
}

.calendar-container {
  .ant-picker-input {
    display: none !important;
  }
}

.search-lwd {
  width: 290px;
  padding: 8px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .filter_apply_btn {
    margin-left: 168px;
  }
}

.ant-picker-footer {
  display: none !important;
}

.calendar-container {
  height: 360px !important;
  thead {
    border-bottom: none !important;
  }
}

.ant-picker-header {
  border-bottom: none !important;
}

.ant-picker-content {
  border: none !important;
}

/* .ant-input-disabled {
  background-color: white !important;
  color: black !important;
} */
