.leader-board-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 700px;
  overflow-y: scroll;
  position: relative;
}
.leader-board-section::-webkit-scrollbar {
  width: 4px;
  background: #fff;
}
.leader-board-section::-webkit-scrollbar-thumb {
  background-color: #aeaeae;
}
.leader-board-section::-webkit-scrollbar-track {
  display: none;
}
.non-user-card:hover {
  background: #e6efff;
}
.selected-card {
  border: 1px solid #0642b5;
  background: #e6efff !important;
}

.leaderBoardCardContainer {
  margin: 0px 16px 0px 22px;
  /* height: 48px; */
  border-radius: 8px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 40px;
  cursor: pointer;
  gap: 12px;
  width: -webkit-fill-available;
}
.leaderBoardCardContainer .serial-num {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100px;
  text-align: left;
}
.leaderBoardCardContainer .user-name {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
}
.leaderBoardCardContainer .user-name-text {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
}
.leaderBoardCardContainer .avatar-container {
  position: relative;
}
.leaderBoardCardContainer .country-flag {
  position: absolute;
  height: 11px;
  width: 11px;
  bottom: 3px;
  left: 30px;
}

.leaderBoardCardContainer .club-data {
  display: flex;
  gap: 4px;
  align-items: center;
}
.leaderBoardCardContainer .club-data-wrapper {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.leaderBoardCardContainer .club-name {
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}
.leaderBoardCardContainer .club-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.leaderBoardCardContainer .points-container {
  display: flex;
  gap: 4px;
  display: flex;
  width: 70px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  background: #fff;
  align-items: center;
}

.leaderBoardCardContainer .point-text {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}
.leaderBoardCardContainer .point-icon {
  height: 16px;
  width: 16px;
  object-fit: contain;
}

.user-card-container-bottom {
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 8px 0px;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
  align-self: baseline;
}
.user-card-container-top {
  background-color: green;
  position: sticky;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  top: 0;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
  padding-bottom: 12px;
}

/* never */

.leaderboard_header {
  display: inline-flex;
  justify-content: space-between;
  padding: 0px 16px 12px 5px;
  width: 100%;
  height: 50px;
}
.leaderboard_header .leaderboard_title {
  display: flex;
  width: 140px;
  height: 31px;
  padding: 0px;
  margin: 0px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: "Proximanova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px; /* 140% */
}
.leaderboard_header .button {
  padding: 5px 0px 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: #fff;
  background: #fff;
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
}
.leadertile_mega {
  padding: 18px 6px 5px 24px;
  width: 99%;
}
.leader_game {
  height: auto;
  background: #fff;
}
.user_tile {
  display: inline-flex;
  align-items: center;
  padding: 2.12px 16px 2.12px 40px;
  margin-bottom: 12px;
  width: 96%;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: space-between;
  background: #f5f5f5;
}
.leadertile_mega .user_tile.selected {
  border: 1px solid #095dff;
  background: #e6efff;
}
.leadertile_mega .user_tile:hover {
  background: #e6efff;
}
.leadertile_mega .current_user_tile {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.12px 16px 2.12px 40px;
  margin-bottom: 12px;
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(
    343deg,
    #1e1e1e -13.23%,
    #1e1e1e 48.19%,
    #1e1e1e 109.61%
  );
}
.leadertile_mega .current_user_tile.Ruby {
  background: linear-gradient(86deg, #520000 2.98%, #cd0000 97.02%);
}
.current_user_tile.Jade {
  background: linear-gradient(86deg, #031 2.98%, #00b512 97.02%);
}
.current_user_tile.Amber {
  background: linear-gradient(86deg, #622400 2.98%, #d8902e 97.02%);
}
.current_user_tile.Turquiose {
  background: linear-gradient(86deg, #004969 2.98%, #00b9e8 97.02%);
}
.current_user_tile.Sapphire {
  background: linear-gradient(86deg, #00104a 2.98%, #0069d2 97.02%);
}
.leadertile_mega .current_user_tile:hover {
  box-shadow: 2px 2px 4px 2px rgba(1, 1, 1, 0.1);
  border: 1px solid #484444;
}
.leadertile_mega .rank_display {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  width: 11.49px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.leadertile_mega .current_rank_display {
  color: #fff;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  width: 11.49px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.leadertile_mega .lead_user_display {
  display: flex;
  padding-left: 119px;
  justify-content: center;
  align-items: center;
}
.leadertile_mega .username_lead {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  width: 150px;
  font-weight: 600;
  line-height: 19.6px !important;
  text-overflow: ellipsis;
}
.leadertile_mega .current_username_lead {
  color: #fff;
  font-family: "ProximaNova-Semibold";
  width: 142px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 19.6px !important;
}
.user_profile_image {
  position: relative;
}
.profile_game {
  width: 31.368px;
  height: 31.765px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  background: #fff;
  border: solid #d5d5d5;
  margin-right: 13.54px;
}
.profile_game_avatar {
  width: 31.368px;
  height: 31.765px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  background: #fff;
  border: solid #d5d5d5;
  margin-right: 13.54px;
}
.profile_game_avatar .ant-avatar-group .ant-avatar {
  position: relative;
  left: -1px;
  top: -1px;
  width: 28.368px;
  height: 28.765px !important;
}
.profile_game_avatar .ant-avatar-group .ant-avatar .ant-avatar-string {
  font-size: 14px;
}
.user_flag {
  position: absolute;
  width: 11.8px;
  height: 11.8px;
  top: 20px;
  left: 18px;
}
.points_display {
  color: #1f1f1f;
  display: block;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  left: 0px;
  justify-content: center;
  line-height: 19.6px;
  display: flex;
  width: 70px;
  height: 32px;
  padding: 8px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.current_points_display {
  color: #fff;
  font-family: "Proximanova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
  display: flex;
  width: 70px;
  height: 32px;
  margin-left: 8px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #f5f5f5;
}
.leaderboard_coin {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.userclub_leader {
  display: flex;
  justify-content: flex-start;
  width: 203px;
}
.club_display {
  color: #1f1f1f;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-family: "ProximaNova-Regular";
  font-weight: 400;
  line-height: 19.6px; /* 140% */
}
.current_club_display {
  color: #fff;
  display: flex;
  align-items: center;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  padding: 3px 8px 3px 8px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  font-weight: 400;
  line-height: 19.6px; /* 140% */
  border-radius: 32px;
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
}
.user_clubimg {
  margin-left: 4px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.gamification-checkinEmployee {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom: 16px;
  display: none !important;
}
.gamification-checkinEmployee .all-commentsText:nth-child(2) {
  display: block !important;
}
.gamification-checkinEmployee .all-commentsText:first-child {
  display: block !important;
}
.centerItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 227px;
}
.gamification-checkin-error-main {
  height: 80px;
  width: 80px;
  margin-bottom: 8px;
}
.something-went-wrong-text-gamification-checkin {
  color: #454545;
  text-align: center;
  font-family: "ProximaNova-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px; /* 140% */
}
.something-went-wrong-sub-header {
  color: #8c8c8c;
  text-align: center;
  margin-top: 4px;
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

.user-card-background {
  background-color: #00b512;
}
/* .z-index-outer-div {
    position: relative;
    z-index: 999;
  } */

/* Special Club Styles */
.special-card-font {
  color: #fff !important;
}
.club-container-sp {
  display: flex;
  padding: 3px 8px 3px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
}
.border-color-special {
  border: 1px solid #f5f5f5 !important;
  background-color: transparent !important;
}
.no-club-background {
  background: url("../../../../../themes/assets/images/gamification/tab_background/noclub-bg.png");
}
.ruby-background {
  /* background: linear-gradient(86deg, #4e0e0e 2.98%, #cd0000 97.02%) !important; */
  background: url("../../../../../themes/assets/images/gamification/tab_background/ruby-bg.png");
}
.amber-background {
  /* background: linear-gradient(86deg, #622400 2.98%, #d8902e 97.02%) !important; */
  background: url("../../../../../themes/assets/images/gamification/tab_background/amber-bg.png");
}
.jade-background {
  /* background: linear-gradient(86deg, #031 2.98%, #00b512 97.02%) !important; */
  background: url("../../../../../themes/assets/images/gamification/tab_background/jade-bg.png");
}
.turquiose-background {
  /* background: linear-gradient(86deg, #004969 2.98%, #00b9e8 97.02%) !important; */
  background: url("../../../../../themes/assets/images/gamification/tab_background/turquoise-bg.png");
}
.sapphire-background {
  /* background: linear-gradient(86deg, #00104a 2.98%, #0069d2 97.02%) !important; */
  background: url("../../../../../themes/assets/images/gamification/tab_background/sapphire-bg.png");
}

.leader-board-section .card-list {
  margin-top: 12px;
}
.identifying-end {
  /* height: 20px; */
  flex: 1;
}
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 500px;
}
.not-found-container .not-found-img {
  height: 120px;
  width: 120px;
  object-fit: contain;
}
.not-found-container .no-matching-detail {
  color: #000;
  text-align: center;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
}
