.submitted-goal-asp-form {
  width: 100% !important;
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 1px;
}
.submitted-goal-asp-form .submitted-goal-header {
  /* height: 60px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.submitted-goal-asp-form .goal-type {
  display: inline-flex;
  height: 32px;
  padding: 6px 16px;
  align-items: flex-end;
  flex-shrink: 0;
  border-radius: 4px 0px 8px 0px;
  background: #d1e4f6;
  color: #1976d2;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}
.submitted-goal-asp-form .toogle-goal-details {
  margin-top: 18px;
  margin-right: 16px;
  cursor: pointer;
  display: flex;
}
.submitted-goal-asp-form .goal-title {
  color: #1f1f1f;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  padding: 16px 24px;
}
/* feedbackCard */

.feedback-asp-view {
  margin: 0 24px;
  border-radius: 4px;
  background: #f5f5f5;
  margin-bottom: 16px;
  gap: 20px;
  display: flex;
  padding: 24px;
}
.review-comment-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  gap: 16px;
  border-radius: 4px;
  padding: 16px;
  justify-content: space-between;
}
.review-comment-card-signature {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 2px dashed #bfbfbf;
  background-color: #fff;
  gap: 16px;
  border-radius: 4px;
  padding: 16px;
}
.review-comment-card-signed {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  gap: 16px;
  border-radius: 4px;
  padding: 16px;
}
.feedback-asp-view .comment-desc {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px; /* 140% */
}
.feedback-asp-view .signature-pending {
  color: #595959;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
}
.feedback-asp-view .profile-checkin {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.feedback-asp-view .profile-checkin .avatar-container {
  margin-right: 8px;
}
.feedback-asp-view .profilepic-checkin {
  height: 32px;
  width: 32px;
  border-radius: 32px;
  margin-right: 8px;
}
.feedback-asp-view .userData-checkin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feedback-asp-view .name-checkin {
  color: #000;
  font-family: "ProximaNova-Semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
}

.feedback-asp-view .designation-checkin {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.toogle-goal-details .text-toggle {
  color: #095dff;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.6px;
}

.toogle-goal-details .toggle-icon {
  height: 22px;
  width: 28px;
}

/* waiting review card */
.review-comment-card .waiting-asp-image {
  height: 80px;
  width: 80px;
}
.center-contents-waiting-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.review-comment-card .waiting-asp-review-text {
  color: #8c8c8c;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-top: 8px;
}
.goal-evalution-list-asp-waiting {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 24px;
  padding-top: 0px;
}
.submitted-goal-asp-form .goal-type-show-details {
  color: #1f1f1f;
  font-family: "ProximaNova-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: -16px;
}
.submitted-goal-asp-form .title-desc {
  margin-top: 4px;
  font-family: "ProximaNova-Regular";
  line-height: 25px;
  padding-bottom: 0px 
}

/* .custom-rating {
  color: #ffad09 !important;
}
.custom-rating:active {
  color: #fff !important;
} */
.rating-container-launch {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.goal-title-desc {
  color: #000;
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 24px;
  margin-top: -10px;
  margin-bottom: 16px;
}
.rating-calculated-container {
  margin-top: 20px;
}
