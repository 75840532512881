.filterWrapper {
  display: inline-grid;
  padding: 5px;
}

.filterWrapper .ant-checkbox-wrapper:first-child {
  margin-inline-start: 8px;
}

.filterWrapper {
  overflow-y: auto;
  min-height: 48px;
  max-height: 200px;
  margin-top: 3px;
  overflow-x: hidden;
  width: 260px;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b0b3ba;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b3ba;
  border-radius: 5px;
}

.search-card {
  padding: 10px;
  width: 260px;
}

.clear-apply-parent {
  display: flex;
  justify-content: space-between;
}

.landing-radio-group {
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 250px;
}

.no-matched-records {
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
}
