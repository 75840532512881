.calendar {
  width: 75%;
}

.ant-fullcalendar-header {
  width: 70%;
  padding: 30px 16px 30px 0px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.calendarEvent {
  display: flex;
  justify-content: space-between;
}

.additionalEventCard {
  background-color: rgba(248, 239, 214, 1);
  color: rgba(115, 80, 27, 1);
  border-left: 4px solid #ffbe0f;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 93%;
}

.vibeEventCard {
  background-color: rgba(222, 210, 226, 1);
  color: rgba(47, 1, 61, 1);
  border-left: 4px solid #52006a;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 93%;
}

.impactEventCard {
  background-color: rgba(253, 239, 232, 1);
  color: rgba(138, 54, 12, 1);
  border-left: 4px solid #eb5816;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 93%;
}

.learningEvent {
  background-color: rgba(216, 238, 240, 1);
  color: rgba(2, 127, 135, 1);
  border-left: 4px solid #1db9c3;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 93%;
}

.additionlEventCard {
  background-color: rgba(244, 235, 239, 1);
  color: rgba(170, 11, 80, 1);
  border-left: 4px solid #b80d57;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 93%;
}

.eventsDuration {
  padding: 20px 0 20px 0;
  display: flex;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px 30px 40px;
}

.calendarContainer {
  width: 80%;
}

.integratedCalendar {
  font-weight: 700;
  font-size: 32px;
  line-height: 28.8px;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  padding: 24px;
  border-radius: 4px 4px 0 0;
  border: 1px solid #d4d5d9;
}

.rbc-toolbar-label {
  font-size: 20px;
  font-weight: 400;
  font-family: "ProximaNova-Semibold";
}

.rbc-header + .rbc-header {
  border-left: none !important;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
  padding: 15px 0 15px 0;
  text-align: center;
  border-top: none !important;
  border-bottom: 1px solid #d4d5d9 !important;
}

.rbc-header {
  border: none !important;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
  font-size: 16px;
  border-bottom: none;
}

.rbc-off-range-bg {
  background-color: #fafbfc !important;
  color: #b0b3ba !important;
  pointer-events: none !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  /* box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  /* background-color: #e6e6e6; */
  border-color: #adadad;
  border-radius: 4px;
}

.eventsWrapper {
  border-radius: 4px;
  border: 1px solid var(--tonal-palette-secondary-secondary-10, #d4d5d9);
  padding: 24px 0px 10px 24px;
  /* margin-right: 20px; */
  width: 40%;
}

.ant-card-body {
  padding: 15px;
}

.createEventButton {
  background-color: #0642b5 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 4px 16px !important;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.rbc-event {
  background-color: white !important;
}

.rbc-show-more {
  color: #b0b3ba !important;
  padding-top: 7px !important;
}

.EventDisplayCards {
  width: 93%;
  border-radius: 4px;
}

.eventTypeWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
}

.eventType img {
  padding-right: 12px;
}

.eventDescription {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  padding: 5px;
}

.CalendarDate {
  font-size: 16px;
  font-weight: 700;
  padding-right: 10px;
  font-family: "ProximaNova-Semibold";
}

.bookings {
  color: #576c77;
  font-size: 14px;
  font-weight: 400;
  font-family: "ProximaNova-Regular";
}

.invalid-date {
  color: #ccc;
  pointer-events: none;
}

.displayEvents:focus {
  outline: none !important;
}
.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 1px solid #b0b3ba;
  border-bottom: 1px solid #b0b3ba;
  border-right: 1px solid #b0b3ba;
  background-color: white;
}
.calendarContainer {
  width: 700px;
  height: 600px;
}
